import React, { Component } from "react";
import {
  Card,
  CardBody,
  Col,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

//Simple bar
import SimpleBar from "simplebar-react";
import moment from "moment";
class RecentlyActivity extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: false,
    };
  }

  render() {
    return (
      <React.Fragment>
        <Col lg={6}>
          <Card>
            <CardBody>
              {/* <Dropdown
                className="float-right"
                isOpen={this.state.menu}
                toggle={() => this.setState({ menu: !this.state.menu })}
              >
                <DropdownToggle
                  tag="i"
                  className="darrow-none card-drop"
                  aria-expanded="false"
                >
                  <i className="mdi mdi-dots-vertical"></i>
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem href="">Sales Report</DropdownItem>

                  <DropdownItem href="">Export Report</DropdownItem>

                  <DropdownItem href="">Profit</DropdownItem>

                  <DropdownItem href="">Action</DropdownItem>
                </DropdownMenu>
              </Dropdown> */}

              <h4 className="card-title mb-4">Recently Added Videos</h4>

              <SimpleBar style={{ maxHeight: "330px" }}>
                <ul className="list-unstyled activity-wid">
                  {this.props.data.map((item, index) => {
                    return (
                      <li className="activity-list">
                        <div className="activity-icon avatar-xs">
                          <span className="avatar-title bg-soft-primary text-primary rounded-circle">
                            <i className="ri-edit-2-fill"></i>
                          </span>
                        </div>
                        <div>
                          <div>
                            <h5 className="font-size-13">
                              {moment(item.created_on).format("LL")}{" "}
                              <small className="text-muted">
                                {moment(item.created_on).format("hh:mm A")}
                              </small>
                            </h5>
                          </div>

                          <div>
                            <p className="text-muted mb-0">{item.name}</p>
                          </div>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </SimpleBar>
            </CardBody>
          </Card>
        </Col>
      </React.Fragment>
    );
  }
}

export default RecentlyActivity;
