import React, { Component } from "react";
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  FormGroup,
  Label,
  Input,
  CustomInput,
  Button,
  UncontrolledAlert,
} from "reactstrap";
import { Link } from "react-router-dom";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import ReactTags from "react-tag-autocomplete";
import { MDBDataTable } from "mdbreact";
import { reactLocalStorage } from "reactjs-localstorage";
import moment from "moment";
import Dropzone from "react-dropzone";
import BaseInfo from "../ChooseDesign/baseInfo";
import HttpClient from "./../../utils/HttpClient";
import Select from "react-select";
import ProgressBar from "@ramonak/react-progress-bar";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

// import EcomPreview from "./E-ComPreview/index";
class FormElements extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: "News", link: "#" },
        { title: "Add & Manage News", link: "#" },
      ],
      customchk: true,
      toggleSwitch: true,
      // page states
      name: "",
      data: [],
      alert: false,
      message: "",
      type: "",
      result: [],
      edit_enable: false,
      edit_item_id: "",
      image_select: false,
      img_url: "",
      userId: "",
      userDetails: {},
      uploaded_images: [],
      categorycourse: [],

      trailer: "",
      showSelect: true,
      submit: false,
      uploading_trailer: false,
      trailerPlaylists: "",
      progressBarParsent: 0,
      progressBarParsentVideo: 0,
      uploading_movie: false,

      videoUrl: "",
      url: "",
      user_banner: "",
      videoUpload: false,

      priority: "",
      title: "",
      desc: "",
      cat_id: "",
      author: "",
    };
  }

  handleType = (selectedType) => {
    console.log(selectedType);
    this.state.selectedTypevalue = selectedType.value;
    this.state.selectedcourseType.push(selectedType);
    this.state.price = 0;
    this.setState({});
    console.log(this.state.selectedType);
  };

  handleMultiCat = (selected) => {
    console.log(selected);
    this.state.selectedMultiCatvaue = selected.value;
    this.state.selectedMultiCat.push(selected);
    this.setState({});
    console.log(this.state.selectedMultiCat);
  };

  handleMultiFilter = (selected) => {
    console.log(selected);
    this.state.selectedMultiFiltervalue = selected.value;
    this.state.selectedMultiFilter.push(selected);
    this.setState({});
    console.log(this.state.selectedMultiFilter);
  };

  componentDidMount = async () => {
    this.checkUser();
    this.fetchDropdown();
    // this.fetchLecturer();
  };

  fetchDropdown = async () => {
    let resultCat = await HttpClient.requestData("admin/news-category", "GET");
    console.log("resultCat", resultCat);
    if (resultCat && resultCat.status) {
      this.setState({ categorycourse: resultCat.data });
    }
  };


  uploadVideo = async (video, type) => {
    if (type == "Trailer") {
      this.setState({ uploading_trailer: true });
    } else {
      this.setState({ uploading_movie: true });
    }

    let dataSend = { buckate: false };
    let result = await HttpClient.uploadFileRequest(
      "video-upload/video",
      video,
      (data, data1, data2) => {
        console.log("data, data1, data2", data, data1, data2);

        if (type == "Trailer") {
          this.setState({ progressBarParsent: data });
        } else {
          this.setState({ progressBarParsentVideo: data });
        }
      }
    );
    if (result && result.status) {
      if (type == "Trailer") {
        this.setState({
          trailerPlaylists: result.playlists,
          trailer: result.data.url,
          uploading_trailer: false,
        });
      } else {
        this.setState({
          videoUrl: result.playlists,
          url: result.data.url,
          uploading_movie: false,
        });
      }
    }
    console.log("result", result);
  };

  fetchData = async (type) => {
    console.log("call");
    let result = await HttpClient.requestData("admin/news", "GET");
    console.log("call", result);

    if (result && result.status > 0) {
      let data = [];
      let i = 1;
      this.setState({ result: result.data });
      this.state.result.forEach((element, index) => {
        let rows = {
          sl: i,
          created_by: element.created_by ? element.created_by.name + " on " + moment(element.created_on).utcOffset(element.created_on).format('DD/MM/YYYY, HH:mm') : "" + " on " + moment(element.created_on).utcOffset(element.created_on).format('DD/MM/YYYY, HH:mm'),
          updated_by: element.updated_by ? element.updated_by.name + " on " + moment(element.updated_on).utcOffset(element.updated_on).format('DD/MM/YYYY, HH:mm') : "" + "",
          name: element.title,
          user_banner: element.use_banner ? "No" : "Yes",
          img:
            element.img.length > 0 ? (
              <div>
                <img
                  src={element.img[0].preview}
                  alt="images"
                  className="rounded avatar-md card-img"
                />
              </div>
            ) : null,
          //   desc: element.desc,
          author: element.author,
          status: (
            <button
              title="Delete"
              className={
                element.status ? "btn btn-success mr-2" : "btn btn-danger mr-2"
              }
              onClick={() => {
                this.status(element, index);
              }}
            >
              {element.status ? "Active" : "Not Active"}
            </button>
          ),
          review: (
            <Link
              className="btn btn-success mr-2"
              to={"/news-review/" + element.id}
            >
              Review
            </Link>
          ),

          priority: element.priority,
          action: (
            <>
            {this.state.userDetails.sub_admin == true ? null : (
            <button 
              title="Delete"
              className="btn btn-danger mr-2"
              onClick={() => { if (window.confirm('Are you sure you wish to delete this item?')) this.delete(element, index) }}
              >
              <i className="fa fa-trash" />
              </button>
            )}
              <button
                title="Edit"
                className="btn btn-primary"
                onClick={() => {
                  this.edit(element, index);
                  window.scrollTo(0, 0);
                }}
              >
                <i className="fa fa-edit" />
              </button>
            </>
          ),
        };
        i++;
        data.push(rows);
      });
      this.setState({
        data: data,
      });
    } else {
      this.setState({
        data: [],
      });
    }
  };

  delete = async (item, index) => {
    let sendData = {
      id: item._id,
    };
    let result = await HttpClient.requestData(
      "admin/news/delete",
      "POST",
      sendData
    );
    if (result && result.status) {
      let index = this.state.result.indexOf(item);
      if (index > -1) {
        this.state.result.splice(index, 1);
        this.setState({
          alert: true,
          message: "Deleted Successfully",
          type: "success",
        });
        setTimeout(() => {
          this.setState({
            alert: false,
            message: "",
            type: "",
          });
        }, 3000);
        this.fetchData(this.state.selectedType);
      }
    }
  };

  edit = async (item, index) => {
    console.log(item);
    this.setState({
      edit_enable: true,
      title: item.title,
      uploaded_images: item.img,
      edit_item_id: item._id,
      selectedcourseType: item.type,
      desc: item.desc,
      cat_id: item.cat_id,
      user_banner: item.use_banner ? "Yes" : "No",
      author: item.author
      //   priority: item.priority,
    });
    // setTimeout(() => {
    //   this.setState({ showSelect: true });
    // }, 200);
    // this.handleMulti(item.category);
  };

  status = async (item, index) => {
    let sendData = {
      id: item._id,
      status: !item.status,
    };
    let result = await HttpClient.requestData(
      "admin/news/status",
      "POST",
      sendData
    );
    if (result && result.status) {
      this.state.result.splice(index, 1);
      this.setState({
        alert: true,
        message: "Status Updated Succesfully",
        type: "success",
      });
      setTimeout(() => {
        this.setState({
          alert: false,
          message: "",
          type: "",
        });
      }, 3000);
      this.fetchData();
    }
  };

  submit = async () => {
    if (this.state.title != "" && this.state.cat_id != '') {
      if (this.state.uploaded_images.length > 0) {
        this.setState({ loading: true });

        let data = null;
        let result = null;
        if (this.state.edit_enable) {
          data = {
            edit_id: this.state.edit_item_id,
            title: this.state.title,
            cat_id: Number(this.state.cat_id),
            desc: this.state.desc,
            img: this.state.uploaded_images,
            author: this.state.author,
            use_banner: this.state.user_banner == "No" ? false : true,
            updated_by: this.state.userId,
            // priority: Number(this.state.priority),
          };
          console.log(data);
          result = await HttpClient.requestData(
            "admin/news/update",
            "POST",
            data
          );
        } else {
          data = {
            title: this.state.title,
            cat_id: Number(this.state.cat_id),
            desc: this.state.desc,
            img: this.state.uploaded_images,
            author: this.state.author,
            use_banner: this.state.user_banner == "No" ? false : true,
            created_by: this.state.userId,
          };
          console.log(data);
          result = await HttpClient.requestData("admin/news", "POST", data);
        }

        // return false;

        if (result && result.status) {
          this.setState({
            alert: true,
            message: this.state.edit_enable
              ? "Updated Successfully"
              : "Added Successfully",
            type: "success",
            title: "",
            cat_id: "",
            desc: "",
            uploaded_images: [],
            images: [],
            selectedFiles: [],
            edit_enable: false,
            user_banner: "",
            uploadVideo: false,
            url: "",
            trailer: "",

            priority: "",
            author: ''
          });
          // this.fileInputTrl.value = "";
          // this.fileInputVideo.value = "";
          this.fetchData();
        } else {
          this.setState({
            alert: true,
            message:
              typeof result.error == "string"
                ? result.error
                : result.error[0].msg,
            type: "danger",
          });
        }
      } else {
        this.setState({
          alert: true,
          message: "Please Select Atleast One Image.",
          type: "warning",
        });
      }
    } else {
      this.setState({
        alert: true,
        message: "Please Fill Up All Details.",
        type: "warning",
      });
    }
    setTimeout(() => {
      this.setState({
        alert: false,
        message: "",
        type: "",
      });
    }, 3000);
  };

  imageUpload = async (file, index) => {
    // console.log("e", file);

    let dataSend = { buckate: false };

    let result = await HttpClient.newFileUpload(
      "image-upload/movie",
      file,
      dataSend
    );
    console.log("result", result);
    if (result && result.status) {
      this.state.uploaded_images.push({ preview: result.url });
      // if (this.state.images.length - 1 == index) {
      //   setTimeout(() => {
      //     this.setState({ loading: false });
      //     console.log("this.state.uploaded_images", this.state.uploaded_images);
      //   }, 200);
      // }
      this.setState({});
    }
  };

  handleAcceptedFiles = (files) => {
    this.imageUpload(files[0]);
  };

  async remove_image(item, index) {
    this.state.uploaded_images.pop(index);
    this.setState({});
  }

  /**
   * Formats the size
   */
  formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  checkUser = async () => {
    let user = await reactLocalStorage.getObject("userData");
    if (user != null && Object.keys(user).length > 0) {
      this.setState({
        userId: user.id,
        userDetails: user,
      });
    } else {
    }
    this.fetchData();
  };

  handleChange = async (event) => {
    let filterSub = this.state.subCategoryAll.filter(
      (item) => item.category_id == event.target.value
    );
    this.setState({ cat_id: event.target.value, subCategory: filterSub });
  };

  onDelete(i) {
    // const tags = this.state.tags.slice(0)
    // tags.splice(i, 1)
    // this.setState({ tags })
    // console.log(this.state.tags);
    this.state.tags.splice(i, 1);
    this.setState({ tags: this.state.tags });
    console.log(this.state.tags);
  }

  onAddition(tag) {
    console.log(tag);
    this.state.tags.push(tag);
    this.setState({ tags: this.state.tags });
    console.log(this.state.tags);
  }
  render() {
    const data = {
      columns: [
        {
          label: "Sl.",
          field: "sl",
          sort: "asc",
          width: 150,
        },
        {
          label: "Image",
          field: "img",
          sort: "asc",
          width: 270,
        },
        {
          label: "Name",
          field: "name",
          sort: "asc",
          width: 270,
        },
        {
          label: "Created By",
          field: "created_by",
          sort: "asc",
          width: 270,
        },
        {
          label: "Updated By",
          field: "updated_by",
          sort: "asc",
          width: 270,
        },

        {
          label: "Author",
          field: "author",
          sort: "asc",
          width: 270,
        },

        {
          label: "Status",
          field: "status",
          sort: "asc",
          width: 100,
        },
        {
          label: "Review",
          field: "review",
          sort: "asc",
          width: 100,
        },

        {
          label: "Action",
          field: "action",
          sort: "asc",
          width: 100,
        },
      ],
      rows: this.state.data,
    };
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title={this.state.breadcrumbItems[0].title}
              breadcrumbItems={this.state.breadcrumbItems}
            />

            <Row>
              {/* <BaseInfo /> */}
              <Col xs={12}>
                <Card>
                  <CardBody>
                    {this.state.alert ? (
                      <UncontrolledAlert
                        color={this.state.type}
                        className="alert-dismissible fade show"
                        role="alert"
                      >
                        {this.state.type == "warning" ? (
                          <i className="mdi mdi-alert-outline mr-2"></i>
                        ) : this.state.type == "success" ? (
                          <i className="mdi mdi-check-all mr-2"></i>
                        ) : this.state.type == "danger" ? (
                          <i className="mdi mdi-block-helper mr-2"></i>
                        ) : null}
                        {this.state.message}
                      </UncontrolledAlert>
                    ) : null}

                    <FormGroup row>
                      <Label
                        htmlFor="example-search-input"
                        className="col-md-2 col-form-label"
                      >
                        Title<span style={{ color: 'red' }}>*</span>
                      </Label>
                      <Col md={10}>
                        <textarea
                          id="question-input1"
                          className="form-control"
                          rows="2"
                          placeholder=""
                          onChange={(val) => {
                            this.setState({ title: val.target.value });
                          }}
                          value={this.state.title}
                        ></textarea>
                      </Col>
                    </FormGroup>

                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Details
                      </Label>
                      <Col md={10}>
                        <CKEditor
                          editor={ClassicEditor}
                          data={this.state.desc}
                          onReady={(editor) => {
                            // You can store the "editor" and use when it is needed.
                            // console.log("Editor is ready to use!", editor);
                          }}
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            // console.log({ event, editor, data });
                          }}
                          onBlur={(event, editor) => {
                            const data = editor.getData();
                            this.setState({ desc: data });
                            console.log("Blur.", editor.getData());
                          }}
                          onFocus={(event, editor) => {
                            // console.log("Focus.", editor.getData());
                          }}
                        />
                      </Col>
                    </FormGroup>

                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Categories<span style={{ color: 'red' }}>*</span>
                      </Label>
                      <Col md={10}>
                        <select
                          className="form-control"
                          onChange={(val) => {
                            this.setState({ cat_id: val.target.value });
                          }}
                          value={this.state.cat_id}
                        >
                          <option value="">Select Categories</option>
                          {this.state.categorycourse.map((item, index) => {
                            return <option value={item.id}>{item.name}</option>;
                          })}
                        </select>
                      </Col>
                    </FormGroup>

                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Author
                      </Label>
                      <Col md={10}>
                        <Input
                          className="form-control"
                          type="text"
                          value={this.state.author}
                          id="example-text-input"
                          onChange={(val) => {
                            this.setState({ author: val.target.value });
                          }}
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Use as Banner
                      </Label>
                      <Col md={10}>
                        <select
                          className="form-control"
                          onChange={(event) => {
                            this.setState({
                              user_banner: event.target.value,
                            });
                          }}
                          value={this.state.user_banner}
                        >
                          <option value="">Select Options</option>
                          <option value="Yes">Yes</option>
                          <option value="No">No</option>
                        </select>
                      </Col>
                    </FormGroup>

                    {/* <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Upload Video
                      </Label>
                      <Col md={6}>
                        <Input
                          className="form-control"
                          type="file"
                          id="example-text-input"
                          onChange={(val) => {
                            this.uploadVideo(val.target.files[0], "Trailer");
                          }}
                          // ref={(ref) => (this.fileInputTrl = ref)}
                        />
                      </Col>
                      <Col md={4}>
                        {this.state.uploading_trailer ? (
                          <>
                            <ProgressBar
                              completed={this.state.progressBarParsent}
                            />
                            {this.state.progressBarParsent == 100
                              ? "please wait untill transcoding is done"
                              : null}
                          </>
                        ) : (
                          <>
                            {this.state.trailer != "" ? (
                              <video
                                width="100%"
                                controls
                                className="rounded-lg"
                              >
                                <source
                                  src={this.state.trailer}
                                  type="video/mp4"
                                />
                              </video>
                            ) : null}
                          </>
                        )}
                      </Col>
                    </FormGroup> */}

                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Image<span style={{ color: 'red' }}>*</span>
                      </Label>
                      <Col md={10}>
                        <Dropzone
                          accept=".jpeg,.jpg,.png,.svg,.webp,.jfif,.apng"
                          onDrop={(acceptedFiles) =>
                            this.handleAcceptedFiles(acceptedFiles)
                          }
                        >
                          {({ getRootProps, getInputProps }) => (
                            <div className="dropzone">
                              <div
                                className="dz-message needsclick mt-2"
                                {...getRootProps()}
                              >
                                <input {...getInputProps()} />
                                <div className="mb-3">
                                  <i className="display-4 text-muted ri-upload-cloud-2-line"></i>
                                </div>
                                <h4>Drop files here or click to upload.</h4>
                              </div>
                            </div>
                          )}
                        </Dropzone>
                        <div
                          className="dropzone-previews mt-3"
                          id="file-previews"
                        >
                          <p style={{ color: 'red' }}>[image should be in dimensions 500 * 500 px]</p>
                          {this.state.uploaded_images.map((f, i) => {
                            return (
                              <Card
                                className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                key={i + "-file"}
                              >
                                <div className="p-2">
                                  <Row className="align-items-center">
                                    <Col className="col-auto">
                                      <img
                                        style={{ height: 120, width: 100 }}
                                        data-dz-thumbnail=""
                                        height="100"
                                        className="avatar-sm rounded bg-light"
                                        // alt={f.name}
                                        src={f.preview}
                                      />
                                    </Col>
                                    <Col>
                                      <Button
                                        className="btn-icon"
                                        color="danger"
                                        style={{ marginRight: 25 }}
                                        onClick={(e) => this.remove_image(f, i)}
                                      >
                                        {" "}
                                        <span className="btn-icon-label">
                                          <i className="mdi mdi-alpha-x-box-outline mr-2"></i>
                                        </span>{" "}
                                        Remove
                                      </Button>
                                    </Col>
                                  </Row>
                                </div>
                              </Card>
                            );
                          })}
                        </div>
                      </Col>
                    </FormGroup>
                    {/* <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Priority
                      </Label>
                      <Col md={10}>
                        <Input
                          className="form-control"
                          type="number"
                          value={this.state.priority}
                          id="example-text-input"
                          min={1}
                          onChange={(val) => {
                            this.setState({ priority: val.target.value });
                          }}
                        />
                      </Col>
                    </FormGroup> */}
                    <p style={{ marginTop: 5, textAlign: "right" }}>
                      [Note: Asterisk (<span style={{ color: 'red' }}>*</span>) denotes the mandatory field.]
                    </p>
                    <FormGroup className="mb-0">
                      <div className="button-items pt-4">
                        <Button
                          color="primary"
                          type="button"
                          className="waves-effect waves-light mr-1"
                          onClick={() => {
                            this.submit();
                          }}
                          disabled={this.state.submit}
                        >
                          {this.state.edit_enable ? "Update" : "Submit"}{" "}
                          <i className="ri-arrow-right-line align-middle ml-1"></i>
                        </Button>
                      </div>
                    </FormGroup>
                  </CardBody>
                </Card>
              </Col>
              <Col xl={4}>
                {/* <div>
                    <EcomPreview />
                  </div> */}
              </Col>
            </Row>
            <Row>
              <Col xl={12}>
                <div className="card p-3">
                <div class="d-flex justify-content-between w-100">
                    <h5>NEWS</h5>
                    {this.state.userDetails.sub_admin == true ? null : (
                <Link
                      className="btn btn-danger mr-2"
                      to={"/news-undo/"}
                    >
                      Deleted News
                    </Link>
                    )}
                </div>
                  <MDBDataTable responsive bordered data={data} />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default FormElements;
