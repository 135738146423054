import React, { Component } from "react";
import {
    Card,
    CardBody,
    Col,
    Row,
    Container,
    FormGroup,
    Label,
    Input,
    CustomInput,
    Button,
    UncontrolledAlert,
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { MDBDataTable } from "mdbreact";
import { reactLocalStorage } from "reactjs-localstorage";
import moment from "moment";
import HttpClient from "./../../utils/HttpClient";
class FormElements extends Component {
    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems: [
                { title: "Loyalty", link: "#" },
                { title: "Add & Manage Loyalty", link: "#" },
            ],
            customchk: true,
            toggleSwitch: true,
            // page states
            name: "",
            points: "",
            desc: "",
            duration: "",
            price: "",
            data: [],
            alert: false,
            message: "",
            type: "",
            result: [],
            edit_enable: false,
            edit_item_id: "",
            type: "",
        };
    }

    componentDidMount = async () => {
        this.fetchData();
    };

    fetchData = async () => {
        let result = await HttpClient.requestData
            ("admin/loyality", "GET");

        if (result && result.status > 0) {
            let data = [];
            let i = 1;
            this.setState({ result: result.data });
            this.state.result.forEach((element, index) => {
                let rows = {
                    sl: i,
                    name: element.name,
                    points: element.points,
                    desc: element.desc,
                    status: (
                        <>
                            <button
                                title={element.status ? "Active" : "Not active"}
                                className={
                                    element.status
                                        ? "btn btn-success mr-2"
                                        : "btn btn-danger mr-2"
                                }
                                onClick={() => {
                                    this.status(element, index);
                                }}
                            >
                                {element.status ? "Active" : "Not active"}
                            </button>
                        </>
                    ),
                    action: (
                        <>
                            {/* <button
                title="Delete"
                className="btn btn-danger mr-2"
                onClick={() => {
                  if (window.confirm('Are you sure you wish to delete this item?'))
                    this.delete(element, index)
                }}
              >
                <i className="fa fa-trash" />
              </button> */}

                            <button
                                title="Edit"
                                className="btn btn-primary"
                                onClick={() => {
                                    this.edit(element, index);
                                    window.scrollTo(0, 0);
                                }}
                            >
                                <i className="fa fa-edit" />
                            </button>
                        </>
                    ),
                };
                i++;
                data.push(rows);
            });
            this.setState({
                data: data,
            });
        } else {
            this.setState({
                data: [],
            });
        }
    };
    delete = async (item, index) => {
        let sendData = {
            id: item._id,
            type: item.id,
        };
        let result = await HttpClient.requestData(
            "admin/type/delete",
            "POST",
            sendData
        );
        if (result && result.status == true) {
            let index = this.state.result.indexOf(item);
            if (index > -1) {
                this.state.result.splice(index, 1);
                this.setState({
                    alert: true,
                    message: "Deleted Successfully",
                    type: "success",
                });
                setTimeout(() => {
                    this.setState({
                        alert: false,
                        message: "",
                        type: "",
                    });
                }, 3000);
                this.fetchData();
            }
        } else {
            this.setState({
                alert: true,
                message: "Sorry !! Video exists! At first Delete the Video",
                type: "warning",
            });
        }
    };

    status = async (item, index) => {
        let sendData = {
            id: item._id,
            status: !item.status,
        };
        let result = await HttpClient.requestData(
            "admin/loyality/status",
            "POST",
            sendData
        );
        if (result && result.status) {
            let index = this.state.result.indexOf(item);
            if (index > -1) {
                this.state.result.splice(index, 1);
                this.setState({
                    alert: true,
                    message: item.status
                        ? "Status Update Successfully"
                        : "Status Update Successfully",
                    type: "success",
                });
                setTimeout(() => {
                    this.setState({
                        alert: false,
                        message: "",
                        type: "",
                    });
                }, 3000);
                this.fetchData();
            }
        }
    };

    edit = async (item, index) => {
        this.setState({
            edit_enable: true,
            name: item.name,
            edit_item_id: item._id,
            points: item.points,
            desc: item.desc,
        });
    };
    submit = async () => {
        if (
            this.state.name != ""
        ) {
            let data = null;
            let result = null;

            if (this.state.edit_enable == false) {
                // data = {
                //     name: this.state.name,
                //     points: this.state.points,
                //     desc: this.state.desc,
                // };
                // result = await HttpClient.requestData
                //     ("admin/loyality", "POST", data);
            } else {
                data = {
                    name: this.state.name,
                    points: this.state.points,
                    desc: this.state.desc,
                    id: this.state.edit_item_id,
                };
                console.log("data", data);
                // return false;
                result = await HttpClient.requestData(
                    "admin/loyality/update",
                    "POST",
                    data
                );
            }
            console.log("result", result);
            if (result && result.status) {
                this.setState({
                    alert: true,
                    message: this.state.edit_enable
                        ? " Updated Successfully"
                        : " Added Successfully",
                    type: "success",
                    name: "",
                    points: "",
                    edit_enable: false,
                    edit_item_id: "",
                    desc: "",

                });

                this.fetchData();
            } else {
                this.setState({
                    alert: true,
                    message: result.error,
                    type: "danger",
                });
            }
        } else {
            this.setState({
                alert: true,
                message: "Please Fill Up All Details.",
                type: "warning",
            });
        }
        setTimeout(() => {
            this.setState({
                alert: false,
                message: "",
                type: "",
            });
        }, 3000);
    };

    handleChange = (event) => {
        console.log(event.target.value);
        this.setState({ category_type: event.target.value });
    };

    imageUpload = async (e) => {
        console.log("e", e.target.files);
        let file = e.target.files;
        //   if(file.lenght>0)
        //   {
        let dataSend = { buckate: true, buckate_name: "appIcon" };

        let result = await HttpClient.newFileUpload(
            "image-upload/category",
            e.target.files[0],
            dataSend
        );
        // console.log("result", result);
        if (result && result.status) {
            this.setState({ img_url: result.url, image_select: true });
        }

        //   }
    };

    render() {
        const data = {
            columns: [
                {
                    label: "Sl.",
                    field: "sl",
                    sort: "asc",
                    width: 150,
                },
                {
                    label: "Name",
                    field: "name",
                    sort: "asc",
                    width: 270,
                },
                {
                    label: "Description",
                    field: "desc",
                    sort: "asc",
                    width: 270,
                },
                {
                    label: "Points",
                    field: "points",
                    sort: "asc",
                    width: 270,
                },

                {
                    label: "Action",
                    field: "action",
                    sort: "asc",
                    width: 100,
                },
                {
                    label: "Status",
                    field: "status",
                    sort: "asc",
                    width: 270,
                },
            ],
            rows: this.state.data,
        };
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs
                            title={this.state.breadcrumbItems[0].title}
                            breadcrumbItems={this.state.breadcrumbItems}
                        />
                        {this.state.edit_enable == true ? (
                            <Row>
                                <Col xs={12}>
                                    <Card>
                                        <CardBody>
                                            <FormGroup row>
                                                <Label
                                                    htmlFor="example-text-input"
                                                    className="col-md-2 col-form-label"
                                                >
                                                    Loyalty Name<span style={{ color: 'red' }}>*</span>
                                                </Label>
                                                <Col md={10}>
                                                    <Input
                                                        className="form-control"
                                                        type="text"
                                                        disabled={true}
                                                        style={{ background: '#E5E4E2' }}
                                                        value={this.state.name}
                                                        id="example-text-input"
                                                        onChange={(val) => {
                                                            this.setState({ name: val.target.value });
                                                        }}
                                                    />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label
                                                    htmlFor="example-text-input"
                                                    className="col-md-2 col-form-label"
                                                >
                                                    Points
                                                </Label>
                                                <Col md={10}>
                                                    <Input
                                                        className="form-control"
                                                        type="number"
                                                        value={this.state.points}
                                                        id="example-text-input"
                                                        onChange={(val) => {
                                                            this.setState({ points: val.target.value });
                                                        }}
                                                    />
                                                </Col>
                                            </FormGroup>
                                            {/* <FormGroup row>
                                            <Label
                                                htmlFor="example-text-input"
                                                className="col-md-2 col-form-label"
                                            >
                                                Type
                                            </Label>
                                            <Col md={10}>
                                                <Input
                                                    className="form-control"
                                                    type="text"
                                                    value={this.state.type}
                                                    id="example-text-input"
                                                    onChange={(val) => {
                                                        this.setState({ type: val.target.value });
                                                    }}
                                                />
                                            </Col>
                                          </FormGroup> */}

                                            <FormGroup row>
                                                <Label
                                                    htmlFor="example-search-input"
                                                    className="col-md-2 col-form-label"
                                                >
                                                    Description
                                                </Label>
                                                <Col md={10}>
                                                    <textarea
                                                        id="question-input1"
                                                        className="form-control"
                                                        rows="2"
                                                        disabled={true}
                                                        style={{ background: '#E5E4E2' }}
                                                        onChange={(val) => {
                                                            this.setState({ desc: val.target.value });
                                                        }}
                                                        value={this.state.desc}
                                                    ></textarea>
                                                </Col>
                                            </FormGroup>
                                            <p style={{ marginTop: 5, textAlign: "right" }}>
                                                [Note: Asterisk (<span style={{ color: 'red' }}>*</span>) denotes the mandatory field.]
                                            </p>
                                            {/* </FormGroup> */}
                                            <FormGroup className="mb-0">
                                                <div className="button-items pt-4">
                                                    <Button
                                                        color="primary"
                                                        type="button"
                                                        className="waves-effect waves-light mr-1"
                                                        onClick={() => {
                                                            this.submit();
                                                        }}
                                                    >
                                                        {/* {this.state.edit_enable ? "Update" : "Submit"}{" "} */}
                                                        Update
                                                        <i className="ri-arrow-right-line align-middle ml-1"></i>
                                                    </Button>
                                                </div>
                                            </FormGroup>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        ) : null}
                        <Row>
                            <Col xl={12}>
                                <CardBody>
                                    {this.state.alert ? (
                                        <UncontrolledAlert
                                            color={this.state.type}
                                            className="alert-dismissible fade show"
                                            role="alert"
                                        >
                                            {this.state.type == "warning" ? (
                                                <i className="mdi mdi-alert-outline mr-2"></i>
                                            ) : this.state.type == "success" ? (
                                                <i className="mdi mdi-check-all mr-2"></i>
                                            ) : this.state.type == "danger" ? (
                                                <i className="mdi mdi-block-helper mr-2"></i>
                                            ) : null}
                                            {this.state.message}
                                        </UncontrolledAlert>
                                    ) : null}
                                    <div className="card p-3">
                                        <MDBDataTable responsive bordered data={data} />
                                    </div>
                                </CardBody>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default FormElements;
