import React, { Component } from "react";
import {
    Card,
    CardBody,
    Col,
    Row,
    Container,
    FormGroup,
    Label,
    Input,
    CustomInput,
    Button,
    UncontrolledAlert,
    InputGroup,
} from "reactstrap";
import { Link } from "react-router-dom";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { MDBDataTable } from "mdbreact";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { reactLocalStorage } from "reactjs-localstorage";
import moment from "moment";
import HttpClient from "./../../utils/HttpClient";
import Dropzone from "react-dropzone";
import Select from "react-select";

class FormElements extends Component {
    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems: [
                { title: "Speaker Wise Event Joiners", link: "#" },
                { title: "Manage Speaker Wise Event Joiners", link: "#" },
            ],
            customchk: true,
            toggleSwitch: true,
            // page states
            name: "",
            email: "",
            mobile: "",
            password: "",
            data: [],
            alert: false,
            message: "",
            type: "",
            result: [],
            edit_enable: false,
            edit_item_id: "",
            image_select: false,
            userId: "",
            userDetails: {},
            default_date: new Date(),
            details: "",

            start_date: "",
            end_date: "",
            from_date: "",
            to_date: "",

            selectedFiles: [],
            images: [],
            uploaded_images: [],

            event_id: this.props.match.params.id,
            eventData: null,
            start_time: new Date(),

            optionGroup: [],
            user_id: "",
        };

        this.handleMulti = this.handleMulti.bind(this);

    }

    componentDidMount = async () => {
        // this.checkUser();
        this.fetchUser();
    };

    fetchUser = async () => {
        let result = await HttpClient.requestData("admin/event/get-all-speakers", "GET");
        if (result && result.status > 0) {
            let optionAppowner = {};
            optionAppowner.label = "Speakers";
            optionAppowner.options = [];
            result.data.map((item, index) => {
                // console.log('item',item)
                optionAppowner.options.push({
                    label: item.name,
                    value: item.id,
                });
            });

            let newOptions = [];
            newOptions.push(optionAppowner);

            this.setState({ optionGroup: newOptions });
        }
    };

    handleMulti = (selectedSpeaker) => {
        console.log("selectedMulti", selectedSpeaker);

        this.setState({
            name: selectedSpeaker.label,
            user_id: Number(selectedSpeaker.value),
        });
        this.setState({ selectedSpeaker });
    };


    fetchData = async (speaker) => {
        let SendData = { specker_id: speaker }
        let result = await HttpClient.requestData(
            "admin/event/specker-wise-event-join",
            "POST",
            SendData
        );
        // console.log("zzzzzzzzzzzzzzz",SendData);
        if (result && result.status) {
            let data = [];
            let i = 1;
            this.setState({ result: result.data });
            this.state.result.forEach((element, index) => {
                let rows = {
                    sl: i,
                    name: element.EventData.name,
                    timing:
                        moment(element.EventData.start_date).format("LL") +
                        " To " +
                        moment(element.EventData.end_date).format("LL"),
                    // time:
                    //     moment(element.EventdData.start_time, "hh:mm").format("hh:mm a") +
                    //     " To " +
                    //     moment(element.EventdData.end_time, "hh:mm").format("hh:mm a"),
                    username: element.UserData ? element.UserData.firstname + " " + element.UserData.lastname : null,
                    email: element.UserData ? element.UserData.email : null,
                    phone: element.UserData ? element.UserData.phone_no : null,
                    image: (
                        <div>
                            <img
                                src={element.UserData ? element.UserData.image : ""}
                                alt="images"
                                className="rounded avatar-md card-img"
                            />
                        </div>
                    ),
                    rgt_date: moment(element.created_on).format("LL"),
                }
                this.setState({ rows: rows });
                i++;
                data.push(this.state.rows);
            });
            this.setState({
                data: data,
            });
        } else {
            this.setState({
                data: [],
            });
        }
    };


    submit = async () => {
        if (
            this.state.user_id != ""
        ) {

            this.fetchData(this.state.user_id);
            // console.log("zzzzzzzzzzzzzzz",this.state.user_id);

        } else {
            this.setState({
                alert: true,
                message: "Please Select Speaker.",
                type: "warning",
            });
        }
        setTimeout(() => {
            this.setState({
                alert: false,
                message: "",
                type: "",
            });
        }, 3000);
    };


    checkUser = async () => {
        let user = await reactLocalStorage.getObject("userData");
        if (user != null && Object.keys(user).length > 0) {
            this.setState({
                userId: user.id,
                userDetails: user,
            });
        } else {
        }
        this.fetchData();
    };

    render() {
        const data = {
            columns: [
                {
                    label: "Sl.",
                    field: "sl",
                    sort: "asc",
                    width: 150,
                },
                {
                    label: "Name",
                    field: "name",
                    sort: "asc",
                    width: 270,
                },
                {
                    label: "Event Date",
                    field: "timing",
                    sort: "asc",
                    width: 270,
                },
                // {
                //     label: "Time",
                //     field: "time",
                //     sort: "asc",
                //     width: 270,
                // },
                {
                    label: "Username",
                    field: "username",
                    sort: "asc",
                    width: 270,
                },
                {
                    label: "Email",
                    field: "email",
                    sort: "asc",
                    width: 270,
                },
                {
                    label: "Phone",
                    field: "phone",
                    sort: "asc",
                    width: 270,
                },
                {
                    label: "Image",
                    field: "image",
                    sort: "asc",
                    width: 270,
                },
                {
                    label: "Join Date",
                    field: "rgt_date",
                    sort: "asc",
                    width: 270,
                },
            ],
            rows: this.state.data,
        };
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs
                            title={this.state.breadcrumbItems[0].title}
                            breadcrumbItems={this.state.breadcrumbItems}
                        />

                        <Row>
                            <Col xs={12}>
                                <Card>
                                    <CardBody>
                                        {this.state.alert ? (
                                            <UncontrolledAlert
                                                color={this.state.type}
                                                className="alert-dismissible fade show"
                                                role="alert"
                                            >
                                                {this.state.type == "warning" ? (
                                                    <i className="mdi mdi-alert-outline mr-2"></i>
                                                ) : this.state.type == "success" ? (
                                                    <i className="mdi mdi-check-all mr-2"></i>
                                                ) : this.state.type == "danger" ? (
                                                    <i className="mdi mdi-block-helper mr-2"></i>
                                                ) : null}
                                                {this.state.message}
                                            </UncontrolledAlert>
                                        ) : null}
                                        <FormGroup row>
                                            <Label
                                                htmlFor="example-text-input"
                                                className="col-md-2 col-form-label"
                                            >
                                                Select Speaker<span style={{ color: 'red' }}>*</span>
                                            </Label>
                                            <Col md={10}>
                                                <Select
                                                    isMulti={false}
                                                    options={this.state.optionGroup}
                                                    onChange={this.handleMulti}
                                                    value={this.state.selectedSpeaker}
                                                />
                                            </Col>
                                        </FormGroup>

                                        <p style={{ marginTop: 5, textAlign: "right" }}>
                                            [Note: Asterisk (<span style={{ color: 'red' }}>*</span>) denotes the mandatory field.]
                                        </p>

                                        <FormGroup className="mb-0">
                                            <div className="button-items pt-4">
                                                <Button
                                                    color="primary"
                                                    type="button"
                                                    className="waves-effect waves-light mr-1"
                                                    onClick={() => {
                                                        this.submit();
                                                    }}
                                                >
                                                    {this.state.edit_enable ? "Update" : "Submit"}{" "}
                                                    <i className="ri-arrow-right-line align-middle ml-1"></i>
                                                </Button>
                                            </div>
                                        </FormGroup>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>

                        <Row>
                            <Col xl={12}>
                                <div className="card p-3">
                                    <h5>SPEAKER WISE EVENT JOINERS</h5>
                                    <MDBDataTable responsive bordered data={data} />
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default FormElements;
