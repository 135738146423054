import React, { Component } from "react";
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  FormGroup,
  Label,
  Input,
  CustomInput,
  Button,
  UncontrolledAlert,
  InputGroup,
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { MDBDataTable } from "mdbreact";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { reactLocalStorage } from "reactjs-localstorage";
import moment from "moment";
import HttpClient from "./../../utils/HttpClient";
class FormElements extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: "ScratchCardWinner", link: "#" },
        { title: "Add & Manage ScratchCardWinner", link: "#" },
      ],
      customchk: true,
      toggleSwitch: true,
      // page states
      name: "",
      email: "",
      mobile: "",
      password: "",
      data: [],
      alert: false,
      message: "",
      type: "",
      result: [],
      edit_enable: false,
      edit_item_id: "",
      image_select: false,
      img_url: "",
      userId: "",
      userDetails: {},
      default_date: new Date(),
      details: "",
      link: "",
      price: "",
      total_seats: "",
      vanue:"",
      latitude:"",
      longitude:'',
      image_select:false,
      img_url:""
    };
    
  }

  componentDidMount = async () => {
    this.checkUser();
  };

  

  fetchData = async () => {
    let data={};
    console.log("DDD",data);
    let result = await HttpClient.requestData(
      "admin/scrach-card/user-scrach",
      "POST",
      data,
    );
    

    console.log("ScratchCardWinnerList",result);
    if (result && result.status > 0) {
      let data = [];
      let i = 1;
      this.setState({ result: result.data });
      this.state.result.forEach((element, index) => {
        let rows = {
          sl: i,
          name: element.userData.firstname + " " + element.userData.lastname,
          email: element.userData.email,
          date: moment(element.created_on).format("L"),
          giftname: element.scrachCard.giftName,
          image: (<img src={element.scrachCard.giftImage} style={{height:80,width:80,borderRadius:10}} />),
         
          status: (
            <button
              title="Delete"
              className={
                element.userData.status ? "btn btn-success mr-2" : "btn btn-danger mr-2"
              }
              onClick={() => {
                this.status(element, index);
              }}
            >
              {element.userData.status ? "Active" : "Not Active"}
            </button>
          ),
          // action: (
          //   <>
          //     <button
          //       title="Delete"
          //       className="btn btn-danger mr-2"
          //       onClick={() => {
          //         this.delete(element, index);
          //       }}
          //     >
          //       <i className="fa fa-trash" />
          //     </button>
          //     <button
          //       title="Edit"
          //       className="btn btn-primary"
          //       onClick={() => {
          //         this.edit(element, index);
          //       }}
          //     >
          //       <i className="fa fa-edit" />
          //     </button>
          //   </>
          // ),
        };
        i++;
        data.push(rows);
      });
      this.setState({
        data: data,
      });
    } else {
      this.setState({
        data: [],
      });
    }
  };
  delete = async (item, index) => {
    let sendData = {
      id: item._id,
    };
    let result = await HttpClient.requestData(
      "app-owner/event/delete",
      "POST",
      sendData
    );
    if (result && result.status) {
      let index = this.state.result.indexOf(item);
      if (index > -1) {
        this.state.result.splice(index, 1);
        this.setState({
          alert: true,
          message: "Deleted Successfullyy",
          type: "success",
        });
        setTimeout(() => {
          this.setState({
            alert: false,
            message: "",
            type: "",
          });
        }, 3000);
        this.fetchData();
      }
    }
  };
  edit = async (item, index) => {
    this.setState({
      edit_enable: true,
      name: item.name,
      edit_item_id: item._id,
      timing: moment(item.timing).format("dd/mm/YYYY"),
      default_date: new Date(item.timing),
      link: item.link,
      details: item.details,
      vanue:item.vanue,
      latitude:item.latitude,
      longitude:item.longitude,
      image_select:item.img_url == ''?false:true,
      img_url:item.img_url,
      price: item.seat_price,
      total_seats: item.total_seats,
    });
  };
  // status = async (item, index) => {
  //   let sendData = {
  //     id: item._id,
  //     status: !item.status,
  //   };
  //   let result = await HttpClient.requestData(
  //     "app-owner/event/status",
  //     "POST",
  //     sendData
  //   );
  //   if (result && result.status) {
  //     this.state.result.splice(index, 1);
  //     this.setState({
  //       alert: true,
  //       message: "Status Updated Successfullyy",
  //       type: "success",
  //     });
  //     setTimeout(() => {
  //       this.setState({
  //         alert: false,
  //         message: "",
  //         type: "",
  //       });
  //     }, 3000);
  //     this.fetchData();
  //   }
  // };
  
  imageUpload = async (e) => {
    let file = e.target.files[0].type;
    let fileType = file.split("/")[0];
    // fileType = fileType[0];
    console.log("e", file.split("/")[0]);

    if (fileType == "image" || fileType == "video") {
      // return false;
      this.setState({ fileType: fileType });
      let dataSend = { buckate: true, buckate_name: "appIcon" };

      let result = await HttpClient.newFileUpload(
        "image-upload/advertise",
        e.target.files[0],
        dataSend
      );
      console.log("result", result);
      if (result && result.status) {
        this.setState({ img_url: result.url, image_select: true });
      }
    } else {
      this.setState({
        alert: true,
        message: "Please Fill Up All Details.",
        type: "warning",
      });
    }
  };

  checkUser = async () => {
    let user = await reactLocalStorage.getObject("userData");
    if (user != null && Object.keys(user).length > 0) {
      this.setState({
        userId: user.id,
        userDetails: user,
      });
    } else {
    }
    this.fetchData();
  };

  render() {
    const data = {
      columns: [
        {
          label: "Sl.",
          field: "sl",
          sort: "asc",
          width: 150,
        },
        {
          label: "Name",
          field: "name",
          sort: "asc",
          width: 270,
        },
        // timing
        // link
        // details
        // price
        
        {
          label: "Email Id",
          field: "email",
          sort: "asc",
          width: 270,
        },
        {
          label: "Date",
          field: "date",
          sort: "asc",
          width: 270,
        },

        {
          label: "Gift Name",
          field: "giftname",
          sort: "asc",
          width: 270,
        },
        {
          label: "Gift Image",
          field: "image",
          sort: "asc",
          width: 270,
        },
        
        
        // {
        //   label: "Status",
        //   field: "status",
        //   sort: "asc",
        //   width: 100,
        // },
        // {
        //   label: "Action",
        //   field: "action",
        //   sort: "asc",
        //   width: 100,
        // },
      ],
      rows: this.state.data,
    };
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title={this.state.breadcrumbItems[0].title}
              breadcrumbItems={this.state.breadcrumbItems}
            />

            <Row>
              <Col xs={12}>
                
              </Col>
            </Row>
            <Row>
              <Col xl={12}>
                <div className="card p-3">
                  <MDBDataTable responsive bordered data={data} />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default FormElements;
