import React, { Component } from "react";
// MetisMenu
import MetisMenu from "metismenujs";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
//i18n
import { withNamespaces } from "react-i18next";
import { connect } from "react-redux";
import {
  changeLayout,
  changeLayoutWidth,
  changeSidebarTheme,
  changeSidebarType,
  changePreloader,
} from "../../store/actions";

class SidebarContent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.initMenu();
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      if (this.props.type !== prevProps.type) {
        this.initMenu();
      }
    }
  }

  initMenu() {
    new MetisMenu("#side-menu");

    var matchingMenuItem = null;
    var ul = document.getElementById("side-menu");
    var items = ul.getElementsByTagName("a");
    for (var i = 0; i < items.length; ++i) {
      if (this.props.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      this.activateParentDropdown(matchingMenuItem);
    }
  }

  activateParentDropdown = (item) => {
    item.classList.add("active");
    const parent = item.parentElement;

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show");

        const parent3 = parent2.parentElement;

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement;
          if (parent4) {
            parent4.classList.add("mm-active");
          }
        }
      }
      return false;
    }
    return false;
  };


  render() {
    return (
      <React.Fragment>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title">{this.props.t("Menu")}</li>

            <li>
              <Link to="/dashboard" className="waves-effect">
                <i className="ri-dashboard-line"></i>

                <span className="ml-1">{this.props.t("Dashboard")}</span>
              </Link>
            </li>

            {this.props.userData.sub_admin == true && this.props.userData.modules.filter((ikk) => ikk.label == "Ecommerce").length > 0 ? (
              <li>
                <Link to="/#" className="has-arrow waves-effect">
                  <i className="ri-mail-send-line"></i>
                  <span className="ml-1">{this.props.t("Ecommerce")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/category">
                      {this.props.t("Add & Manage Categories")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/sub-category">
                      {this.props.t("Add & Manage Sub Categories")}
                    </Link>
                  </li>

                  <li>
                    <Link to="/product-add">
                      {this.props.t("Add & Manage Products")}
                    </Link>
                  </li>

                  <li>
                    <Link to="/order">
                      {this.props.t("Manage Order History")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/add-banner">
                      {this.props.t("Add & Manage Banner")}
                    </Link>
                  </li>
                </ul>
              </li>
            ) : (
              this.props.userData.sub_admin == true ? null : (
                <li>
                  <Link to="/#" className="has-arrow waves-effect">
                    <i className="ri-mail-send-line"></i>
                    <span className="ml-1">{this.props.t("Ecommerce")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link to="/category">
                        {this.props.t("Add & Manage Categories")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/sub-category">
                        {this.props.t("Add & Manage Sub Categories")}
                      </Link>
                    </li>

                    <li>
                      <Link to="/product-add">
                        {this.props.t("Add & Manage Products")}
                      </Link>
                    </li>

                    <li>
                      <Link to="/order">
                        {this.props.t("Manage Order History")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/add-banner">
                        {this.props.t("Add & Manage Banner")}
                      </Link>
                    </li>
                  </ul>
                </li>
              )
            )}



            {/* events */}
            {this.props.userData.sub_admin == true && this.props.userData.modules.filter((ikk) => ikk.label == "Event").length > 0 ? (
              <li>
                <Link to="/#" className="has-arrow waves-effect">
                  <i className="ri-mail-send-line"></i>
                  <span className="ml-1">{this.props.t("Events")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/events">
                      {this.props.t("Add & manage events")}
                    </Link>
                  </li>

                  <li>
                    <Link to="/advertisement">
                      {this.props.t("Add & Manage Advertisement")}
                    </Link>
                  </li>
                </ul>
              </li>
            ) : (
              this.props.userData.sub_admin == true ? null : (
                <li>
                  <Link to="/#" className="has-arrow waves-effect">
                    <i className="ri-mail-send-line"></i>
                    <span className="ml-1">{this.props.t("Events")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link to="/events">
                        {this.props.t("Add & manage events")}
                      </Link>
                    </li>

                    <li>
                      <Link to="/advertisement">
                        {this.props.t("Add & Manage Advertisement")}
                      </Link>
                    </li>
                  </ul>
                </li>
              )
            )}

            {this.props.userData.sub_admin == true && this.props.userData.modules.filter((ikk) => ikk.label == "Scratch-card").length > 0 ? (
              <li>
                <Link to="/#" className="has-arrow waves-effect">
                  <i className="ri-mail-send-line"></i>
                  <span className="ml-1">{this.props.t("Scratch Card")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/scratchCard">
                      {this.props.t("Add & manage Scratch Card")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/scratch-winner">
                      {this.props.t("Manage Scratch Card Winner")}
                    </Link>
                  </li>
                </ul>
              </li>
            ) : (
              this.props.userData.sub_admin == true ? null : (
                <li>
                  <Link to="/#" className="has-arrow waves-effect">
                    <i className="ri-mail-send-line"></i>
                    <span className="ml-1">{this.props.t("Scratch Card")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link to="/scratchCard">
                        {this.props.t("Add & manage Scratch Card")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/scratch-winner">
                        {this.props.t("Manage Scratch Card Winner")}
                      </Link>
                    </li>
                  </ul>
                </li>
              )
            )}


            {this.props.userData.sub_admin == true && this.props.userData.modules.filter((ikk) => ikk.label == "Loyalty").length > 0 ? (
              <li>
                <Link to="/#" className="has-arrow waves-effect">
                  <i className="ri-mail-send-line"></i>
                  <span className="ml-1">{this.props.t("Loyalty")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/loyalty">
                      {this.props.t("Add & manage Loyalty")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/loyaltydetails">
                      {this.props.t("Manage Loyalty")}
                    </Link>
                  </li>
                </ul>
              </li>
            ) : (
              this.props.userData.sub_admin == true ? null : (
                <li>
                  <Link to="/#" className="has-arrow waves-effect">
                    <i className="ri-mail-send-line"></i>
                    <span className="ml-1">{this.props.t("Loyalty")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link to="/loyalty">
                        {this.props.t("Add & manage Loyalty")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/loyaltydetails">
                        {this.props.t("Manage Loyalty")}
                      </Link>
                    </li>
                  </ul>
                </li>
              )
            )}

            {this.props.userData.sub_admin == true && this.props.userData.modules.filter((ikk) => ikk.label == "User").length > 0 ? (
              <li className="menu-title">{this.props.t("User")}</li>
            ) : (
              this.props.userData.sub_admin == true ? null : (
                <li className="menu-title">{this.props.t("User")}</li>
              )
            )}
            {this.props.userData.sub_admin == true && this.props.userData.modules.filter((ikk) => ikk.label == "User").length > 0 ? (
              <li>
                <Link to="/#" className="has-arrow waves-effect">
                  <i className="ri-mail-send-line"></i>
                  <span className="ml-1">{this.props.t("User")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/userlist">
                      {this.props.t("User List")}
                    </Link>
                  </li>
                </ul>
              </li>
            ) : (
              this.props.userData.sub_admin == true ? null : (

                <li>
                  <Link to="/userlist" className="waves-effect">
                    <i className="ri-mail-send-line"></i>
                    <span className="ml-1">{this.props.t("User")}</span>
                  </Link>
                </li>
              )
            )}


            {this.props.userData.sub_admin == true && this.props.userData.modules.filter((ikk) => ikk.label == "Social").length > 0 ? (
              <li className="menu-title">{this.props.t("Social")}</li>
            ) : (
              this.props.userData.sub_admin == true ? null : (
                <li className="menu-title">{this.props.t("Social")}</li>
              )
            )}
            {this.props.userData.sub_admin == true && this.props.userData.modules.filter((ikk) => ikk.label == "Social").length > 0 ? (
              <li>
                <Link to="/socialPost" className="waves-effect">
                  <i className="ri-mail-send-line"></i>
                  <span className="ml-1">{this.props.t("Social Post")}</span>
                </Link>
              </li>
            ) : (
              this.props.userData.sub_admin == true ? null : (
                <li>
                  <Link to="/socialPost" className="waves-effect">
                    <i className="ri-mail-send-line"></i>
                    <span className="ml-1">{this.props.t("Social Post")}</span>
                  </Link>
                </li>
              )
            )}

            {this.props.userData.sub_admin == true && this.props.userData.modules.filter((ikk) => ikk.label == "Podcast").length > 0 ? (
              <>
                <li className="menu-title">{this.props.t("PODCAST MENU")}</li>
                <li>
                  <Link to="/podtype" className="waves-effect">
                    <i className="ri-mail-send-line"></i>
                    <span className="ml-1">{this.props.t("Types")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/podCategories" className="waves-effect">
                    <i className="ri-mail-send-line"></i>
                    <span className="ml-1">{this.props.t("Categories")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/podgenre" className="waves-effect">
                    <i className="ri-mail-send-line"></i>
                    <span className="ml-1">{this.props.t("Genre")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/podcast" className="waves-effect">
                    <i className="ri-mail-send-line"></i>
                    <span className="ml-1">{this.props.t("Podcast")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/podcastbaner" className="waves-effect">
                    <i className="ri-mail-send-line"></i>
                    <span className="ml-1">{this.props.t("Banner")}</span>
                  </Link>
                </li>
              </>
            ) : (
              this.props.userData.sub_admin == true ? null : (
                <>
                  <li className="menu-title">{this.props.t("PODCAST MENU")}</li>
                  <li>
                    <Link to="/podtype" className="waves-effect">
                      <i className="ri-mail-send-line"></i>
                      <span className="ml-1">{this.props.t("Types")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/podCategories" className="waves-effect">
                      <i className="ri-mail-send-line"></i>
                      <span className="ml-1">{this.props.t("Categories")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/podgenre" className="waves-effect">
                      <i className="ri-mail-send-line"></i>
                      <span className="ml-1">{this.props.t("Genre")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/podcast" className="waves-effect">
                      <i className="ri-mail-send-line"></i>
                      <span className="ml-1">{this.props.t("Podcast")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/podcastbaner" className="waves-effect">
                      <i className="ri-mail-send-line"></i>
                      <span className="ml-1">{this.props.t("Banner")}</span>
                    </Link>
                  </li>
                </>
              )
            )}


            {this.props.userData.sub_admin == true && this.props.userData.modules.filter((ikk) => ikk.label == "OTT").length > 0 ? (
              <li className="menu-title">{this.props.t("OTT Menu")}</li>
            ) : (
              this.props.userData.sub_admin == true ? null : (
                <li className="menu-title">{this.props.t("OTT Menu")}</li>
              )
            )}

            {this.props.userData.sub_admin == true && this.props.userData.modules.filter((ikk) => ikk.label == "OTT").length > 0 ? (
              <li>
                <Link to="/types" className="waves-effect">
                  <i className="ri-mail-send-line"></i>
                  <span className="ml-1">{this.props.t("Types")}</span>
                </Link>
              </li>
            ) : (
              this.props.userData.sub_admin == true ? null : (
                <li>
                  <Link to="/types" className="waves-effect">
                    <i className="ri-mail-send-line"></i>
                    <span className="ml-1">{this.props.t("Types")}</span>
                  </Link>
                </li>
              )
            )}
            {this.props.userData.sub_admin == true && this.props.userData.modules.filter((ikk) => ikk.label == "OTT").length > 0 ? (
              <li>
                <Link to="/jd3category" className="waves-effect">
                  <i className="ri-mail-send-line"></i>
                  <span className="ml-1">{this.props.t("Categories")}</span>
                </Link>
              </li>
            ) : (
              this.props.userData.sub_admin == true ? null : (
                <li>
                  <Link to="/jd3category" className="waves-effect">
                    <i className="ri-mail-send-line"></i>
                    <span className="ml-1">{this.props.t("Categories")}</span>
                  </Link>
                </li>
              )
            )}

            {this.props.userData.sub_admin == true && this.props.userData.modules.filter((ikk) => ikk.label == "OTT").length > 0 ? (
              <li>
                <Link to="/genre" className="waves-effect">
                  <i className="ri-mail-send-line"></i>
                  <span className="ml-1">{this.props.t("Genre")}</span>
                </Link>
              </li>
            ) : (
              this.props.userData.sub_admin == true ? null : (
                <li>
                  <Link to="/genre" className="waves-effect">
                    <i className="ri-mail-send-line"></i>
                    <span className="ml-1">{this.props.t("Genre")}</span>
                  </Link>
                </li>
              )
            )}

            {this.props.userData.sub_admin == true && this.props.userData.modules.filter((ikk) => ikk.label == "OTT").length > 0 ? (
              <li>
                <Link to="/videos" className="waves-effect">
                  <i className="ri-mail-send-line"></i>
                  <span className="ml-1">{this.props.t("Add Videos")}</span>
                </Link>
              </li>
            ) : (
              this.props.userData.sub_admin == true ? null : (
                <li>
                  <Link to="/videos" className="waves-effect">
                    <i className="ri-mail-send-line"></i>
                    <span className="ml-1">{this.props.t("Add Videos")}</span>
                  </Link>
                </li>
              )
            )}

            {this.props.userData.sub_admin == true && this.props.userData.modules.filter((ikk) => ikk.label == "OTT").length > 0 ? (
              <li>
                <Link to="/banner" className="waves-effect">
                  <i className="ri-mail-send-line"></i>
                  <span className="ml-1">{this.props.t("Banner")}</span>
                </Link>
              </li>
            ) : (
              this.props.userData.sub_admin == true ? null : (
                <li>
                  <Link to="/banner" className="waves-effect">
                    <i className="ri-mail-send-line"></i>
                    <span className="ml-1">{this.props.t("Banner")}</span>
                  </Link>
                </li>
              )
            )}

            {this.props.userData.sub_admin == true && this.props.userData.modules.filter((ikk) => ikk.label == "News").length > 0 ? (
              <li className="menu-title">{this.props.t("News Menu")}</li>
            ) : (
              this.props.userData.sub_admin == true ? null : (
                <li className="menu-title">{this.props.t("News Menu")}</li>
              )
            )}

            {this.props.userData.sub_admin == true && this.props.userData.modules.filter((ikk) => ikk.label == "News").length > 0 ? (
              <li>
                <Link to="/news-category" className="waves-effect">
                  <i className="ri-mail-send-line"></i>
                  <span className="ml-1">{this.props.t("News Categories")}</span>
                </Link>
              </li>
            ) : (
              this.props.userData.sub_admin == true ? null : (
                <li>
                  <Link to="/news-category" className="waves-effect">
                    <i className="ri-mail-send-line"></i>
                    <span className="ml-1">{this.props.t("News Categories")}</span>
                  </Link>
                </li>
              )
            )}

            {this.props.userData.sub_admin == true && this.props.userData.modules.filter((ikk) => ikk.label == "News").length > 0 ? (
              <li>
                <Link to="/news" className="waves-effect">
                  <i className="ri-mail-send-line"></i>
                  <span className="ml-1">{this.props.t("News")}</span>
                </Link>
              </li>
            ) : (
              this.props.userData.sub_admin == true ? null : (
                <li>
                  <Link to="/news" className="waves-effect">
                    <i className="ri-mail-send-line"></i>
                    <span className="ml-1">{this.props.t("News")}</span>
                  </Link>
                </li>
              )
            )}

            {this.props.userData.sub_admin == true ? null :
              (<li className="menu-title">{this.props.t("Sub-admin")}</li>)}
            {this.props.userData.sub_admin == true ? null : (
              <li>
                <Link to="/sub-admin" className="waves-effect">
                  <i className="ri-mail-send-line"></i>
                  <span className="ml-1">{this.props.t("Sub-admin")}</span>
                </Link>
              </li>
            )}


            {this.props.userData.sub_admin == true && this.props.userData.modules.filter((ikk) => ikk.label == "Course").length > 0 ? (
              <li className="menu-title">{this.props.t("Learning Menu")}</li>
            ) : (
              this.props.userData.sub_admin == true ? null : (
                <li className="menu-title">{this.props.t("Learning Menu")}</li>
              )
            )}
            {this.props.userData.sub_admin == true && this.props.userData.modules.filter((ikk) => ikk.label == "Course").length > 0 ? (
              <li>
                <Link to="/course-category" className="waves-effect">
                  <i className="ri-mail-send-line"></i>
                  <span className="ml-1">{this.props.t("Course Categories")}</span>
                </Link>
              </li>
            ) : (
              this.props.userData.sub_admin == true ? null : (
                <li>
                  <Link to="/course-category" className="waves-effect">
                    <i className="ri-mail-send-line"></i>
                    <span className="ml-1">{this.props.t("Course Categories")}</span>
                  </Link>
                </li>
              )
            )}
            {this.props.userData.sub_admin == true && this.props.userData.modules.filter((ikk) => ikk.label == "Course").length > 0 ? (
              <li>
                <Link to="/lecturer" className="waves-effect">
                  <i className="ri-mail-send-line"></i>
                  <span className="ml-1">{this.props.t("Lecturer")}</span>
                </Link>
              </li>
            ) : (
              this.props.userData.sub_admin == true ? null : (
                <li>
                  <Link to="/lecturer" className="waves-effect">
                    <i className="ri-mail-send-line"></i>
                    <span className="ml-1">{this.props.t("Lecturer")}</span>
                  </Link>
                </li>
              )
            )}
            {this.props.userData.sub_admin == true && this.props.userData.modules.filter((ikk) => ikk.label == "Course").length > 0 ? (
              <li>
                <Link to="/course" className="waves-effect">
                  <i className="ri-mail-send-line"></i>
                  <span className="ml-1">{this.props.t("Add Course")}</span>
                </Link>
              </li>
            ) : (
              this.props.userData.sub_admin == true ? null : (
                <li>
                  <Link to="/course" className="waves-effect">
                    <i className="ri-mail-send-line"></i>
                    <span className="ml-1">{this.props.t("Add Course")}</span>
                  </Link>
                </li>
              )
            )}

            {this.props.userData.sub_admin == true && this.props.userData.modules.filter((ikk) => ikk.label == "Coaching").length > 0 ? (
              <li className="menu-title">{this.props.t("Program Menu")}</li>
            ) : (
              this.props.userData.sub_admin == true ? null : (
                <li className="menu-title">{this.props.t("Program Menu")}</li>
              )
            )}
            {this.props.userData.sub_admin == true && this.props.userData.modules.filter((ikk) => ikk.label == "Coaching").length > 0 ? (
              <li>
                <Link to="/coaching" className="waves-effect">
                  <i className="ri-mail-send-line"></i>
                  <span className="ml-1">{this.props.t("Coaching")}</span>
                </Link>
              </li>
            ) : (
              this.props.userData.sub_admin == true ? null : (
                <li>
                  <Link to="/coaching" className="waves-effect">
                    <i className="ri-mail-send-line"></i>
                    <span className="ml-1">{this.props.t("Coaching")}</span>
                  </Link>
                </li>
              )
            )}



            {this.props.userData.sub_admin == true ? null
              : (<li className="menu-title">{this.props.t("Settings")}</li>)}
            {this.props.userData.sub_admin == true ? null : (
              <li>
                <Link to="/#" className="has-arrow waves-effect">
                  <i className="ri-mail-send-line"></i>
                  <span className="ml-1">{this.props.t("Analytics")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/Date-wise-user">
                      {this.props.t("Manage Date Wise User")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/Date-wise-order">
                      {this.props.t("Manage Date Wise Order")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/Date-wise-events">
                      {this.props.t("Manage Date Wise Event Joiners")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/Speaker-wise-events">
                      {this.props.t("Manage Speaker Wise Event Joiners")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/eventHistory">
                      {this.props.t("Add & Manage Event History")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/video-wise-video">
                      {this.props.t("Video Wise Video")}
                    </Link>
                  </li>
                </ul>
              </li>
            )}
            {this.props.userData.sub_admin == true ? null : (
              <li>
                <Link to="/terms-and-conditions" className="waves-effect">
                  <i className="ri-mail-send-line"></i>
                  <span className="ml-1">
                    {this.props.t("Terms and conditions")}
                  </span>
                </Link>
              </li>
            )}
            {this.props.userData.sub_admin == true ? null : (
              <li>
                <Link to="/about-us" className="waves-effect">
                  <i className="ri-mail-send-line"></i>
                  <span className="ml-1">{this.props.t("Privacy Policy")}</span>
                </Link>
              </li>
            )}
            {this.props.userData.sub_admin == true ? null : (
              <li>
                <Link to="/notification" className="waves-effect">
                  <i className="ri-mail-send-line"></i>
                  <span className="ml-1">{this.props.t("Notification")}</span>
                </Link>
              </li>
            )}
            {this.props.userData.sub_admin == true ? null : (
              <li>
                <Link to="/faq" className="waves-effect">
                  <i className="ri-mail-send-line"></i>
                  <span className="ml-1">{this.props.t("FAQ")}</span>
                </Link>
              </li>
            )}

          </ul>
        </div>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  // return { ...state.Layout };
  return {
    layout: state.Layout,
    userData: state.Login.userData,
  };
};

export default withRouter(
  connect(mapStatetoProps, {
    changeLayout,
    changeSidebarTheme,
    changeSidebarType,
    changeLayoutWidth,
    changePreloader,
  })(withNamespaces()(SidebarContent))
);
