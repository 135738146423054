import React, { Component } from "react";
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  FormGroup,
  Label,
  Input,
  CustomInput,
  Button,
  UncontrolledAlert,
  InputGroup,
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { MDBDataTable } from "mdbreact";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { reactLocalStorage } from "reactjs-localstorage";
import moment from "moment";
import HttpClient from "./../../utils/HttpClient";
class FormElements extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: "Subscription", link: "#" },
        { title: "Add & Manage Subscription", link: "#" },
      ],
      customchk: true,
      toggleSwitch: true,
      // page states
      name: "",
      email: "",
      mobile: "",
      password: "",
      data: [],
      alert: false,
      message: "",
      type: "",
      result: [],
      edit_enable: false,
      edit_item_id: "",
      image_select: false,
      img_url: "",
      userId: "",
      userDetails: {},

      image_select: false,
      img_url: "",
      title: "",
      points: [{

      }],
      id: "",
      desc: "",
      duration: "",
    };
    this.handleDefault = this.handleDefault.bind(this);
  }

  componentDidMount = async () => {
    this.checkUser();
  };

  handleDefault(date) {
    this.setState({ default_date: date });
  }

  fetchData = async () => {
    let result = await HttpClient.requestData("admin/subscription", "GET");
    console.log(result);
    if (result && result.status) {
      let data = [];
      let i = 1;
      this.setState({ result: result.data });
      console.log(this.state.pointarr);
      this.state.result.forEach((element, index) => {
        let rows = {
          sl: i,
          //   img: (
          //     <div>
          //       <img
          //         src={element.image}
          //         alt="images"
          //         className="rounded avatar-md card-img"
          //       />
          //     </div>
          //   ),
          title: element.title,
          price: element.price,
          desc: element.Description,
          duration: element.duration,
          subtype: element.subtype,
          points: element.points.length > 0 ? element.points.join() : "",
          status: (
            <button
              title="Delete"
              className={
                element.status ? "btn btn-success mr-2" : "btn btn-danger mr-2"
              }
              onClick={() => {
                this.status(element, index);
              }}
            >
              {element.status ? "Active" : "Not Active"}
            </button>
          ),
          action: (
            <>
              {/* <button
                title="Delete"
                className="btn btn-danger mr-2"
                onClick={() => {
                  this.delete(element, index);
                }}
              >
                <i className="fa fa-trash" />
              </button>{" "} */}
              <button
                title="Edit"
                className="btn btn-primary"
                onClick={() => {
                  this.edit(element, index);
                }}
              >
                <i className="fa fa-edit" />
              </button>
            </>
          ),
        };
        i++;
        data.push(rows);
      });
      this.setState({
        data: data,
      });
    } else {
      this.setState({
        data: [],
      });
    }
  };
  delete = async (item, index) => {
    let sendData = {
      id: item._id,
    };
    console.log(sendData);
    let result = await HttpClient.requestData(
      "admin/ecom_banner/delete",
      "POST",
      sendData
    );
    console.log(result);
    if (result && result.status) {
      let index = this.state.result.indexOf(item);
      if (index > -1) {
        this.state.result.splice(index, 1);
        this.setState({
          alert: true,
          message: "Deleted Successfully",
          type: "success",
        });
        setTimeout(() => {
          this.setState({
            alert: false,
            message: "",
            type: "",
          });
        }, 3000);
        this.fetchData();
      }
    }
  };
  edit = async (item, index) => {
    this.setState({
      edit_enable: true,
      title: item.title,
      price: item.price,
      edit_item_id: item._id,
      points: item.points,
      id: item.id,
      duration: item.duration,
      desc: item.Description,
    });
  };
  status = async (item, index) => {
    let sendData = {
      id: item._id,
      status: !item.status,
    };
    let result = await HttpClient.requestData(
      "admin/ecom_banner/status",
      "POST",
      sendData
    );
    if (result && result.status) {
      this.state.result.splice(index, 1);
      this.setState({
        alert: true,
        message: "Status Updated Successfully",
        type: "success",
      });
      setTimeout(() => {
        this.setState({
          alert: false,
          message: "",
          type: "",
        });
      }, 3000);
      this.fetchData();
    }
  };
  submit = async () => {
    if (
      this.state.title != "" && 
      this.state.price != ""
      ) {
      let data = null;
      let result = null;

      if (this.state.edit_enable == false) {
        data = {
          title: this.state.title,
          price: this.state.price,
          points: this.state.points,
          duration: this.state.duration,
          Description: this.state.desc,
        };
        console.log(data);
        result = await HttpClient.requestData(
          "admin/subscription",
          "POST",
          data
        );
      } else {
        data = {
          _id: this.state.edit_item_id,
          edit_id: this.state.edit_item_id,
          title: this.state.title,
          price: this.state.price,
          points: this.state.points,
          duration: this.state.duration,
          Description: this.state.desc,
        };
        console.log(data);
        result = await HttpClient.requestData(
          "admin/subscription/update",
          "POST",
          data
        );
      }
      console.log("result", result);
      if (result && result.status) {
        this.setState({
          alert: true,
          message: this.state.edit_enable
            ? "Subcription Updated Successfully"
            : "Subcription submitted Successfully",

          type: "success",
          title: "",
          duration: "",
          price: "",
          points: [""],
          desc: "",
          image_select: false,
          img_url: "",
        });

        this.fetchData();
      } else {
        this.setState({
          alert: true,
          message:
            typeof result.error == "string"
              ? result.error
              : result.error[0].msg,
          type: "danger",
        });
      }
    } else {
      this.setState({
        alert: true,
        message: "Please Fill Up All Details.",
        type: "warning",
      });
    }
    setTimeout(() => {
      this.setState({
        alert: false,
        message: "",
        type: "",
      });
    }, 3000);
  };

  imageUpload = async (e) => {
    let file = e.target.files[0].type;
    let fileType = file.split("/")[0];
    // fileType = fileType[0];
    console.log("e", file.split("/")[0]);

    if (fileType == "image" || fileType == "video") {
      // return false;
      this.setState({ fileType: fileType });
      let dataSend = { buckate: true, buckate_name: "appIcon" };

      let result = await HttpClient.newFileUpload(
        "image-upload/movie",
        e.target.files[0],
        dataSend
      );
      console.log("result", result);
      if (result && result.status) {
        this.setState({ img_url: result.url, image_select: true });
      }
    } else {
      this.setState({
        alert: true,
        message: "Please Fill Up All Details.",
        type: "warning",
      });
    }
  };

  checkUser = async () => {
    let user = await reactLocalStorage.getObject("userData");
    if (user != null && Object.keys(user).length > 0) {
      this.setState({
        userId: user.id,
        userDetails: user,
      });
    } else {
    }
    this.fetchData();
  };

  render() {
    const data = {
      columns: [
        {
          label: "Sl.",
          field: "sl",
          sort: "asc",
          width: 150,
        },
        // {
        //   label: "Image",
        //   field: "img",
        //   sort: "asc",
        //   width: 270,
        // },
        {
          label: "Title",
          field: "title",
          sort: "asc",
          width: 270,
        },
        {
          label: "Price",
          field: "price",
          sort: "asc",
          width: 270,
        },
        {
          label: "Duration",
          field: "duration",
          sort: "asc",
          width: 270,
        },
        {
          label: "Description",
          field: "desc",
          sort: "asc",
          width: 270,
        },

        // {
        //   label: "Points",
        //   field: "points",
        //   sort: "asc",
        //   width: 270,
        // },

        // {
        //   label: "Status",
        //   field: "status",
        //   sort: "asc",
        //   width: 100,
        // },
        {
          label: "Action",
          field: "action",
          sort: "asc",
          width: 100,
        },
      ],
      rows: this.state.data,
    };
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title={this.state.breadcrumbItems[0].title}
              breadcrumbItems={this.state.breadcrumbItems}
            />
            {/* {this.state.edit_enable ? ( */}
            <Row>
              <Col xs={12}>
                <Card>
                  <CardBody>
                    {this.state.alert ? (
                      <UncontrolledAlert
                        color={this.state.type}
                        className="alert-dismissible fade show"
                        role="alert"
                      >
                        {this.state.type == "warning" ? (
                          <i className="mdi mdi-alert-outline mr-2"></i>
                        ) : this.state.type == "success" ? (
                          <i className="mdi mdi-check-all mr-2"></i>
                        ) : this.state.type == "danger" ? (
                          <i className="mdi mdi-block-helper mr-2"></i>
                        ) : null}
                        {this.state.message}
                      </UncontrolledAlert>
                    ) : null}

                    <FormGroup row>
                      <Label
                        htmlFor="example-search-input"
                        className="col-md-2 col-form-label"
                      >
                        Title<span style={{color:'red'}}>*</span>
                      </Label>
                      <Col md={10}>
                        <textarea
                          id="question-input1"
                          className="form-control"
                          rows="2"
                          placeholder=""
                          onChange={(val) => {
                            this.setState({ title: val.target.value });
                          }}
                          value={this.state.title}
                        ></textarea>
                      </Col>
                    </FormGroup>

                    <FormGroup row>
                      <Label
                        htmlFor="example-search-input"
                        className="col-md-2 col-form-label"
                      >
                        Price(in USD)<span style={{color:'red'}}>*</span>
                      </Label>
                      <Col md={10}>
                        <input
                          id="question-input1"
                          className="form-control"
                          rows="2"
                          placeholder=""
                          onChange={(val) => {
                            this.setState({ price: val.target.value });
                          }}
                          value={this.state.price}
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label
                        htmlFor="example-search-input"
                        className="col-md-2 col-form-label"
                      >
                        Duration<span style={{color:'red'}}>*</span>
                      </Label>
                      <Col md={10}>
                        <input
                          id="question-input1"
                          className="form-control"
                          rows="2"
                          placeholder=""
                          onChange={(val) => {
                            this.setState({ duration: val.target.value });
                          }}
                          value={this.state.duration}
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label
                        htmlFor="example-search-input"
                        className="col-md-2 col-form-label"
                      >
                        Description
                      </Label>
                      <Col md={10}>
                        <input
                          id="question-input1"
                          className="form-control"
                          rows="2"
                          placeholder=""
                          onChange={(val) => {
                            this.setState({ desc: val.target.value });
                          }}
                          value={this.state.desc}
                        />
                      </Col>
                    </FormGroup>
                    {this.state.points.map((item, index) => {
                      return (
                        <>
                          {index > 0 ? (
                            <Col
                              md={2}
                              style={{ marginTop: index > 0 ? 5 : 0 }}
                            ></Col>
                          ) : null}
                          <FormGroup row>
                            <Label
                              htmlFor="example-search-input"
                              className="col-md-2 col-form-label"
                            >
                             {index == 0 ? "Points" : ""}
                            </Label>
                            <Col md={8}>
                              <Input
                                className="form-control"
                                type="text"
                                value={this.state.points[index].points}
                                id="example-text-input"
                                onChange={(val) => {
                                  this.state.points[index].points =
                                    val.target.value;
                                  this.setState({});
                                }}
                              />
                            </Col>

                            <Col md={2}>
                              <Button
                                color={index > 0 ? "danger" : "success"}
                                type="button"
                                className="waves-effect waves-light customUploadBtn mr-1"
                                onClick={(e) => {
                                  if (index > 0) {
                                    let indexx =
                                      this.state.points.indexOf(item);

                                    if (indexx > -1) {
                                      this.state.points.pop(indexx);
                                      this.setState({});
                                    }
                                  } else {
                                    this.state.points.push({
                                      // name: "",
                                      // desc: "",


                                    });
                                    this.setState({});
                                  }
                                }}
                              >
                                {index > 0 ? " - " : " + "}
                              </Button>
                            </Col>
                          </FormGroup>
                        </>
                      );
                    })}
                     <p style={{marginTop: 5, textAlign: "right" }}>
                       [Note: Asterisk (<span style={{color:'red'}}>*</span>) denotes the mandatory field.]
                       </p>
                    {/* <button
                      type="button"
                      className="btn btn-success"
                      onClick={() => {
                        this.state.points.push("");
                        this.setState({});
                      }}
                    >
                      <i className="mdi mdi-plus" />
                    </button> */}
                    {/* {this.state.points.length > 0 &&
                      this.state.points.map((item, index) => {
                        console.log(item);
                        return (
                          <FormGroup row key={index}>
                            <Label
                              htmlFor="example-search-input"
                              className="col-md-2 col-form-label"
                            >
                              {index == 0 ? "Points" : ""}
                            </Label>
                            <Col md={10}>
                              <input
                                id="question-input1"
                                className="form-control"
                                rows="2"
                                placeholder=""
                                onChange={(val) => {
                                  this.state.points[0] = val.target.value;
                                  this.setState({});
                                }}
                                value={item}
                              />
                              {index == 0 ? null : (
                                <button
                                  type="button"
                                  className="btn btn-danger"
                                  onClick={() => {
                                    this.state.points.pop(index);
                                    this.setState({});
                                  }}
                                >
                                  <i className="mdi mdi-window-close" />
                                </button>
                              )}
                            </Col>
                          </FormGroup>
                        );
                      })}
                    <button
                      type="button"
                      className="btn btn-success"
                      onClick={() => {
                        this.state.points.push("");
                        this.setState({});
                      }}
                    >
                      <i className="mdi mdi-plus" />
                    </button> */}
                    <FormGroup className="mb-0">
                      <div className="button-items pt-4">
                        <Button
                          color="primary"
                          type="button"
                          className="waves-effect waves-light mr-1"
                          onClick={() => {
                            this.submit();
                          }}
                        >
                          {this.state.edit_enable ? "Update" : "Submit"}{" "}
                          <i className="ri-arrow-right-line align-middle ml-1"></i>
                        </Button>
                      </div>
                    </FormGroup>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            {/* ) : null} */}
            <Row>
              <Col xl={12}>
                <div className="card p-3">
                  <MDBDataTable responsive bordered data={data} />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default FormElements;
