import React, { Component } from "react";
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  FormGroup,
  Label,
  Input,
  CustomInput,
  Button,
  UncontrolledAlert,
  InputGroup,
  InputGroupAddon
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { MDBDataTable } from "mdbreact";
import { reactLocalStorage } from "reactjs-localstorage";
import moment from "moment";
import Dropzone from "react-dropzone";
import BaseInfo from "../ChooseDesign/baseInfo";
import HttpClient from "./../../utils/HttpClient";
import EcomPreview from "./E-ComPreview/index";
import Select from "react-select";
import ColorPicker from "@vtaits/react-color-picker";
import "@vtaits/react-color-picker/dist/index.css";
import index from "../ChooseDesign";
import { Link } from "react-router-dom";
class FormElements extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: "Product", link: "#" },
        { title: "Add Product", link: "#" },
      ],
      customchk: true,
      toggleSwitch: true,
      // page states
      name: "",
      email: "",
      mobile: "",
      password: "",
      data: [],
      alert: false,
      message: "",
      type: "",
      result: [],
      edit_enable: false,
      edit_item_id: "",
      image_select: false,
      img_url: "",
      userId: "",
      userDetails: {},
      //   this page states
      category: [],
      categoryAll: [],
      subCategory: [],
      subCategoryAll: [],

      cat_id: "",
      // pruduct_type: "",
      sub_cat_id: "",

      selectedFiles: [],
      images: [],
      img: [],

      sell_price: "",
      purchase_price: "",
      discount: "",
      availableSize: [],
      availableColor: [],

      optionGroupSize: [],
      selectedMultiSize: [],

      showSelectColor: false,
      showSelect: false,
      colorRgb: "#4667CC",
      simple_color1: false,
      color: [{ code: "", active: false }],
      // colorChoose: "",
      created_by: "",
      updated_by: "",
    };
    this.handleMulti = this.handleMulti.bind(this);
    this.onDragRgb = this.onDragRgb.bind(this);
  }
  onDragRgb(index, c1) {
    // alert(c1);
    this.state.color[index].code = c1;
    this.setState({ colorRgb: c1 });
  }

  handleMulti = (selectedMultiSize) => {
    this.setState({ selectedMultiSize });
    setTimeout(() => {
      console.log('selectedMulti', selectedMultiSize)
    })
  };

  componentDidMount = async () => {
    this.checkUser();
    this.fetchCat();
  };

  fetchCat = async () => {
    let result = await HttpClient.requestData("admin/category", "GET");
    if (result && result.status > 0) {
      this.setState({ categoryAll: result.data });
    }
    console.log('result cat', result);
    let resultsub = await HttpClient.requestData(
      "admin/sub-category",
      "GET"
    );
    console.log("resultsub", resultsub);
    if (resultsub && resultsub.status > 0) {
      this.setState({ subCategoryAll: resultsub.data });
    }
  };

  fetchData = async () => {
    let result = await HttpClient.requestData(
      "admin/product",
      "GET"
    );
    console.log("admin/product", result);
    if (result && result.status > 0) {
      let data = [];
      let i = 1;
      this.setState({ result: result.data });
      this.state.result.forEach((element, index) => {
        let rows = {
          sl: i,
          name: element.name,
          created_by: element.created_by ? element.created_by.name + " on " + moment(element.created_on).utcOffset(element.created_on).format('DD/MM/YYYY, HH:mm') : "" + " on " + moment(element.created_on).utcOffset(element.created_on).format('DD/MM/YYYY, HH:mm'),
          updated_by: element.updated_by ? element.updated_by.name + " on " + moment(element.updated_on).utcOffset(element.updated_on).format('DD/MM/YYYY, HH:mm') : "" + "",
          own_event: element.own_event ? "Yes" : "No",
          img:
            element.img.length > 0 ? (
              <div>
                <img
                  src={element.img[0].preview}
                  alt="images"
                  className="rounded avatar-md card-img"
                />
              </div>
            ) : null,
          // product_type: element.pruduct_type ? element.pruduct_type : "",
          category: element.categoryData ? element.categoryData.name : "",

          subcategory: element.subcategoryData ? element.subcategoryData.name : "",
          sell_price: element.sell_price !== 0 ? element.sell_price : 0,
          purchase_price: element.purchase_price ? element.purchase_price : "",
          // discount: element.discount ? element.discount : "",
          status: (
            <button
              title="Delete"
              className={
                element.status ? "btn btn-success mr-2" : "btn btn-danger mr-2"
              }
              onClick={() => {
                this.status(element, index);
              }}
            >
              {element.status ? "Active" : "Not Active"}
            </button>
          ),
          tranding: (
            <button
              title="Delete"
              className={
                element.tranding ? "btn btn-success mr-2" : "btn btn-danger mr-2"
              }
              onClick={() => {
                this.tranding(element, index);
              }}
            >
              {element.tranding ? "Active" : "Not Active"}
            </button>
          ),
          review: (
            <Link
              className="btn btn-success mr-2"
              to={"/review/" + element.id}
            >
              Review
            </Link>
          ),
          action: (
            <>
              {this.state.userDetails.sub_admin == true ? null : (
                <button
                  title="Delete"
                  className="btn btn-danger mr-2"
                  onClick={() => {
                    if (window.confirm('Are you sure you wish to delete this item?'))
                      this.delete(element, index)
                  }}
                >
                  <i className="fa fa-trash" />
                </button>
              )}
              <button
                title="Edit"
                className="btn btn-primary"
                onClick={() => {
                  this.edit(element, index);
                }}
              >
                <i className="fa fa-edit" />
              </button>
            </>
          ),
        };
        i++;
        data.push(rows);
      });
      this.setState({
        data: data,
      });
    } else {
      this.setState({
        data: [],
      });
    }
  };
  delete = async (item, index) => {
    let sendData = {
      id: item._id,
    };
    let result = await HttpClient.requestData(
      "admin/product/delete",
      "POST",
      sendData
    );
    if (result && result.status) {
      let index = this.state.result.indexOf(item);
      if (index > -1) {
        this.state.result.splice(index, 1);
        this.setState({
          alert: true,
          message: "Deleted Successfully",
          type: "success",
        });
        setTimeout(() => {
          this.setState({
            alert: false,
            message: "",
            type: "",
          });
        }, 3000);
        this.fetchData();
      }
    }
  };
  edit = async (item, index) => {
    console.log("*****EditSelected****");
    let filterSub = this.state.subCategoryAll.filter(
      (it) => it.category_id == item.cat_id
    );
    let catSize = this.state.categoryAll.filter((ig) => ig.id == item.cat_id);
    let optionAppowner = {};
    optionAppowner.label = "Sizes";
    optionAppowner.options = [];
    // catSize[0].size.map((item, index) => {
    //   optionAppowner.options.push({
    //     label: item.name,
    //     value: item.name
    //   });
    // });

    let newOptions = [];
    newOptions.push(optionAppowner);

    this.setState({ optionGroupSize: newOptions, showSelect: true });

    this.setState({ subCategory: filterSub, showSelect: true });
    this.setState({
      edit_enable: true,
      name: item.name,
      // img_url: item.img,
      image_select: true,
      edit_item_id: item._id,
      cat_id: item.cat_id,
      sub_cat_id: item.sub_cat_id,
      desc: item.desc,
      // pruduct_type: item.pruduct_type,
      sell_price: Number(item.sell_price),
      purchase_price: Number(item.purchase_price),
      discount: Number(item.discount),
      img: item.img,
      selectedMultiSize: item.size,
      color: item.color && item.color.length > 0 ? item.color : [{ code: "", active: false }],
      // colorChoose: item.color,
      own_event: typeof item.own_event == "undefined" ? false : item.own_event,
      event_url: typeof item.event_url == "undefined" ? "" : item.event_url,

    });
    console.log("color", item.color);
  };
  status = async (item, index) => {
    let sendData = {
      id: item._id,
      status: !item.status,
    };
    let result = await HttpClient.requestData(
      "admin/product/status",
      "POST",
      sendData
    );
    if (result && result.status) {
      this.state.result.splice(index, 1);
      this.setState({
        alert: true,
        message: "Status Updated Successfully",
        type: "success",
      });
      setTimeout(() => {
        this.setState({
          alert: false,
          message: "",
          type: "",
        });
      }, 3000);
      this.fetchData();
    }
  };
  tranding = async (item, index) => {
    let sendData = {
      id: item.id,
      tranding: !item.tranding,
    };
    let result = await HttpClient.requestData(
      "admin/product/tranding",
      "POST",
      sendData
    );
    if (result && result.status) {
      this.state.result.splice(index, 1);
      this.setState({
        alert: true,
        message: "Trending Updated Successfully",
        type: "success",
      });
      setTimeout(() => {
        this.setState({
          alert: false,
          message: "",
          type: "",
        });
      }, 3000);
      this.fetchData(this.state.selectedType);
    }
  };
  submit = async () => {
    if (
      this.state.name != "" &&
      this.state.cat_id != "" &&
      this.state.sub_cat_id != ""
      // this.state.pruduct_type != "" 
    ) {
      if (!this.state.own_event && this.state.sell_price == '') {
        this.setState({
          alert: true,
          message: "Please Fill Sellprice.",
          type: "warning",
        });
        return;
      }
      if (this.state.img.length > 0) {
        this.setState({ loading: true });

        let data = null;
        let result = null;

        if (this.state.edit_enable) {
          console.log("****Edit****");
          data = {
            name: this.state.name,
            cat_id: this.state.cat_id,
            sub_cat_id: this.state.sub_cat_id,
            desc: this.state.desc,
            // pruduct_type: this.state.pruduct_type,
            // img: JSON.stringify(this.state.uploaded_images),
            img: this.state.img,
            size: this.state.selectedMultiSize,
            color: this.state.color.length > 0 ? this.state.color[0].code !== "" ? this.state.color : [] : [],
            // colorChoose: this.state.color,
            edit_id: this.state.edit_item_id,
            own_event: this.state.own_event,
            event_url: this.state.event_url,
            updated_by: this.state.userId,
          };
          if (this.state.own_event) {
            data.event_url = this.state.event_url;
          }
          else {
            data.event_url = null;
          }
          if (!this.state.own_event) {
            data.sell_price = this.state.sell_price;
            data.discount = this.state.discount;
            data.purchase_price = this.state.purchase_price;
          } else {
            data.sell_price = null;
            data.discount = null;
            data.purchase_price = null;
          }

          console.log("EditDataaaaaaaaaaa", data);
          result = await HttpClient.requestData(
            "admin/product/update",
            "POST",
            data
          );
        }
        else {
          console.log("****Submit****");
          data = {
            name: this.state.name,
            cat_id: this.state.cat_id,
            sub_cat_id: this.state.sub_cat_id,
            desc: this.state.desc,
            // pruduct_type: this.state.pruduct_type,
            sell_price: Number(this.state.sell_price),
            purchase_price: Number(this.state.purchase_price),
            discount: Number(this.state.discount),
            owner_id: this.state.userId,
            // img: JSON.stringify(this.state.uploaded_images),
            img: this.state.img,
            size: this.state.selectedMultiSize,
            color: this.state.color[0].code !== "" ? this.state.color : [],
            // colorChoose: this.state.color,
            own_event: this.state.own_event,
            event_url: this.state.event_url,
            created_by: this.state.userId,
          };
          console.log("SubmitDataaaaaaaaaa", data);
          result = await HttpClient.requestData(
            "admin/product",
            "POST",
            data
          );

        }


        if (result && result.status) {
          this.setState({
            alert: true,
            message: this.state.edit_enable
              ? "Product Updated Successfully"
              : "Product Added Successfully",
            type: "success",
            name: "",
            cat_id: "",
            sub_cat_id: "",
            desc: "",
            // pruduct_type: "",
            sell_price: "",
            purchase_price: "",
            discount: "",
            img: [],
            images: [],
            selectedFiles: [],
            edit_enable: false,
            edit_item_id: "",
            selectedMultiSize: [],
            colorRgb: "#4667CC",
            simple_color1: false,
            color: [{ code: "", active: false }],
            own_event: false,
            event_url: "",
            // colorChoose: "",
          });

          this.fetchData();
        } else {
          this.setState({
            alert: true,
            message:
              typeof result.error == "string"
                ? result.error
                : result.error[0].msg,
            type: "danger",
          });
        }

      } else {
        this.setState({
          alert: true,
          message: "Please Select Atleast One Image.",
          type: "warning",
        });
      }
    } else {
      this.setState({
        alert: true,
        message: "Please Fill Up All Details.",
        type: "warning",
      });
    }
    setTimeout(() => {
      this.setState({
        alert: false,
        message: "",
        type: "",
      });
    }, 3000);
  };

  imageUpload = async (file, index) => {
    console.log("e", file);

    let dataSend = { buckate: false };

    let result = await HttpClient.newFileUpload(
      "image-upload/product",
      file,
      dataSend
    );
    if (result && result.status) {
      this.state.img.push({ preview: result.url });
      if (this.state.images.length - 1 == index) {
        setTimeout(() => {
          this.setState({ loading: false });
        }, 200);
      }
      this.setState({});
    }
  };

  handleAcceptedFiles = (files) => {
    let data = [];
    let file = {
      preview: URL.createObjectURL(files[0]),
      formattedSize: this.formatBytes(files[0].size),
      // image:files,
    };
    let file2 = {
      image: files,
    };

    let file3 = files;
    console.log("data", file);
    this.state.selectedFiles.push(file);
    this.state.images.push(files[0]);
    this.imageUpload(files[0]);
    // console.log("data", this.state.images);
    this.setState({});

    // console.log('files',files)
    // files.map(file =>
    //   Object.assign(file, {
    //     preview: URL.createObjectURL(file),
    //     formattedSize: this.formatBytes(file.size)
    //   })
    // );

    // this.setState({ selectedFiles: files });
  };

  async remove_image(item, index) {
    this.state.selectedFiles.pop(index);
    this.state.images.pop(index);
    this.state.img.pop(index);
    this.setState({});
  }

  /**
   * Formats the size
   */
  formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  checkUser = async () => {
    let user = await reactLocalStorage.getObject("userData");
    if (user != null && Object.keys(user).length > 0) {
      this.setState({
        userId: user.id,
        userDetails: user,
      });
    } else {
    }
    this.fetchData();
  };

  handleChange = async (event) => {
    let filterSub = this.state.subCategoryAll.filter(
      (item) => item.category_id == event.target.value
    );
    this.setState({ cat_id: event.target.value, subCategory: filterSub });


    let catSize = this.state.categoryAll.filter((item) => item.id == event.target.value);
    console.log("catSize", catSize)
    let optionAppowner = {};
    optionAppowner.label = "Sizes";
    optionAppowner.options = [];
    catSize[0].size.map((item, index) => {
      optionAppowner.options.push({
        label: item.name,
        value: item.name
      });
    });

    let newOptions = [];
    newOptions.push(optionAppowner);

    this.setState({ optionGroupSize: newOptions, showSelect: true, selectedMultiSize: [] });

    setTimeout(() => {
      console.log('optionGroupSize', this.state.optionGroupSize)
    }, 200)

  };

  render() {
    const data = {
      columns: [
        {
          label: "Sl.",
          field: "sl",
          sort: "asc",
          width: 150,
        },
        {
          label: "External Products",
          field: "own_event",
          sort: "asc",
          width: 270,
        },
        {
          label: "Name",
          field: "name",
          sort: "asc",
          width: 270,
        },
        {
          label: "Image",
          field: "img",
          sort: "asc",
          width: 270,
        },
        {
          label: "Created By",
          field: "created_by",
          sort: "asc",
          width: 270,
        },
        {
          label: "Updated By",
          field: "updated_by",
          sort: "asc",
          width: 270,
        },
        {
          label: "Categories",
          field: "category",
          sort: "asc",
          width: 270,
        },
        {
          label: "Sub Categories",
          field: "subcategory",
          sort: "asc",
          width: 270,
        },
        {
          label: "Sell Price",
          field: "sell_price",
          sort: "asc",
          width: 270,
        },
        // {
        //   label: "Purchase Price",
        //   field: "purchase_price",
        //   sort: "asc",
        //   width: 270,
        // },
        // {
        //   label: "Discount",
        //   field: "discount",
        //   sort: "asc",
        //   width: 270,
        // },
        {
          label: "Status",
          field: "status",
          sort: "asc",
          width: 100,
        },
        {
          label: "Trending",
          field: "tranding",
          sort: "asc",
          width: 100,
        },
        {
          label: "Review",
          field: "review",
          sort: "asc",
          width: 100,
        },
        {
          label: "Action",
          field: "action",
          sort: "asc",
          width: 100,
        },
      ],
      rows: this.state.data,
    };
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title={this.state.breadcrumbItems[0].title}
              breadcrumbItems={this.state.breadcrumbItems}
            />

            <Row>
              {/* <BaseInfo /> */}
              <Col xs={12}>
                <Card>
                  <CardBody>
                    {this.state.alert ? (
                      <UncontrolledAlert
                        color={this.state.type}
                        className="alert-dismissible fade show"
                        role="alert"
                      >
                        {this.state.type == "warning" ? (
                          <i className="mdi mdi-alert-outline mr-2"></i>
                        ) : this.state.type == "success" ? (
                          <i className="mdi mdi-check-all mr-2"></i>
                        ) : this.state.type == "danger" ? (
                          <i className="mdi mdi-block-helper mr-2"></i>
                        ) : null}
                        {this.state.message}
                      </UncontrolledAlert>
                    ) : null}

                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        External Products
                      </Label>
                      <Col md={10}>
                        <select
                          className="form-control"
                          onChange={(val) => {
                            if (val.target.value == "No") {
                              this.setState({ own_event: false });
                            } else {
                              this.setState({ own_event: true });
                            }
                          }}
                          value={this.state.own_event ? "Yes" : "No"}
                        >
                          <option value="" selected disabled>
                            Select One
                          </option>

                          <option value="Yes">Yes</option>
                          <option value="No">No</option>
                        </select>
                      </Col>
                    </FormGroup>
                    {this.state.own_event == true && (
                      <FormGroup row>
                        <Label
                          htmlFor="example-text-input"
                          className="col-md-2 col-form-label"
                        >
                          Product Url
                        </Label>
                        <Col md={10}>
                          <Input
                            className="form-control"
                            type="text"
                            value={this.state.event_url}
                            id="example-text-input"
                            onChange={(val) => {
                              this.setState({ event_url: val.target.value });
                            }}
                          />
                        </Col>
                      </FormGroup>
                    )}
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Product Name<span style={{ color: 'red' }}>*</span>
                      </Label>
                      <Col md={10}>
                        <Input
                          className="form-control"
                          type="text"
                          value={this.state.name}
                          id="example-text-input"
                          onChange={(val) => {
                            this.setState({ name: val.target.value });
                          }}
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label
                        htmlFor="example-search-input"
                        className="col-md-2 col-form-label"
                      >
                        Description<span style={{ color: 'red' }}>*</span>
                      </Label>
                      <Col md={10}>
                        <textarea
                          id="question-input1"
                          className="form-control"
                          rows="2"
                          onChange={(val) => {
                            this.setState({ desc: val.target.value });
                          }}
                          value={this.state.desc}
                        ></textarea>
                      </Col>
                    </FormGroup>
                    {/* <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Product Type<span style={{color:'red'}}>*</span>
                      </Label>
                      <Col md={10}>
                        <select
                          className="form-control"
                          onChange={(event) => {
                            let cat = this.state.categoryAll.filter(
                              (item, index) =>
                                item.category_type == event.target.value
                            );

                            this.setState({
                              pruduct_type: event.target.value,
                              category: cat,
                            });
                          }}
                          value={this.state.pruduct_type}
                        >
                          <option value="">Select Product Type</option>
                          <option value="Product Type">Product Based</option>
                          <option value="Service Type">Service Based</option>
                        </select>
                      </Col>
                    </FormGroup> */}

                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Categories<span style={{ color: 'red' }}>*</span>
                      </Label>
                      <Col md={4}>
                        <select
                          className="form-control"
                          onChange={this.handleChange}
                          value={this.state.cat_id}
                        >
                          <option value="">Select Categories</option>
                          {this.state.categoryAll.map((item, index) => {
                            return (
                              <option value={item.id} key={index}>
                                {item.name}
                              </option>
                            );
                          })}
                        </select>
                      </Col>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Sub Categories<span style={{ color: 'red' }}>*</span>
                      </Label>
                      <Col md={4}>
                        <select
                          className="form-control"
                          onChange={(event) => {
                            this.setState({ sub_cat_id: event.target.value });
                          }}
                          value={this.state.sub_cat_id}
                        >
                          <option value="">Select Sub Categories</option>

                          {this.state.subCategory.map((item, index) => {
                            return (
                              <option value={item.id} key={index}>
                                {item.name}
                              </option>
                            );
                          })}
                        </select>
                      </Col>
                    </FormGroup>
                    <FormGroup row>

                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Size
                      </Label>
                      <Col md={10}>
                        {
                          console.log("this.state.optionGroupSize", this.state.optionGroupSize)
                        }
                        {this.state.showSelect ? <Select
                          isMulti={true}
                          options={this.state.optionGroupSize}
                          values={this.state.selectedMultiSize}
                          onChange={
                            // (values) => this.setValues(values)
                            this.handleMulti
                          }
                          value={this.state.selectedMultiSize}
                        /> : null}
                      </Col>
                    </FormGroup>

                    <FormGroup row>
                      {this.state.color.map((item, index) => {
                        return (<>
                          <Label
                            htmlFor="example-text-input"
                            className="col-md-2 col-form-label"
                            style={{ marginTop: index > 0 ? 5 : 0 }}
                          >
                            {index > 0 ? "" : "Color"}
                          </Label>
                          <Col md={6} style={{ marginTop: index > 0 ? 5 : 0 }}>
                            <InputGroup className="colorpicker-default"
                              title="Using format option">

                              <Input type="text" value={item.code} className="form-control input-lg" maxLength="7"
                                onChange={(val) => {
                                  // this.state.color[index].active = !this.state.color[index].active;
                                  this.state.color[index].code = val.target.value;
                                  this.setState({});
                                }}
                                onClick={() => {
                                  this.state.color[index].active = !this.state.color[index].active;
                                  this.setState({});
                                }} />
                              <InputGroupAddon addonType="append">
                                <span
                                  className="input-group-text colorpicker-input-addon"
                                  // value={"#" + this.state.colorChoose}
                                  onClick={() => {
                                    this.state.color[index].active = !this.state.color[index].active;
                                    this.setState({});
                                  }}
                                >
                                  <i style={{
                                    height: "16px",
                                    width: "16px",
                                    background: item.code
                                  }}></i>
                                </span>
                              </InputGroupAddon>
                            </InputGroup>
                          </Col>
                          <Col md={2} style={{ marginTop: index > 0 ? 5 : 0 }}>
                            <Button
                              color={index > 0 ? "danger" : "success"}
                              type="button"
                              className="waves-effect waves-light customUploadBtn mr-1"
                              onClick={(e) => {
                                if (index > 0) {
                                  let indexx = this.state.color.indexOf(
                                    item
                                  );
                                  if (indexx > -1) {
                                    this.state.color.pop(indexx);
                                    this.setState({})
                                  }
                                }
                                else {
                                  this.state.color.push({ active: false });
                                  this.setState({})
                                }
                              }}
                            >
                              {index > 0 ? " - Remove" : " + Add More"}
                            </Button>
                          </Col>
                          {item.active ?
                            <ColorPicker saturationHeight={100} saturationWidth={100}
                              value={item.code != '' ? item.code : this.state.colorRgb}
                              onDrag={this.onDragRgb.bind(this, index)} />
                            : <Col md={2}></Col>}
                        </>)
                      })

                      }

                    </FormGroup>

                    {this.state.own_event == true ? null : (
                      <>
                        <FormGroup row>
                          <Label
                            htmlFor="example-text-input"
                            className="col-md-2 col-form-label"
                          >
                            Sell Price(in USD)<span style={{ color: 'red' }}>*</span>
                          </Label>
                          <Col md={4}>
                            <Input
                              className="form-control"
                              type="number"
                              value={this.state.sell_price}
                              id="example-text-input"
                              onChange={(val) => {
                                this.setState({ sell_price: val.target.value });
                              }}
                              min="0"
                            />
                          </Col>
                          <Label
                            htmlFor="example-text-input"
                            className="col-md-2 col-form-label"
                          >
                            Purchase Price(in USD)
                          </Label>
                          <Col md={4}>
                            <Input
                              className="form-control"
                              type="number"
                              value={this.state.purchase_price}
                              id="example-text-input"
                              onChange={(val) => {
                                this.setState({ purchase_price: val.target.value });
                              }}
                              min="0"
                            />
                          </Col>
                        </FormGroup>

                        <FormGroup row>
                          <Label
                            htmlFor="example-text-input"
                            className="col-md-2 col-form-label"
                          >
                            Discount(in %)
                          </Label>
                          <Col md={4}>
                            <Input
                              className="form-control"
                              type="number"
                              value={this.state.discount}
                              id="example-text-input"
                              onChange={(val) => {
                                this.setState({ discount: val.target.value });
                              }}
                              min="0"
                            />
                          </Col>
                          {/* <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Purchase Price
                      </Label>
                      <Col md={4}>
                        <Input
                          className="form-control"
                          type='number'
                          value={this.state.purchase_price}
                          id="example-text-input"
                          onChange={(val) => {
                            this.setState({ purchase_price: val.target.value });
                          }}
                          min="0"
                        />
                      </Col> */}
                        </FormGroup>
                      </>
                    )}
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Image<span style={{ color: 'red' }}>*</span>
                      </Label>
                      <Col md={10}>
                        <Dropzone
                          accept=".jpeg,.jpg,.png,.svg,.webp,.jfif,.apng"
                          onDrop={(acceptedFiles) =>
                            this.handleAcceptedFiles(acceptedFiles)
                          }
                        >
                          {({ getRootProps, getInputProps }) => (
                            <div className="dropzone">
                              <div
                                className="dz-message needsclick mt-2"
                                {...getRootProps()}
                              >
                                <input {...getInputProps()} />
                                <div className="mb-3">
                                  <i className="display-4 text-muted ri-upload-cloud-2-line"></i>
                                </div>
                                <h4>Drop files here or click to upload.</h4>
                              </div>
                            </div>
                          )}
                        </Dropzone>
                        <div
                          className="dropzone-previews mt-3"
                          id="file-previews"
                        >
                          <p style={{ color: 'red' }}>[image should be in dimensions 500 * 500 px]</p>
                          {this.state.img.map((f, i) => {
                            return (
                              <Card
                                className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                key={i + "-file"}
                              >
                                <div className="p-2">
                                  <Row className="align-items-center">
                                    <Col className="col-auto">
                                      <img
                                        data-dz-thumbnail=""
                                        height="80"
                                        className="avatar-sm rounded bg-light"
                                        // alt={f.name}
                                        src={f.preview}
                                      />
                                    </Col>
                                    <Col>
                                      <Button
                                        className="btn-icon"
                                        color="danger"
                                        style={{ marginRight: 25 }}
                                        onClick={(e) => this.remove_image(f, i)}
                                      >
                                        {" "}
                                        <span className="btn-icon-label">
                                          <i className="mdi mdi-alpha-x-box-outline mr-2"></i>
                                        </span>{" "}
                                        Remove
                                      </Button>
                                    </Col>
                                  </Row>
                                </div>
                              </Card>
                            );
                          })}
                        </div>
                      </Col>
                    </FormGroup>
                    <p style={{ marginTop: 5, textAlign: "right" }}>
                      [Note: Asterisk (<span style={{ color: 'red' }}>*</span>) denotes the mandatory field.]
                    </p>
                    <FormGroup className="mb-0">
                      <div className="button-items pt-4">
                        <Button
                          color="primary"
                          type="button"
                          className="waves-effect waves-light mr-1"
                          onClick={() => {
                            this.submit();
                          }}
                        >
                          {this.state.edit_enable ? "Update" : "Submit"}{" "}
                          <i className="ri-arrow-right-line align-middle ml-1"></i>
                        </Button>
                      </div>
                    </FormGroup>
                  </CardBody>
                </Card>
              </Col>
              <Col xl={4}>
                {/* <div>
                    <EcomPreview />
                  </div> */}
              </Col>
            </Row>
            <Row>
              <Col xl={12}>
                <div className="card p-3">
                  <div class="d-flex justify-content-between w-100">
                    <h5>PRODUCT</h5>
                    {this.state.userDetails.sub_admin == true ? null : (
                      <Link
                        className="btn btn-danger btn-right mr-2"
                        to={"/product-undo/"}
                      >
                        Deleted Product List
                      </Link>
                    )}
                  </div>
                  <MDBDataTable responsive bordered data={data} />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default FormElements;
