import React, { Component } from "react";
import {
    Card,
    CardBody,
    Col,
    Row,
    Container,
    FormGroup,
    Label,
    Input,
    CustomInput,
    Button,
    UncontrolledAlert,
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { MDBDataTable } from "mdbreact";
import { reactLocalStorage } from "reactjs-localstorage";
import moment from "moment";
import HttpClient from "./../../utils/HttpClient";
import { Link } from "react-router-dom";

class FormElements extends Component {
    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems: [
                { title: "Event Joiners", link: "#" },
                { title: "Manage Event Joiners", link: "#" },
            ],
            customchk: true,
            toggleSwitch: true,
            // page states
            name: "",
            desc: "",
            duration: "",
            price: "",
            data: [],
            alert: false,
            message: "",
            type: "",
            result: [],
            edit_enable: false,
            edit_item_id: "",
            image_select: false,
            img_url: "",
            category_type: "",
            event_id: this.props.match.params.id,
        };
    }

    componentDidMount = async () => {
        this.fetchData();
    };

    fetchData = async () => {
        let result = await HttpClient.requestData(
            "admin/event/event-wise-event-join",
            "POST",
            {
                event_id: Number(this.state.event_id)
            }
        );

        if (result && result.status > 0) {
            let data = [];
            let i = 1;
            this.setState({ result: result.data });
            this.state.result && this.state.result.forEach((element, index) => {
                let rows = {
                    sl: i,
                    image: (
                        <div>
                            <img
                                src={element.image ? element.image : ""}
                                alt="images"
                                className="rounded avatar-md card-img"
                            />
                        </div>
                    ),
                    name: element.firstname + " " + element.lastname,
                    email: element.email,
                    rgt_date: moment(element.created_on).format("LL"),
                };
                i++;
                data.push(rows);
            });
            this.setState({
                data: data,
            });
        } else {
            this.setState({
                data: [],
            });
        }
    };
    delete = async (item, index) => {
        let sendData = {
            id: item._id,
        };
        let result = await HttpClient.requestData(
            "admin/user/delete-user",
            "POST",
            sendData
        );
        if (result && result.status) {
            let index = this.state.result.indexOf(item);
            if (index > -1) {
                this.state.result.splice(index, 1);
                this.setState({
                    alert: true,
                    message: "Deleted Successfully",
                    type: "success",
                });
                setTimeout(() => {
                    this.setState({
                        alert: false,
                        message: "",
                        type: "",
                    });
                }, 3000);
                this.fetchData();
            }
        }
    };
    status = async (item, index) => {
        let sendData = {
            id: item._id,
            status: !item.status,
        };
        let result = await HttpClient.requestData(
            "admin/user/status-user",
            "POST",
            sendData
        );
        if (result && result.status) {
            this.state.result.splice(index, 1);
            this.setState({
                alert: true,
                message: "Status Updated Successfully",
                type: "success",
            });
            setTimeout(() => {
                this.setState({
                    alert: false,
                    message: "",
                    type: "",
                });
            }, 3000);
            this.fetchData();
        }
    };
    vipStatus = async (item, index) => {
        let sendData = {
            id: item._id,
            vip: !item.vip,
        };
        let result = await HttpClient.requestData(
            "admin/user/status-user-vip",
            "POST",
            sendData
        );
        if (result && result.status) {
            this.state.result.splice(index, 1);
            this.setState({
                alert: true,
                message: "VIP Status Updated Successfully",
                type: "success",
            });
            setTimeout(() => {
                this.setState({
                    alert: false,
                    message: "",
                    type: "",
                });
            }, 3000);
            this.fetchData();
        }
    };
    edit = async (item, index) => {
        this.setState({
            edit_enable: true,
            name: item.name,
            // category_type: item.category_type,
            edit_item_id: item._id,
            img_url: item.img,
            image_select: true,
            desc: item.desc,
        });
    };
    submit = async () => {
        if (
            this.state.name != ""
            // this.state.category_type != "" &&
            // this.state.img_url != ""
        ) {
            let data = null;
            let result = null;

            if (this.state.edit_enable == false) {
                data = {
                    name: this.state.name,
                    // category_type: this.state.category_type,
                    // img: this.state.img_url,
                    desc: this.state.desc,
                };
                result = await HttpClient.requestData("admin/jdcategory", "POST", data);
            } else {
                data = {
                    name: this.state.name,
                    // category_type: this.state.category_type,
                    // img: this.state.img_url,
                    desc: this.state.desc,
                    id: this.state.edit_item_id,
                };
                result = await HttpClient.requestData(
                    "admin/jdcategory/update",
                    "POST",
                    data
                );
            }
            console.log("result", result);
            if (result && result.status) {
                this.setState({
                    alert: true,
                    message: this.state.edit_enable
                        ? "Category Updated Successfully"
                        : "Category Added Successfully",
                    type: "success",
                    name: "",
                    img_url: "",
                    // category_type:'',
                    image_select: false,
                    edit_enable: false,
                    edit_item_id: "",
                    desc: "",
                });

                this.fetchData();
            } else {
                this.setState({
                    alert: true,
                    message: result.error,
                    type: "danger",
                });
            }
        } else {
            this.setState({
                alert: true,
                message: "Please Fill Up All Details.",
                type: "warning",
            });
        }
        setTimeout(() => {
            this.setState({
                alert: false,
                message: "",
                type: "",
            });
        }, 3000);
    };

    handleChange = (event) => {
        console.log(event.target.value);
        this.setState({ category_type: event.target.value });
    };

    imageUpload = async (e) => {
        console.log("e", e.target.files);
        let file = e.target.files;
        //   if(file.lenght>0)
        //   {
        let dataSend = { buckate: true, buckate_name: "appIcon" };

        let result = await HttpClient.newFileUpload(
            "image-upload/category",
            e.target.files[0],
            dataSend
        );
        // console.log("result", result);
        if (result && result.status) {
            this.setState({ img_url: result.url, image_select: true });
        }

        //   }
    };

    render() {
        const data = {
            columns: [
                {
                    label: "Sl.",
                    field: "sl",
                    sort: "asc",
                    width: 150,
                },
                {
                    label: "Image",
                    field: "image",
                    sort: "asc",
                    width: 270,
                },
                {
                    label: "Name",
                    field: "name",
                    sort: "asc",
                    width: 270,
                },
                {
                    label: "Email",
                    field: "email",
                    sort: "asc",
                    width: 270,
                },
                {
                    label: "Rgt. Date",
                    field: "rgt_date",
                    sort: "asc",
                    width: 270,
                },
            ],
            rows: this.state.data,
        };
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs
                            title={this.state.breadcrumbItems[0].title}
                            breadcrumbItems={this.state.breadcrumbItems}
                        />
                        <Row>
                            <Col xl={12}>
                                {this.state.alert ? (
                                    <UncontrolledAlert
                                        color={this.state.type}
                                        className="alert-dismissible fade show"
                                        role="alert"
                                    >
                                        {this.state.type == "warning" ? (
                                            <i className="mdi mdi-alert-outline mr-2"></i>
                                        ) : this.state.type == "success" ? (
                                            <i className="mdi mdi-check-all mr-2"></i>
                                        ) : this.state.type == "danger" ? (
                                            <i className="mdi mdi-block-helper mr-2"></i>
                                        ) : null}
                                        {this.state.message}
                                    </UncontrolledAlert>
                                ) : null}
                                <div className="card p-3">
                                    <MDBDataTable responsive bordered data={data} />
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default FormElements;
