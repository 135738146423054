import React, { Component } from "react";
import {
    Card,
    CardBody,
    Col,
    Row,
    Container,
    FormGroup,
    Label,
    Input,
    CustomInput,
    Button,
    UncontrolledAlert,
    InputGroup,
} from "reactstrap";
import { Link } from "react-router-dom";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { MDBDataTable } from "mdbreact";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { reactLocalStorage } from "reactjs-localstorage";
import moment from "moment";
import HttpClient from "./../../utils/HttpClient";
import Dropzone from "react-dropzone";
class FormElements extends Component {
    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems: [
                { title: "Date Wise Event Joiners", link: "#" },
                { title: "Manage Date Wise Event Joiners", link: "#" },
            ],
            customchk: true,
            toggleSwitch: true,
            // page states
            name: "",
            email: "",
            mobile: "",
            password: "",
            data: [],
            alert: false,
            message: "",
            type: "",
            result: [],
            edit_enable: false,
            edit_item_id: "",
            image_select: false,
            userId: "",
            userDetails: {},
            default_date: new Date(),
            details: "",

            start_date: "",
            end_date: "",
            from_date: "",
            to_date: "",

            selectedFiles: [],
            images: [],
            uploaded_images: [],

            event_id: this.props.match.params.id,
            eventData: null,

            start_time: new Date(),
        };
        this.handleDefault = this.handleDefault.bind(this);
        this.handleDefaultEnd = this.handleDefaultEnd.bind(this);
    }

    componentDidMount = async () => {
        this.checkUser();
    };

    handleDefault(date) {
        this.setState({ start_date: date });
    }

    handleDefaultEnd(date) {
        this.setState({ end_date: date });
    }

    fetchData = async (from_date, to_date) => {
        let result = await HttpClient.requestData(
            "admin/event/datewise-event",
            "POST",
            {
                frmdate: from_date,
                todate: to_date,
            }
        );
        if (result && result.status) {
            let data = [];
            let i = 1;
            this.setState({ result: result.data });
            this.state.result.forEach((element, index) => {
                if (Object.keys(element.EventdData).length === 0) {
                    this.setState({ rows: null });
                } else {
                    let rows = {
                        sl: i,
                        name: element.EventdData.name,
                        timing:
                            moment(element.EventdData.start_date).format("LL") +
                            " To " +
                            moment(element.EventdData.end_date).format("LL"),
                        time:
                            moment(element.EventdData.start_time, "hh:mm").format("hh:mm a") +
                            " To " +
                            moment(element.EventdData.end_time, "hh:mm").format("hh:mm a"),
                        username: element.UserdData ? element.UserdData.firstname + " " + element.UserdData.lastname : null,
                        email: element.UserdData ? element.UserdData.email : null,
                        phone: element.UserdData ? element.UserdData.phone_no : null,
                        image: (
                            <div>
                                <img
                                    src={element.UserdData ? element.UserdData.image : ""}
                                    alt="images"
                                    className="rounded avatar-md card-img"
                                />
                            </div>
                        ),
                        rgt_date: moment(element.created_on).format("LL"),
                    }
                    this.setState({ rows: rows });
                }
                i++;
                data.push(this.state.rows);
            });
            this.setState({
                data: data,
            });
        } else {
            this.setState({
                data: [],
            });
        }
    };


    // status = async (item, index) => {
    //     let sendData = {
    //         id: item._id,
    //         status: !item.status,
    //     };
    //     let result = await HttpClient.requestData(
    //         "admin/event/session/status",
    //         "POST",
    //         sendData
    //     );
    //     if (result && result.status) {
    //         this.state.result.splice(index, 1);
    //         this.setState({
    //             alert: true,
    //             message: "Status Updated Successfully",
    //             type: "success",
    //         });
    //         setTimeout(() => {
    //             this.setState({
    //                 alert: false,
    //                 message: "",
    //                 type: "",
    //             });
    //         }, 3000);
    //         this.fetchData();
    //     }
    // };

    submit = async () => {
        if (
            this.state.start_date != "" &&
            this.state.end_date != ""
        ) {

            this.fetchData(this.state.start_date, this.state.end_date);

        } else {
            this.setState({
                alert: true,
                message: "Please Fill Up All Details.",
                type: "warning",
            });
        }
        setTimeout(() => {
            this.setState({
                alert: false,
                message: "",
                type: "",
            });
        }, 3000);
    };


    handleAcceptedFiles = (files) => {
        let data = [];
        let file = {
            preview: URL.createObjectURL(files[0]),
            formattedSize: this.formatBytes(files[0].size),
            // image:files,
        };
        let file2 = {
            image: files,
        };

        let file3 = files;
        console.log("data", file);
        this.state.selectedFiles.push(file);
        this.state.images.push(files[0]);
        this.imageUpload(files[0]);
        this.setState({});
    };

    async remove_image(item, index) {
        this.state.selectedFiles.pop(index);
        this.state.images.pop(index);
        this.state.uploaded_images.pop(index);
        this.setState({});
    }

    /**
     * Formats the size
     */
    formatBytes = (bytes, decimals = 2) => {
        if (bytes === 0) return "0 Bytes";
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    };

    checkUser = async () => {
        let user = await reactLocalStorage.getObject("userData");
        if (user != null && Object.keys(user).length > 0) {
            this.setState({
                userId: user.id,
                userDetails: user,
            });
        } else {
        }
        this.fetchData();
    };

    render() {
        const data = {
            columns: [
                {
                    label: "Sl.",
                    field: "sl",
                    sort: "asc",
                    width: 150,
                },
                {
                    label: "Name",
                    field: "name",
                    sort: "asc",
                    width: 270,
                },
                {
                    label: "Event Date",
                    field: "timing",
                    sort: "asc",
                    width: 270,
                },
                // {
                //     label: "Time",
                //     field: "time",
                //     sort: "asc",
                //     width: 270,
                // },
                {
                    label: "Username",
                    field: "username",
                    sort: "asc",
                    width: 270,
                },
                {
                    label: "Email",
                    field: "email",
                    sort: "asc",
                    width: 270,
                },
                {
                    label: "Phone",
                    field: "phone",
                    sort: "asc",
                    width: 270,
                },
                {
                    label: "Image",
                    field: "image",
                    sort: "asc",
                    width: 270,
                },
                {
                    label: "Join Date",
                    field: "rgt_date",
                    sort: "asc",
                    width: 270,
                },
            ],
            rows: this.state.data,
        };
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs
                            title={this.state.breadcrumbItems[0].title}
                            breadcrumbItems={this.state.breadcrumbItems}
                        />

                        <Row>
                            <Col xs={12}>
                                <Card>
                                    <CardBody>
                                        {this.state.alert ? (
                                            <UncontrolledAlert
                                                color={this.state.type}
                                                className="alert-dismissible fade show"
                                                role="alert"
                                            >
                                                {this.state.type == "warning" ? (
                                                    <i className="mdi mdi-alert-outline mr-2"></i>
                                                ) : this.state.type == "success" ? (
                                                    <i className="mdi mdi-check-all mr-2"></i>
                                                ) : this.state.type == "danger" ? (
                                                    <i className="mdi mdi-block-helper mr-2"></i>
                                                ) : null}
                                                {this.state.message}
                                            </UncontrolledAlert>
                                        ) : null}
                                        <FormGroup row>
                                            <Label
                                                htmlFor="example-text-input"
                                                className="col-md-2 col-form-label"
                                            >
                                                From Date<span style={{ color: 'red' }}>*</span>
                                            </Label>
                                            <Col md={4}>
                                                <InputGroup>
                                                    <DatePicker
                                                        className="form-control"
                                                        selected={this.state.start_date}
                                                        onChange={this.handleDefault}
                                                    />
                                                </InputGroup>
                                            </Col>
                                            <Label
                                                htmlFor="example-text-input"
                                                className="col-md-2 col-form-label"
                                            >
                                                To Date<span style={{ color: 'red' }}>*</span>
                                            </Label>
                                            <Col md={4}>
                                                <InputGroup>
                                                    <DatePicker
                                                        className="form-control"
                                                        selected={this.state.end_date}
                                                        onChange={this.handleDefaultEnd}
                                                    />
                                                    {/* {this.state.default_end_date.toDateString()} */}
                                                </InputGroup>
                                            </Col>
                                        </FormGroup>

                                        <p style={{ marginTop: 5, textAlign: "right" }}>
                                            [Note: Asterisk (<span style={{ color: 'red' }}>*</span>) denotes the mandatory field.]
                                        </p>

                                        <FormGroup className="mb-0">
                                            <div className="button-items pt-4">
                                                <Button
                                                    color="primary"
                                                    type="button"
                                                    className="waves-effect waves-light mr-1"
                                                    onClick={() => {
                                                        this.submit();
                                                    }}
                                                >
                                                    {this.state.edit_enable ? "Update" : "Submit"}{" "}
                                                    <i className="ri-arrow-right-line align-middle ml-1"></i>
                                                </Button>
                                            </div>
                                        </FormGroup>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>

                        <Row>
                            <Col xl={12}>
                                <div className="card p-3">
                                    <h5>DATE WISE EVENT JOINERS</h5>
                                    <MDBDataTable responsive bordered data={data} />
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default FormElements;
