import React, { Component } from "react";
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  FormGroup,
  Label,
  Input,
  CustomInput,
  Button,
  UncontrolledAlert,
  InputGroup,
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { MDBDataTable } from "mdbreact";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { reactLocalStorage } from "reactjs-localstorage";
import moment from "moment";
import HttpClient from "./../../utils/HttpClient";
class FormElements extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: "Social", link: "#" },
        { title: "Manage Social", link: "#" },
      ],
      customchk: true,
      toggleSwitch: true,
      // page states
      name: "",
      email: "",
      mobile: "",
      password: "",
      data: [],
      alert: false,
      message: "",
      type: "",
      result: [],
      edit_enable: false,
      edit_item_id: "",
      image_select: false,
      img_url: "",
      userId: "",
      userDetails: {},
      default_date: new Date(),
      details: "",
      link: "",
      price: "",
      total_seats: "",
      vanue: "",
      latitude: "",
      longitude: '',
      image_select: false,
      img_url: "",
      order_status: [],
      order_status_type: "",
      optionsdata: [
        { key: '100', value: 'Select' },
        { key: '101', value: 'Accepted' },
        { key: '102', value: 'Pending' },
        { key: '103', value: 'Shipped' },
        { key: '104', value: 'Delivered' },

      ],
    };

  }

  componentDidMount = async () => {
    this.checkUser();
  };



  fetchData = async () => {
    let data = {};

    let result = await HttpClient.requestData(
      "admin/order/get-all-post",
      "GET",
      data,

    );

    console.log("OrderFetchAll", result);

    if (result && result.status > 0) {
      let data = [];
      let i = 1;

      this.setState({ result: result.data });

      this.state.result.forEach((element, index) => {

        let rows = {
          sl: i,
          title: element.title,
          post: element.post,
          email: element.userData.email,
          userame: element.userData.firstname + " " + element.userData.lastname,
          date: moment(element.created_on).format("L"),
          image:
          element.userData.image && element.userData.image.length > 0 ? (
              <div>
                <img
                  src={element.userData.image}
                  alt="images"
                  className="rounded avatar-md card-img"
                />
              </div>
            ) : null,
          status: (
            <button
              title="Delete"
              className={
                element.status ? "btn btn-success mr-2" : "btn btn-danger mr-2"
              }
              onClick={() => {
                this.status(element, index);
              }}
            >
              {element.status ? "Active" : "Not Active"}
            </button>
          ),



          product: (
            <>

              <button
                title="products"
                className="btn btn-success"
                onClick={() => {

                  window.location.href = "/products" + element.id;

                }}
              >
                View Products
              </button>

            </>
          ),

        };
        i++;
        data.push(rows);
      });
      this.setState({
        data: data,
      });
    } else {
      this.setState({
        data: [],
      });
    }
  };




  // }
  status = async (item, index) => {
    let sendData = {
      id: item._id,
      status: !item.status,
    };
    let result = await HttpClient.requestData(
      "admin/order/status-post",
      "POST",
      sendData
    );
    if (result && result.status) {
      this.state.result.splice(index, 1);
      this.setState({
        alert: true,
        message: "Status Updated Successfully",
        type: "success",
      });
      setTimeout(() => {
        this.setState({
          alert: false,
          message: "",
          type: "",
        });
      }, 3000);
      this.fetchData();
    }
  };

  checkUser = async () => {
    let user = await reactLocalStorage.getObject("userData");
    if (user != null && Object.keys(user).length > 0) {
      this.setState({
        userId: user.id,
        userDetails: user,
      });
    } else {
    }
    this.fetchData();
  };

  render() {
    const data = {
      columns: [
        {
          label: "Sl.",
          field: "sl",
          sort: "asc",
          width: 150,
        },
        {
          label: "Title",
          field: "title",
          sort: "asc",
          width: 150,
        },
        {
          label: "Name",
          field: "userame",
          sort: "asc",
          width: 150,
        },
        {
          label: "Email",
          field: "email",
          sort: "asc",
          width: 270,
        },
        {
          label: "Date of Post",
          field: "date",
          sort: "asc",
          width: 270,
        },
        {
          label: "Image",
          field: "image",
          sort: "asc",
          width: 270,
        },

        {
          label: "Post",
          field: "post",
          sort: "asc",
          width: 270,
        },

        // {
        //   label: "Images",
        //   field: "image",
        //   sort: "asc",
        //   width: 270,
        // },


        {
          label: "Status",
          field: "status",
          sort: "asc",
          width: 100,
        },
        // {
        //   label: "Action",
        //   field: "action",
        //   sort: "asc",
        //   width: 100,
        // },
      ],
      rows: this.state.data,
    };
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title={this.state.breadcrumbItems[0].title}
              breadcrumbItems={this.state.breadcrumbItems}
            />

            <Row>
              <Col xs={12}>


              </Col>
            </Row>
            <Row>
              <Col xl={12}>
                <div className="card p-3">
                  <MDBDataTable responsive bordered data={data} />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default FormElements;
