import React, { Component } from "react";
import { Card, CardBody, Row, Col } from "reactstrap";

//Import Charts
import ReactApexChart from "react-apexcharts";
import "./dashboard.scss";

class SalesAnalytics extends Component {
  state = {
    series: [20, 40, 40],
    options: {
      labels: ["", "", ""],
      plotOptions: {
        pie: {
          donut: {
            size: "75%",
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },
      colors: ["#5664d2", "#1cbb8c", "#eeb902"],
    },
    files: [],
    refresh: false,
    colors: ["#5664d2", "#1cbb8c", "#eeb902"],
  };

  componentDidMount = () => {
    console.log("data", this.props.data);
    let files = [];
    let movieNames = [];
    let series = [];
    this.props.data.map((item, index) => {
      let movie = item.movieData.name;
      let percentage = Number(item.count / this.props.count) * 100;
      files.push({ name: movie, percetage: Number(percentage) });
      movieNames.push(movie);
      series.push(Number(percentage));
    });
    this.setState({ files });
    this.state.options.labels = movieNames;
    this.state.series = series;

    setTimeout(() => {
      console.log(series);
      this.setState({ refresh: !this.state.refresh });
    }, 100);
  };
  render() {
    return (
      <React.Fragment>
          <Col lg={6}>
        <Card>
          <CardBody>
            {/* <div className="float-right">
              <select className="custom-select custom-select-sm">
                <option defaultValue>Apr</option>
                <option value="1">Mar</option>
                <option value="2">Feb</option>
                <option value="3">Jan</option>
              </select>
            </div> */}
            <h4 className="card-title mb-4">{this.props.name} Video</h4>

            {this.state.refresh ? (
              <div id="donut-chart" className="apex-charts">
                <ReactApexChart
                  options={this.state.options}
                  series={this.state.series}
                  type="donut"
                  height="230"
                  refresh={this.state.refresh}
                />
              </div>
            ) : null}

            <Row>
              {this.state.files.map((item, index) => {
                return (
                  <Col xs={4}>
                    <div className="text-center mt-4">
                      <div
                        style={{
                          backgroundColor: this.state.colors[index],
                          height: 10,
                          width: 10,
                          borderRadius: 5,
                        }}
                      ></div>
                      <p className="mb-2 text-truncate">
                        {/* <i className="mdi mdi-circle text-primary font-size-10 mr-1" ></i> */}{" "}
                        {item.name}
                      </p>
                      <h5>{Number(this.state.series[index]).toFixed(2)} %</h5>
                    </div>
                  </Col>
                );
              })}
              {/* <Col xs={4}>
                <div className="text-center mt-4">
                  <p className="mb-2 text-truncate">
                    <i className="mdi mdi-circle text-success font-size-10 mr-1"></i>{" "}
                    Product B
                  </p>
                  <h5>26 %</h5>
                </div>
              </Col>
              <Col xs={4}>
                <div className="text-center mt-4">
                  <p className="mb-2 text-truncate">
                    <i className="mdi mdi-circle text-warning font-size-10 mr-1"></i>{" "}
                    Product C
                  </p>
                  <h5>42 %</h5>
                </div>
              </Col> */}
            </Row>
          </CardBody>
        </Card>
        </Col>
      </React.Fragment>
    );
  }
}

export default SalesAnalytics;
