import React, { Component } from "react";
import {
    Card,
    CardBody,
    Col,
    Row,
    Container,
    FormGroup,
    Label,
    Input,
    CustomInput,
    Button,
    UncontrolledAlert,
    InputGroup,
} from "reactstrap";
import { Link } from "react-router-dom";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { MDBDataTable } from "mdbreact";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { reactLocalStorage } from "reactjs-localstorage";
import moment from "moment";
import HttpClient from "./../../utils/HttpClient";
import Dropzone from "react-dropzone";
import ShowMoreText from "react-show-more-text";
class FormElements extends Component {
    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems: [
                { title: "Coaching", link: "#" },
                { title: "Add & Manage Coaching", link: "#" },
            ],
            customchk: true,
            toggleSwitch: true,
            // page states
            title: "",
            email: "",
            mobile: "",
            password: "",
            data: [],
            alert: false,
            message: "",
            type: "",
            result: [],
            edit_enable: false,
            edit_item_id: "",
            image_select: false,
            img_url: "",
            userId: "",
            userDetails: {},
            default_date: new Date(),
            //   details: "",
            desc: "",
            start_time: "",
            end_date: "",
            addmore: [
                {
                    title: "",
                    desc: "",


                },
            ],

            price: "",
            duration: "",
            total_articles: "",
            real_projects: "",
            certificate: "",
            venue: "",
            documentation: "",
            selectedFiles: [],
            images: [],
            uploaded_images: [],

            event_id: this.props.match.params.id,
            eventData: null,
            userDetails: {},
            start_time: new Date(),
        };
        this.handleDefault = this.handleDefault.bind(this);
        this.handleDefaultEnd = this.handleDefaultEnd.bind(this);
        this.handleStartTIme = this.handleStartTIme.bind(this);
    }

    componentDidMount = async () => {
        this.checkUser();
        this.fetchEvent();
    };

    handleDefault(date) {
        this.setState({ default_date: date });
    }

    handleDefaultEnd(date) {
        this.setState({ default_end_date: date });
    }

    handleStartTIme(time) {
        console.log("time", time);
    }

    fetchEvent = async () => {
        let result = await HttpClient.requestData(
            "admin/cooching/all-cooching",
            "POST",
            { id: this.state.event_id }
        );

        if (result && result.status) {
            let data = result.data[0];
            // this.state.breadcrumbItems[0].title = "Coaching - " + data.name;
            this.setState({ eventData: data });
        }
    };

    fetchData = async () => {
        let result = await HttpClient.requestData(
            "admin/cooching/all-cooching",
            "POST",
            { event_id: Number(this.state.event_id) }
        );
        if (result && result.status) {
            let data = [];
            let i = 1;
            this.setState({ result: result.data });
            this.state.result.forEach((element, index) => {
                let rows = {
                    sl: i,
                    created_by: element.created_by ? element.created_by.name + " on " + moment(element.created_on).utcOffset(element.created_on).format('DD/MM/YYYY, HH:mm') : "" + " on " + moment(element.created_on).utcOffset(element.created_on).format('DD/MM/YYYY, HH:mm'),
                    updated_by: element.updated_by ? element.updated_by.name + " on " + moment(element.updated_on).utcOffset(element.updated_on).format('DD/MM/YYYY, HH:mm') : "" + "",
                    image: (
                        <div>
                            <img
                                src={element.img}
                                alt="images"
                                className="rounded avatar-md card-img"
                            />
                        </div>
                    ),
                    title: element.title,

                    desc: <ShowMoreText
                        lines={3}
                        more='Show more'
                        less='Show less'
                        onClick={this.executeOnClick}
                        expanded={false}
                    >{element.desc}</ShowMoreText>,
                    // topic: element.topic,
                    price: element.price,
                    Mentor: (
                        <Link
                            className="btn btn-danger mr-2"
                            to={"/coaching-mentor/" + element.id}
                        >
                            Mentor
                        </Link>
                    ),
                    // ProgramDetails: (
                    //     <Link
                    //       className="btn btn-danger mr-2"
                    //       to={"/coaching-ProgramDetails/" + element.id}
                    //     >
                    //      Program Details

                    //     </Link>

                    //   ),
                    status: (
                        <button
                            title="Delete"
                            className={
                                element.status ? "btn btn-success mr-2" : "btn btn-danger mr-2"
                            }
                            onClick={() => {
                                this.status(element, index);
                            }}
                        >
                            {element.status ? "Active" : "Not Active"}
                        </button>
                    ),
                    action: (
                        <>
                            {this.state.userDetails.sub_admin == true ? null : (
                                <button
                                    title="Delete"
                                    className="btn btn-danger mr-2"
                                    onClick={() => {
                                        if (window.confirm('Are you sure you wish to delete this item?'))
                                            this.delete(element, index)
                                    }}
                                >
                                    <i className="fa fa-trash" />
                                </button>
                            )}
                            <button
                                title="Edit"
                                className="btn btn-primary"
                                onClick={() => {
                                    this.edit(element, index);
                                    window.scrollTo(0, 0);
                                }}
                            >
                                <i className="fa fa-edit" />
                            </button>
                        </>
                    ),
                };
                i++;
                data.push(rows);
            });
            this.setState({
                data: data,
            });
        } else {
            this.setState({
                data: [],
            });
        }
    };

    delete = async (item, index) => {
        let sendData = {
            id: item._id,
        };
        let result = await HttpClient.requestData(
            "admin/cooching/delete",
            "POST",
            sendData
        );
        if (result && result.status) {
            let index = this.state.result.indexOf(item);
            if (index > -1) {
                this.state.result.splice(index, 1);
                this.setState({
                    alert: true,
                    message: "Deleted Successfully",
                    type: "success",
                });
                setTimeout(() => {
                    this.setState({
                        alert: false,
                        message: "",
                        type: "",
                    });
                }, 3000);
                this.fetchData();
            }
        }
    };

    edit = async (item, index) => {
        this.setState({
            edit_enable: true,
            title: item.title,
            edit_item_id: item.id,
            //   details: item.details,
            desc: item.desc,
            addmore: item.program_topic,
            duration: item.duration,
            total_articles: item.total_articles,
            real_projects: item.real_projects,
            certificate: item.certificate,
            venue: item.venue,
            documentation: item.documentation,
            price: item.price,
            img_url: item.img,
            image_select: true,
        });
    };

    status = async (item, index) => {
        let sendData = {
            id: item._id,
            status: !item.status,
        };
        let result = await HttpClient.requestData(
            "admin/cooching/status",
            "POST",
            sendData
        );
        if (result && result.status) {
            this.state.result.splice(index, 1);
            this.setState({
                alert: true,
                message: "Status Updated Successfully",
                type: "success",
            });
            setTimeout(() => {
                this.setState({
                    alert: false,
                    message: "",
                    type: "",
                });
            }, 3000);
            this.fetchData();
        }
    };

    submit = async () => {
        if (this.state.image_select == 0) {
            this.setState({
                alert: true,
                message: "Please Upload Images.",
                type: "warning",
            });
            return;
        }
        if (this.state.addmore[0].title != "" &&
            this.state.price != "" &&
            this.state.addmore[0].desc != "") {
            let data = null;
            let result = null;

            if (this.state.edit_enable == false) {
                data = {
                    title: this.state.addmore[0].title,
                    desc: this.state.addmore[0].desc,
                    program_topic: this.state.addmore,
                    duration: this.state.duration,
                    total_articles: this.state.total_articles,
                    real_projects: this.state.real_projects,
                    certificate: this.state.certificate,
                    venue: this.state.venue,
                    documentation: this.state.documentation,
                    // topic: this.state.topic,
                    price: this.state.price,
                    //   event_id: Number(this.state.event_id),
                    //   design: this.state.design,
                    img: this.state.img_url,
                    created_by: this.state.userId,
                };
                result = await HttpClient.requestData(
                    "admin/cooching",
                    "POST",
                    data
                );
            } else {
                data = {
                    title: this.state.addmore[0].title,
                    desc: this.state.addmore[0].desc,
                    img: this.state.img_url,
                    // topic: this.state.topic,
                    price: this.state.price,
                    duration: this.state.duration,
                    total_articles: this.state.total_articles,
                    real_projects: this.state.real_projects,
                    certificate: this.state.certificate,
                    venue: this.state.venue,
                    documentation: this.state.documentation,
                    //   design: this.state.design,
                    program_topic: this.state.addmore,
                    edit_id: this.state.edit_item_id,
                    updated_by: this.state.userId,
                };
                result = await HttpClient.requestData(
                    "admin/cooching/update",
                    "POST",
                    data
                );
            }
            if (result && result.status) {
                this.setState({
                    alert: true,
                    message: this.state.edit_enable
                        ? "Coaching Updated Successfully"
                        : "Coaching Added Successfully",
                    type: "success",
                    title: "",
                    img_url: "",
                    image_select: false,
                    password: "",
                    edit_enable: false,
                    edit_item_id: "",
                    default_date: new Date(),
                    default_end_date: new Date(),
                    start_time: "",
                    end_date: "",
                    desc: "",
                    duration: "",
                    total_articles: "",
                    real_projects: "",
                    certificate: "",
                    venue: "",
                    documentation: "",
                    // topic: "",
                    addmore: [
                        {
                            title: "",
                            desc: "",


                        },
                    ],
                    details: "",
                    price: "",
                    uploaded_images: [],
                });

                this.fetchData();
            } else {
                this.setState({
                    alert: true,
                    message:
                        typeof result.error == "string"
                            ? result.error
                            : result.error[0].msg,
                    type: "danger",
                });
            }
        } else {
            this.setState({
                alert: true,
                message: "Please Fill Up All Details.",
                type: "warning",
            });
        }
        setTimeout(() => {
            this.setState({
                alert: false,
                message: "",
                type: "",
            });
        }, 3000);
    };

    imageUpload = async (e) => {
        console.log("e", e.target.files);
        let file = e.target.files;
        //   if(file.lenght>0)
        //   {
        let dataSend = { buckate: true, buckate_name: "appIcon" };

        let result = await HttpClient.newFileUpload(
            "image-upload/category",
            e.target.files[0],
            dataSend
        );
        // console.log("result", result);
        if (result && result.status) {
            this.setState({ img_url: result.url, image_select: true });
        }

        //   }
    };
    checkUser = async () => {
        let user = await reactLocalStorage.getObject("userData");
        if (user != null && Object.keys(user).length > 0) {
            this.setState({
                userId: user.id,
                userDetails: user,
            });
        } else {
        }
        this.fetchData();
        this.fetchEvents();
    };

    handleAcceptedFiles = (files, index) => {
        let data = [];
        let file = {
            preview: URL.createObjectURL(files[0]),
            formattedSize: this.formatBytes(files[0].size),
            // image:files,
        };
        let file2 = {
            image: files,
        };

        let file3 = files;
        console.log("data", file);
        this.state.selectedFiles.push(file);
        this.state.images.push(files[0]);
        this.imageUpload(files[0], index);
        this.setState({});
    };

    async remove_image(item, index) {
        // this.state.selectedFiles.pop(index);
        // this.state.images.pop(index);
        this.state.uploaded_images.pop(index);
        this.state.addmore[index].uploaded_images = ""
        this.setState({});
    }
    /**
     * Formats the size
     */
    formatBytes = (bytes, decimals = 2) => {
        if (bytes === 0) return "0 Bytes";
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    };

    checkUser = async () => {
        let user = await reactLocalStorage.getObject("userData");
        if (user != null && Object.keys(user).length > 0) {
            this.setState({
                userId: user.id,
                userDetails: user,
            });
        } else {
        }
        this.fetchData();
    };

    render() {
        const data = {
            columns: [
                {
                    label: "Sl.",
                    field: "sl",
                    sort: "asc",
                    width: 150,
                },
                {
                    label: "Coaching Name",
                    field: "title",
                    sort: "asc",
                    width: 270,
                },
                {
                    label: "Description",
                    field: "desc",
                    sort: "asc",
                    width: 270,
                },
                {
                    label: "Price",
                    field: "price",
                    sort: "asc",
                    width: 270,
                },
                {
                    label: "Created By",
                    field: "created_by",
                    sort: "asc",
                    width: 270,
                },
                {
                    label: "Updated By",
                    field: "updated_by",
                    sort: "asc",
                    width: 270,
                },
                {
                    label: "Image",
                    field: "image",
                    sort: "asc",
                    width: 270,
                },
                // {
                //     label: "Program Details",
                //     field: "ProgramDetails",
                //     sort: "asc",
                //     width: 100,
                // },
                {
                    label: "Mentor",
                    field: "Mentor",
                    sort: "asc",
                    width: 100,
                },
                {
                    label: "Status",
                    field: "status",
                    sort: "asc",
                    width: 100,
                },
                {
                    label: "Action",
                    field: "action",
                    sort: "asc",
                    width: 100,
                },
            ],
            rows: this.state.data,
        };
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs
                            title={this.state.breadcrumbItems[0].title}
                            breadcrumbItems={this.state.breadcrumbItems}
                        />

                        <Row>
                            <Col xs={12}>
                                <Card>
                                    <CardBody>
                                        {this.state.alert ? (
                                            <UncontrolledAlert
                                                color={this.state.type}
                                                className="alert-dismissible fade show"
                                                role="alert"
                                            >
                                                {this.state.type == "warning" ? (
                                                    <i className="mdi mdi-alert-outline mr-2"></i>
                                                ) : this.state.type == "success" ? (
                                                    <i className="mdi mdi-check-all mr-2"></i>
                                                ) : this.state.type == "danger" ? (
                                                    <i className="mdi mdi-block-helper mr-2"></i>
                                                ) : null}
                                                {this.state.message}
                                            </UncontrolledAlert>
                                        ) : null}

                                        {this.state.addmore.map((item, index) => {
                                            return (
                                                <>
                                                    {index > 0 ? (
                                                        <Col
                                                            md={2}
                                                            style={{ marginTop: index > 0 ? 5 : 0 }}
                                                        ></Col>
                                                    ) : null}
                                                    <FormGroup row>
                                                        <Label
                                                            htmlFor="example-text-input"
                                                            className="col-md-2 col-form-label"
                                                        >
                                                            Coaching name<span style={{ color: 'red' }}>*</span>
                                                        </Label>
                                                        <Col md={10}>
                                                            <Input
                                                                className="form-control"
                                                                type="text"
                                                                value={this.state.addmore[index].title}
                                                                id="example-text-input"
                                                                onChange={(val) => {
                                                                    this.state.addmore[index].title =
                                                                        val.target.value;
                                                                    this.setState({});
                                                                }}
                                                            />
                                                        </Col>
                                                    </FormGroup>

                                                    <FormGroup row>
                                                        <Label
                                                            htmlFor="example-search-input"
                                                            className="col-md-2 col-form-label"
                                                        >
                                                            Description<span style={{ color: 'red' }}>*</span>
                                                        </Label>
                                                        <Col md={10}>
                                                            <textarea
                                                                className="form-control"
                                                                type="text"
                                                                value={this.state.addmore[index].desc}
                                                                id="example-text-input"
                                                                onChange={(val) => {
                                                                    this.state.addmore[index].desc =
                                                                        val.target.value;
                                                                    this.setState({});
                                                                }}
                                                            />
                                                        </Col>
                                                    </FormGroup>

                                                    <FormGroup row>
                                                        <Label
                                                            htmlFor="example-search-input"
                                                            className="col-md-2 col-form-label"
                                                        >
                                                            Program Topic
                                                        </Label>
                                                        <Col md={8}>
                                                            <Button
                                                                color={index > 0 ? "danger" : "success"}
                                                                type="button"
                                                                className="waves-effect waves-light customUploadBtn mr-1"
                                                                onClick={(e) => {
                                                                    if (index > 0) {
                                                                        let indexx =
                                                                            this.state.addmore.indexOf(item);

                                                                        if (indexx > -1) {
                                                                            this.state.addmore.pop(indexx);
                                                                            this.setState({});
                                                                        }
                                                                    } else {
                                                                        this.state.addmore.push({
                                                                            name: "",
                                                                            desc: "",


                                                                        });
                                                                        this.setState({});
                                                                    }
                                                                }}
                                                            >
                                                                {index > 0 ? " - Remove" : " + Topic Name"}
                                                            </Button>
                                                        </Col>
                                                    </FormGroup>
                                                </>
                                            );
                                        })}
                                        <FormGroup row>
                                            <Label
                                                htmlFor="example-text-input"
                                                className="col-md-2 col-form-label"
                                            >
                                                Price<span style={{ color: 'red' }}>*</span>
                                            </Label>
                                            <Col md={10}>
                                                <Input
                                                    className="form-control"
                                                    type="text"
                                                    value={this.state.price}
                                                    id="example-text-input"
                                                    onChange={(val) => {
                                                        this.setState({ price: val.target.value });
                                                    }}
                                                />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label
                                                htmlFor="example-text-input"
                                                className="col-md-2 col-form-label"
                                            >
                                                Image<span style={{ color: 'red' }}>*</span>
                                            </Label>
                                            <Col md={10}>
                                                {this.state.image_select ? (
                                                    <div>
                                                        <img
                                                            src={this.state.img_url}
                                                            alt="images"
                                                            className="rounded avatar-md card-img"
                                                        />
                                                    </div>
                                                ) : null}
                                                <Button
                                                    color="danger"
                                                    type="button"
                                                    className="waves-effect waves-light customUploadBtn mr-1"
                                                >
                                                    <i className="ri-add-fill align-middle mr-1"></i> Add
                                                    image
                                                    <input
                                                        type="file"
                                                        accept=".jpeg,.jpg,.png,.svg,.webp,.jfif,.apng"
                                                        className="upload_input"
                                                        onChange={(e) => {
                                                            this.imageUpload(e);
                                                        }}
                                                    />
                                                </Button>
                                                <span style={{ color: 'red' }}>[image should be in dimensions 500 * 500 px]</span>
                                            </Col>
                                        </FormGroup>

                                        <FormGroup row>
                                            <Label
                                                htmlFor="example-text-input"
                                                className="col-md-2 col-form-label"
                                            >
                                                Program Details :-
                                            </Label>
                                            <Col md={10}>
                                                {/* <Input
                                                    className="form-control"
                                                    type="text"
                                                    value={this.state.price}
                                                    id="example-text-input"
                                                    onChange={(val) => {
                                                        this.setState({ price: val.target.value });
                                                    }}
                                                /> */}
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label
                                                htmlFor="example-text-input"
                                                className="col-md-2 col-form-label"
                                            >
                                                Duration
                                            </Label>
                                            <Col md={10}>
                                                <Input
                                                    className="form-control"
                                                    type="text"
                                                    value={this.state.duration}
                                                    id="example-text-input"
                                                    onChange={(val) => {
                                                        this.setState({ duration: val.target.value });
                                                    }}
                                                />
                                            </Col>
                                        </FormGroup>

                                        <FormGroup row>
                                            <Label
                                                htmlFor="example-text-input"
                                                className="col-md-2 col-form-label"
                                            >
                                                Total Articles
                                            </Label>
                                            <Col md={10}>
                                                <Input
                                                    className="form-control"
                                                    type="text"
                                                    value={this.state.total_articles}
                                                    id="example-text-input"
                                                    onChange={(val) => {
                                                        this.setState({ total_articles: val.target.value });
                                                    }}
                                                />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label
                                                htmlFor="example-text-input"
                                                className="col-md-2 col-form-label"
                                            >
                                                Real Projects
                                            </Label>
                                            <Col md={10}>
                                                <Input
                                                    className="form-control"
                                                    type="text"
                                                    value={this.state.real_projects}
                                                    id="example-text-input"
                                                    onChange={(val) => {
                                                        this.setState({ real_projects: val.target.value });
                                                    }}
                                                />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label
                                                htmlFor="example-text-input"
                                                className="col-md-2 col-form-label"
                                            >
                                                Certificate
                                            </Label>
                                            <Col md={10}>
                                                <Input
                                                    className="form-control"
                                                    type="text"
                                                    value={this.state.certificate}
                                                    id="example-text-input"
                                                    onChange={(val) => {
                                                        this.setState({ certificate: val.target.value });
                                                    }}
                                                />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label
                                                htmlFor="example-text-input"
                                                className="col-md-2 col-form-label"
                                            >
                                                Venue
                                            </Label>
                                            <Col md={10}>
                                                <Input
                                                    className="form-control"
                                                    type="text"
                                                    value={this.state.venue}
                                                    id="example-text-input"
                                                    onChange={(val) => {
                                                        this.setState({ venue: val.target.value });
                                                    }}
                                                />
                                            </Col>
                                        </FormGroup>

                                        <FormGroup row>
                                            <Label
                                                htmlFor="example-text-input"
                                                className="col-md-2 col-form-label"
                                            >
                                                Documentation
                                            </Label>
                                            <Col md={10}>
                                                <Input
                                                    className="form-control"
                                                    type="text"
                                                    value={this.state.documentation}
                                                    id="example-text-input"
                                                    onChange={(val) => {
                                                        this.setState({ documentation: val.target.value });
                                                    }}
                                                />
                                            </Col>
                                        </FormGroup>

                                        <p style={{ marginTop: 5, textAlign: "right" }}>
                                            [Note: Asterisk (<span style={{ color: 'red' }}>*</span>) denotes the mandatory field.]
                                        </p>
                                        <FormGroup className="mb-0">
                                            <div className="button-items pt-4">
                                                <Button
                                                    color="primary"
                                                    type="button"
                                                    className="waves-effect waves-light mr-1"
                                                    onClick={() => {
                                                        this.submit();
                                                    }}
                                                >
                                                    {this.state.edit_enable ? "Update" : "Submit"}{" "}
                                                    <i className="ri-arrow-right-line align-middle ml-1"></i>
                                                </Button>
                                            </div>
                                        </FormGroup>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={12}>
                                <div className="card p-3">
                                    <div class="d-flex justify-content-between w-100">
                                        <h5>COACHING</h5>
                                        {this.state.userDetails.sub_admin == true ? null : (
                                            <Link
                                                className="btn btn-danger mr-2"
                                                to={"/coaching-undo/"}
                                            >
                                                Deleted Coachings
                                            </Link>
                                        )}
                                    </div>
                                    <MDBDataTable responsive bordered data={data} />
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment >
        );
    }
}

export default FormElements;
