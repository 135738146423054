import React, { Component } from "react";
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  FormGroup,
  Label,
  Input,
  CustomInput,
  Button,
  UncontrolledAlert,
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { MDBDataTable } from "mdbreact";
import { reactLocalStorage } from "reactjs-localstorage";
import moment from "moment";
import HttpClient from "../../utils/HttpClient";
import ProgressBar from "@ramonak/react-progress-bar";
class FormElements extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: "Scratch Card", link: "#" },
        { title: "Add & Manage Scratch Card", link: "#" },
      ],
      customchk: true,
      toggleSwitch: true,
      // page states
      scratchName: "",
      giftName: "",
      duration: "",
      giftImage: "",
      price: "",
      data: [],
      alert: false,
      message: "",
      type: "",
      result: [],
      edit_enable: false,
      edit_item_id: "",
      image_select: false,
      img_url: "",
      category_type: "",
      uploading_trailer: false,
      progressBarParsent: 0,
      trailer: "",
      uploading_trailer: false,
      trailerPlaylists: "",
      winningUser: "",
      loyaltyPoint: "",

    };
  }

  componentDidMount = async () => {
    this.checkUser();
  };

  checkUser = async () => {
    let user = await reactLocalStorage.getObject("userData");
    if (user != null && Object.keys(user).length > 0) {
      this.setState({
        userId: user.id,
        userDetails: user,
      });
    } else {
    }
    this.fetchData();
  };

  uploadVideo = async (video, type) => {
    if (type == "Trailer") {
      this.setState({ uploading_trailer: true });
    } else {
      this.setState({ uploading_movie: true });
    }

    let dataSend = { buckate: false };
    let result = await HttpClient.uploadFileRequest(
      "video-upload/video",
      video,
      (data, data1, data2) => {
        console.log("data, data1, data2", data, data1, data2);

        if (type == "Trailer") {
          this.setState({ progressBarParsent: data });
        } else {
          this.setState({ progressBarParsentVideo: data });
        }
      }
    );
    if (result && result.status) {
      if (type == "Trailer") {
        this.setState({
          trailerPlaylists: result.playlists,
          trailer: result.data.url,
          uploading_trailer: false,
        });
      } else {
        this.setState({
          videoUrl: result.playlists,
          url: result.data.url,
          uploading_movie: false,
        });
      }
    }
    console.log("result", result);
  };

  fetchData = async () => {
    let data = {
      owner_id: "Admin"
    }
    let result = await HttpClient.requestData("admin/scrach-card/fetch-all", "GET", data);
    if (result && result.status > 0) {
      let data = [];
      let i = 1;
      this.setState({ result: result.data });
      this.state.result.forEach((element, index) => {
        let rows = {
          sl: i,
          created_by: element.created_by ? element.created_by.name + " on " + moment(element.created_on).utcOffset(element.created_on).format('DD/MM/YYYY, HH:mm') : "" + " on " + moment(element.created_on).utcOffset(element.created_on).format('DD/MM/YYYY, HH:mm'),
          updated_by: element.updated_by ? element.updated_by.name + " on " + moment(element.updated_on).utcOffset(element.updated_on).format('DD/MM/YYYY, HH:mm') : "" + "",
          scratchName: element.scratchName,
          giftName: element.giftName,
          winningUser: element.winningUser,
          loyaltyPoint: element.loyaltyPoint,
          winningUser: element.winningUser,
          loyaltyPoint: element.loyaltyPoint,

          // category_type: element.category_type,
          giftImage: (
            <div>
              <img
                src={element.giftImage}
                alt="images"
                className="rounded avatar-md card-img"
              />
            </div>
          ),
          action: (
            <>
              {this.state.userDetails.sub_admin == true ? null : (
                <button
                  title="Delete"
                  className="btn btn-danger mr-2"
                  onClick={() => {
                    if (window.confirm('Are you sure you wish to delete this item?'))
                      this.delete(element, index)
                  }}
                >
                  <i className="fa fa-trash" />
                </button>
              )}
              <button
                title="Edit"
                className="btn btn-primary"
                onClick={() => {
                  this.edit(element, index);
                  window.scrollTo(0, 0);
                }}
              >
                <i className="fa fa-edit" />
              </button>
            </>
          ),
        };
        i++;
        data.push(rows);
      });
      this.setState({
        data: data,
      });
    } else {
      this.setState({
        data: [],
      });
    }
  };
  delete = async (item, index) => {
    let sendData = {
      id: item._id,
    };
    let result = await HttpClient.requestData(
      "admin/scrach-card/delete",
      "POST",
      sendData
    );
    if (result && result.status) {
      let index = this.state.result.indexOf(item);
      if (index > -1) {
        this.state.result.splice(index, 1);
        this.setState({
          alert: true,
          message: "Deleted Successfully",
          type: "success",
        });
        setTimeout(() => {
          this.setState({
            alert: false,
            message: "",
            type: "",
          });
        }, 3000);
        this.fetchData();
      }
    }
  };
  edit = async (item, index) => {

    this.setState({
      edit_enable: true,
      scratchName: item.scratchName,
      // category_type: item.category_type,
      edit_item_id: item._id,
      giftImage: item.giftImage,
      image_select: true,
      giftName: item.giftName,
      winningUser: item.winningUser,
      loyaltyPoint: item.loyaltyPoint,
      // url: item.url,
      // videoUrl: item.videoUrl,
      // url: item.url,
      // trailer: item.trailer,
      // trailerPlaylists: item.trailerUrl,
    });
  };
  submit = async () => {
    // if (this.state.videoUpload && this.state.url == "") {
    //   this.setState({
    //     alert: true,
    //     message: "Please Upload Video.",
    //     type: "warning",
    //   });
    //   return false;
    // }
    if (

      this.state.scratchName != "" 
      // this.state.trailer != ""
      // this.state.category_type != "" &&
      // this.state.img_url != ""
    ) {
      let data = null;
      let result = null;

      if (this.state.edit_enable == false) {
        data = {
          scratchName: this.state.scratchName,
          // category_type: this.state.category_type,
          giftImage: this.state.giftImage,
          giftName: this.state.giftName,
          winningUser: Number(this.state.winningUser),
          loyaltyPoint: Number(this.state.loyaltyPoint),
          owner_id: "Admin",
          created_by: this.state.userId,
          // trailer: this.state.trailer,
          // trailerUrl: this.state.trailerPlaylists,
          // videoUrl: this.state.videoUrl,
          // url: this.state.url,
        };
        result = await HttpClient.requestData("admin/scrach-card", "POST", data);
      } else {
        data = {
          scratchName: this.state.scratchName,
          // category_type: this.state.category_type,
          giftImage: this.state.giftImage,
          giftName: this.state.giftName,
          id: this.state.edit_item_id,
          winningUser: Number(this.state.winningUser),
          loyaltyPoint: Number(this.state.loyaltyPoint),
          owner_id: "Admin",
          edit_id: this.state.edit_item_id,
          updated_by: this.state.userId,
          // trailer: this.state.trailer,
          // trailerUrl: this.state.trailerPlaylists,
          // videoUrl: this.state.videoUrl,
          // url: this.state.url,
        };
        result = await HttpClient.requestData(
          "admin/scrach-card/update",
          "POST",
          data
        );
      }
      console.log("result", result);
      if (result && result.status) {
        this.setState({
          alert: true,
          message: this.state.edit_enable
            ? "Scratch Card Updated Successfully"
            : "Scratch Card Added Successfully",
          type: "success",
          scratchName: "",
          giftImage: "",
          // category_type:'',
          image_select: false,
          edit_enable: false,
          edit_item_id: "",
          giftName: "",
          uploading_trailer: false,
          progressBarParsent: 0,
          trailer: "",
          uploading_trailer: false,
          trailerPlaylists: "",
          loyaltyPoint: "",
          winningUser: "",

        });

        this.fetchData();
      } else {
        this.setState({
          alert: true,
          message: result.error,
          type: "danger",
        });
      }
    } else {
      this.setState({
        alert: true,
        message: "Please Fill Up All Details.",
        type: "warning",
      });
    }
    setTimeout(() => {
      this.setState({
        alert: false,
        message: "",
        type: "",
      });
    }, 3000);
  };

  handleChange = (event) => {
    console.log(event.target.value);
    this.setState({ category_type: event.target.value });
  };

  imageUpload = async (e) => {
    console.log("e", e.target.files);
    let file = e.target.files;
    //   if(file.lenght>0)
    //   {
    let dataSend = { buckate: true, buckate_name: "appIcon" };

    let result = await HttpClient.newFileUpload(
      "image-upload/category",
      e.target.files[0],
      dataSend
    );
    // console.log("result", result);
    if (result && result.status) {
      this.setState({ giftImage: result.url, image_select: true });
    }

    //   }
  };

  render() {
    const data = {
      columns: [
        {
          label: "Sl.",
          field: "sl",
          sort: "asc",
          width: 150,
        },
        {
          label: "Scratch Name",
          field: "scratchName",
          sort: "asc",
          width: 270,
        },
        {
          label: "Gift Name",
          field: "giftName",
          sort: "asc",
          width: 270,
        },
        {
          label: "Winning User",
          field: "winningUser",
          sort: "asc",
          width: 270,
        },
        {
          label: "Loyalty Point",
          field: "loyaltyPoint",
          sort: "asc",
          width: 270,
        },
        {
          label: "Image",
          field: "giftImage",
          sort: "asc",
          width: 270,
        },
        {
          label: "Created By",
          field: "created_by",
          sort: "asc",
          width: 270,
        },
        {
          label: "Updated By",
          field: "updated_by",
          sort: "asc",
          width: 270,
        },
        {
          label: "Action",
          field: "action",
          sort: "asc",
          width: 100,
        },
      ],
      rows: this.state.data,
    };
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title={this.state.breadcrumbItems[0].title}
              breadcrumbItems={this.state.breadcrumbItems}
            />

            <Row>
              <Col xs={12}>
                <Card>
                  <CardBody>
                    {this.state.alert ? (
                      <UncontrolledAlert
                        color={this.state.type}
                        className="alert-dismissible fade show"
                        role="alert"
                      >
                        {this.state.type == "warning" ? (
                          <i className="mdi mdi-alert-outline mr-2"></i>
                        ) : this.state.type == "success" ? (
                          <i className="mdi mdi-check-all mr-2"></i>
                        ) : this.state.type == "danger" ? (
                          <i className="mdi mdi-block-helper mr-2"></i>
                        ) : null}
                        {this.state.message}
                      </UncontrolledAlert>
                    ) : null}
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Scratch Name<span style={{ color: 'red' }}>*</span>
                      </Label>
                      <Col md={10}>
                        <Input
                          className="form-control"
                          type="text"
                          value={this.state.scratchName}
                          id="example-text-input"
                          onChange={(val) => {
                            this.setState({ scratchName: val.target.value });
                          }}
                        />
                      </Col>
                    </FormGroup>

                    <FormGroup row>
                      <Label
                        htmlFor="example-search-input"
                        className="col-md-2 col-form-label"
                      >
                        Gift Name
                      </Label>
                      <Col md={10}>
                        <input
                          id="question-input1"
                          className="form-control"
                          rows="2"
                          onChange={(val) => {
                            this.setState({ giftName: val.target.value });
                          }}
                          value={this.state.giftName}
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        No of Winning User
                      </Label>
                      <Col md={10}>
                        <Input
                          className="form-control"
                          type="number"
                          value={this.state.winningUser}
                          id="example-text-input"
                          min={1}
                          onChange={(val) => {
                            this.setState({ winningUser: val.target.value });
                          }}
                        />
                      </Col>
                    </FormGroup>

                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Loyalty Point
                      </Label>
                      <Col md={10}>
                        <Input
                          className="form-control"
                          type="number"
                          value={this.state.loyaltyPoint}
                          id="example-text-input"
                          min={1}
                          onChange={(val) => {
                            this.setState({ loyaltyPoint: val.target.value });
                          }}
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Image
                      </Label>
                      <Col md={10}>
                        {this.state.image_select ? (
                          <div>
                            <img
                              src={this.state.giftImage}
                              alt="images"
                              className="rounded avatar-md card-img"
                            />
                          </div>
                        ) : null}
                        <Button
                          color="danger"
                          type="button"
                          className="waves-effect waves-light customUploadBtn mr-1"
                        >
                          <i className="ri-add-fill align-middle mr-1"></i> Add
                          image
                          <input
                            type="file"
                            accept=".jpeg,.jpg,.png,.svg,.webp,.jfif,.apng"
                            className="upload_input"
                            onChange={(e) => {
                              this.imageUpload(e);
                            }}
                          />
                        </Button>
                        <p style={{ color: 'red' }}>[image should be in dimensions 500 * 500 px]</p>
                      </Col>
                      <p style={{ marginTop: 5, textAlign: "right" }}>
                      [Note: Asterisk (<span style={{ color: 'red' }}>*</span>) denotes the mandatory field.]
                    </p>
                    </FormGroup>


                    {/* <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Upload Video
                      </Label>
                      <Col md={6}>
                        <Input
                          className="form-control"
                          type="file"
                          id="example-text-input"
                          onChange={(val) => {
                            this.uploadVideo(val.target.files[0], "Trailer");
                          }}
                        // ref={(ref) => (this.fileInputTrl = ref)}
                        />
                      </Col>
                      <Col md={4}>
                        {this.state.uploading_trailer ? (
                          <>
                            <ProgressBar
                              completed={this.state.progressBarParsent}
                            />
                            {this.state.progressBarParsent == 100
                              ? "please wait untill transcoding is done"
                              : null}
                          </>
                        ) : (
                          <>
                            {this.state.trailer != "" ? (
                              <video
                                width="100%"
                                controls
                                className="rounded-lg"
                              >
                                <source
                                  src={this.state.trailer}
                                  type="video/mp4"
                                />
                              </video>
                            ) : null}
                          </>
                        )}
                      </Col>
                    </FormGroup> */}

                    {/* {this.state.videoUpload ? (
                      <FormGroup row>
                        <Label
                          htmlFor="example-text-input"
                          className="col-md-2 col-form-label"
                        >
                          Upload Video
                        </Label>
                        <Col md={6}>
                          <Input
                            className="form-control"
                            type="file"
                            id="example-text-input"
                            onChange={(val) => {
                              this.uploadVideo(val.target.files[0], "Video");
                            }}
                            ref={(ref) => (this.fileInputVideo = ref)}
                          />
                        </Col>
                        <Col md={4}>
                          {this.state.uploading_movie ? (
                            <>
                              <ProgressBar
                                completed={this.state.progressBarParsentVideo}
                              />
                              {this.state.progressBarParsentVideo == 100
                                ? "please wait untill transcoding is done"
                                : null}
                            </>
                          ) : (
                            <>
                              {this.state.url != "" ? (
                                <video
                                  width="100%"
                                  controls
                                  className="rounded-lg"
                                >
                                  <source
                                    src={this.state.url}
                                    type="video/mp4"
                                  />
                                </video>
                              ) : null}
                            </>
                          )}
                        </Col>
                      </FormGroup>
                    ) : null} */}
                    <FormGroup className="mb-0">
                      <div className="button-items pt-4">
                        <Button
                          color="primary"
                          type="button"
                          className="waves-effect waves-light mr-1"
                          onClick={() => {
                            this.submit();
                          }}
                        >
                          {this.state.edit_enable ? "Update" : "Submit"}{" "}
                          <i className="ri-arrow-right-line align-middle ml-1"></i>
                        </Button>
                      </div>
                    </FormGroup>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col xl={12}>
                <div className="card p-3">
                  <h5>SCRATCH CARD</h5>
                  <MDBDataTable responsive bordered data={data} />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default FormElements;
