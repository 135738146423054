import React, { Component } from "react";
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  FormGroup,
  Label,
  Input,
  CustomInput,
  Button,
  UncontrolledAlert,
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { MDBDataTable } from "mdbreact";
import { reactLocalStorage } from "reactjs-localstorage";
import moment from "moment";
import HttpClient from "./../../utils/HttpClient";
import { Link } from "react-router-dom";

class FormElements extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: "Genre", link: "#" },
        { title: "Add & Manage Genre", link: "#" },
      ],
      customchk: true,
      toggleSwitch: true,
      // page states
      name: "",
      desc: "",
      duration: "",
      price: "",
      data: [],
      alert: false,
      message: "",
      type: "",
      result: [],
      edit_enable: false,
      edit_item_id: "",
      image_select: false,
      img_url: '',
      category_type: "",
      userDetails: {},
    };
  }

  componentDidMount = async () => {
    this.checkUser();
  };

  checkUser = async () => {
    let user = await reactLocalStorage.getObject("userData");
    if (user != null && Object.keys(user).length > 0) {
      this.setState({
        userId: user.id,
        userDetails: user,
      });
    } else {
    }
    this.fetchData();
  };

  fetchData = async () => {
    let result = await HttpClient.requestData("admin/podcast-genre", "GET");
    if (result && result.status > 0) {
      let data = [];
      let i = 1;
      this.setState({ result: result.data });
      this.state.result.forEach((element, index) => {
        let rows = {
          sl: i,
          name: element.name,
          category_type: element.category_type,
          created_by: element.created_by ? element.created_by.name + " on " + moment(element.created_on).utcOffset(element.created_on).format('DD/MM/YYYY, HH:mm') : "" + " on " + moment(element.created_on).utcOffset(element.created_on).format('DD/MM/YYYY, HH:mm'),
          updated_by: element.updated_by ? element.updated_by.name + " on " + moment(element.updated_on).utcOffset(element.updated_on).format('DD/MM/YYYY, HH:mm') : "" + "",
          img: (<div>
            <img
              src={element.img}
              alt="images"
              className="rounded avatar-md card-img"
            />
          </div>),
          action: (
            <>
             {this.state.userDetails.sub_admin == true ? null : (
              <button
                title="Delete"
                className="btn btn-danger mr-2"
                onClick={() => {
                  if (window.confirm('Are you sure you wish to delete this item?'))
                    this.delete(element, index)
                }}
              >
                <i className="fa fa-trash" />
              </button>
             )}
              <button
                title="Edit"
                className="btn btn-primary"
                onClick={() => {
                  this.edit(element, index);
                  window.scrollTo(0, 0);
                }}
              >
                <i className="fa fa-edit" />
              </button>
            </>
          ),
        };
        i++;
        data.push(rows);
      });
      this.setState({
        data: data,
      });
    } else {
      this.setState({
        data: [],
      });
    }
  };
  delete = async (item, index) => {
    let sendData = {
      id: item._id,
      genre: item.id
    };
    let result = await HttpClient.requestData(
      "admin/podcast-genre/delete",
      "POST",
      sendData
    );
    if (result && result.status == true) {
      let index = this.state.result.indexOf(item);
      if (index > -1) {
        this.state.result.splice(index, 1);
        this.setState({
          alert: true,
          message: "Deleted Successfully",
          type: "success",
        });
        setTimeout(() => {
          this.setState({
            alert: false,
            message: "",
            type: "",
          });
        }, 3000);
        this.fetchData();
      }
    } else {
      this.setState({
        alert: true,
        message: "Sorry !! Video exists! At first Delete the Video",
        type: "warning",
      });
    }
  };
  edit = async (item, index) => {
    this.setState({
      edit_enable: true,
      name: item.name,
      // category_type: item.category_type,
      edit_item_id: item._id,
      img_url: item.img,
      image_select: true,
      desc: item.desc
    });
  };
  submit = async () => {
    if (
      this.state.name != ""
      // this.state.category_type != "" &&
      // this.state.img_url != ""
    ) {
      let data = null;
      let result = null;

      if (this.state.edit_enable == false) {
        data = {
          name: this.state.name,
          // category_type: this.state.category_type,
          // img: this.state.img_url,
          desc: this.state.desc,
          created_by: this.state.userId,
        };
        result = await HttpClient.requestData(
          "admin/podcast-genre",
          "POST",
          data
        );
      } else {
        data = {
          name: this.state.name,
          // category_type: this.state.category_type,
          // img: this.state.img_url,
          desc: this.state.desc,
          id: this.state.edit_item_id,
          updated_by: this.state.userId,
        };
        result = await HttpClient.requestData(
          "admin/podcast-genre/update",
          "POST",
          data
        );
      }
      console.log('result', result);
      if (result && result.status) {
        this.setState({
          alert: true,
          message: this.state.edit_enable
            ? "Genre Updated Successfully"
            : "Genre Added Successfully",
          type: "success",
          name: "",
          img_url: "",
          // category_type:'',
          image_select: false,
          edit_enable: false,
          edit_item_id: "",
          desc: ''
        });

        this.fetchData();
      } else {
        this.setState({
          alert: true,
          message: result.error,
          type: "danger",
        });
      }
    } else {
      this.setState({
        alert: true,
        message: "Please Fill Up All Details.",
        type: "warning",
      });
    }
    setTimeout(() => {
      this.setState({
        alert: false,
        message: "",
        type: "",
      });
    }, 3000);
  };

  handleChange = (event) => {
    console.log(event.target.value);
    this.setState({ category_type: event.target.value });
  };

  imageUpload = async (e) => {
    console.log("e", e.target.files);
    let file = e.target.files;
    //   if(file.lenght>0)
    //   {
    let dataSend = { buckate: true, buckate_name: "appIcon" };

    let result = await HttpClient.newFileUpload(
      "image-upload/category",
      e.target.files[0],
      dataSend
    );
    // console.log("result", result);
    if (result && result.status) {
      this.setState({ img_url: result.url, image_select: true });
    }

    //   }
  };

  render() {
    const data = {
      columns: [
        {
          label: "Sl.",
          field: "sl",
          sort: "asc",
          width: 150,
        },
        {
          label: "Name",
          field: "name",
          sort: "asc",
          width: 270,
        },
        {
          label: "Created By",
          field: "created_by",
          sort: "asc",
          width: 270,
        },
        {
          label: "Updated By",
          field: "updated_by",
          sort: "asc",
          width: 270,
        },
        // {
        //   label: "Category Type",
        //   field: "category_type",
        //   sort: "asc",
        //   width: 270,
        // },
        // {
        //   label: "Image",
        //   field: "img",
        //   sort: "asc",
        //   width: 270,
        // },
        {
          label: "Action",
          field: "action",
          sort: "asc",
          width: 100,
        },
      ],
      rows: this.state.data,
    };
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title={this.state.breadcrumbItems[0].title}
              breadcrumbItems={this.state.breadcrumbItems}
            />

            <Row>
              <Col xs={12}>
                <Card>
                  <CardBody>
                    {this.state.alert ? (
                      <UncontrolledAlert
                        color={this.state.type}
                        className="alert-dismissible fade show"
                        role="alert"
                      >
                        {this.state.type == "warning" ? (
                          <i className="mdi mdi-alert-outline mr-2"></i>
                        ) : this.state.type == "success" ? (
                          <i className="mdi mdi-check-all mr-2"></i>
                        ) : this.state.type == "danger" ? (
                          <i className="mdi mdi-block-helper mr-2"></i>
                        ) : null}
                        {this.state.message}
                      </UncontrolledAlert>
                    ) : null}
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Name<span style={{ color: 'red' }}>*</span>
                      </Label>
                      <Col md={10}>
                        <Input
                          className="form-control"
                          type="text"
                          value={this.state.name}
                          id="example-text-input"
                          onChange={(val) => {
                            this.setState({ name: val.target.value });
                          }}
                        />
                      </Col>
                    </FormGroup>

                    {/* <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Image
                      </Label>
                      <Col md={10}>
                        {this.state.image_select ? (
                          <div>
                            <img
                              src={this.state.img_url}
                              alt="images"
                              className="rounded avatar-md card-img"
                            />
                          </div>
                        ) : null}
                        <Button
                          color="danger"
                          type="button"
                          className="waves-effect waves-light customUploadBtn mr-1"
                        >
                          <i className="ri-add-fill align-middle mr-1"></i> Add
                          image
                          <input
                            type="file"
                            className="upload_input"
                            onChange={(e) => {
                              this.imageUpload(e);
                            }}
                          />
                        </Button>
                      </Col>
                    </FormGroup> */}

                    <FormGroup row>
                      <Label
                        htmlFor="example-search-input"
                        className="col-md-2 col-form-label"
                      >
                        Description
                      </Label>
                      <Col md={10}>
                        <textarea
                          id="question-input1"
                          className="form-control"
                          rows="2"
                          onChange={(val) => {
                            this.setState({ desc: val.target.value });
                          }}
                          value={this.state.desc}
                        ></textarea>
                      </Col>
                      <p style={{ marginTop: 5, textAlign: "right" }}>
                      [Note: Asterisk (<span style={{ color: 'red' }}>*</span>) denotes the mandatory field.]
                    </p>
                    </FormGroup>
                    <FormGroup className="mb-0">
                      <div className="button-items pt-4">
                        <Button
                          color="primary"
                          type="button"
                          className="waves-effect waves-light mr-1"
                          onClick={() => {
                            this.submit();
                          }}
                        >
                          {this.state.edit_enable ? "Update" : "Submit"}{" "}
                          <i className="ri-arrow-right-line align-middle ml-1"></i>
                        </Button>
                      </div>
                    </FormGroup>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col xl={12}>
                <div className="card p-3">
                  <div class="d-flex justify-content-between w-100">
                    <h5>GENRES</h5>
                    {this.state.userDetails.sub_admin == true ? null : (
                    <Link
                      className="btn btn-danger mr-2"
                      to={"/undoGenre/"}
                    >
                      Deleted Genres
                    </Link>
                    )}
                  </div>
                  <MDBDataTable responsive bordered data={data} />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default FormElements;
