import React, { Component } from "react";
import {
  Card,
  CardBody,
  Col,
  Row,
  Container
} from "reactstrap";
import { Link } from "react-router-dom";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { MDBDataTable } from "mdbreact";
import moment from "moment";
import HttpClient from "./../../utils/HttpClient";

class FormElements extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: "User", link: "#" },
        { title: "Manage Users", link: "#" },
      ],
      customchk: true,
      toggleSwitch: true,
      // page states
      name: "",
      desc: "",
      duration: "",
      price: "",
      data: [],
      alert: false,
      message: "",
      type: "",
      result: [],
      edit_enable: false,
      edit_item_id: "",
      image_select: false,
      img_url: "",
      category_type: "",
    };
  }

  componentDidMount = async () => {
    this.fetchData();
  };

  fetchData = async () => {
    let result = await HttpClient.requestData("admin/user", "GET");
    if (result && result.status > 0) {
      let data = [];
      let i = 1;
      this.setState({ result: result.data });
      this.state.result.forEach((element, index) => {
        let rows = {
          sl: i,
          image: (
            <div>
              <img
                src={element.image?element.image:""}
                alt="images"
                className="rounded avatar-md card-img"
              />
            </div>
          ),
          name: element.firstname + " " + element.lastname,
          email: element.email,
          rgt_date: moment(element.created_on).format("LL"),
          mobile: element.mobile,
          status: (
            <button
              title="Delete"
              className={
                element.status ? "btn btn-success mr-2" : "btn btn-danger mr-2"
              }
              onClick={() => {
                this.status(element, index);
              }}
            >
              {element.status ? "Active" : "Not Active"}
            </button>
          ),
          action: (
            <>
            
              <button
                title="Delete"
                className="btn btn-danger mr-2"
                onClick={() => {
                  if (window.confirm('Are you sure you wish to delete this item?'))
                    this.delete(element, index)
                }}
              >
                <i className="fa fa-trash" />
              </button>
              {/* <button
                title="Edit"
                className="btn btn-primary"
                onClick={() => {
                  this.edit(element, index);
                }}
              >
                <i className="fa fa-edit" />
              </button> */}
            </>
          ),
        };
        i++;
        data.push(rows);
      });
      this.setState({
        data: data,
      });
    } else {
      this.setState({
        data: [],
      });
    }
  };
  delete = async (item, index) => {
    let sendData = {
      id: item._id,
    };
    let result = await HttpClient.requestData(
      "admin/user/delete-user ",
      "POST",
      sendData
    );
    if (result && result.status) {
      let index = this.state.result.indexOf(item);
      if (index > -1) {
        this.state.result.splice(index, 1);
        this.setState({
          alert: true,
          message: "Deleted Successfully",
          type: "success",
        });
        setTimeout(() => {
          this.setState({
            alert: false,
            message: "",
            type: "",
          });
        }, 3000);
        this.fetchData();
      }
    }
  };
  status = async (item, index) => {
    let sendData = {
      id: item._id,
      status: !item.status,
    };
    let result = await HttpClient.requestData(
      "admin/user/status-user",
      "POST",
      sendData
    );
    if (result && result.status) {
      this.state.result.splice(index, 1);
      this.setState({
        alert: true,
        message: "Status Updated Successfully",
        type: "success",
      });
      setTimeout(() => {
        this.setState({
          alert: false,
          message: "",
          type: "",
        });
      }, 3000);
      this.fetchData();
    }
  };

  handleChange = (event) => {
    console.log(event.target.value);
    this.setState({ category_type: event.target.value });
  };

  imageUpload = async (e) => {
    console.log("e", e.target.files);
    let file = e.target.files;
    //   if(file.lenght>0)
    //   {
    let dataSend = { buckate: true, buckate_name: "appIcon" };

    let result = await HttpClient.newFileUpload(
      "image-upload/category",
      e.target.files[0],
      dataSend
    );
    // console.log("result", result);
    if (result && result.status) {
      this.setState({ img_url: result.url, image_select: true });
    }

    //   }
  };

  render() {
    const data = {
      columns: [
        {
          label: "Sl.",
          field: "sl",
          sort: "asc",
          width: 150,
        },
        {
            label: "Image",
            field: "image",
            sort: "asc",
            width: 270,
        },
        {
          label: "Name",
          field: "name",
          sort: "asc",
          width: 270,
        },
        {
          label: "Email",
          field: "email",
          sort: "asc",
          width: 270,
        },
        // {
        //   label: "Phone number",
        //   field: "mobile",
        //   sort: "asc",
        //   width: 270,
        // },
        {
          label: "Register date",
          field: "rgt_date",
          sort: "asc",
          width: 270,
        },
        //   label: "Rgt. Date",
        //   field: "rgt_date",
        //   sort: "asc",
        //   width: 270,
        // },
        {
            label: "Status",
            field: "status",
            sort: "asc",
            width: 100,
          },
        {
          label: "Action",
          field: "action",
          sort: "asc",
          width: 100,
        },
        
      ],
      rows: this.state.data,
    };
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title={this.state.breadcrumbItems[0].title}
              breadcrumbItems={this.state.breadcrumbItems}
            />

            <Row>
              <Col xl={12}>
                <div className="card p-3">
                <div class="d-flex justify-content-between w-100">
                    <h4>USER</h4>
                    <Link
                      className="btn btn-danger mr-2"
                      to={"/userlist-undo/"}
                    >
                      Deleted User List
                    </Link>
                  </div>
                  <MDBDataTable responsive bordered data={data} />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default FormElements;