import React, { Component } from "react";
import {
    Card,
    CardBody,
    Col,
    Row,
    Container,
    FormGroup,
    Label,
    Input,
    CustomInput,
    Button,
    UncontrolledAlert,
    CardImg
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { MDBDataTable } from "mdbreact";
import { reactLocalStorage } from "reactjs-localstorage";
import moment from "moment";
import HttpClient from "./../../utils/HttpClient";
import Select from "react-select";

class FormElements extends Component {
    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems: [
                { title: "Sub-admin", link: "#" },
                { title: "Add & Manage Sub-admin", link: "#" },
            ],
            customchk: true,
            toggleSwitch: true,
            // page states

            data: [],
            alert: false,
            message: "",
            type: "",
            result: [],
            edit_enable: false,
            edit_item_id: "",
            edit_item_nid: "",
            image_select: false,
            image: "",
            name: "",
            email: "",
            password: "",
            userId: "",
            token: "",
            optionGroupALL: [],
            selectedMultiAll: [],
            modules: [],
        };
    }

    componentDidMount = async () => {
        this.checkUser();
        this.fetchModule();
    };

    checkUser = async () => {
        let user = await reactLocalStorage.getObject("userData");
        console.log("profile", user);
        if (user != null && Object.keys(user).length > 0) {
            this.setState({ userId: user.id, token: user.token });
            this.fetchData(user.token);
        } else {
        }
    };

    fetchModule = async () => {
        let result = await HttpClient.requestData("admin/all-module", "GET");
        console.log("admin/all-module", result);

        if (result && result.status > 0) {
            this.setState({ modules: result.data });
            let optionAppowner = {};
            optionAppowner.label = "Modules";
            optionAppowner.options = [];
            result.data.map((item, index) => {
                optionAppowner.options.push({
                    label: item.name,
                    value: item.id,
                });
            });

            let newOptions = [];
            newOptions.push(optionAppowner);

            this.setState({
                optionGroupALL: newOptions,
                optionGroupFilter: newOptions,
            });
        }
    };

    handleMultiAll = (selectedMultiAll) => {
        let typesNew = [];
        if (selectedMultiAll && selectedMultiAll.length > 0) {
            selectedMultiAll.map((item, index) => {
                let findNew = this.state.modules.filter((ig) => ig.id == item.value);
                if (findNew.length > 0) {
                    typesNew.push(findNew[0]);
                }
            });
            let showVideo = typesNew.filter((ikk) => ikk.type == true);
            if (showVideo.length > 0) {
                this.setState({ videoUpload: true });
            } else {
                this.setState({ videoUpload: false });
            }
            this.setState({ selectedMultiAll });
        }
    };

    fetchData = async (token) => {
        let result = await HttpClient.requestData("admin/all-subadmin", "GET");
        if (result && result.status > 0) {
            let data = [];
            let i = 1;
            this.setState({ result: result.data });
            this.state.result.forEach((element, index) => {
                let modules = "";
                if (element.modules) {
                    modules = element.modules.reduce(
                        (acc, curr) => `${acc}${curr.label} | `,
                        ""
                    );
                    modules = modules.substring(0, modules.length - 2);
                    console.log("modules", modules);
                }
                let rows = {
                    sl: i,
                    name: element.name,
                    email: element.email,
                    password: element.password,
                    modules: element.modules ? modules : "",
                    img: (
                        <div>
                            <img
                                src={element.image}
                                alt="images"
                                className="rounded avatar-md card-img"
                            />
                        </div>
                    ),
                    status: (
                        <button
                            title="Status"
                            className={
                                element.status ? "btn btn-success mr-2" : "btn btn-danger mr-2"
                            }
                            onClick={() => {
                                    this.status(element, index);
                            }}
                        >
                            {element.status ? "Active" : "Deactive"}
                        </button>
                    ),
                    action: (
                        <>
                            <button
                                title="Delete"
                                className="btn btn-danger mr-2"
                                onClick={() => {
                                    if (window.confirm('Are you sure you wish to delete this show host?'))
                                        this.delete(element, index);
                                }}
                            >
                                <i className="fa fa-trash" />
                            </button>
                            <button
                                title="Edit"
                                className="btn btn-primary"
                                onClick={() => {
                                    this.edit(element, index);
                                }}
                            >
                                <i className="fa fa-edit" />
                            </button>
                        </>
                    ),
                };
                i++;
                data.push(rows);
            });
            this.setState({
                data: data,
            });
        } else {
            this.setState({
                data: [],
            });
        }
    };

    delete = async (item, index) => {
        let sendData = {
            id: item._id,
        };
        let result = await HttpClient.requestData("admin/delete", "POST", sendData);
        if (result && result.status) {
            let index = this.state.result.indexOf(item);
            if (index > -1) {
                this.state.result.splice(index, 1);
                this.setState({
                    alert: true,
                    message: "Deleted Successfully",
                    type: "success",
                });
                setTimeout(() => {
                    this.setState({
                        alert: false,
                        message: "",
                        type: "",
                    });
                }, 3000);
                this.fetchData(this.state.token);
            }
        }
    };

    status = async (item, index) => {
        let sendData = {
            id: item._id,
            status: !item.status,
        };
        let result = await HttpClient.requestData("admin/status", "POST", sendData);
        if (result && result.status) {
            this.state.result.splice(index, 1);
            this.setState({
                alert: true,
                message: "Status Updated Successfully",
                type: "success",
            });
            setTimeout(() => {
                this.setState({
                    alert: false,
                    message: "",
                    type: "",
                });
            }, 3000);
            this.fetchData(this.state.token);
        }
    };

    edit = async (item, index) => {
        // let allDf = [];
        // let setSelect = this.state.modules.filter((ikk) => ikk.id == item.modules);
        // setSelect.map((ifg, indd) => {
        //     allDf.push({ label: ifg.name, value: ifg.id });
        // });
        // this.setState({ selectedMultiAll: allDf });
        this.setState({
            edit_enable: true,
            name: item.name,
            email: item.email,
            // password: item.password,
            edit_item_id: item._id,
            edit_item_nid: item.id,
            image: item.image,
            image_select: true,
            selectedMultiAll: item.modules,

        });
    };

    submit = async () => {
        if (this.state.image_select == false) {
            this.setState({
                alert: true,
                message: "Please Select Image.",
                type: "warning",
            });

            return;
        }
        if (
            this.state.name != "" &&
            this.state.email != "" &&
            this.state.password != "" &&
            this.state.selectedMultiAll.length > 0
        ) {
            let data = null;
            let result = null;

            if (this.state.edit_enable == false) {
                data = {
                    name: this.state.name,
                    email: this.state.email,
                    password: this.state.password,
                    modules: this.state.selectedMultiAll,
                    image: this.state.image,
                    sub_admin: true,
                };
                result = await HttpClient.requestData("admin/register", "POST", data);
            } else {
                data = {
                    name: this.state.name,
                    email: this.state.email,
                    password: this.state.password,
                    modules: this.state.selectedMultiAll,
                    image: this.state.image,
                    _id: this.state.edit_item_id,
                    id: this.state.edit_item_nid,
                };
                result = await HttpClient.requestData("admin/update", "POST", data);
            }
            if (result && result.status) {
                this.setState({
                    alert: true,
                    message: this.state.edit_enable
                        ? "Updated Successfully"
                        : "Added Successfully",
                    type: "success",
                    name: "",
                    email: "",
                    password: "",
                    edit_enable: false,
                    edit_item_id: "",
                    image_select: false,
                    image: "",
                    selectedMultiAll: [],
                });

                this.fetchData(this.state.token);
            } else {
                this.setState({
                    alert: true,
                    message:
                        typeof result.error == "string"
                            ? result.error
                            : result.error[0].msg,
                    type: "danger",
                });
            }
        } else {
            this.setState({
                alert: true,
                message: "Please Fill Up All Details.",
                type: "warning",
            });
        }
        setTimeout(() => {
            this.setState({
                alert: false,
                message: "",
                type: "",
            });
        }, 3000);
    };

    handleChange = (event) => {
        console.log(event.target.value);
        this.setState({ category_type: event.target.value });
    };

    imageUpload = async (e) => {
        console.log("e", e.target.files);
        let file = e.target.files;
        //   if(file.lenght>0)
        //   {
        let dataSend = { buckate: true, buckate_name: "appIcon" };

        let result = await HttpClient.newFileUpload(
            "image-upload/profile",
            e.target.files[0],
            dataSend
        );
        console.log("result", result);
        if (result && result.status) {
            this.setState({ image: result.url, image_select: true });
        }

        //   }
    };

    render() {
        const data = {
            columns: [
                {
                    label: "Sl.",
                    field: "sl",
                    sort: "asc",
                    width: 150,
                },
                {
                    label: "Image",
                    field: "img",
                    sort: "asc",
                    width: 270,
                },
                {
                    label: "Name",
                    field: "name",
                    sort: "asc",
                    width: 270,
                },
                {
                    label: "Email",
                    field: "email",
                    sort: "asc",
                    width: 270,
                },
                {
                    label: "Status",
                    field: "status",
                    sort: "asc",
                    width: 100,
                },
                {
                    label: "Action",
                    field: "action",
                    sort: "asc",
                    width: 100,
                },
            ],
            rows: this.state.data,
        };
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs
                            title={this.state.breadcrumbItems[0].title}
                            breadcrumbItems={this.state.breadcrumbItems}
                        />

                        <Row>
                            <Col xs={12}>
                                <Card>
                                    <CardBody>
                                        {this.state.alert ? (
                                            <UncontrolledAlert
                                                color={this.state.type}
                                                className="alert-dismissible fade show"
                                                role="alert"
                                            >
                                                {this.state.type == "warning" ? (
                                                    <i className="mdi mdi-alert-outline mr-2"></i>
                                                ) : this.state.type == "success" ? (
                                                    <i className="mdi mdi-check-all mr-2"></i>
                                                ) : this.state.type == "danger" ? (
                                                    <i className="mdi mdi-block-helper mr-2"></i>
                                                ) : null}
                                                {this.state.message}
                                            </UncontrolledAlert>
                                        ) : null}
                                        <FormGroup row>
                                            <Label
                                                htmlFor="example-text-input"
                                                className="col-md-2 col-form-label"
                                            >
                                                Name<span style={{ color: 'red' }}>*</span>
                                            </Label>
                                            <Col md={10}>
                                                <Input
                                                    className="form-control"
                                                    type="text"
                                                    value={this.state.name}
                                                    id="example-text-input"
                                                    onChange={(val) => {
                                                        this.setState({ name: val.target.value });
                                                    }}
                                                />
                                            </Col>
                                        </FormGroup>

                                        <FormGroup row>
                                            <Label
                                                htmlFor="example-search-input"
                                                className="col-md-2 col-form-label"
                                            >
                                                Email<span style={{ color: 'red' }}>*</span>
                                            </Label>
                                            <Col md={4}>
                                                <Input
                                                    className="form-control"
                                                    type="email"
                                                    value={this.state.email}
                                                    id="example-text-input"
                                                    onChange={(val) => {
                                                        this.setState({ email: val.target.value });
                                                    }}
                                                />
                                            </Col>
                                            <Label
                                                htmlFor="example-search-input"
                                                className="col-md-2 col-form-label"
                                            >
                                                Password<span style={{ color: 'red' }}>*</span>
                                            </Label>
                                            <Col md={4}>
                                                <Input
                                                    className="form-control"
                                                    type="password"
                                                    value={this.state.password}
                                                    id="example-text-input"
                                                    onChange={(val) => {
                                                        this.setState({ password: val.target.value });
                                                    }}
                                                />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label
                                                htmlFor="example-search-input"
                                                className="col-md-2 col-form-label"
                                            >
                                                Select Image<span style={{ color: 'red' }}>*</span>
                                            </Label>
                                            <Col md={6}>
                                                <div className="custom-file">
                                                    <CustomInput
                                                        type="file"
                                                         accept=".jpeg,.jpg,.png,.svg,.webp,.jfif,.apng"
                                                        className="custom-file-input"
                                                        id="customFile"
                                                        onChange={(e) => {
                                                            this.imageUpload(e);
                                                        }}
                                                    />
                                                    <Label
                                                        className="custom-file-label"
                                                        htmlFor="customFile"
                                                    >
                                                        Choose file
                                                    </Label>
                                                </div>
                                                <p style={{ color: 'red' }}>[image should be in dimensions 500 * 500 px]</p>
                                            </Col>
                                            <Col md={4}>
                                                {this.state.image != "" && <CardImg
                                                    top
                                                    className="img-fluid"
                                                    src={this.state.image}
                                                    alt="Skote"
                                                    style={{
                                                        alignSelf: "center",
                                                        width: 200,
                                                        height: 200,
                                                        borderRadius: 100,
                                                        marginTop: 20,
                                                    }}
                                                />}
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label
                                                htmlFor="example-text-input"
                                                className="col-md-2 col-form-label"
                                            >
                                                Select Modules<span style={{ color: 'red' }}>*</span>
                                            </Label>
                                            <Col md={10}>
                                                <Select
                                                    isMulti={true}
                                                    options={this.state.optionGroupALL}
                                                    values={this.state.selectedMultiAll}
                                                    onChange={
                                                        // (values) => this.setValues(values)
                                                        this.handleMultiAll
                                                    }
                                                    value={this.state.selectedMultiAll}
                                                />
                                            </Col>
                                        </FormGroup>

                                        <p style={{ marginTop: 5, textAlign: "right" }}>
                                            [Note: Asterisk (<span style={{ color: 'red' }}>*</span>) denotes the mandatory field.]
                                        </p>

                                        <FormGroup className="mb-0">
                                            <div className="button-items pt-4">
                                                <Button
                                                    color="primary"
                                                    type="button"
                                                    className="waves-effect waves-light mr-1"
                                                    onClick={() => {
                                                        this.submit();
                                                    }}
                                                >
                                                    {this.state.edit_enable ? "Update" : "Submit"}{" "}
                                                    <i className="ri-arrow-right-line align-middle ml-1"></i>
                                                </Button>
                                            </div>
                                        </FormGroup>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={12}>
                                <div className="card p-3">
                                    <MDBDataTable responsive bordered data={data} />
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default FormElements;
