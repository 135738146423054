import React, { Component } from "react";
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  FormGroup,
  Label,
  Input,
  CustomInput,
  Button,
  UncontrolledAlert,
} from "reactstrap";
import { Link } from "react-router-dom";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { MDBDataTable } from "mdbreact";
import { reactLocalStorage } from "reactjs-localstorage";
import moment from "moment";
import Dropzone from "react-dropzone";
import BaseInfo from "../ChooseDesign/baseInfo";
import HttpClient from "./../../utils/HttpClient";
import Select from "react-select";
import ProgressBar from "@ramonak/react-progress-bar";
import Switch from "react-switch";

// import EcomPreview from "./E-ComPreview/index";
class FormElements extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: "Video", link: "#" },
        { title: "Add & Manage Video", link: "#" },
      ],
      customchk: true,
      toggleSwitch: true,
      // page states
      name: "",
      data: [],
      alert: false,
      message: "",
      type: "",
      result: [],
      edit_enable: false,
      edit_item_id: "",
      image_select: false,
      img_url: "",
      userId: "",
      userDetails: {},
      //   this page states
      category: [],
      categoryAll: [],
      geners: [],

      selectedFiles: [],
      images: [],
      uploaded_images: [],

      optionGroup: [],
      selectedMulti: [],

      optionGroupALL: [],
      selectedMultiAll: [],

      optionGroupCat: [],
      selectedMultiCat: [],

      director: "",
      desc: "",
      season: "",
      trailer: "",
      showSelect: true,
      submit: false,
      uploading_trailer: false,
      trailerPlaylists: "",
      cat_id: "",
      cast: "",
      progressBarParsent: 0,
      progressBarParsentVideo: 0,
      uploading_movie: false,

      videoUrl: "",
      url: "",

      videoUpload: false,
      types: "",
      duration: "",
      optionGroupFilter: [],
      selectedMultiFilter: [],
      selectedType: "",
      normalId: "",
      priority: "",
      currentDate: new Date().toISOString().slice(0, 10),
      priceType: "",
      plansGroup: [],
      selectedplansMulti: [],
      childrenSuitable: true,
    };
    this.handleMulti = this.handleMulti.bind(this);
    this.handleMultiAll = this.handleMultiAll.bind(this);
    this.handleMultiCat = this.handleMultiCat.bind(this);
    this.handleMultiFilter = this.handleMultiFilter.bind(this);
    this.handleMultiPlan = this.handleMultiPlan.bind(this);
  }
  handleMulti = (selectedMulti) => {
    this.setState({ selectedMulti });
  };


  handleMultiAll = (selectedMultiAll) => {
    let typesNew = [];
    if (selectedMultiAll && selectedMultiAll.length == 1) {
      selectedMultiAll.map((item, index) => {
        let findNew = this.state.types.filter((ig) => ig.id == item.value);
        // console.log('findNew', findNew)
        if (findNew.length > 0) {
          typesNew.push(findNew[0]);
        }
      });
      // console.log('typesNew', typesNew)
      let showVideo = typesNew.filter((ikk) => ikk.type == true);
      if (showVideo.length > 0) {
        this.setState({ videoUpload: true });
      } else {
        this.setState({ videoUpload: false });
      }
      console.log('selectedMultiAll', selectedMultiAll)
      this.setState({ selectedMultiAll });
    } else {
      selectedMultiAll && selectedMultiAll.map((item, index) => {
        let findNew = this.state.types.filter((ig) => ig.id == item.value);
        // console.log('findNew', findNew)
        if (findNew.length > 0) {
          typesNew.pop(findNew[0]);
          typesNew.push(findNew[0]);
        }
      });
      // console.log('typesNew', typesNew)
      let showVideo = typesNew.filter((ikk) => ikk.type == true);
      if (showVideo.length > 0) {
        this.setState({ videoUpload: true });
      } else {
        this.setState({ videoUpload: false });
      }
      console.log('selectedMultiAll', selectedMultiAll)
      this.setState({ selectedMultiAll });
    }
  };

  handleMultiCat = (selectedMultiCat) => {
    this.setState({ selectedMultiCat });
  };

  handleMultiFilter = (selectedMultiFilter) => {
    console.log("selectedMultiFilter", selectedMultiFilter);
    this.setState({ selectedMultiFilter });
    if (Object.keys(selectedMultiFilter).length > 0) {
      this.setState({ selectedType: selectedMultiFilter.value });
      this.fetchData(selectedMultiFilter.value);
    }
  };

  handleMultiPlan = (selectedplansMulti) => {
    this.setState({ selectedplansMulti });
  };

  componentDidMount = async () => {
    this.checkUser();
    this.fetchCat();
    this.fetchgenre();
    this.fetchType();
    this.fetchPlanData();
  };

  fetchCat = async () => {
    let result = await HttpClient.requestData("admin/jdcategory", "GET");
    if (result && result.status > 0) {
      this.setState({ categoryAll: result.data });
      let optionAppowner = {};
      optionAppowner.label = "Categories";
      optionAppowner.options = [];
      result.data.map((item, index) => {
        optionAppowner.options.push({
          label: item.name,
          value: item.id,
        });
      });

      let newOptions = [];
      newOptions.push(optionAppowner);

      this.setState({ optionGroupCat: newOptions });
    }
  };

  fetchType = async () => {
    let result = await HttpClient.requestData("admin/type", "GET");
    console.log("admin/type", result);

    if (result && result.status > 0) {
      this.setState({ types: result.data });
      let optionAppowner = {};
      optionAppowner.label = "Types";
      optionAppowner.options = [];
      result.data.map((item, index) => {
        optionAppowner.options.push({
          label: item.name,
          value: item.id,
        });
      });

      let newOptions = [];
      newOptions.push(optionAppowner);

      this.setState({
        optionGroupALL: newOptions,
        optionGroupFilter: newOptions,
      });
    }
  };

  fetchgenre = async () => {
    let result = await HttpClient.requestData("admin/gener", "GET");
    // console.log("admin/genre", result);

    if (result && result.status > 0) {
      this.setState({ geners: result.data });
      let optionAppowner = {};
      optionAppowner.label = "Categories";
      optionAppowner.options = [];
      result.data.map((item, index) => {
        optionAppowner.options.push({
          label: item.name,
          value: item.id,
        });
      });

      let newOptions = [];
      newOptions.push(optionAppowner);

      this.setState({ optionGroup: newOptions });
    }
  };

  fetchPlanData = async () => {
    let result = await HttpClient.requestData("admin/subscription", "GET");
    console.log("abc", result);
    if (result && result.status > 0) {
      let optionAppowner = {};
      optionAppowner.label = "Plans";
      optionAppowner.options = [];
      let newData = result.data;
      // newData = newData.filter((item) => item.free == false);
      newData.map((item, index) => {
        optionAppowner.options.push({
          label: item.title,
          value: item.id,
        });
      });
      let newOptions = [];
      newOptions.push(optionAppowner);
      this.setState({ plansGroup: newOptions });
    }
  };

  uploadVideo = async (video, type) => {
    if (type == "Trailer") {
      this.setState({ uploading_trailer: true });
    } else {
      this.setState({ uploading_movie: true });
    }

    let dataSend = { buckate: false };
    let result = await HttpClient.uploadFileRequest(
      "video-upload/video",
      video,
      (data, data1, data2) => {
        console.log("data, data1, data2", data, data1, data2);

        if (type == "Trailer") {
          this.setState({ progressBarParsent: data });
        } else {
          this.setState({ progressBarParsentVideo: data });
        }
      }
    );
    if (result && result.status) {
      if (type == "Trailer") {
        this.setState({
          trailerPlaylists: result.playlists,
          trailer: result.data.url,
          uploading_trailer: false,
        });
      } else {
        this.setState({
          videoUrl: result.playlists,
          url: result.data.url,
          uploading_movie: false,
        });
      }
    }
    console.log("result", result);
  };

  fetchData = async (type) => {
    console.log("call");
    let result = await HttpClient.requestData(
      "admin/movies/get-all",
      "POST",
      type == "" ? {} : { type: type }
    );
    console.log("call", result);

    if (result && result.status > 0) {
      let data = [];
      let i = 1;
      this.setState({ result: result.data });
      this.state.result.forEach((element, index) => {
        let categoryy = "";
        if (element.categorys) {
          categoryy = element.categorys.reduce(
            (acc, curr) => `${acc}${curr.category.name} | `,
            ""
          );
          categoryy = categoryy.substring(0, categoryy.length - 2);
        }
        let rows = {
          sl: i,
          type: element.types.length > 0 ? element.types[0].name : null,
          name: element.name,
          created_by: element.created_by.length > 0 ? element.created_by[0].name + " on " + moment(element.created_on).utcOffset(element.created_on).format('DD/MM/YYYY, HH:mm') : "" + " on " + moment(element.created_on).utcOffset(element.created_on).format('DD/MM/YYYY, HH:mm'),
          updated_by: element.updated_by.length > 0 ? element.updated_by[0].name + " on " + moment(element.updated_on).utcOffset(element.updated_on).format('DD/MM/YYYY, HH:mm') : "" + " ",   
          img:
            element.img.length > 0 ? (
              <div>
                <img
                  src={element.img[0].preview}
                  alt="images"
                  className="rounded avatar-md card-img"
                />
              </div>
            ) : null,
          director: element.director ? element.director : "",
          category: categoryy,
          genre: element.genres.length > 0 ? element.genres[0].genre.name : "",
          cast: element.cast ? element.cast : "",
          year: element.year ? element.year : "",

          discount: element.discount ? element.discount : "",
          status: (
            <button
              title="Delete"
              className={
                element.status ? "btn btn-success mr-2" : "btn btn-danger mr-2"
              }
              onClick={() => {
                this.status(element, index);
              }}
            >
              {element.status ? "Active" : "Not Active"}
            </button>
          ),
          popular: (
            <button
              title="Delete"
              className={
                element.propular ? "btn btn-success mr-2" : "btn btn-danger mr-2"
              }
              onClick={() => {
                this.popular(element, index);
              }}
            >
              {element.propular ? "Active" : "Not Active"}
            </button>
          ),
          episodes: (
            <>
              {element.types.length > 0 && element.types[0].type == false ? (
                <button
                  title="Episodes"
                  className="btn btn-success"
                  onClick={() => {
                    this.props.history.push("episodes/" + element.id);
                  }}
                >
                  Episodes
                </button>
              ) : null}
            </>
          ),
          priority: element.priority,
          action: (
            <>
              {this.state.userDetails.sub_admin == true ? null : (
                <button
                  title="Delete"
                  className="btn btn-danger mr-2"
                  onClick={() => {
                    if (window.confirm('Are you sure you wish to delete this item?'))
                      this.delete(element, index)
                  }}
                >
                  <i className="fa fa-trash" />
                </button>
              )}
              <button
                title="Edit"
                className="btn btn-primary"
                onClick={() => {
                  this.edit(element, index);
                  window.scrollTo(0, 0);
                }}
              >
                <i className="fa fa-edit" />
              </button>
            </>
          ),
        };
        i++;
        data.push(rows);
      });
      this.setState({
        data: data,
      });
    } else {
      this.setState({
        data: [],
      });
    }
  };

  delete = async (item, index) => {
    let sendData = {
      id: item._id,
    };
    let result = await HttpClient.requestData(
      "admin/movies/delete",
      "POST",
      sendData
    );
    if (result && result.status) {
      let index = this.state.result.indexOf(item);
      if (index > -1) {
        this.state.result.splice(index, 1);
        this.setState({
          alert: true,
          message: "Deleted Successfully",
          type: "success",
        });
        setTimeout(() => {
          this.setState({
            alert: false,
            message: "",
            type: "",
          });
        }, 3000);
        this.fetchData(this.state.selectedType);
      }
    }
  };

  edit = async (item, index) => {
    let allDf = [];
    let setSelect = this.state.types.filter((ikk) => ikk.id == item.type);
    setSelect.map((ifg, indd) => {
      allDf.push({ label: ifg.name, value: ifg.id });
    });
    this.setState({ selectedMultiAll: allDf });
    let showVideo = setSelect.filter((ikk) => ikk.type == true);
    if (showVideo.length > 0) {
      this.setState({ videoUpload: true });
    } else {
      this.setState({ videoUpload: false });
    }
    // console.log("setSelect", setSelect);

    let allCat = [];
    item.categorys.map((catI, catN) => {
      allCat.push({ label: catI.category.name, value: catI.category.id });
    })

    let allGen = [];
    item.genres.map((genI,genN) => {
      allGen.push({ label: genI.genre.name, value: genI.genre.id});
    })

    this.handleMultiAll([{ label: item.types[0].name, value: item.types[0].id }])

    this.setState({
      edit_enable: true,
      name: item.name,
      uploaded_images: item.img ? item.img : [],
      image_select: true,
      edit_item_id: item.id,
      director: item.director,
      desc: item.desc,
      showSelect: false,
      // selectedMulti: item.category,
      // selectedMultiCat: typeof item.cat_id == "string" ? [] : item.cat_id,
      selectedMulti: typeof allGen == "string" ? [] : allGen,
      selectedMultiCat: typeof allCat == "string" ? [] : allCat,
      cast: item.cast,
      year: item.year,
      duration: item.duration,
      url: item.url,
      videoUrl: item.videoUrl,
      url: item.url,
      trailer: item.trailer,
      trailerPlaylists: item.trailerUrl,
      normalId: item.id,
      priority: item.priority,
      childrenSuitable:
        typeof item.childrenSuitable == "undefined"
          ? true
          : item.childrenSuitable,
      priceType: typeof item.priceType == "undefined" ? "Free" : item.priceType,
      selectedplansMulti:
        typeof item.plansGroup == "undefined" ? [] : item.plansGroup,
    });
    console.log("hi", item.priceType);
    setTimeout(() => {
      this.setState({ showSelect: true });
    }, 200);
    // this.handleMulti(item.category);
    // this.handleMulti(item.genres.length > 0 ? [{ label: item.genres[0].genre.name, value: item.genres[0].genre.id }] : null,);
    // this.handleMultiCat(item.categorys.length > 0 ? [{ label: item.categorys[0].category.name, value: item.categorys[0].category.id }] : null);
  };

  status = async (item, index) => {
    let sendData = {
      id: item._id,
      status: !item.status,
    };
    let result = await HttpClient.requestData(
      "admin/movies/status",
      "POST",
      sendData
    );
    if (result && result.status) {
      this.state.result.splice(index, 1);
      this.setState({
        alert: true,
        message: "Status Updated Successfully",
        type: "success",
      });
      setTimeout(() => {
        this.setState({
          alert: false,
          message: "",
          type: "",
        });
      }, 3000);
      this.fetchData(this.state.selectedType);
    }
  };
  popular = async (item, index) => {
    let sendData = {
      id: item.id,
      propular: !item.propular,
    };
    let result = await HttpClient.requestData(
      "admin/movies/propular-status",
      "POST",
      sendData
    );
    if (result && result.status) {
      this.state.result.splice(index, 1);
      this.setState({
        alert: true,
        message: "Status Updated Successfully",
        type: "success",
      });
      setTimeout(() => {
        this.setState({
          alert: false,
          message: "",
          type: "",
        });
      }, 3000);
      this.fetchData(this.state.selectedType);
    }
  };

  submit = async () => {
    if (this.state.videoUpload && this.state.url == "") {
      this.setState({
        alert: true,
        message: "Please Upload Video.",
        type: "warning",
      });
      return false;
    }

    if (
      this.state.name != "" &&
      this.state.director != "" &&
      this.state.uploaded_images.length > 0 &&
      this.state.cast != "" &&
      this.state.trailer != "" &&
      this.state.selectedMulti.length > 0 &&
      this.state.selectedMultiCat.length > 0
    ) {
      if (this.state.uploaded_images.length > 0) {
        this.setState({ loading: true });

        let data = null;
        let result = null;
        if (this.state.edit_enable) {
          data = {
            name: this.state.name,
            director: this.state.director,
            desc: this.state.desc,
            duration: this.state.duration,
            img: this.state.uploaded_images,
            genre: this.state.selectedMulti,
            edit_id: this.state.edit_item_id,
            trailer: this.state.trailer,
            trailerUrl: this.state.trailerPlaylists,
            cat_id: this.state.selectedMultiCat,
            cast: this.state.cast,
            year: this.state.year,
            type: this.state.selectedMultiAll.length == 2 ? this.state.selectedMultiAll[1].value : this.state.selectedMultiAll[0].value,
            videoUrl: this.state.videoUrl,
            url: this.state.url,
            category: this.state.selectedMulti,
            id: this.state.normalId,
            priority: Number(this.state.priority),
            childrenSuitable: this.state.childrenSuitable,
            priceType: this.state.priceType,
            plansGroup: this.state.selectedplansMulti,
            updated_by: this.state.userId,
          };
          console.log("xxxxxxxxxxxxxxxxxxxx", data);
          result = await HttpClient.requestData(
            "admin/movies/update",
            "POST",
            data
          );
        } else {
          data = {
            name: this.state.name,
            director: this.state.director,
            desc: this.state.desc,
            duration: this.state.duration,
            img: this.state.uploaded_images,
            category: this.state.selectedMulti,
            trailer: this.state.trailer,
            trailerUrl: this.state.trailerPlaylists,
            cat_id: this.state.selectedMultiCat,
            cast: this.state.cast,
            year: this.state.year,
            type: this.state.selectedMultiAll.length == 2 ? this.state.selectedMultiAll[1].value : this.state.selectedMultiAll[0].value,
            videoUrl: this.state.videoUrl,
            url: this.state.url,
            genre: this.state.selectedMulti,
            priority: Number(this.state.priority),
            childrenSuitable: this.state.childrenSuitable,
            priceType: this.state.priceType,
            plansGroup: this.state.selectedplansMulti,
            created_by: this.state.userId,
          };
          console.log("xxxxxxxxxxxxxxxxxxxx", data);
          result = await HttpClient.requestData(
            "admin/movies",
            "POST",
            data
          );
        }

        // return false;

        if (result && result.status) {
          this.setState({
            alert: true,
            message: this.state.edit_enable
              ? "Video Updated Successfully"
              : "Video Added Successfully",
            type: "success",
            name: "",
            types: "",
            cat_id: "",
            sub_cat_id: "",
            desc: "",
            pruduct_type: "",
            sell_price: "",
            purchase_price: "",
            discount: "",
            uploaded_images: [],
            images: [],
            selectedFiles: [],
            edit_enable: false,
            edit_item_id: "",
            selectedMulti: [],
            selectedMultiAll: [],
            selectedMultiCat: [],
            uploadVideo: false,
            url: "",
            trailer: "",
            cast: "",
            year: "",
            duration: "",
            director: "",
            priority: "",
            priceType: "",
            selectedplansMulti: [],
            childrenSuitable: true,
          });
          window.location.reload(false);
          // this.fileInputTrl.value = "";
          // this.fileInputVideo.value = "";
          await this.fetchData(this.state.selectedType);
        } else {
          this.setState({
            alert: true,
            message:
              typeof result.error == "string"
                ? result.error
                : result.error[0].msg,
            type: "danger",
          });
        }
      } else {
        this.setState({
          alert: true,
          message: "Please Select Atleast One Image.",
          type: "warning",
        });
      }
    } else {
      this.setState({
        alert: true,
        message: "Please Fill Up All Details.",
        type: "warning",
      });
    }
    setTimeout(() => {
      this.setState({
        alert: false,
        message: "",
        type: "",
      });
    }, 3000);
  };

  imageUpload = async (file, index) => {
    // console.log("e", file);

    let dataSend = { buckate: false };

    let result = await HttpClient.newFileUpload(
      "image-upload/event",
      file,
      dataSend
    );
    console.log("result", result);
    if (result && result.status) {
      this.setState({ uploaded_images: [], images: [] });
      this.state.uploaded_images.push({ preview: result.url });
      this.setState({});
    }
  };

  handleAcceptedFiles = (files) => {
    let data = [];
    let file = {
      preview: URL.createObjectURL(files[0]),
      formattedSize: this.formatBytes(files[0].size),
      // image:files,
    };
    let file2 = {
      image: files,
    };

    let file3 = files;
    console.log("data", file);
    this.state.selectedFiles.push(file);
    this.state.images.push(files[0]);
    this.imageUpload(files[0]);
    this.setState({});
  };

  async remove_image(item, index) {
    this.state.selectedFiles.pop(index);
    this.state.images.pop(index);
    this.state.uploaded_images.pop(index);
    this.setState({});
  }

  /**
   * Formats the size
   */
  formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  checkUser = async () => {
    let user = await reactLocalStorage.getObject("userData");
    if (user != null && Object.keys(user).length > 0) {
      this.setState({
        userId: user.id,
        userDetails: user,
      });
    } else {
    }
    this.fetchData(this.state.selectedType);
  };

  handleChange = async (event) => {
    let filterSub = this.state.subCategoryAll.filter(
      (item) => item.category_id == event.target.value
    );
    this.setState({ cat_id: event.target.value, subCategory: filterSub });
  };

  OnSymbol(text) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        {" "}
        {text}
      </div>
    );
  }

  Offsymbol(text) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        {" "}
        {text}
      </div>
    );
  }

  render() {
    const data = {
      columns: [
        {
          label: "Sl.",
          field: "sl",
          sort: "asc",
          width: 150,
        },
        {
          label: "Type",
          field: "type",
          sort: "asc",
          width: 270,
        },
        {
          label: "Name",
          field: "name",
          sort: "asc",
          width: 270,
        },
        {
          label: "Image",
          field: "img",
          sort: "asc",
          width: 270,
        },
        {
          label: "Director",
          field: "director",
          sort: "asc",
          width: 270,
        },

        {
          label: "Category",
          field: "category",
          sort: "asc",
          width: 270,
        },
        {
          label: "Priority",
          field: "priority",
          sort: "asc",
          width: 270,
        },
        {
          label: "Created By",
          field: "created_by",
          sort: "asc",
          width: 270,
        },
        {
          label: "Updated By",
          field: "updated_by",
          sort: "asc",
          width: 270,
        },
        {
          label: "Status",
          field: "status",
          sort: "asc",
          width: 100,
        },
        // {
        //   label: "Popular",
        //   field: "popular",
        //   sort: "asc",
        //   width: 100,
        // },
        {
          label: "Episodes",
          field: "episodes",
          sort: "asc",
          width: 100,
        },
        {
          label: "Action",
          field: "action",
          sort: "asc",
          width: 100,
        },
      ],
      rows: this.state.data,
    };
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title={this.state.breadcrumbItems[0].title}
              breadcrumbItems={this.state.breadcrumbItems}
            />

            <Row>
              {/* <BaseInfo /> */}
              <Col xs={12}>
                <Card>
                  <CardBody>
                    {this.state.alert ? (
                      <UncontrolledAlert
                        color={this.state.type}
                        className="alert-dismissible fade show"
                        role="alert"
                      >
                        {this.state.type == "warning" ? (
                          <i className="mdi mdi-alert-outline mr-2"></i>
                        ) : this.state.type == "success" ? (
                          <i className="mdi mdi-check-all mr-2"></i>
                        ) : this.state.type == "danger" ? (
                          <i className="mdi mdi-block-helper mr-2"></i>
                        ) : null}
                        {this.state.message}
                      </UncontrolledAlert>
                    ) : null}

                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Type<span style={{ color: 'red' }}>*</span>
                      </Label>
                      <Col md={10}>
                        {this.state.showSelect ? (
                          <Select
                            isMulti={true}
                            options={this.state.optionGroupALL}
                            // values={this.state.selectedMultiAll}
                            onChange={
                              // (values) => this.setValues(values)
                              this.handleMultiAll
                            }
                            value={this.state.selectedMultiAll ? (this.state.selectedMultiAll.length == 1 ? this.state.selectedMultiAll : this.state.selectedMultiAll[1]) : null}
                          />
                        ) : null}
                      </Col>
                    </FormGroup>

                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Name<span style={{ color: 'red' }}>*</span>
                      </Label>
                      <Col md={10}>
                        <Input
                          className="form-control"
                          type="text"
                          value={this.state.name}
                          id="example-text-input"
                          onChange={(val) => {
                            this.setState({ name: val.target.value });
                          }}
                        />
                      </Col>
                    </FormGroup>

                    <FormGroup row>
                      <Label
                        htmlFor="example-search-input"
                        className="col-md-2 col-form-label"
                      >
                        Description
                      </Label>
                      <Col md={10}>
                        <textarea
                          id="question-input1"
                          className="form-control"
                          rows="2"
                          onChange={(val) => {
                            this.setState({ desc: val.target.value });
                          }}
                          value={this.state.desc}
                        ></textarea>
                      </Col>
                    </FormGroup>

                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Director<span style={{ color: 'red' }}>*</span>
                      </Label>
                      <Col md={10}>
                        <Input
                          className="form-control"
                          type="text"
                          value={this.state.director}
                          id="example-text-input"
                          onChange={(val) => {
                            this.setState({ director: val.target.value });
                          }}
                        />
                      </Col>
                    </FormGroup>

                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Category<span style={{ color: 'red' }}>*</span>
                      </Label>
                      <Col md={10}>
                        {this.state.showSelect ? (
                          <Select
                            isMulti={true}
                            options={this.state.optionGroupCat}
                            values={this.state.selectedMultiCat}
                            onChange={
                              // (values) => this.setValues(values)
                              this.handleMultiCat
                            }
                            value={this.state.selectedMultiCat}
                          />
                        ) : null}
                      </Col>
                    </FormGroup>

                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Genre<span style={{ color: 'red' }}>*</span>
                      </Label>
                      <Col md={10}>
                        {this.state.showSelect ? (
                          <Select
                            isMulti={true}
                            options={this.state.optionGroup}
                            values={this.state.selectedMulti}
                            onChange={
                              // (values) => this.setValues(values)
                              this.handleMulti
                            }
                            value={this.state.selectedMulti}
                          />
                        ) : null}
                      </Col>
                    </FormGroup>

                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Cast<span style={{ color: 'red' }}>*</span>
                      </Label>
                      <Col md={10}>
                        <Input
                          className="form-control"
                          type="text"
                          value={this.state.cast}
                          id="example-text-input"
                          onChange={(val) => {
                            this.setState({ cast: val.target.value });
                          }}
                        />
                      </Col>
                    </FormGroup>

                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Date
                      </Label>
                      <Col md={10}>
                        <Input
                          className="form-control"
                          type="date"
                          value={this.state.year}
                          min={this.state.currentDate}
                          id="example-text-input"
                          onChange={(val) => {
                            this.setState({ year: val.target.value });
                          }}
                        />
                      </Col>
                    </FormGroup>

                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Upload Trailer<span style={{ color: 'red' }}>*</span>
                      </Label>
                      <Col md={6}>
                        <Input
                          className="form-control"
                          type="file"
                          id="example-text-input"
                          accept=".mp4,.flv,.MOV,.WMV,.AVI,.MKV,.AVCHD"
                          onChange={(val) => {
                            this.uploadVideo(val.target.files[0], "Trailer");
                          }}
                        // ref={(ref) => (this.fileInputTrl = ref)}
                        />
                      </Col>
                      <Col md={4}>
                        {this.state.uploading_trailer ? (
                          <>
                            <ProgressBar
                              completed={this.state.progressBarParsent}
                            />
                            {this.state.progressBarParsent == 100
                              ? "please wait untill transcoding is done"
                              : null}
                          </>
                        ) : (
                          <>
                            {this.state.trailer != "" ? (
                              <video
                                width="100%"
                                controls
                                className="rounded-lg"
                              >
                                <source
                                  src={this.state.trailer}
                                  type="video/mp4"
                                />
                              </video>
                            ) : null}
                          </>
                        )}
                        <p style={{ color: 'red' }}>[video should be in dimensions 1080 * 720 px]</p>

                      </Col>
                    </FormGroup>

                    {this.state.videoUpload ? (
                      <FormGroup row>
                        <Label
                          htmlFor="example-text-input"
                          className="col-md-2 col-form-label"
                        >
                          Upload Video<span style={{ color: 'red' }}>*</span>
                        </Label>
                        <Col md={6}>
                          <Input
                            className="form-control"
                            type="file"
                            id="example-text-input"
                            accept=".mp4,.flv,.MOV,.WMV,.AVI,.MKV,.AVCHD"
                            onChange={(val) => {
                              this.uploadVideo(val.target.files[0], "Video");
                            }}
                            ref={(ref) => (this.fileInputVideo = ref)}
                          />
                        </Col>
                        <Col md={4}>
                          {this.state.uploading_movie ? (
                            <>
                              <ProgressBar
                                completed={this.state.progressBarParsentVideo}
                              />
                              {this.state.progressBarParsentVideo == 100
                                ? "please wait untill transcoding is done"
                                : null}
                            </>
                          ) : (
                            <>
                              {this.state.url != "" ? (
                                <video
                                  width="100%"
                                  controls
                                  className="rounded-lg"
                                >
                                  <source
                                    src={this.state.url}
                                    type="video/mp4"
                                  />
                                </video>
                              ) : null}
                            </>
                          )}
                          <p style={{ color: 'red' }}>[video should be in dimensions 1080 * 720 px]</p>
                        </Col>
                      </FormGroup>
                    ) : null}
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Duration in Minute/Hours
                      </Label>
                      <Col md={10}>
                        <Input
                          className="form-control"
                          type="text"
                          value={this.state.duration}
                          id="example-text-input"
                          onChange={(val) => {
                            this.setState({ duration: val.target.value });
                          }}
                        />
                      </Col>
                    </FormGroup>

                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Thumbnail<span style={{ color: 'red' }}>*</span>
                      </Label>
                      <Col md={10}>
                        <Dropzone
                          accept=".jpeg,.jpg,.png,.svg,.webp,.jfif,.apng"
                          onDrop={(acceptedFiles) =>
                            this.handleAcceptedFiles(acceptedFiles)
                          }
                        >
                          {({ getRootProps, getInputProps }) => (
                            <div className="dropzone">
                              <div
                                className="dz-message needsclick mt-2"
                                {...getRootProps()}
                              >
                                <input {...getInputProps()} />
                                <div className="mb-3">
                                  <i className="display-4 text-muted ri-upload-cloud-2-line"></i>
                                </div>
                                <h4>Drop files here or click to upload.</h4>
                              </div>
                            </div>
                          )}
                        </Dropzone>
                        <p style={{ color: 'red' }}>[image should be in dimensions 500 * 500 px]</p>
                        <div
                          className="dropzone-previews mt-3"
                          id="file-previews"
                        >
                          {this.state.uploaded_images.map((f, i) => {
                            return (
                              <Card
                                className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                key={i + "-file"}
                              >
                                <div className="p-2">
                                  <Row className="align-items-center">
                                    <Col className="col-auto">
                                      <img
                                        style={{ height: 120, width: 100 }}
                                        data-dz-thumbnail=""
                                        height="100"
                                        className="avatar-sm rounded bg-light"
                                        // alt={f.name}
                                        src={f.preview}
                                      />
                                    </Col>
                                    <Col>
                                      <Button
                                        className="btn-icon"
                                        color="danger"
                                        style={{ marginRight: 25 }}
                                        onClick={(e) => this.remove_image(f, i)}
                                      >
                                        {" "}
                                        <span className="btn-icon-label">
                                          <i className="mdi mdi-alpha-x-box-outline mr-2"></i>
                                        </span>{" "}
                                        Remove
                                      </Button>
                                    </Col>
                                  </Row>
                                </div>
                              </Card>
                            );
                          })}
                        </div>
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Priority
                      </Label>
                      <Col md={10}>
                        <Input
                          className="form-control"
                          type="number"
                          value={this.state.priority}
                          id="example-text-input"
                          min={1}
                          onChange={(val) => {
                            this.setState({ priority: val.target.value });
                          }}
                        />
                      </Col>
                    </FormGroup>

                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Price Type
                      </Label>
                      <Col md={4}>
                        <select
                          className="form-control"
                          onChange={(val) => {
                            this.setState({ priceType: val.target.value });
                          }}
                          value={this.state.priceType}
                        >
                          <option value="">Select Price Type</option>
                          <option value="Premium">Premium</option>
                          <option value="Free">Free</option>
                        </select>
                      </Col>
                      {this.state.priceType == "Premium" ? (
                        <>
                          <Label
                            htmlFor="example-text-input"
                            className="col-md-2 col-form-label"
                          >
                            Plan Type
                          </Label>
                          <Col md={4}>
                            <Select
                              isMulti={true}
                              options={this.state.plansGroup}
                              values={this.state.selectedplansMulti}
                              onChange={
                                // (values) => this.setValues(values)
                                this.handleMultiPlan
                              }
                              value={this.state.selectedplansMulti}
                            />
                          </Col>
                        </>
                      ) : null}
                    </FormGroup>
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Suitable for children
                      </Label>
                      <Col md={10}>
                        <Switch
                          uncheckedIcon={this.Offsymbol("No")}
                          checkedIcon={this.OnSymbol("Yes")}
                          onColor="#626ed4"
                          onChange={() => {
                            // this.status(element, index);
                            this.setState({
                              childrenSuitable: !this.state.childrenSuitable,
                            });
                          }}
                          checked={this.state.childrenSuitable}
                          className="mr-1 mt-1"
                        />
                      </Col>
                    </FormGroup>
                    <p style={{ marginTop: 5, textAlign: "right" }}>
                      [Note: Asterisk (<span style={{ color: 'red' }}>*</span>) denotes the mandatory field.]
                    </p>

                    <FormGroup className="mb-0">
                      <div className="button-items pt-4">
                        <Button
                          color="primary"
                          type="button"
                          className="waves-effect waves-light mr-1"
                          onClick={() => {
                            this.submit();
                          }}
                          disabled={this.state.submit}
                        >
                          {this.state.edit_enable ? "Update" : "Submit"}{" "}
                          <i className="ri-arrow-right-line align-middle ml-1"></i>
                        </Button>
                      </div>
                    </FormGroup>
                  </CardBody>
                </Card>
              </Col>
              <Col xl={4}>
                {/* <div>
                    <EcomPreview />
                  </div> */}
              </Col>
            </Row>
            <Row>
              <Col xl={12}>
                <div className="card p-3">
                  <FormGroup row>
                    <Label
                      htmlFor="example-text-input"
                      className="col-md-2 col-form-label"
                    >
                      Type
                    </Label>
                    <Col md={10}>
                      {this.state.showSelect ? (
                        <Select
                          isMulti={false}
                          options={this.state.optionGroupFilter}
                          values={this.state.selectedMultiFilter}
                          onChange={
                            // (values) => this.setValues(values)
                            this.handleMultiFilter
                          }
                          value={this.state.selectedMultiFilter}
                        />
                      ) : null}
                    </Col>
                  </FormGroup>
                  <div className="card p-3"></div>
                  <div class="d-flex justify-content-between w-100">
                    <h5>VIDEOS</h5>
                    {this.state.userDetails.sub_admin == true ? null : (
                      <Link
                        className="btn btn-danger mr-2"
                        to={"/videos-undo/"}
                      >
                        Deleted videos
                      </Link>
                    )}
                  </div>
                  <MDBDataTable responsive bordered data={data} />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default FormElements;
