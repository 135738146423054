import React, { Component } from "react";
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  FormGroup,
  Label,
  Input,
  CustomInput,
  Button,
  UncontrolledAlert,
  InputGroup,
} from "reactstrap";
import { Link } from "react-router-dom";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { MDBDataTable } from "mdbreact";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { reactLocalStorage } from "reactjs-localstorage";
import moment from "moment";
import HttpClient from "./../../utils/HttpClient";
import Dropzone from "react-dropzone";
import Select from "react-select";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { decode as base64_decode, encode as base64_encode } from 'base-64';
import ProgressBar from "@ramonak/react-progress-bar";

class FormElements extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: "Speaker", link: "#" },
        { title: "Add & Manage Speaker", link: "#" },
      ],
      customchk: true,
      toggleSwitch: true,
      // page states
      name: "",
      email: "",
      mobile: "",
      password: "",
      data: [],
      alert: false,
      message: "",
      type: "",
      result: [],
      edit_enable: false,
      edit_item_id: "",
      image_select: false,
      userId: "",
      userDetails: {},
      default_date: new Date(),
      details: "",

      start_time: "",
      end_date: "",

      selectedFiles: [],
      images: [],
      uploaded_images: [],

      session_id: this.props.match.params.id,
      sessionData: null,

      start_time: new Date(),

      speaker_type: "",
      optionGroup: [],
      selectedMulti: [],
      user_id: "",

      value: '',
      copied: false,
      uploading_trailer: false,
      progressBarParsent: 0,
      trailer: "",
      trailerPlaylists: "",
      filetype: true,
    };
    this.handleDefault = this.handleDefault.bind(this);
    this.handleDefaultEnd = this.handleDefaultEnd.bind(this);
    this.handleStartTIme = this.handleStartTIme.bind(this);
    this.handleMulti = this.handleMulti.bind(this);
    this.selectedSpeaker = this.selectedSpeaker.bind(this);
  }

  componentDidMount = async () => {
    this.checkUser();
    this.fetchEvent();
    this.fetchUser();
  };

  handleDefault(date) {
    this.setState({ default_date: date });
  }

  handleDefaultEnd(date) {
    this.setState({ default_end_date: date });
  }

  handleStartTIme(time) {
    console.log("time", time);
  }

  handleMulti = (selectedMulti) => {
    // console.log("selectedMulti", selectedMulti);

    if (this.state.uploaded_images.length == 0) {
      this.state.uploaded_images.push({ preview: selectedMulti.image });
    } else {
      this.state.uploaded_images[0].preview = selectedMulti.image;
    }
    this.setState({
      name: selectedMulti.label,
      user_id: Number(selectedMulti.value),
    });
    this.setState({ selectedMulti });
  };

  selectedSpeaker = (selectedSpeaker) => {
    // console.log("selectedMulti", selectedMulti);

    if (this.state.uploaded_images.length == 0) {
      this.state.uploaded_images.push({ preview: selectedSpeaker.image });
    } else {
      this.state.uploaded_images[0].preview = selectedSpeaker.image;
    }
    this.setState({
      name: selectedSpeaker.label,
      user_id: Number(selectedSpeaker.value),
    });
    this.setState({ selectedSpeaker });
  };

  fetchEvent = async () => {
    let result = await HttpClient.requestData(
      "admin/event/session/single-session",
      "POST",
      { id: this.state.session_id }
    );

    if (result && result.status) {
      let data = result.data[0];
      this.state.breadcrumbItems[0].title = "Speaker - " + data.name;
      this.setState({ sessionData: data });
    }
  };

  fetchUser = async () => {
    let result = await HttpClient.requestData("admin/user", "GET");
    if (result && result.status > 0) {
      let optionAppowner = {};
      optionAppowner.label = "Users";
      optionAppowner.options = [];
      result.data.map((item, index) => {
        // console.log('item',item)
        optionAppowner.options.push({
          label: item.firstname + " " + item.lastname,
          value: item.id,
          image: item.image,
        });
      });

      let newOptions = [];
      newOptions.push(optionAppowner);

      this.setState({ optionGroup: newOptions });
    }
  };

  fetchData = async () => {
    let result = await HttpClient.requestData(
      "admin/event/speaker/all-speaker",
      "POST",
      { session_id: Number(this.state.session_id) }
    );
    if (result && result.status) {
      let data = [];
      let i = 1;
      this.setState({ result: result.data });
      this.state.result.forEach((element, index) => {
        let rows = {
          sl: i,
          image: (
            <div>
              <img
                src={element.images[0].preview}
                alt="images"
                className="rounded avatar-md card-img"
              />
            </div>
          ),
          name: element.name,
          details: element.details,
          created_by: element.created_by ? element.created_by.name + " on " + moment(element.created_on).utcOffset(element.created_on).format('DD/MM/YYYY, HH:mm') : "" + " on " + moment(element.created_on).utcOffset(element.created_on).format('DD/MM/YYYY, HH:mm'),
          updated_by: element.updated_by ? element.updated_by.name + " on " + moment(element.updated_on).utcOffset(element.updated_on).format('DD/MM/YYYY, HH:mm') : "" + "",
          type: (
            <div>
              <p style={{ textTransform: "capitalize" }}>
                {element.speaker_type}
              </p>
              {element.speaker_type == "moderator" ? null : (

                //  <button>Copy to clipboard with button</button>

                <CopyToClipboard text={"https://jd3tv.com/event-speaker/" + base64_encode(element.event_id) + "_" + base64_encode(element.session_id) + "_" + base64_encode(element.id)}
                  onCopy={() => this.setState({ copied: true })}>
                  <button
                    title="Copy"
                    className={
                      element.status
                        ? "btn btn-success mr-2"
                        : "btn btn-danger mr-2"
                    }
                    onClick={() => {
                      this.setState({ copied: true })
                      alert('Link Copied.')
                    }}
                  >
                    {"Copy invite Link"}
                  </button>
                </CopyToClipboard>
              )}
            </div>
          ),
          session: (
            <Link
              className="btn btn-danger mr-2"
              to={"/events-session/" + element.id}
            >
              Speakers
            </Link>
          ),
          status: (
            <button
              title="Delete"
              className={
                element.status ? "btn btn-success mr-2" : "btn btn-danger mr-2"
              }
              onClick={() => {
                this.status(element, index);
              }}
            >
              {element.status ? "Active" : "Not Active"}
            </button>
          ),
          action: (
            <>
              {this.state.userDetails.sub_admin == true ? null : (
                <button
                  title="Delete"
                  className="btn btn-danger mr-2"
                  onClick={() => {
                    if (window.confirm('Are you sure you wish to delete this item?'))
                      this.delete(element, index)
                  }}
                >
                  <i className="fa fa-trash" />
                </button>
              )}
              <button
                title="Edit"
                className="btn btn-primary"
                onClick={() => {
                  this.edit(element, index);
                  window.scrollTo(0, 0);
                }}
              >
                <i className="fa fa-edit" />
              </button>
            </>
          ),
        };
        i++;
        data.push(rows);
      });
      this.setState({
        data: data,
      });
    } else {
      this.setState({
        data: [],
      });
    }
  };

  delete = async (item, index) => {
    let sendData = {
      id: item._id,
    };
    let result = await HttpClient.requestData(
      "admin/event/speaker/delete",
      "POST",
      sendData
    );
    if (result && result.status) {
      let index = this.state.result.indexOf(item);
      if (index > -1) {
        this.state.result.splice(index, 1);
        this.setState({
          alert: true,
          message: "Deleted Successfully",
          type: "success",
        });
        setTimeout(() => {
          this.setState({
            alert: false,
            message: "",
            type: "",
          });
        }, 3000);
        this.fetchData();
      }
    }
  };

  edit = async (item, index) => {
    this.setState({
      edit_enable: true,
      name: item.name,
      edit_item_id: item._id,
      details: item.details,
      uploaded_images: item.images ? item.images : [],
      speaker_type: item.speaker_type,
      selectedMulti: item.selectedMulti,
      selectedSpeaker: item.selectedSpeaker,
      videoUrl: item.videoUrl,
      url: item.url,
      trailer: item.trailer,
      trailerPlaylists: item.trailerUrl,

    });
  };

  status = async (item, index) => {
    let sendData = {
      id: item._id,
      status: !item.status,
    };
    let result = await HttpClient.requestData(
      "admin/event/speaker/status",
      "POST",
      sendData
    );
    if (result && result.status) {
      this.state.result.splice(index, 1);
      this.setState({
        alert: true,
        message: "Status Updated Successfully",
        type: "success",
      });
      setTimeout(() => {
        this.setState({
          alert: false,
          message: "",
          type: "",
        });
      }, 3000);
      this.fetchData();
    }
  };

  submit = async () => {
    if (this.state.uploaded_images.length == 0) {
      this.setState({
        alert: true,
        message: "Please Upload Images.",
        type: "warning",
      });
      return;
    }

    if (
      this.state.speaker_type == "moderator" &&
      this.state.selectedMulti == 0
    ) {
      this.setState({
        alert: true,
        message: "Please Select Moderator.",
        type: "warning",
      });
      return;
    }
    if (
      this.state.speaker_type == "speaker" &&
      this.state.selectedSpeaker == 0
    ) {
      this.setState({
        alert: true,
        message: "Please Select Speaker.",
        type: "warning",
      });
      return;
    }
    // return;
    if (
      this.state.name != "" &&
      this.state.details != "" &&
      this.state.speaker_type != ""
    ) {
      let data = null;
      let result = null;

      if (this.state.edit_enable == false) {
        data = {
          name: this.state.name,
          details: this.state.details,
          event_id: this.state.sessionData.event_id,
          session_id: this.state.sessionData.id,
          images: this.state.uploaded_images,
          speaker_type: this.state.speaker_type,
          selectedMulti: this.state.selectedMulti,
          selectedSpeaker: this.state.selectedSpeaker,
          trailerUrl: this.state.trailerPlaylists,
          videoUrl: this.state.videoUrl,
          url: this.state.url,
          trailer: this.state.trailer,
          created_by: this.state.userId,

        };
        if (this.state.speaker_type == "moderator") {
          data.user_id = Number(this.state.user_id);
        }
        if (this.state.speaker_type == "speaker") {
          data.user_id = Number(this.state.user_id);
        }
        result = await HttpClient.requestData(
          "admin/event/speaker",
          "POST",
          data
        );
      } else {
        data = {
          name: this.state.name,
          details: this.state.details,
          images: this.state.uploaded_images,
          edit_id: this.state.edit_item_id,
          speaker_type: this.state.speaker_type,
          event_id: Number(this.state.sessionData.event_id),
          session_id: this.state.sessionData.id,
          selectedMulti: this.state.selectedMulti,
          selectedSpeaker: this.state.selectedSpeaker,
          trailerUrl: this.state.trailerPlaylists,
          trailer: this.state.trailer,
          videoUrl: this.state.videoUrl,
          url: this.state.url,
          updated_by: this.state.userId,

        };
        if (this.state.speaker_type == "moderator") {
          data.user_id = Number(this.state.user_id);
        }
        if (this.state.speaker_type == "speaker") {
          data.user_id = Number(this.state.user_id);
        }
        result = await HttpClient.requestData(
          "admin/event/speaker/update",
          "POST",
          data
        );
      }
      if (result && result.status) {
        console.log("aaaaaaaaaa", result)
        this.setState({
          alert: true,
          message: this.state.edit_enable
            ? "Updated Successfully"
            : "Added Successfully",
          type: "success",
          name: "",
          img_url: "",
          image_select: false,
          password: "",
          edit_enable: false,
          edit_item_id: "",
          default_date: new Date(),
          default_end_date: new Date(),
          start_time: "",
          end_date: "",
          details: "",
          speaker_type: "",
          uploaded_images: [],
          uploading_trailer: false,
          progressBarParsent: 0,
          trailer: "",
          trailerPlaylists: "",
          url: "",
          uploadVideo: false,
          filetype: false,
        });
        // window.location.reload(false);
        setTimeout(() => {
          this.setState({
            filetype: true,
          })
        }, 500);
        this.fetchData();
      } else {
        this.setState({
          alert: true,
          message:
            typeof result.error == "string"
              ? result.error
              : result.error[0].msg,
          type: "danger",
        });
      }
    } else {
      this.setState({
        alert: true,
        message: "Please Fill Up All Details.",
        type: "warning",
      });
    }
    setTimeout(() => {
      this.setState({
        alert: false,
        message: "",
        type: "",
      });
    }, 3000);
  };
  uploadVideo = async (video, type) => {
    this.setState({ filetype: video })
    if (type == "Trailer") {
      this.setState({ uploading_trailer: true });
    } else {
      this.setState({ uploading_movie: true });
    }

    let dataSend = { buckate: false };
    let result = await HttpClient.uploadFileRequest(
      "video-upload/video",
      video,
      (data, data1, data2) => {
        console.log("data, data1, data2", data, data1, data2);

        if (type == "Trailer") {
          this.setState({ progressBarParsent: data });
        } else {
          this.setState({ progressBarParsentVideo: data });
        }
      }
    );
    if (result && result.status) {
      if (type == "Trailer") {
        this.setState({
          trailerPlaylists: result.playlists,
          trailer: result.data.url,
          uploading_trailer: false,
        });
      } else {
        this.setState({
          videoUrl: result.playlists,
          url: result.data.url,
          uploading_movie: false,
        });
      }
    }
    console.log("result", result);
  };
  imageUpload = async (file, index) => {
    // console.log("e", file);

    let dataSend = { buckate: false };

    let result = await HttpClient.newFileUpload(
      "image-upload/event",
      file,
      dataSend
    );
    console.log("result", result);
    if (result && result.status) {
      this.setState({ uploaded_images: [], images: [] });
      this.state.uploaded_images.push({ preview: result.url });
      this.setState({});
    }
  };

  handleAcceptedFiles = (files) => {
    let data = [];
    let file = {
      preview: URL.createObjectURL(files[0]),
      formattedSize: this.formatBytes(files[0].size),
      // image:files,
    };
    let file2 = {
      image: files,
    };

    let file3 = files;
    console.log("data", file);
    this.state.selectedFiles.push(file);
    this.state.images.push(files[0]);
    this.imageUpload(files[0]);
    this.setState({});
  };

  async remove_image(item, index) {
    this.state.selectedFiles.pop(index);
    this.state.images.pop(index);
    this.state.uploaded_images.pop(index);
    this.setState({});
  }

  /**
   * Formats the size
   */
  formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  checkUser = async () => {
    let user = await reactLocalStorage.getObject("userData");
    if (user != null && Object.keys(user).length > 0) {
      this.setState({
        userId: user.id,
        userDetails: user,
      });
    } else {
    }
    this.fetchData();
  };

  render() {
    const data = {
      columns: [
        {
          label: "Sl.",
          field: "sl",
          sort: "asc",
          width: 150,
        },
        {
          label: "Name",
          field: "image",
          sort: "asc",
          width: 270,
        },
        {
          label: "Name",
          field: "name",
          sort: "asc",
          width: 270,
        },
        {
          label: "Type",
          field: "type",
          sort: "asc",
          width: 270,
        },
        {
          label: "Details",
          field: "details",
          sort: "asc",
          width: 270,
        },
        {
          label: "Created By",
          field: "created_by",
          sort: "asc",
          width: 270,
        },
        {
          label: "Updated By",
          field: "updated_by",
          sort: "asc",
          width: 270,
        },
        {
          label: "Status",
          field: "status",
          sort: "asc",
          width: 100,
        },
        {
          label: "Action",
          field: "action",
          sort: "asc",
          width: 100,
        },
      ],
      rows: this.state.data,
    };
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title={this.state.breadcrumbItems[0].title}
              breadcrumbItems={this.state.breadcrumbItems}
            />
            {/* <Link to={"/events-session/" +index}>
              <button type="button" class="btn btn-danger">Back</button>
              </Link>
              <br/><br/> */}
            <Row>
              <Col xs={12}>
                <Card>
                  <CardBody>
                    {this.state.alert ? (
                      <UncontrolledAlert
                        color={this.state.type}
                        className="alert-dismissible fade show"
                        role="alert"
                      >
                        {this.state.type == "warning" ? (
                          <i className="mdi mdi-alert-outline mr-2"></i>
                        ) : this.state.type == "success" ? (
                          <i className="mdi mdi-check-all mr-2"></i>
                        ) : this.state.type == "danger" ? (
                          <i className="mdi mdi-block-helper mr-2"></i>
                        ) : null}
                        {this.state.message}
                      </UncontrolledAlert>
                    ) : null}
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Select Type<span style={{ color: 'red' }}>*</span>
                      </Label>
                      <Col md={10}>
                        <select
                          className="form-control"
                          onChange={(val) => {
                            this.setState({ speaker_type: val.target.value });
                          }}
                          value={this.state.speaker_type}
                        >
                          <option value={""}>Select Type</option>
                          <option value={"moderator"}>Moderator</option>
                          <option value={"speaker"}>Speaker</option>
                        </select>
                      </Col>
                    </FormGroup>
                    {this.state.speaker_type == "moderator" ? (
                      <FormGroup row>
                        <Label
                          htmlFor="example-text-input"
                          className="col-md-2 col-form-label"
                        >
                          Select Moderator
                        </Label>
                        <Col md={10}>
                          <Select
                            isMulti={false}
                            options={this.state.optionGroup}
                            values={this.state.selectedMulti}
                            onChange={this.handleMulti}
                            value={this.state.selectedMulti}
                          />
                        </Col>
                      </FormGroup>
                    ) : null}
                    {this.state.speaker_type == "speaker" ? (
                      <FormGroup row>
                        <Label
                          htmlFor="example-text-input"
                          className="col-md-2 col-form-label"
                        >
                          Select speaker
                        </Label>
                        <Col md={10}>
                          <Select
                            isMulti={false}
                            options={this.state.optionGroup}
                            values={this.state.selectedSpeaker}
                            onChange={this.selectedSpeaker}
                            value={this.state.selectedSpeaker}
                          />
                        </Col>
                      </FormGroup>
                    ) : null}
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Name<span style={{ color: 'red' }}>*</span>
                      </Label>
                      <Col md={10}>
                        <Input
                          className="form-control"
                          type="text"
                          value={this.state.name}
                          id="example-text-input"
                          onChange={(val) => {
                            this.setState({ name: val.target.value });
                          }}
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Details<span style={{ color: 'red' }}>*</span>
                      </Label>
                      <Col md={10}>
                        <Input
                          className="form-control"
                          type="textarea"
                          value={this.state.details}
                          id="example-text-input"
                          onChange={(val) => {
                            this.setState({ details: val.target.value });
                          }}
                        />
                      </Col>
                    </FormGroup>

                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Upload Video
                      </Label>
                      <Col md={6}>
                      {!this.state.filetype ? null :
                        <Input
                          className="form-control"
                          type="file"
                          id="example-text-input"
                          accept=".mp4,.flv,.MOV,.WMV,.AVI,.MKV,.AVCHD"
                          onChange={(val) => {
                            this.uploadVideo(val.target.files[0], "Trailer");
                          }}
                        // ref={(ref) => (this.fileInputTrl = ref)}
                        />}
                      </Col>
                      <Col md={4}>
                        {this.state.uploading_trailer ? (
                          <>
                            <ProgressBar
                              completed={this.state.progressBarParsent}
                            />
                            {this.state.progressBarParsent == 100
                              ? "please wait untill transcoding is done"
                              : null}
                          </>
                        ) : (
                          <>
                            {this.state.trailer != "" ? (
                              <video
                                width="100%"
                                controls
                                className="rounded-lg"
                              >
                                <source
                                  src={this.state.trailer}
                                  type="video/mp4"
                                />
                              </video>
                            ) : null}
                          </>
                        )}
                        <p style={{ color: 'red' }}>[video should be in dimensions 1080 * 720 px]</p>

                      </Col>
                    </FormGroup>

                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Images<span style={{ color: 'red' }}>*</span>
                      </Label>
                      <Col md={10}>
                        <Dropzone
                          accept=".jpeg,.jpg,.png,.svg,.webp,.jfif,.apng"
                          onDrop={(acceptedFiles) =>
                            this.handleAcceptedFiles(acceptedFiles)
                          }
                        >
                          {({ getRootProps, getInputProps }) => (
                            <div className="dropzone">
                              <div
                                className="dz-message needsclick mt-2"
                                {...getRootProps()}
                              >
                                <input {...getInputProps()} />
                                <div className="mb-3">
                                  <i className="display-4 text-muted ri-upload-cloud-2-line"></i>
                                </div>
                                <h4>Drop files here or click to upload.</h4>
                              </div>
                            </div>
                          )}
                        </Dropzone>
                        <div
                          className="dropzone-previews mt-3"
                          id="file-previews"
                        >
                          <span style={{ color: 'red' }}>[image should be in dimensions 500 * 333 px]</span>
                          {this.state.uploaded_images.map((f, i) => {
                            return (
                              <Card
                                className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                key={i + "-file"}
                              >
                                <div className="p-2">
                                  <Row className="align-items-center">
                                    <Col className="col-auto">
                                      <img
                                        style={{ height: 120, width: 100 }}
                                        data-dz-thumbnail=""
                                        height="100"
                                        className="avatar-sm rounded bg-light"
                                        // alt={f.name}
                                        src={f.preview}
                                      />
                                    </Col>
                                    <Col>
                                      <Button
                                        className="btn-icon"
                                        color="danger"
                                        style={{ marginRight: 25 }}
                                        onClick={(e) => this.remove_image(f, i)}
                                      >
                                        {" "}
                                        <span className="btn-icon-label">
                                          <i className="mdi mdi-alpha-x-box-outline mr-2"></i>
                                        </span>{" "}
                                        Remove
                                      </Button>
                                    </Col>
                                  </Row>
                                </div>
                              </Card>
                            );
                          })}
                        </div>
                      </Col>
                    </FormGroup>
                    <p style={{ marginTop: 5, textAlign: "right" }}>
                      [Note: Asterisk (<span style={{ color: 'red' }}>*</span>) denotes the mandatory field.]
                    </p>
                    <FormGroup className="mb-0">
                      <div className="button-items pt-4">
                        <Button
                          color="primary"
                          type="button"
                          className="waves-effect waves-light mr-1"
                          onClick={() => {
                            this.submit();
                          }}
                        >
                          {this.state.edit_enable ? "Update" : "Submit"}{" "}
                          <i className="ri-arrow-right-line align-middle ml-1"></i>
                        </Button>
                      </div>
                    </FormGroup>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col xl={12}>
                <div className="card p-3">
                  <div class="d-flex justify-content-between w-100">
                    <h5>SPEAKERS</h5>
                    {this.state.userDetails.sub_admin == true ? null : (
                      <Link
                        className="btn btn-danger mr-2"
                        to={"/speakerUndo/" + this.state.session_id}
                      >
                        Deleted Speaker
                      </Link>
                    )}
                  </div>
                  <MDBDataTable responsive bordered data={data} />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default FormElements;
