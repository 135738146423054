import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//Import Components
import MiniWidgets from "./MiniWidgets";
import RevenueAnalytics from "./RevenueAnalytics";
import SalesAnalytics from "./SalesAnalytics";
import EarningReports from "./EarningReports";
import Sources from "./Sources";
import RecentlyActivity from "./RecentlyActivity";
import RevenueByLocations from "./RevenueByLocations";
import ChatBox from "./ChatBox";
import LatestTransactions from "./LatestTransactions";
import HttpClient from "./../../utils/HttpClient";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: "Twiller Portis", link: "#" },
        { title: "Dashboard", link: "#" },
      ],
      reports: [
        {
          icon: "ri-stack-line",
          title: "Users",
          value: "0",
          rate: "2.4%",
          desc: "From previous period",
          url: "/userlist",
        },
        {
          icon: "ri-store-2-line",
          title: "Active Subscriber",
          value: "0",
          rate: "2.4%",
          desc: "From previous period",
          url: "/dashboard",
        },
        {
          icon: "ri-briefcase-4-line",
          title: "Videos",
          value: "0",
          rate: "2.4%",
          desc: "From previous period",
          url: "/dashboard",
        },
        {
          icon: "ri-briefcase-4-line",
          title: "Subscription",
          value: "0",
          rate: "2.4%",
          desc: "From previous period",
          url: "/dashboard",
        },
      ],
      recentVideos: [],
      recentTransactions: [],
      topVideos: [],
      topCount: 0,
      subs: [],
      totalAmount: 0,
    };
  }

  componentDidMount = async () => {
    this.fetchDash();
  };

  fetchDash = async () => {
    let result = await HttpClient.requestData("admin/dashboard", "GET");
    if (result && result.status) {
      let data = result.data;
      this.state.reports[0].value = data.user;
      this.state.reports[1].value = data.subscriber;
      this.state.reports[2].value = data.videos;
      this.state.reports[3].value = data.subscription;
      this.setState({});
    }
    let recentVideos = await HttpClient.requestData(
      "admin/dashboard/recent-video",
      "GET"
    );
    if (recentVideos && recentVideos.status) {
      this.setState({ recentVideos: recentVideos.data });
    }

    let recentTransactions = await HttpClient.requestData(
      "admin/dashboard/recent-transactions",
      "GET"
    );
    if (recentTransactions && recentTransactions.status) {
      this.setState({ recentTransactions: recentTransactions.data });
    }

    let topVideos = await HttpClient.requestData(
      "admin/dashboard/top-videos",
      "GET"
    );
    if (topVideos && topVideos.status) {
      let test = topVideos.data.slice(0, 3);
      let totalP = 0;
      test.map((item, index) => {
        totalP = Number(item.count) + Number(totalP);
      });
      this.setState({
        topVideos: topVideos.data.slice(0, 3),
        topCount: totalP,
      });
    }

    let subs = await HttpClient.requestData(
      "admin/dashboard/payment-per",
      "GET"
    );
    if (subs && subs.status) {
      let test = subs.data;
      let totalAmount = 0;
      test.map((item, index) => {
        totalAmount =
          Number(Number(item.count) * Number(item.price)) + Number(totalAmount);
      });
      this.setState({
        subs: subs.data,
        totalAmount: totalAmount,
      });
      console.log("totalAmount", totalAmount);
    }

    // console.log("result", result);
  };

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title="Dashboard"
              breadcrumbItems={this.state.breadcrumbItems}
            />
            <Row>
              <Col xl={12}>
                <Row>
                  <MiniWidgets reports={this.state.reports} />
                </Row>

                {/* <RevenueAnalytics/> */}
              </Col>

              {/* <Col xl={12}>

                                <SalesAnalytics name={"Sales"}/>

                                <EarningReports/>

                            </Col> */}
            </Row>

            <Row>
              {this.state.topVideos.length > 0 ? (
                <SalesAnalytics
                  name={"Video Most Viewed"}
                  data={this.state.topVideos}
                  count={this.state.topCount}
                />
              ) : null}
              {/* recent activity */}
              <RecentlyActivity data={this.state.recentVideos} />

              {/* revenue by locations */}
              {/* <RevenueByLocations/> */}
            </Row>

            <Row>
              {/* chat box */}
              {/* sources */}
              <Sources
                totalAmount={this.state.totalAmount}
                subs={this.state.subs}
              />

              {/* latest transactions */}
              {this.state.recentTransactions.length > 0 ? (
                <LatestTransactions data={this.state.recentTransactions} />
              ) : null}
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default Dashboard;
