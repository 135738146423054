import Axios from "axios";

async function requestData(url, method, params = null) {
  // let bash_url = 'http://api.vintagebazaar.in/api/';
  // let bash_url = "http://127.0.0.1:3030/api/";
  // let bash_url = "http://54.152.5.43:3040/api/";
  let bash_url = "https://admin.twilerportis.app/api/";
  let apiUrl = bash_url + url;
  console.log("Url " + method, apiUrl);
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  // myHeaders.append("Access-Control-Allow-Origin", "http://127.0.0.1:3030");
  myHeaders.append("Access-Control-Allow-Origin", "https://admin.twilerportis.app");
  const options = {
    method: method,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };
  if (method === "DELETE") {
    // options['body'] = none;
  } else if (method !== "GET") {
    options["body"] = JSON.stringify(params);
  }
  return await fetch(apiUrl, options)
    .then((res) => res.json())
    .then(
      (result) => {
        console.log("result", result);
        return result;
      },
      (error) => {
        // this.setState({ error });
      }
    );
}

async function fileUplode(
  url,
  method,
  file,
  option,
  object_get = {},
  tokenCustom = null
) {
  let bash_url = "https://admin.twilerportis.app/api/";
  let apiUrl = bash_url + url;
  const data = new FormData();
  if (option == "Single") {
    data.append("image", file);
  } else {
    let i = 0;
    Object.keys(file).forEach(function (key) {
      data.append("image", file[i]);
      i++;
    });
  }

  Object.keys(object_get).forEach(function (key) {
    let ddd = object_get[key];
    if (key == "dynamic_fields") {
      ddd = JSON.stringify(object_get[key]);
    }
    console.log(key, ddd);
    data.append(key, ddd);
  });

  // for (var pair of data.entries()) {
  //     console.log(pair[0]+ ', ' + pair[1]);
  // }

  // console.log('data',data);
  let headers = {
    // 'Accept': 'application/json',
    // 'Content-Type': 'multipart/form-data',
    "Access-Control-Allow-Origin": "https://admin.twilerportis.app",
    // 'Authorization': 'Bearer ' + login_status,
  };
  console.log("data", data);

  return await fetch(apiUrl, {
    method: method,
    headers: headers,
    body: data,
  })
    .then((response) => response.json())
    .then(
      (result) => {
        console.log(result);
        return result;
      },
      (error) => {
        return error;
      }
    );
}

async function newFileUpload(url, file, object_get) {
  // let bash_url = "http://127.0.0.1:3030/api/";
  let bash_url = "https://admin.twilerportis.app/api/";

  let apiUrl = bash_url + url;

  const data = new FormData();
  if (typeof file == "string") {
    data.append("image", {
      uri: String(file),
      type: "image/jpeg",
      name: "filename.jpg",
    });
  } else {
    data.append("image", file);
  }
  for (var pair of data.entries()) {
    console.log(pair[0] + ", " + pair[1]);
  }
  console.log("file", file);
  Object.keys(object_get).forEach(function (key) {
    let ddd = object_get[key];
    console.log(key, ddd);
    data.append(key, ddd);
  });

  var requestOptions = {
    method: "POST",
    body: data,
    redirect: "follow",
  };

  return await fetch(apiUrl, requestOptions)
    .then((response) => response.json())
    .then((result) => {
      return result;
    })
    .catch((error) => {
      console.log("error", error);
      return error;
    });
}

async function newFileCropUpload(url, file, object_get) {
  let bash_url = "https://admin.twilerportis.app/api/";
  // let bash_url = "http://127.0.0.1:3030/api/";

  let apiUrl = bash_url + url;

  const data = new FormData();
  data.append("image", {
    uri: file,
    type: "image/jpeg",
    name: "filename.jpg",
  });

  var requestOptions = {
    method: "POST",
    body: data,
    redirect: "follow",
  };

  return await fetch(apiUrl, requestOptions)
    .then((response) => response.json())
    .then((result) => {
      return result;
    })
    .catch((error) => {
      console.log("error", error);
      return error;
    });
}

async function videoUpload(url, file, object_get) {
  let bash_url = "https://admin.twilerportis.app/api/";

  let apiUrl = bash_url + url;
  console.log("file", file);
  const data = new FormData();
  // // data.append("video", fileInput.files[0], "Mumbai ka secret haath laga _ RCB vs RR _ Pre match chat.mp4");
  data.append("video", file);

  var requestOptions = {
    method: "POST",
    body: data,
    redirect: "follow",
  };

  return await fetch(apiUrl, requestOptions)
    .then((response) => response.json())
    .then((result) => {
      return result;
    })
    .catch((error) => {
      console.log("error", error);
      return error;
    });
}

async function uploadFileRequest(url, file, callback) {
  const BASE_URL = "https://admin.twilerportis.app/api/";
  var cancelToken = Axios.CancelToken;
  var source = cancelToken.source();
  let apiUrl = BASE_URL + url;

  const data = new FormData();
  data.append("video", file);

  let config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
    },
    onUploadProgress: (progressEvent) => {
      var percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );
      console.log(percentCompleted);
      callback(percentCompleted, null, null);
    },
    cancelToken: source.token,
  };

  return await Axios.post(apiUrl, data, config)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      callback(null, null, error);
    });
}

const getOriginalname = async (data, extn) => {
  let arr = data.split("/");
  let lent = Number(arr.length - 1);
  // console.log("arr[lent]", extn)
  if (!arr[lent].match(/.(jpg|jpeg|png|gif|pdf|csv|plainText|zip)$/i)) {
    return arr[lent] + "." + extn.substring(extn.lastIndexOf("/") + 1);
  } else {
    return arr[lent];
  }
};

export default {
  requestData,
  fileUplode,
  newFileUpload,
  newFileCropUpload,
  videoUpload,
  uploadFileRequest,
  // get,
  // post,
  // put,
  // // delete,
  // upload,
};
