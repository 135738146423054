import React, { Component } from "react";
import {
    Card,
    CardBody,
    Col,
    Row,
    Container,
    FormGroup,
    Label,
    Input,
    CustomInput,
    Button,
    UncontrolledAlert,
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { MDBDataTable } from "mdbreact";
import { reactLocalStorage } from "reactjs-localstorage";
import moment from "moment";
import Dropzone from "react-dropzone";
import BaseInfo from "../ChooseDesign/baseInfo";
import HttpClient from "./../../utils/HttpClient";
import Select from "react-select";
import ProgressBar from "@ramonak/react-progress-bar";
import Switch from "react-switch";

// import EcomPreview from "./E-ComPreview/index";
class FormElements extends Component {
    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems: [
                { title: "Video", link: "#" },
                { title: "Add & Manage Video", link: "#" },
            ],
            customchk: true,
            toggleSwitch: true,
            // page states
            name: "",
            data: [],
            alert: false,
            message: "",
            type: "",
            result: [],
            edit_enable: false,
            edit_item_id: "",
            image_select: false,
            img_url: "",
            userId: "",
            userDetails: {},
            //   this page states
            category: [],
            categoryAll: [],

            selectedFiles: [],
            images: [],
            uploaded_images: [],

            optionGroup: [],
            selectedMulti: [],

            optionGroupALL: [],
            selectedMultiAll: [],

            optionGroupCat: [],
            selectedMultiCat: [],

            director: "",
            desc: "",
            season: "",
            trailer: "",
            showSelect: true,
            submit: false,
            uploading_trailer: false,
            trailerPlaylists: "",
            cat_id: "",
            cast: "",
            progressBarParsent: 0,
            progressBarParsentVideo: 0,
            uploading_movie: false,

            videoUrl: "",
            url: "",

            videoUpload: false,
            types: [],

            optionGroupFilter: [],
            selectedMultiFilter: [],
            selectedType: "",
            normalId: "",
            priority: "",

            priceType: "",
            plansGroup: [],
            selectedplansMulti: [],
            childrenSuitable: true,
        };

    }


    componentDidMount = async () => {
        this.checkUser();
    };



    fetchData = async () => {
        let result = await HttpClient.requestData(
            "admin/movies/video-wise-video",
            "POST",
            { add_by: this.state.userId }
        );
        if (result && result.status > 0) {
            let data = [];
            let cat = "";
            let i = 1;
            this.setState({ result: result.data });
            this.state.result.forEach((element, index) => {
                if (element.movies.length > 0) {
                    let rows = {
                        sl: i,
                        no_of_view: element.num_of_view,
                        videoName: element.movies.length > 0 ? element.movies[0].name : null,
                        director: element.movies.length > 0 ? element.movies[0].director : null,
                        desc: element.movies.length > 0 ? element.movies[0].desc : null,
                        cast: element.movies.length > 0 ? element.movies[0].cast : null,
                        img:
                            element.movies[0].img.length > 0 ? (
                                <div>
                                    <img
                                        src={element.movies[0].img[0].preview}
                                        alt="images"
                                        className="rounded avatar-md card-img"
                                    />
                                </div>
                            ) : null,
                    };
                    this.setState({ rows: rows });
                }
                i++;
                data.push(this.state.rows);
            });
            this.setState({
                data: data,
            });
        } else {
            this.setState({
                data: [],
            });
        }
    };

    checkUser = async () => {
        let user = await reactLocalStorage.getObject("userData");
        if (user != null && Object.keys(user).length > 0) {
            this.setState({
                userId: user.id,
                userDetails: user,
            });
        } else {
        }
        this.fetchData();
    };



    render() {
        const data = {
            columns: [
                {
                    label: "Sl.",
                    field: "sl",
                    sort: "asc",
                    width: 150,
                },
                {
                    label: "Video Name",
                    field: "videoName",
                    sort: "asc",
                    width: 270,
                },
                {
                    label: "No Of Views",
                    field: "no_of_view",
                    sort: "asc",
                    width: 270,
                },
                {
                    label: "Image",
                    field: "img",
                    sort: "asc",
                    width: 270,
                },
                {
                    label: "Director",
                    field: "director",
                    sort: "asc",
                    width: 270,
                },
                {
                    label: "Description",
                    field: "desc",
                    sort: "asc",
                    width: 270,
                },
                {
                  label: "Cast",
                  field: "cast",
                  sort: "asc",
                  width: 270,
                },
            ],
            rows: this.state.data,
        };
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs
                            title={this.state.breadcrumbItems[0].title}
                            breadcrumbItems={this.state.breadcrumbItems}
                        />
                        <Row>
                            <Col xl={12}>
                                <div className="card p-3">
                                    <MDBDataTable responsive bordered data={data} />
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default FormElements;
