import React, { Component } from "react";
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  FormGroup,
  Label,
  Input,
  CustomInput,
  Button,
  UncontrolledAlert,
  InputGroup,
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { MDBDataTable } from "mdbreact";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { reactLocalStorage } from "reactjs-localstorage";
import moment from "moment";
import HttpClient from "./../../utils/HttpClient";
class FormElements extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: "Order", link: "#" },
        { title: "Manage Order", link: "#" },
      ],
      customchk: true,
      toggleSwitch: true,
      // page states
      name: "",
      email: "",
      mobile: "",
      password: "",
      data: [],
      alert: false,
      message: "",
      type: "",
      result: [],
      edit_enable: false,
      edit_item_id: "",
      image_select: false,
      img_url: "",
      userId: "",
      userDetails: {},
      default_date: new Date(),
      details: "",
      link: "",
      price: "",
      total_seats: "",
      vanue:"",
      latitude:"",
      longitude:'',
      image_select:false,
      img_url:"",
      order_status:[],
      order_status_type:"",
      optionsdata : [
        {key:'100',value:'Select'},
        {key:'101',value:'Accepted'},
        {key:'102',value:'Pending'},
        {key:'103',value:'Shipped'},
        {key:'104',value:'Delivered'},
       
      ],
    };
    
  }

  componentDidMount = async () => {
    this.checkUser();
  };

  

  fetchData = async () => {
    let data={};
    
    let result = await HttpClient.requestData(
      "admin/order/order-all",
      "POST",
      data,
     
    );
    
    
    console.log("OrderFetchAll",result);
    
    if (result && result.status > 0) {
      let data = [];
      let i = 1;
      
      this.setState({ result: result.data });

      this.state.result.forEach((element, index) => {
        
        let rows = {
          sl: i,
          invoice:"#" + element.id,
          userame:element.userData.firstname + " " + element.userData.lastname,
          date: moment(element.created_on).format("L"),
          payment_type: element.paymentType,
          total:"$" + " " + element.total/100,
          
          
          status: (

            <select
            className="form-control"
            onChange={(val)=>this.ChangeStatus(val,element._id,index)}
            value={ typeof element.order_status != "undefined" ? element.order_status  : "Pending"}
          >
          
          {this.state.optionsdata.map(function(data, key){  return (
          <option key={key} value={data.value} 
         
          >{data.value}</option> )
        })}
          </select>

          ),


          product: (
            <>

              <button
                title="products"
                className="btn btn-success"
                onClick={() => {
                  
               window.location.href = "/products" + element.id;
               
                }}
              >
                View Products
                </button>

            </>
          ),
          
        };
        i++;
        data.push(rows);
      });
      this.setState({
        data: data,
      });
    } else {
      this.setState({
        data: [],
      });
    }
  };
 

  ChangeStatus = async(event,_id,index) =>{
    console.log(event.target.value);
    console.log(_id);
   

    let data={
      order_status:event.target.value,
      id:_id,
    };
   
    let result = await HttpClient.requestData(
      "admin/order/change-status",
      "POST",
      data,
    );
   
    console.log("OrderStatus",result);

     if(result && result.status > 0)
     {
       console.log("ChangeSet");

     
      this.state.result[index].order_status=result.data.order_status;
      this.setState({});
      this.fetchData();

        
     }
     else{
       console.log("No data");
     }

    
  }
  
  

  checkUser = async () => {
    let user = await reactLocalStorage.getObject("userData");
    if (user != null && Object.keys(user).length > 0) {
      this.setState({
        userId: user.id,
        userDetails: user,
      });
    } else {
    }
    this.fetchData();
  };

  render() {
    const data = {
      columns: [
        {
          label: "Sl.",
          field: "sl",
          sort: "asc",
          width: 150,
        },
        {
          label: "Invoice No",
          field: "invoice",
          sort: "asc",
          width: 150,
        },
        {
          label: "Name",
          field: "userame",
          sort: "asc",
          width: 150,
        },
        {
          label: "Date",
          field: "date",
          sort: "asc",
          width: 270,
        },
        {
          label: "Payment Type",
          field: "payment_type",
          sort: "asc",
          width: 270,
        },
       
        {
          label: "Total Amount",
          field: "total",
          sort: "asc",
          width: 270,
        },
       

        {
          label: "Change Status",
          field: "status",
          sort: "asc",
          width: 270,
        },
        {
          label: "Product",
          field: "product",
          sort: "asc",
          width: 270,
        },
        
        
        // {
        //   label: "Status",
        //   field: "status",
        //   sort: "asc",
        //   width: 100,
        // },
        // {
        //   label: "Action",
        //   field: "action",
        //   sort: "asc",
        //   width: 100,
        // },
      ],
      rows: this.state.data,
    };
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title={this.state.breadcrumbItems[0].title}
              breadcrumbItems={this.state.breadcrumbItems}
            />

            <Row>
              <Col xs={12}>
                
            
              </Col>
            </Row>
            <Row>
              <Col xl={12}>
                <div className="card p-3">
                  <MDBDataTable responsive bordered data={data} />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default FormElements;
