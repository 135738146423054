import React, { Component } from "react";
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  FormGroup,
  Label,
  Input,
  CustomInput,
  Button,
  UncontrolledAlert,
} from "reactstrap";
import { Link } from "react-router-dom";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { MDBDataTable } from "mdbreact";
import { reactLocalStorage } from "reactjs-localstorage";
import moment from "moment";
import Dropzone from "react-dropzone";
import BaseInfo from "../ChooseDesign/baseInfo";
import HttpClient from "./../../utils/HttpClient";
import Select from "react-select";
import ProgressBar from "@ramonak/react-progress-bar";
import { v1 as uuidv1 } from "uuid";

// import EcomPreview from "./E-ComPreview/index";
class FormElements extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: "Video", link: "#" },
        { title: "Add & Manage Video", link: "#" },
      ],
      customchk: true,
      toggleSwitch: true,
      // page states
      name: "",
      data: [],
      alert: false,
      message: "",
      type: "",
      result: [],
      edit_enable: false,
      edit_item_id: "",
      image_select: false,
      course_id: this.props.match.params.id,
      img_url: "",
      userId: "",
      userDetails: {},

      trailer: "",
      showSelect: true,
      submit: false,
      uploading_trailer: false,
      trailerPlaylists: "",
      cat_id: "",
      cast: "",
      progressBarParsent: 0,
      progressBarParsentVideo: 0,
      uploading_movie: false,
      selectedvideoArr: [],
      alllesson: [],
      selectedLesson: [],
      selectedLessonvalue: "",
      filetype: true,
      videoUrl: "",
      url: "",

      videoUpload: false,
    };
  }

  componentDidMount() {
    this.fetchLesson();
    this.filterData();
    this.fetchData();
  }

  fetchLesson = async () => {
    let data = {
      course: parseInt(this.state.course_id),
    };
    console.log("COURSEid", data);

    let result = await HttpClient.requestData(
      "admin/lessons/get-all",
      "POST",
      data
    );
    console.log("jdCategoryresult", result);
    if (result && result.status > 0) {
      result.data.length > 0 &&
        result.data.forEach((element, index) => {
          console.log(element);
          element.data.length > 0 &&
            element.data.forEach((elementt, index) => {
              let dom = { label: elementt.name, value: uuidv1() };
              console.log(dom);
              this.state.alllesson.push(dom);

              this.setState({});
              console.log(this.state.alllesson);
            });
        });
    }
  };

  filterData = async (type) => {
    console.log("call");
    let result = await HttpClient.requestData("admin/course", "GET");
    console.log("CoursePageDataAll", result);

    if (result && result.status > 0) {
      let tr = result.data.filter((it) => it.id == this.state.course_id);
      console.log(tr[0]);
      //   this.state.data.push(tr[0]);

      this.setState({
        name: tr[0].name,
      });
      //  console.log(this.state.data[0].name);
      console.log(this.state.name);
    }
  };

  uploadVideo = async (video, type) => {
    this.setState({ filetype: video })
    if (type == "Trailer") {
      this.setState({ uploading_trailer: true, progressBarParsent: 0 });
    } else {
      // this.setState({ uploading_movie: true, progressBarParsent: 0 });
    }

    let dataSend = { buckate: false };
    let result = await HttpClient.uploadFileRequest(
      "video-upload/video",
      video,
      (data, data1, data2) => {
        console.log("data, data1, data2", data, data1, data2);

        if (type == "Trailer") {
          this.setState({ progressBarParsent: data });
        } else {
          // this.setState({ progressBarParsentVideo: data });
        }
      }
    );

    console.log("VideoResult", result);
    if (result && result.status) {
      console.log("VideoResult", result);
      if (type == "Trailer") {
        this.setState({
          trailerPlaylists: result.playlists,
          trailer: result.data.url,
          uploading_trailer: false,
        });
        setTimeout(() => {
          this.state.selectedvideoArr.push({ preview: result.data.url });
          this.setState({});
        }, 3000);

        console.log("SelectedVideoArrayData", this.state.selectedvideoArr);
      } else {
        this.setState({
          videoUrl: result.playlists,
          url: result.data.url,
          uploading_movie: false,
        });
      }
    }
    console.log("result", result);
  };

  fetchData = async () => {
    let data = {
      course: parseInt(this.state.course_id),
    };
    console.log("callFetching", data);
    let result = await HttpClient.requestData(
      "admin/lesson-videos/get-all",
      "POST",
      data
    );
    console.log("CourseVideoFetch", result);

    if (result && result.status > 0) {
      this.setState({
        data: [],
      });
      console.log("VideoFetchIF", result);
      let data = [];
      let i = 1;
      this.setState({ result: result.data });
      this.state.result.forEach((element, index) => {
        let rows = {
          sl: i,

          name: element.name,

          CourseVideo:
            element.trailer.length > 0 ? (
              <video width="150" height="120" controls className="rounded-lg">
                <source src={element.trailer[0].preview} type="video/mp4" />
              </video>
            ) : null,

          lessonname: element.lesson ? element.lesson.label : "",

          action: (
            <>
              <button 
              title="Delete"
              className="btn btn-danger mr-2"
              onClick={() => { if (window.confirm('Are you sure you wish to delete this item?')) 
              this.delete(element, index) }}
              >
              <i className="fa fa-trash" />
              </button>
              <button
                title="Edit"
                className="btn btn-primary"
                onClick={() => {
                  this.edit(element, index);
                }}
              >
                <i className="fa fa-edit" />
              </button>
            </>
          ),
        };
        i++;
        data.push(rows);
      });
      this.setState({
        data: data,
      });
    } else {
      this.setState({
        data: [],
      });
    }
  };

  delete = async (item, index) => {
    let sendData = {
      id: item._id,
    };
    let result = await HttpClient.requestData(
      "admin/lesson-videos/delete",
      "POST",
      sendData
    );
    if (result && result.status) {
      let index = this.state.result.indexOf(item);
      if (index > -1) {
        this.state.result.splice(index, 1);
        this.setState({
          alert: true,
          message: "Deleted Successfully",
          type: "success",
        });
        setTimeout(() => {
          this.setState({
            alert: false,
            message: "",
            type: "",
          });
        }, 3000);
        this.fetchData();
      }
    }
  };

  edit = async (item, index) => {
    console.log(item);

    this.setState({
      edit_enable: true,

      selectedLesson: item.lesson,
      name: item.name,

      edit_item_id: item._id,
      uploading_trailer: item.uploading_trailer,

      selectedvideoArr: item.trailer,
    });
  };

  submit = async () => {

    if (this.state.selectedvideoArr.length > 0) {
      this.setState({ loading: true });

      let data = null;
      let result = null;
      if (this.state.edit_enable) {
        data = {
          // lesson:this.state.selectedLessonvalue,
          lesson: this.state.selectedLesson,
          name: this.state.name,
          id: this.state.edit_item_id,
          uploading_trailer: this.state.uploading_trailer,
          trailer: this.state.selectedvideoArr,
          course: parseInt(this.state.course_id),
        };
        console.log(data);
        result = await HttpClient.requestData(
          "admin/lesson-videos/update",
          "POST",
          data
        );
      } else {
        data = {
          // lesson:this.state.selectedLessonvalue,
          lesson: this.state.selectedLesson,
          name: this.state.name,
          trailer: this.state.selectedvideoArr,
          uploading_trailer: this.state.uploading_trailer,
          course: parseInt(this.state.course_id),
        };
        console.log(data);
        result = await HttpClient.requestData(
          "admin/lesson-videos",
          "POST",
          data
        );
      }
      console.log("result", result);
      if (result && result.status) {
        console.log("CourseSUBMIT", result);
        this.setState({
          alert: true,
          message: this.state.edit_enable
            ? "Video Updated Successfully"
            : "Video Added Successfully",
          type: "success",
          selectedLesson: [],
          selectedvideoArr: [],
          filetype: false,
          edit_enable: false,
          edit_item_id: "",
          uploadVideo: false,
          url: "",
          trailer: "",
          uploading_trailer: false,

        });
        setTimeout(() => {
          this.setState({
            filetype: true,
          })
        }, 500);
        this.fetchData();
      } else {
        this.setState({
          alert: true,
          message: "",
          type: "danger",
        });
      }
    } else {
      this.setState({
        alert: true,
        message: "Please Select Atleast One Video.",
        type: "warning",
      });
    }

    setTimeout(() => {
      this.setState({
        alert: false,
        message: "",
        type: "",
      });
    }, 3000);
  };

  async remove_video(item, index) {
    console.log("INDEXSS", index);
    this.state.selectedvideoArr.pop(index);
    this.setState({});
  }

  /**
   * Formats the size
   */
  formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  LessonChange = async (selected) => {
    console.log("SELECTEDoption", selected);
    this.state.selectedLessonvalue = selected.value;
    this.state.selectedLesson = selected;

    this.setState({});

    setTimeout(() => {
      console.log(this.state.selectedLesson);
    }, 2000);
  };

  render() {
    const data = {
      columns: [
        {
          label: "Sl.",
          field: "sl",
          sort: "asc",
          width: 150,
        },

        {
          label: "Name",
          field: "name",
          sort: "asc",
          width: 270,
        },
        {
          label: "Video",
          field: "CourseVideo",
          sort: "asc",
          width: 270,
        },
        {
          label: "LessonName",
          field: "lessonname",
          sort: "asc",
          width: 270,
        },
        {
          label: "Action",
          field: "action",
          sort: "asc",
          width: 100,
        },
      ],
      rows: this.state.data,
    };
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title={this.state.breadcrumbItems[0].title}
              breadcrumbItems={this.state.breadcrumbItems}
            />
            <Link to="/course">
              <button type="button" class="btn btn-danger">Back</button>
              </Link>
              <br/><br/>

            <Row>
              {/* <BaseInfo /> */}
              <Col xs={12}>
                <Card>
                  <CardBody>
                    {this.state.alert ? (
                      <UncontrolledAlert
                        color={this.state.type}
                        className="alert-dismissible fade show"
                        role="alert"
                      >
                        {this.state.type == "warning" ? (
                          <i className="mdi mdi-alert-outline mr-2"></i>
                        ) : this.state.type == "success" ? (
                          <i className="mdi mdi-check-all mr-2"></i>
                        ) : this.state.type == "danger" ? (
                          <i className="mdi mdi-block-helper mr-2"></i>
                        ) : null}
                        {this.state.message}
                      </UncontrolledAlert>
                    ) : null}

                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Lesson
                      </Label>
                      <Col md={10}>
                        <Select
                          options={this.state.alllesson}
                          value={this.state.selectedLesson}
                          onChange={this.LessonChange}
                        />
                      </Col>
                    </FormGroup>

                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Upload Video<span style={{ color: 'red' }}>*</span>
                      </Label>
                      <Col md={6}>
                        {!this.state.filetype ? null :
                          <Input
                            className="form-control"
                            accept=".mp4,.flv,.MOV,.WMV,.AVI,.MKV,.AVCHD"
                            type="file"
                            id="example-text-input"
                            onChange={(val) => {

                              this.uploadVideo(val.target.files[0], "Trailer");
                            }}
                          // ref={(ref) => (this.fileInputTrl = ref)}
                          />}
                      </Col>
                      <Col md={4}>
                        {this.state.uploading_trailer ? (
                          <>
                            <ProgressBar
                              completed={this.state.progressBarParsent}
                            />
                            {this.state.progressBarParsent == 100
                              ? "please wait untill transcoding is done"
                              : null}
                          </>
                        ) : (
                          ""
                        )}
                        <span style={{color:'red'}}>[video should be in dimensions 1080 * 720 px]</span>
                      </Col>
                    </FormGroup>

                    <FormGroup row>
                      <Col md={10}>
                        <div
                          className="dropzone-previews mt-3"
                          id="file-previews"
                        >
                          {this.state.selectedvideoArr.length > 0 &&
                            this.state.selectedvideoArr.map((f, i) => {
                              return (
                                <Card
                                  className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                  key={i + "-file"}
                                >
                                  <div className="p-2">
                                    <Row className="align-items-center">
                                      <Col className="col-auto">
                                        <video
                                          width="150"
                                          height="120"
                                          controls
                                          className="rounded-lg"
                                        >
                                          <source
                                            src={f.preview}
                                            type="video/mp4"
                                          />
                                        </video>
                                      </Col>
                                      <Col>
                                        <Button
                                          className="btn-icon"
                                          color="danger"
                                          style={{ marginRight: 25 }}
                                          onClick={(e) =>
                                            this.remove_video(f, i)
                                          }
                                        >
                                          {" "}
                                          <span className="btn-icon-label">
                                            <i className="mdi mdi-alpha-x-box-outline mr-2"></i>
                                          </span>{" "}
                                          Remove
                                        </Button>
                                      </Col>
                                    </Row>
                                  </div>
                                </Card>
                              );
                            })}
                        </div>
                      </Col>
                    </FormGroup>
                    <p style={{marginTop: 5, textAlign: "right" }}>
                       [Note: Asterisk (<span style={{color:'red'}}>*</span>) denotes the mandatory field.]
                       </p>

                    <FormGroup className="mb-0">
                      <div className="button-items pt-4">
                        <Button
                          color="primary"
                          type="button"
                          className="waves-effect waves-light mr-1"
                          onClick={() => {
                            this.submit();
                          }}
                          disabled={this.state.submit}
                        >
                          {this.state.edit_enable ? "Update" : "Submit"}{" "}
                          <i className="ri-arrow-right-line align-middle ml-1"></i>
                        </Button>
                      </div>
                    </FormGroup>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col xl={12}>
                <div className="card p-3">
                  <MDBDataTable responsive bordered data={data} />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default FormElements;
