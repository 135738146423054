import React, { Component } from "react";
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  FormGroup,
  Label,
  Input,
  CustomInput,
  Button,
  UncontrolledAlert,
  InputGroup,
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { MDBDataTable } from "mdbreact";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { reactLocalStorage } from "reactjs-localstorage";
import moment from "moment";
import HttpClient from "./../../utils/HttpClient";
class FormElements extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: "Products", link: "#" },
        { title: "Manage Products", link: "#" },
      ],
      customchk: true,
      toggleSwitch: true,
      // page states
      name: "",
      email: "",
      mobile: "",
      password: "",
      data: [],
      alert: false,
      message: "",
      type: "",
      result: [],
      invoice_id:this.props.match.params.id,
      edit_enable: false,
      edit_item_id: "",
      image_select: false,
      img_url: "",
      userId: "",
      userDetails: {},
      default_date: new Date(),
      details: "",
      
    };
    
  }

  componentDidMount = async () => {
    this.checkUser();
    
  };

  
  
  fetchData = async () => {
    let data={
      id:this.state.invoice_id
    };
   
    let result = await HttpClient.requestData(
      "admin/order/order-single",
      "POST",
      data,
     
    );
    
    
    console.log("ProductsFetch",result);
    
    if (result && result.status > 0) {
      let data = [];
      let i = 1;
      let k=0;
      
      this.setState({ result: result.data });

      this.state.result.forEach((element, index) => {
        
        let rows = {
          sl: i,
          name:element.cartData.length > 0&& element.cartData.map((elementt,indexx)=>{

            console.log("Arraylength",element.cartData.length)
            console.log("index",indexx)

           
           return elementt.products.length >0&&elementt.products.map((el,index)=>{
              
              console.log(element.cartData.length)
              return indexx == element.cartData.length -1 ?  el.name + " " + " "  : el.name + " ," + " "
            })
          }),

         
          orderedperson: element.userData.firstname + " " + element.userData.lastname,
          deliveryaddress: element.address,
          mobile:element.mobile,
          alt:element.alt_no,
          landmark:element.landmark,
          
        };
        i++;
        data.push(rows);
      });
      this.setState({
        data: data,
      });
    } else {
      this.setState({
        data: [],
      });
    }
  };
 

 
  

  checkUser = async () => {
    let user = await reactLocalStorage.getObject("userData");
    if (user != null && Object.keys(user).length > 0) {
      this.setState({
        userId: user.id,
        userDetails: user,
      });
    } else {
    }
    this.fetchData();
  };

  render() {
    const data = {
      columns: [
        {
          label: "Sl.",
          field: "sl",
          sort: "asc",
          width: 150,
        },
        {
          label: "Product Name",
          field: "name",
          sort: "asc",
          width: 150,
        },
        {
          label: "Order By",
          field: "orderedperson",
          sort: "asc",
          width: 270,
        },
        {
          label: "Address",
          field: "deliveryaddress",
          sort: "asc",
          width: 270,
        },
       
        {
          label: "Mobile No",
          field: "mobile",
          sort: "asc",
          width: 270,
        },
        
        {
          label: "Alternate No",
          field: "alt",
          sort: "asc",
          width: 270,
        },
       

        {
          label: "Landmark",
          field: "landmark",
          sort: "asc",
          width: 270,
        },
        
        
        
        // {
        //   label: "Status",
        //   field: "status",
        //   sort: "asc",
        //   width: 100,
        // },
        // {
        //   label: "Action",
        //   field: "action",
        //   sort: "asc",
        //   width: 100,
        // },
      ],
      rows: this.state.data,
    };
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title={this.state.breadcrumbItems[0].title}
              breadcrumbItems={this.state.breadcrumbItems}
            />

            <Row>
              <Col xs={12}>
                
            
              </Col>
            </Row>
            <Row>
              <Col xl={12}>
                <div className="card p-3">
                  <MDBDataTable responsive bordered data={data} />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default FormElements;
