import React, { Component } from "react";
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  FormGroup,
  Label,
  Input,
  CustomInput,
  Button,
  UncontrolledAlert,
  InputGroup,
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { MDBDataTable } from "mdbreact";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { reactLocalStorage } from "reactjs-localstorage";
import moment from "moment";
import HttpClient from "./../../utils/HttpClient";
class FormElements extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: "Quiz Participant", link: "#" },
        { title: "Add & Manage Quiz Participant", link: "#" },
      ],
      customchk: true,
      toggleSwitch: true,
      // page states
      name: "",
      email: "",
      mobile: "",
      password: "",
      data: [],
      alert: false,
      message: "",
      quiz_id:this.props.match.params.id,
      type: "",
      result: [],
      edit_enable: false,
      edit_item_id: "",
      image_select: false,
      img_url: "",
      userId: "",
      userDetails: {},
      
    };
    
  }

  componentDidMount = async () => {
    this.checkUser();
  };

  

  fetchData = async () => {
    let data={
      quiz_id:this.state.quiz_id,
    };
    console.log("DDD",data);
    let result = await HttpClient.requestData(
      "admin/quiz/user-quiz",
      "POST",
      data,
    );

    console.log("QuizParticipant Fetch",result);

    if (result && result.status > 0) {
      let data = [];
      let i = 1;
      this.setState({ result: result.data });
      this.state.result.forEach((element, index) => {
        let rows = {
          sl: i,
          name: element.userData.firstname + " " + element.userData.lastname,
          email: element.userData.email,
          date: moment(element.created_on).format("L"),
          total_question: element.total_question,
          total_attempt: element.total_attempt,
         
          status: (
            <button
              title="Delete"
              className={
                element.userData.status ? "btn btn-success mr-2" : "btn btn-danger mr-2"
              }
              onClick={() => {
                this.status(element, index);
              }}
            >
              {element.userData.status ? "Active" : "Not Active"}
            </button>
          ),
          // action: (
          //   <>
          //     <button
          //       title="Delete"
          //       className="btn btn-danger mr-2"
          //       onClick={() => {
          //         this.delete(element, index);
          //       }}
          //     >
          //       <i className="fa fa-trash" />
          //     </button>
          //     <button
          //       title="Edit"
          //       className="btn btn-primary"
          //       onClick={() => {
          //         this.edit(element, index);
          //       }}
          //     >
          //       <i className="fa fa-edit" />
          //     </button>
          //   </>
          // ),
        };
        i++;
        data.push(rows);
      });
      this.setState({
        data: data,
      });
    } else {
      this.setState({
        data: [],
      });
    }
  };
 
 
  // status = async (item, index) => {
  //   let sendData = {
  //     id: item._id,
  //     status: !item.status,
  //   };
  //   let result = await HttpClient.requestData(
  //     "app-owner/event/status",
  //     "POST",
  //     sendData
  //   );
  //   if (result && result.status) {
  //     this.state.result.splice(index, 1);
  //     this.setState({
  //       alert: true,
  //       message: "Status Updated Successfullyy",
  //       type: "success",
  //     });
  //     setTimeout(() => {
  //       this.setState({
  //         alert: false,
  //         message: "",
  //         type: "",
  //       });
  //     }, 3000);
  //     this.fetchData();
  //   }
  // };
  

  checkUser = async () => {
    let user = await reactLocalStorage.getObject("userData");
    if (user != null && Object.keys(user).length > 0) {
      this.setState({
        userId: user.id,
        userDetails: user,
      });
    } else {
    }
    this.fetchData();
  };

  render() {
    const data = {
      columns: [
        {
          label: "Sl.",
          field: "sl",
          sort: "asc",
          width: 150,
        },
        {
          label: "Name",
          field: "name",
          sort: "asc",
          width: 270,
        },
        
        
        {
          label: "Email Id",
          field: "email",
          sort: "asc",
          width: 270,
        },
        {
          label: "Date",
          field: "date",
          sort: "asc",
          width: 270,
        },
        {
          label: "Total Question",
          field: "total_question",
          sort: "asc",
          width: 270,
        },
        {
          label: "Total Attempt",
          field: "total_attempt",
          sort: "asc",
          width: 270,
        },
       
        
        // {
        //   label: "Status",
        //   field: "status",
        //   sort: "asc",
        //   width: 100,
        // },
        // {
        //   label: "Action",
        //   field: "action",
        //   sort: "asc",
        //   width: 100,
        // },
      ],
      rows: this.state.data,
    };
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title={this.state.breadcrumbItems[0].title}
              breadcrumbItems={this.state.breadcrumbItems}
            />

            <Row>
              <Col xs={12}>
                
              </Col>
            </Row>
            <Row>
              <Col xl={12}>
                <div className="card p-3">
                  <MDBDataTable responsive bordered data={data} />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default FormElements;
