import React, { Component } from "react";
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  FormGroup,
  Label,
  Input,
  CustomInput,
  Button,
  UncontrolledAlert,
} from "reactstrap";
import { Link } from "react-router-dom";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import ReactTags from "react-tag-autocomplete";
import { MDBDataTable } from "mdbreact";
import { reactLocalStorage } from "reactjs-localstorage";
import moment from "moment";
import Dropzone from "react-dropzone";
import BaseInfo from "../ChooseDesign/baseInfo";
import HttpClient from "./../../utils/HttpClient";
import Select from "react-select";
import ProgressBar from "@ramonak/react-progress-bar";

// import EcomPreview from "./E-ComPreview/index";
class FormElements extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: "Course Exam", link: "#" },
        { title: "Add & Manage Course Exam", link: "#" },
      ],
      customchk: true,
      toggleSwitch: true,
      // page states
      name: "",
      data: [],
      alert: false,
      message: "",
      type: "",
      result: [],
      edit_enable: false,
      edit_item_id: "",
      image_select: false,
      img_url: "",
      userId: "",
      userDetails: {},
      uploaded_images: [],
      categorycourse: [],
      selectedMultiCat: [],
      selectedMultiCatvaue: "",
      selectedMultiFilter: [],
      selectedMultiFiltervalue: "",
      lecturer: [],
      selectedType: [],
      selectedTypevalue: "",
      courseType: [
        {
          label: "Paid",
          value: "paid",
        },
        {
          label: "Free",
          value: "Free",
        },
      ],
      selectedcourseType: [],
      selectedTypevalue: "",
      director: "",
      desc: "",
      season: "",
      trailer: "",
      showSelect: true,
      submit: false,
      uploading_trailer: false,
      trailerPlaylists: "",
      cat_id: "",
      cast: "",
      progressBarParsent: 0,
      progressBarParsentVideo: 0,
      uploading_movie: false,

      videoUrl: "",
      url: "",

      videoUpload: false,

      priority: "",
      price: 0,
      suggestions: [],
      tags: [],
    };
  }

  handleType = (selectedType) => {
    console.log(selectedType);
    this.state.selectedTypevalue = selectedType.value;
    this.state.selectedcourseType.push(selectedType);
    this.state.price = 0;
    this.setState({});
    console.log(this.state.selectedType);
  };

  handleMultiCat = (selected) => {
    console.log(selected);
    this.state.selectedMultiCatvaue = selected.value;
    this.state.selectedMultiCat.push(selected);
    this.setState({});
    console.log(this.state.selectedMultiCat);
  };

  handleMultiFilter = (selected) => {
    console.log(selected);
    this.state.selectedMultiFiltervalue = selected.value;
    this.state.selectedMultiFilter.push(selected);
    this.setState({});
    console.log(this.state.selectedMultiFilter);
  };

  componentDidMount = async () => {
    this.checkUser();
    this.fetchDropdown();
    this.fetchLecturer();
  };

  fetchDropdown = async () => {
    let resultCat = await HttpClient.requestData(
      "admin/course-category",
      "GET"
    );
    console.log("CourseCat", resultCat);
    if (resultCat && resultCat.status > 0) {
      resultCat.data.forEach((element, index) => {
        let dom = { label: element.name, value: element.id };
        this.state.categorycourse.push(dom);
        this.setState({});
      });
    }
  };

  fetchLecturer = async () => {
    let resultCat = await HttpClient.requestData("admin/lecturer", "GET");
    console.log("CourseCat", resultCat);
    if (resultCat && resultCat.status > 0) {
      resultCat.data.forEach((element, index) => {
        let dom = { label: element.name, value: element.id };
        this.state.lecturer.push(dom);
        this.setState({});
      });
    }
  };

  uploadVideo = async (video, type) => {
    if (type == "Trailer") {
      this.setState({ uploading_trailer: true });
    } else {
      this.setState({ uploading_movie: true });
    }

    let dataSend = { buckate: false };
    let result = await HttpClient.uploadFileRequest(
      "video-upload/video",
      video,
      (data, data1, data2) => {
        console.log("data, data1, data2", data, data1, data2);

        if (type == "Trailer") {
          this.setState({ progressBarParsent: data });
        } else {
          this.setState({ progressBarParsentVideo: data });
        }
      }
    );
    if (result && result.status) {
      if (type == "Trailer") {
        this.setState({
          trailerPlaylists: result.playlists,
          trailer: result.data.url,
          uploading_trailer: false,
        });
      } else {
        this.setState({
          videoUrl: result.playlists,
          url: result.data.url,
          uploading_movie: false,
        });
      }
    }
    console.log("result", result);
  };

  fetchData = async (type) => {
    console.log("call");
    let result = await HttpClient.requestData("admin/course", "GET");
    console.log("call", result);

    if (result && result.status > 0) {
      let data = [];
      let i = 1;
      this.setState({ result: result.data });
      this.state.result.forEach((element, index) => {
        let rows = {
          sl: i,

          name: element.name,
          img:
            element.img.length > 0 ? (
              <div>
                <img
                  src={element.img[0].preview}
                  alt="images"
                  className="rounded avatar-md card-img"
                />
              </div>
            ) : null,
          desc: element.desc,
          price: element.price,
          priority: element.priority,
          year: element.year ? element.year : "",

          status: (
            <button
              title="Delete"
              className={
                element.status ? "btn btn-success mr-2" : "btn btn-danger mr-2"
              }
              onClick={() => {
                this.status(element, index);
              }}
            >
              {element.status ? "Active" : "Not Active"}
            </button>
          ),
          ctest: (
            <Link to={"/exam-add/" + element.id} className="btn btn-success">
              Add
              <span className="ml-1"></span>
            </Link>
          ),
          priority: element.priority,
          action: (
            <>
              <button
                title="Delete"
                className="btn btn-danger mr-2"
                onClick={() => {
                  this.delete(element, index);
                }}
              >
                <i className="fa fa-trash" />
              </button>
              <button
                title="Edit"
                className="btn btn-primary"
                onClick={() => {
                  this.edit(element, index);
                }}
              >
                <i className="fa fa-edit" />
              </button>
            </>
          ),
        };
        i++;
        data.push(rows);
      });
      this.setState({
        data: data,
      });
    } else {
      this.setState({
        data: [],
      });
    }
  };

  delete = async (item, index) => {
    let sendData = {
      id: item._id,
    };
    let result = await HttpClient.requestData(
      "admin/movies/delete",
      "POST",
      sendData
    );
    if (result && result.status) {
      let index = this.state.result.indexOf(item);
      if (index > -1) {
        this.state.result.splice(index, 1);
        this.setState({
          alert: true,
          message: "Deleted Successfully",
          type: "success",
        });
        setTimeout(() => {
          this.setState({
            alert: false,
            message: "",
            type: "",
          });
        }, 3000);
        this.fetchData(this.state.selectedType);
      }
    }
  };

  edit = async (item, index) => {
    console.log(item);
    this.setState({
      edit_enable: true,
      name: item.name,
      uploaded_images: item.img,
      price: item.price,
      edit_item_id: item._id,
      selectedMultiCat: item.coursecat,
      selectedMultiFilter: item.lecturer,
      selectedcourseType: item.type,
      desc: item.desc,

      year: item.year,

      trailer: item.trailer,

      priority: item.priority,
      tags: item.interest,
    });
    // setTimeout(() => {
    //   this.setState({ showSelect: true });
    // }, 200);
    // this.handleMulti(item.category);
  };

  status = async (item, index) => {
    let sendData = {
      id: item._id,
      status: !item.status,
    };
    let result = await HttpClient.requestData(
      "admin/movies/status",
      "POST",
      sendData
    );
    if (result && result.status) {
      this.state.result.splice(index, 1);
      this.setState({
        alert: true,
        message: "Status Updated Successfullyy",
        type: "success",
      });
      setTimeout(() => {
        this.setState({
          alert: false,
          message: "",
          type: "",
        });
      }, 3000);
      this.fetchData();
    }
  };



  checkUser = async () => {
    let user = await reactLocalStorage.getObject("userData");
    if (user != null && Object.keys(user).length > 0) {
      this.setState({
        userId: user.id,
        userDetails: user,
      });
    } else {
    }
    this.fetchData();
  };

  handleChange = async (event) => {
    let filterSub = this.state.subCategoryAll.filter(
      (item) => item.category_id == event.target.value
    );
    this.setState({ cat_id: event.target.value, subCategory: filterSub });
  };

  onDelete(i) {
    // const tags = this.state.tags.slice(0)
    // tags.splice(i, 1)
    // this.setState({ tags })
    // console.log(this.state.tags);
    this.state.tags.splice(i, 1);
    this.setState({ tags: this.state.tags });
    console.log(this.state.tags);
  }

  onAddition(tag) {
    console.log(tag);
    this.state.tags.push(tag);
    this.setState({ tags: this.state.tags });
    console.log(this.state.tags);
  }
  render() {
    const data = {
      columns: [
        {
          label: "Sl.",
          field: "sl",
          sort: "asc",
          width: 150,
        },
        {
            label: "Image",
            field: "img",
            sort: "asc",
            width: 270,
          },
        {
          label: "Name",
          field: "name",
          sort: "asc",
          width: 270,
        },
        {
            label: "Exam",
            field: "ctest",
            sort: "asc",
            width: 100,
        },
        // {
        //   label: "Action",
        //   field: "action",
        //   sort: "asc",
        //   width: 100,
        // },
      ],
      rows: this.state.data,
    };
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title={this.state.breadcrumbItems[0].title}
              breadcrumbItems={this.state.breadcrumbItems}
            />

            
            <Row>
              <Col xl={12}>
                <div className="card p-3">
                  <MDBDataTable responsive bordered data={data} />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default FormElements;
