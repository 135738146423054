import React, { Component } from "react";
import {
    Card,
    CardBody,
    Col,
    Row,
    Container,
    FormGroup,
    Label,
    Input,
    CustomInput,
    Button,
    UncontrolledAlert,
    InputGroup,
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { MDBDataTable } from "mdbreact";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { reactLocalStorage } from "reactjs-localstorage";
import moment from "moment";
import HttpClient from "./../../utils/HttpClient";
class FormElements extends Component {
    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems: [
                { title: "Reviews", link: "#" },
                { title: "Manage Products Reviews", link: "#" },
            ],
            customchk: true,
            toggleSwitch: true,
            // page states
            name: "",
            email: "",
            mobile: "",
            data: [],
            alert: false,
            message: "",
            type: "",
            result: [],
            edit_enable: false,
            edit_item_id: "",
            image_select: false,
            img_url: "",
            userId: "",
            userDetails: {},
            default_date: new Date(),
            details: "",
            link: "",
            latitude: "",
            longitude: '',
            review: this.props.match.params.id,
          
        };

    }

    componentDidMount = async () => {
        this.fetchData();
    };



    fetchData = async () => {
        let data = {
            product_id: Number(this.state.review),
        };
        console.log("xxx", data)
        let result = await HttpClient.requestData(
            "admin/order/get-feadback",
            "POST",
            data,
             );
        console.log("OrderFetchAll", result);

        if (result && result.status > 0) {
            let data = [];
            let i = 1;

            this.setState({ result: result.allData });

            this.state.result && this.state.result.forEach((element, index) => {

                let rows = {
                    sl: i,

                    msg: element.msg,
                    username: element.categoryData.firstname + " " + element.categoryData.lastname,
                    email: element.categoryData.email,
                    date: moment(element.created_on).format("L"),
                    image:
                        element.categoryData.image.length > 0 ? (
                            <div>
                                <img
                                    src={element.categoryData.image}
                                    alt="images"
                                    className="rounded avatar-md card-img"
                                />
                            </div>
                        ) : null,

                    status: (
                        <button
                            title="Delete"
                            className={
                                element.status ? "btn btn-success mr-2" : "btn btn-danger mr-2"
                            }
                            onClick={() => {
                                this.status(element, index);
                            }}
                        >
                            {element.status ? "Active" : "Not Active"}
                        </button>
                    ),
                };
                i++;
                data.push(rows);
            });
            this.setState({
                data: data,
            });
        } else {
            this.setState({
                data: [],
            });
        }
    };


    status = async (item, index) => {
        let sendData = {
            id: item._id,
            status: !item.status,
        };
        let result = await HttpClient.requestData(
            "admin/order/status-feadback",
            "POST",
            sendData
        );
        if (result && result.status) {
            this.state.result.splice(index, 1);
            this.setState({
                alert: true,
                message: "Status Updated Successfully",
                type: "success",
            });
            setTimeout(() => {
                this.setState({
                    alert: false,
                    message: "",
                    type: "",
                });
            }, 3000);
            this.fetchData();
        }
    };


    render() {
        const data = {
            columns: [
                {
                    label: "Sl.",
                    field: "sl",
                    sort: "asc",
                    width: 150,
                },
                {
                    label: "Message",
                    field: "msg",
                    sort: "asc",
                    width: 150,
                },
                {
                    label: "Name",
                    field: "username",
                    sort: "asc",
                    width: 150,
                },
                {
                    label: "Date",
                    field: "date",
                    sort: "asc",
                    width: 270,
                },
                {
                    label: "Email",
                    field: "email",
                    sort: "asc",
                    width: 270,
                },
                {
                    label: "Image",
                    field: "image",
                    sort: "asc",
                    width: 270,
                  },

                {
                    label: "Status",
                    field: "status",
                    sort: "asc",
                    width: 270,
                },
            ],
            rows: this.state.data,
        };
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs
                            title={this.state.breadcrumbItems[0].title}
                            breadcrumbItems={this.state.breadcrumbItems}
                        />

                        <Row>
                            <Col xs={12}>


                            </Col>
                        </Row>
                        <Row>
                            <Col xl={12}>
                                <div className="card p-3">
                                    <MDBDataTable responsive bordered data={data} />
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default FormElements;
