import React, { Component } from "react";
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  FormGroup,
  Label,
  Input,
  CustomInput,
  Button,
  UncontrolledAlert,
  InputGroup,
} from "reactstrap";
import { Link } from "react-router-dom";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { MDBDataTable } from "mdbreact";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { reactLocalStorage } from "reactjs-localstorage";
import moment from "moment";
import HttpClient from "./../../utils/HttpClient";
import Dropzone from "react-dropzone";
class FormElements extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: "Sessions", link: "#" },
        { title: "Add & Manage Sessions", link: "#" },
      ],
      customchk: true,
      toggleSwitch: true,
      // page states
      name: "",
      email: "",
      mobile: "",
      password: "",
      data: [],
      alert: false,
      message: "",
      type: "",
      result: [],
      edit_enable: false,
      edit_item_id: "",
      image_select: false,
      userId: "",
      userDetails: {},
      default_date: new Date(),
      details: "",

      start_time: "",
      end_time: "",

      selectedFiles: [],
      images: [],
      uploaded_images: [],

      event_id: this.props.match.params.id,
      eventData: null,

      start_time: new Date(),
    };
    this.handleDefault = this.handleDefault.bind(this);
    this.handleDefaultEnd = this.handleDefaultEnd.bind(this);
    this.handleStartTIme = this.handleStartTIme.bind(this);
  }

  componentDidMount = async () => {
    this.checkUser();
    this.fetchEvent();
  };

  handleDefault(date) {
    this.setState({ default_date: date });
  }

  handleDefaultEnd(date) {
    this.setState({ default_end_date: date });
  }

  handleStartTIme(time) {
    console.log("time", time);
  }

  fetchEvent = async () => {
    let result = await HttpClient.requestData(
      "admin/event/single-event",
      "POST",
      { id: this.state.event_id }
    );

    if (result && result.status) {
      let data = result.data[0];
      this.state.breadcrumbItems[0].title = "Sessions - " + data.name;
      this.setState({ eventData: data });
    }
  };

  fetchData = async () => {
    let result = await HttpClient.requestData(
      "admin/event/session/all-session",
      "POST",
      { event_id: Number(this.state.event_id) }
    );
    console.log('result fetchData', result)
    if (result && result.status) {
      let data = [];
      let i = 1;
      this.setState({ result: result.data });
      this.state.result.forEach((element, index) => {
        let rows = {
          sl: i,
          name: element.name,
          created_by: element.created_by ? element.created_by.name + " on " + moment(element.created_on).utcOffset(element.created_on).format('DD/MM/YYYY, HH:mm') : "" + " on " + moment(element.created_on).utcOffset(element.created_on).format('DD/MM/YYYY, HH:mm'),
          updated_by: element.updated_by ? element.updated_by.name + " on " + moment(element.updated_on).utcOffset(element.updated_on).format('DD/MM/YYYY, HH:mm') : "" + "",
          timing: moment(element.start_date).format("LL"),
          time:
            moment(element.start_time, "hh:mm").format("hh:mm a") +
            " To " +
            moment(element.end_time, "hh:mm").format("hh:mm a"),
          details: element.details,
          image: (
            <div>
              <img
                src={element.images[0].preview}
                alt="images"
                className="rounded avatar-md card-img"
              />
            </div>
          ),
          session: (
            <Link
              className="btn btn-danger mr-2"
              to={"/session-speakers/" + element.id}
            >
              Speakers
            </Link>
          ),
          status: (
            <button
              title="Delete"
              className={
                element.status ? "btn btn-success mr-2" : "btn btn-danger mr-2"
              }
              onClick={() => {
                this.status(element, index);
              }}
            >
              {element.status ? "Active" : "Not Active"}
            </button>
          ),
          action: (
            <>
              {this.state.userDetails.sub_admin == true ? null : (
                <button
                  title="Delete"
                  className="btn btn-danger mr-2"
                  onClick={() => {
                    if (window.confirm('Are you sure you wish to delete this item?'))
                      this.delete(element, index)
                  }}
                >
                  <i className="fa fa-trash" />
                </button>
              )}
              <button
                title="Edit"
                className="btn btn-primary"
                onClick={() => {
                  this.edit(element, index);
                  window.scrollTo(0, 0);
                }}
              >
                <i className="fa fa-edit" />
              </button>
            </>
          ),
        };
        i++;
        data.push(rows);
      });
      this.setState({
        data: data,
      });
    } else {
      this.setState({
        data: [],
      });
    }
  };

  delete = async (item, index) => {
    let sendData = {
      id: item._id,
    };
    let result = await HttpClient.requestData(
      "admin/event/session/delete",
      "POST",
      sendData
    );
    if (result && result.status) {
      let index = this.state.result.indexOf(item);
      if (index > -1) {
        this.state.result.splice(index, 1);
        this.setState({
          alert: true,
          message: "Deleted Successfully",
          type: "success",
        });
        setTimeout(() => {
          this.setState({
            alert: false,
            message: "",
            type: "",
          });
        }, 3000);
        this.fetchData();
      }
    }
  };

  edit = async (item, index) => {
    this.setState({
      edit_enable: true,
      name: item.name,
      edit_item_id: item._id,
      timing: moment(item.timing).format("dd/mm/YYYY"),
      default_date: new Date(item.start_date),
      default_end_date: new Date(item.end_date),
      start_time: item.start_time,
      end_time: item.end_time,
      host: item.host,
      details: item.details,
      vanue: item.vanue,
      uploaded_images: item.images ? item.images : [],
      price: item.seat_price,
      total_tables: item.total_tables,
    });
  };

  status = async (item, index) => {
    let sendData = {
      id: item._id,
      status: !item.status,
    };
    let result = await HttpClient.requestData(
      "admin/event/session/status",
      "POST",
      sendData
    );
    if (result && result.status) {
      this.state.result.splice(index, 1);
      this.setState({
        alert: true,
        message: "Status Updated Successfully",
        type: "success",
      });
      setTimeout(() => {
        this.setState({
          alert: false,
          message: "",
          type: "",
        });
      }, 3000);
      this.fetchData();
    }
  };

  submit = async () => {
    if (this.state.uploaded_images.length == 0) {
      this.setState({
        alert: true,
        message: "Please Upload Images.",
        type: "warning",
      });
      return;
    }
    if (
      this.state.name != "" &&
      this.state.default_date != "" &&
      this.state.start_time != "" &&
      this.state.end_time != "" &&
      this.state.details != ""
    ) {
      if (moment(this.state.eventData.start_date).format("YYYY/DD/MM")
        <= moment(this.state.default_date).format("YYYY/DD/MM")
        && moment(this.state.default_date).format("YYYY/DD/MM")
        <= moment(this.state.eventData.end_date).format("YYYY/DD/MM")) {

        if (this.state.eventData.start_time <= this.state.start_time &&
          this.state.start_time <= this.state.eventData.end_time) {
          if (this.state.eventData.start_time <= this.state.end_time &&
            this.state.end_time <= this.state.eventData.end_time) {
            if (this.state.start_time < this.state.end_time) {
              let data = null;
              let result = null;

              if (this.state.edit_enable == false) {
                data = {
                  name: this.state.name,
                  start_date: this.state.default_date,
                  start_time: this.state.start_time,
                  end_time: this.state.end_time,
                  details: this.state.details,
                  images: this.state.uploaded_images,
                  event_id: Number(this.state.event_id),
                  created_by: this.state.userId,
                };
                result = await HttpClient.requestData(
                  "admin/event/session",
                  "POST",
                  data
                );
              } else {
                data = {
                  name: this.state.name,
                  start_date: this.state.default_date,
                  start_time: this.state.start_time,
                  end_time: this.state.end_time,
                  seat_price: this.state.price,
                  details: this.state.details,
                  host: this.state.host,
                  total_tables: this.state.total_tables,
                  vanue: this.state.vanue,
                  images: this.state.uploaded_images,
                  edit_id: this.state.edit_item_id,
                  updated_by: this.state.userId,
                };
                result = await HttpClient.requestData(
                  "admin/event/session/update",
                  "POST",
                  data
                );
              }
              if (result && result.status) {
                this.setState({
                  alert: true,
                  message: this.state.edit_enable
                    ? "Session Updated Successfully"
                    : "Session Added Successfully",
                  type: "success",
                  name: "",
                  img_url: "",
                  image_select: false,
                  password: "",
                  edit_enable: false,
                  edit_item_id: "",
                  default_date: new Date(),
                  default_end_date: new Date(),
                  start_time: "",
                  end_time: "",
                  details: "",
                  uploaded_images: [],
                });

                this.fetchData();
              } else {
                this.setState({
                  alert: true,
                  message:
                    typeof result.error == "string"
                      ? result.error
                      : result.error[0].msg,
                  type: "danger",
                });
              }
            } else {
              this.setState({
                alert: true,
                message: "End time must be greater than start time.",
                type: "warning",
              });
            }
          } else {
            this.setState({
              alert: true,
              message: "Please give a end time between event timing.",
              type: "warning",
            });
          }
        } else {
          this.setState({
            alert: true,
            message: "Please give a start time between event timing.",
            type: "warning",
          });
        }
      } else {
        this.setState({
          alert: true,
          message: "Please give a date between event date.",
          type: "warning",
        });
      }
    } else {
      this.setState({
        alert: true,
        message: "Please Fill Up All Details.",
        type: "warning",
      });
    }
    setTimeout(() => {
      this.setState({
        alert: false,
        message: "",
        type: "",
      });
    }, 3000);
  };

  imageUpload = async (file, index) => {
    // console.log("e", file);

    let dataSend = { buckate: false };

    let result = await HttpClient.newFileUpload(
      "image-upload/event",
      file,
      dataSend
    );
    console.log("result", result);
    if (result && result.status) {
      this.state.uploaded_images.push({ preview: result.url });
      if (this.state.images.length - 1 == index) {
        setTimeout(() => {
          this.setState({ loading: false });
          console.log("this.state.uploaded_images", this.state.uploaded_images);
        }, 200);
      }
      this.setState({});
    }
  };

  handleAcceptedFiles = (files) => {
    let data = [];
    let file = {
      preview: URL.createObjectURL(files[0]),
      formattedSize: this.formatBytes(files[0].size),
      // image:files,
    };
    let file2 = {
      image: files,
    };

    let file3 = files;
    console.log("data", file);
    this.state.selectedFiles.push(file);
    this.state.images.push(files[0]);
    this.imageUpload(files[0]);
    this.setState({});
  };

  async remove_image(item, index) {
    this.state.selectedFiles.pop(index);
    this.state.images.pop(index);
    this.state.uploaded_images.pop(index);
    this.setState({});
  }

  /**
   * Formats the size
   */
  formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  checkUser = async () => {
    let user = await reactLocalStorage.getObject("userData");
    if (user != null && Object.keys(user).length > 0) {
      this.setState({
        userId: user.id,
        userDetails: user,
      });
    } else {
    }
    this.fetchData();
  };

  render() {
    const data = {
      columns: [
        {
          label: "Sl.",
          field: "sl",
          sort: "asc",
          width: 150,
        },
        {
          label: "Image",
          field: "image",
          sort: "asc",
          width: 270,
        },
        {
          label: "Name",
          field: "name",
          sort: "asc",
          width: 270,
        },
        {
          label: "Created By",
          field: "created_by",
          sort: "asc",
          width: 270,
        },
        {
          label: "Updated By",
          field: "updated_by",
          sort: "asc",
          width: 270,
        },
        {
          label: "Date",
          field: "timing",
          sort: "asc",
          width: 270,
        },
        {
          label: "Time",
          field: "time",
          sort: "asc",
          width: 270,
        },
        // {
        //   label: "Details",
        //   field: "details",
        //   sort: "asc",
        //   width: 270,
        // },

        {
          label: "Speaker",
          field: "session",
          sort: "asc",
          width: 100,
        },
        {
          label: "Status",
          field: "status",
          sort: "asc",
          width: 100,
        },
        {
          label: "Action",
          field: "action",
          sort: "asc",
          width: 100,
        },
      ],
      rows: this.state.data,
    };
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title={this.state.breadcrumbItems[0].title}
              breadcrumbItems={this.state.breadcrumbItems}
            />

            <Row>
              <Col xs={12}>
                <Card>
                  <CardBody>
                    {this.state.alert ? (
                      <UncontrolledAlert
                        color={this.state.type}
                        className="alert-dismissible fade show"
                        role="alert"
                      >
                        {this.state.type == "warning" ? (
                          <i className="mdi mdi-alert-outline mr-2"></i>
                        ) : this.state.type == "success" ? (
                          <i className="mdi mdi-check-all mr-2"></i>
                        ) : this.state.type == "danger" ? (
                          <i className="mdi mdi-block-helper mr-2"></i>
                        ) : null}
                        {this.state.message}
                      </UncontrolledAlert>
                    ) : null}
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Name<span style={{ color: 'red' }}>*</span>

                      </Label>
                      <Col md={10}>
                        <Input
                          className="form-control"
                          type="text"
                          value={this.state.name}
                          id="example-text-input"
                          onChange={(val) => {
                            this.setState({ name: val.target.value });
                          }}
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Details<span style={{ color: 'red' }}>*</span>
                      </Label>
                      <Col md={10}>
                        <Input
                          className="form-control"
                          type="textarea"
                          value={this.state.details}
                          id="example-text-input"
                          onChange={(val) => {
                            this.setState({ details: val.target.value });
                          }}
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Date<span style={{ color: 'red' }}>*</span>
                      </Label>
                      <Col md={10}>
                        <InputGroup>
                          <DatePicker
                            className="form-control"
                            selected={this.state.default_date}
                            onChange={this.handleDefault}
                          />
                        </InputGroup>
                      </Col>
                    </FormGroup>

                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Start Time<span style={{ color: 'red' }}>*</span>
                      </Label>
                      <Col md={4}>
                        <input
                          type="time"
                          // min={moment(this.state.sessionStartTime, ["hh:mm"]).format("HH:mm")}
                          min={this.state.sessionStartTime}

                          value={this.state.start_time}
                          className="form-control"
                          onChange={(val) => {
                            // console.log(val.target.value);
                            this.setState({ start_time: val.target.value });
                          }}
                        />
                      </Col>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        End Time<span style={{ color: 'red' }}>*</span>
                      </Label>
                      <Col md={4}>
                        <input
                          type="time"
                          value={this.state.end_time}
                          min={this.state.sessionEndTime}
                          className="form-control"
                          onChange={(val) => {
                            console.log(val.target.value);
                            this.setState({ end_time: val.target.value });
                          }}
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Images<span style={{ color: 'red' }}>*</span>
                      </Label>
                      <Col md={10}>
                        <Dropzone
                          accept=".jpeg,.jpg,.png,.svg,.webp,.jfif,.apng"
                          onDrop={(acceptedFiles) =>
                            this.handleAcceptedFiles(acceptedFiles)
                          }
                        >
                          {({ getRootProps, getInputProps }) => (
                            <div className="dropzone">
                              <div
                                className="dz-message needsclick mt-2"
                                {...getRootProps()}
                              >
                                <input {...getInputProps()} />
                                <div className="mb-3">
                                  <i className="display-4 text-muted ri-upload-cloud-2-line"></i>
                                </div>
                                <h4>Drop files here or click to upload.</h4>
                              </div>
                            </div>
                          )}
                        </Dropzone>
                        <div
                          className="dropzone-previews mt-3"
                          id="file-previews"
                        >
                          {this.state.uploaded_images.map((f, i) => {
                            return (
                              <Card
                                className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                key={i + "-file"}
                              >
                                <div className="p-2">
                                  <Row className="align-items-center">
                                    <Col className="col-auto">
                                      <img
                                        style={{ height: 120, width: 100 }}
                                        data-dz-thumbnail=""
                                        height="100"
                                        className="avatar-sm rounded bg-light"
                                        // alt={f.name}
                                        src={f.preview}
                                      />
                                    </Col>
                                    <Col>
                                      <Button
                                        className="btn-icon"
                                        color="danger"
                                        style={{ marginRight: 25 }}
                                        onClick={(e) => this.remove_image(f, i)}
                                      >
                                        {" "}
                                        <span className="btn-icon-label">
                                          <i className="mdi mdi-alpha-x-box-outline mr-2"></i>
                                        </span>{" "}
                                        Remove
                                      </Button>
                                    </Col>
                                  </Row>
                                </div>
                              </Card>
                            );
                          })}
                        </div>
                        <p style={{ color: 'red' }}>[image should be in dimensions 500 * 333 px]</p>
                      </Col>
                    </FormGroup>

                    <p style={{ marginTop: 5, textAlign: "right" }}>
                      [Note: Asterisk (<span style={{ color: 'red' }}>*</span>) denotes the mandatory field.]
                    </p>

                    <FormGroup className="mb-0">
                      <div className="button-items pt-4">
                        <Button
                          color="primary"
                          type="button"
                          className="waves-effect waves-light mr-1"
                          onClick={() => {
                            this.submit();
                          }}
                        >
                          {this.state.edit_enable ? "Update" : "Submit"}{" "}
                          <i className="ri-arrow-right-line align-middle ml-1"></i>
                        </Button>
                      </div>
                    </FormGroup>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col xl={12}>
                <div className="card p-3">
                  <div class="d-flex justify-content-between w-100">
                    <h5>SESSIONS</h5>
                    {this.state.userDetails.sub_admin == true ? null : (
                      <Link
                        className="btn btn-danger mr-2"
                        to={"/sessionUndo/" + this.state.event_id}
                      >
                        Deleted Session
                      </Link>
                    )}
                  </div>
                  <MDBDataTable responsive bordered data={data} />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default FormElements;
