import React, { Component } from "react";
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  FormGroup,
  Label,
  Input,
  CustomInput,
  Button,
  UncontrolledAlert,
} from "reactstrap";
import { Link } from "react-router-dom";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import ReactTags from "react-tag-autocomplete";
import { MDBDataTable } from "mdbreact";
import { reactLocalStorage } from "reactjs-localstorage";
import moment from "moment";
import Dropzone from "react-dropzone";
import HttpClient from "./../../utils/HttpClient";
import Select from "react-select";
import ProgressBar from "@ramonak/react-progress-bar";

// import EcomPreview from "./E-ComPreview/index";
class FormElements extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: "Course Exam - Certificate Test", link: "#" },
        { title: "Add & Manage", link: "#" },
      ],
      customchk: true,
      toggleSwitch: true,
      // page states
      data: [],
      alert: false,
      message: "",
      type: "",
      result: [],
      edit_enable: false,
      edit_item_id: "",
      image_select: false,
      img_url: "",
      userId: "",
      userDetails: {},
      uploaded_images: [],
      categorycourse: [],
      selectedMultiCat: [],
      selectedMultiCatvaue: "",
      selectedMultiFilter: [],
      selectedMultiFiltervalue: "",
      lecturer: [],
      selectedType: [],
      selectedTypevalue: "",

      selectedcourseType: [],
      selectedTypevalue: "",
      director: "",
      desc: "",
      season: "",
      trailer: "",
      showSelect: true,
      submit: false,
      uploading_trailer: false,
      trailerPlaylists: "",
      cat_id: "",
      cast: "",
      progressBarParsent: 0,
      progressBarParsentVideo: 0,
      uploading_movie: false,

      videoUrl: "",
      url: "",

      videoUpload: false,

      priority: "",
      price: 0,
      suggestions: [],
      tags: [],

      //
      course_id: this.props.match.params.id,
      courseData: {},
      examType: [
        {
          label: "Mock Test",
          value: "mockText",
        },
        {
          label: "Certificate Test",
          value: "certificateTest",
        },
      ],
      selectExamType: "",
      name: "",
      questionType: [
        {
          label: "MCQ",
          value: "mcq",
        },
        {
          label: "True / False",
          value: "trueFalse",
        },
      ],
      questions: [
        {
          question: "",
          questionType: "",
          option1: "",
          option2: "",
          option3: "",
          option4: "",
          answer: "",
        },
      ],
    };
  }

  handleType = (selectedType) => {
    console.log(selectedType);
    this.state.selectedTypevalue = selectedType.value;
    this.state.selectedcourseType.push(selectedType);
    this.state.price = 0;
    this.setState({});
    console.log(this.state.selectedType);
  };

  handleMultiCat = (selected) => {
    console.log(selected);
    this.state.selectedMultiCatvaue = selected.value;
    this.state.selectedMultiCat.push(selected);
    this.setState({});
    console.log(this.state.selectedMultiCat);
  };

  handleMultiFilter = (selected) => {
    console.log(selected);
    this.state.selectedMultiFiltervalue = selected.value;
    this.state.selectedMultiFilter.push(selected);
    this.setState({});
    console.log(this.state.selectedMultiFilter);
  };

  componentDidMount = async () => {
    this.checkUser();
    this.fetchCourse();
    this.fetchData();
    // this.fetchDropdown();
    // this.fetchLecturer();
  };

  fetchCourse = async (type) => {
    let result = await HttpClient.requestData("admin/course", "GET");
    if (result && result.status) {
      let tr = result.data.filter((it) => it.id == this.state.course_id);
      if (tr.length > 0) {
        this.setState({ courseData: tr[0] });
        this.state.breadcrumbItems[1].title = tr[0].name;
        this.setState({});
      }
    }
  };

  fetchLecturer = async () => {
    let resultCat = await HttpClient.requestData("admin/lecturer", "GET");
    console.log("CourseCat", resultCat);
    if (resultCat && resultCat.status > 0) {
      resultCat.data.forEach((element, index) => {
        let dom = { label: element.name, value: element.id };
        this.state.lecturer.push(dom);
        this.setState({});
      });
    }
  };

  uploadVideo = async (video, type) => {
    if (type == "Trailer") {
      this.setState({ uploading_trailer: true });
    } else {
      this.setState({ uploading_movie: true });
    }

    let dataSend = { buckate: false };
    let result = await HttpClient.uploadFileRequest(
      "video-upload/video",
      video,
      (data, data1, data2) => {
        console.log("data, data1, data2", data, data1, data2);

        if (type == "Trailer") {
          this.setState({ progressBarParsent: data });
        } else {
          this.setState({ progressBarParsentVideo: data });
        }
      }
    );
    if (result && result.status) {
      if (type == "Trailer") {
        this.setState({
          trailerPlaylists: result.playlists,
          trailer: result.data.url,
          uploading_trailer: false,
        });
      } else {
        this.setState({
          videoUrl: result.playlists,
          url: result.data.url,
          uploading_movie: false,
        });
      }
    }
    console.log("result", result);
  };

  fetchData = async (type) => {
    console.log("call");
    let result = await HttpClient.requestData(
      "admin/course-exam/fetch-all",
      "POST",
      { course_id: Number(this.state.course_id) }
    );
    console.log("call", result);

    if (result && result.status > 0) {
      let data = [];
      let i = 1;
      this.setState({ result: result.data });
      this.state.result.forEach((element, index) => {
        let rows = {
          sl: i,
          name: element.name,
          type:element.examType == "mockText"?"Mock Text":"Certificate Test",
          question: element.questions.length,
          status: (
            <button
              title="Delete"
              className={
                element.status ? "btn btn-success mr-2" : "btn btn-danger mr-2"
              }
              onClick={() => {
                this.status(element, index);
              }}
            >
              {element.status ? "Active" : "Not Active"}
            </button>
          ),
          view: (
            <>
              <Link to={"/#"} className="btn btn-success">
                View Users
                <span className="ml-1"></span>
              </Link>
            </>
          ),
          action: (
            <>
              <button
                title="Delete"
                className="btn btn-danger mr-2"
                onClick={() => {
                  this.delete(element, index);
                }}
              >
                <i className="fa fa-trash" />
              </button>
              <button
                title="Edit"
                className="btn btn-primary"
                onClick={() => {
                  this.edit(element, index);
                }}
              >
                <i className="fa fa-edit" />
              </button>
            </>
          ),
        };
        i++;
        data.push(rows);
      });
      this.setState({
        data: data,
      });
    } else {
      this.setState({
        data: [],
      });
    }
  };

  delete = async (item, index) => {
    let sendData = {
      id: item._id,
    };
    let result = await HttpClient.requestData(
      "admin/course-exam/delete",
      "POST",
      sendData
    );
    if (result && result.status) {
      let index = this.state.result.indexOf(item);
      if (index > -1) {
        this.state.result.splice(index, 1);
        this.setState({
          alert: true,
          message: "Deleted Successfully",
          type: "success",
        });
        setTimeout(() => {
          this.setState({
            alert: false,
            message: "",
            type: "",
          });
        }, 3000);
        this.fetchData(this.state.selectedType);
      }
    }
  };

  edit = async (item, index) => {
    console.log(item);
    this.setState({
      edit_enable: true,
      name: item.name,
      edit_item_id: item._id,
      selectExamType:item.examType,
      questions:item.questions
    });
    // setTimeout(() => {
    //   this.setState({ showSelect: true });
    // }, 200);
    // this.handleMulti(item.category);
  };

  status = async (item, index) => {
    let sendData = {
      id: item._id,
      status: !item.status,
    };
    let result = await HttpClient.requestData(
      "admin/course-exam/status",
      "POST",
      sendData
    );
    if (result && result.status) {
      this.state.result.splice(index, 1);
      this.setState({
        alert: true,
        message: "Status Updated Successfullyy",
        type: "success",
      });
      setTimeout(() => {
        this.setState({
          alert: false,
          message: "",
          type: "",
        });
      }, 3000);
      this.fetchData();
    }
  };

  submit = async () => {
    if (this.state.name != "" && this.state.selectExamType != "") {
      if (this.state.questions.length > 0) {
        this.setState({ loading: true });

        let data = null;
        let result = null;
        if (this.state.edit_enable) {
          data = {
            edit_id: this.state.edit_item_id,
            name: this.state.name,
            examType: this.state.selectExamType,
            questions: this.state.questions,
            
          };
          console.log(data);
          result = await HttpClient.requestData(
            "admin/course-exam/update",
            "POST",
            data
          );
        } else {
          data = {
            name: this.state.name,
            examType: this.state.selectExamType,
            questions: this.state.questions,
            course_id: Number(this.state.course_id),
          };
          result = await HttpClient.requestData(
            "admin/course-exam",
            "POST",
            data
          );
        }

        // return false;

        if (result && result.status) {
          this.setState({
            alert: true,
            message: this.state.edit_enable
              ? "Course Exam Updated Successfully"
              : "Course Exam Added Successfullyy",
            type: "success",
            name: "",
            selectExamType: "",
            questions: [
              {
                question: "",
                questionType: "",
                option1: "",
                option2: "",
                option3: "",
                option4: "",
                answer: "",
              },
            ],
          });
          this.fetchData();
        } else {
          this.setState({
            alert: true,
            message:
              typeof result.error == "string"
                ? result.error
                : result.error[0].msg,
            type: "danger",
          });
        }
      } else {
        this.setState({
          alert: true,
          message: "Please Add Questions.",
          type: "warning",
        });
      }
    } else {
      this.setState({
        alert: true,
        message: "Please Fill Up All Details.",
        type: "warning",
      });
    }
    setTimeout(() => {
      this.setState({
        alert: false,
        message: "",
        type: "",
      });
    }, 3000);
  };

  imageUpload = async (file, index) => {
    // console.log("e", file);

    let dataSend = { buckate: false };

    let result = await HttpClient.newFileUpload(
      "image-upload/movie",
      file,
      dataSend
    );
    console.log("result", result);
    if (result && result.status) {
      this.state.uploaded_images.push({ preview: result.url });
      // if (this.state.images.length - 1 == index) {
      //   setTimeout(() => {
      //     this.setState({ loading: false });
      //     console.log("this.state.uploaded_images", this.state.uploaded_images);
      //   }, 200);
      // }
      this.setState({});
    }
  };

  handleAcceptedFiles = (files) => {
    this.imageUpload(files[0]);
  };

  async remove_image(item, index) {
    this.state.uploaded_images.pop(index);
    this.setState({});
  }

  /**
   * Formats the size
   */
  formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  checkUser = async () => {
    let user = await reactLocalStorage.getObject("userData");
    if (user != null && Object.keys(user).length > 0) {
      this.setState({
        userId: user.id,
        userDetails: user,
      });
    } else {
    }
    // this.fetchData();
  };

  handleChange = async (event) => {
    let filterSub = this.state.subCategoryAll.filter(
      (item) => item.category_id == event.target.value
    );
    this.setState({ cat_id: event.target.value, subCategory: filterSub });
  };

  onDelete(i) {
    // const tags = this.state.tags.slice(0)
    // tags.splice(i, 1)
    // this.setState({ tags })
    // console.log(this.state.tags);
    this.state.tags.splice(i, 1);
    this.setState({ tags: this.state.tags });
    console.log(this.state.tags);
  }

  onAddition(tag) {
    console.log(tag);
    this.state.tags.push(tag);
    this.setState({ tags: this.state.tags });
    console.log(this.state.tags);
  }

  delQuestion = async (item) => {
    let arrIndex = this.state.questions.indexOf(item);
    if (arrIndex > -1) {
      this.state.questions.pop(arrIndex);
      this.setState({});
    }
  };

  validateQuestion = async (index) => {
    let questionObj = {
      question: "",
      questionType: "",
      option1: "",
      option2: "",
      option3: "",
      option4: "",
      answer: "",
    };
    let questionFind = this.state.questions[index];
    if (questionFind) {
      if (questionFind.question == "") {
        this.setState({
          alert: true,
          message: "Please Add Question " + Number(index + 1),
          type: "warning",
        });
        this.hideAlert();
        return;
      }

      if (questionFind.questionType != "mcq") {
        if (questionFind.answer != "") {
          this.state.questions.push(questionObj);
          this.setState({});
        } else {
          this.setState({
            alert: true,
            message: "Please Select Answer Of Question " + Number(index + 1),
            type: "warning",
          });
          this.hideAlert();
          return;
        }
      } else {
        if (
          questionFind.option1 == "" ||
          questionFind.option2 == "" ||
          questionFind.option3 == "" ||
          questionFind.option4 == ""
        ) {
          this.setState({
            alert: true,
            message: "Please Add All Options Of Question" + Number(index + 1),
            type: "warning",
          });
          this.hideAlert();
          return;
        }
        if (
          questionFind.answer == questionFind.option1 ||
          questionFind.answer == questionFind.option2 ||
          questionFind.answer == questionFind.option3 ||
          questionFind.answer == questionFind.option4
        ) {
          this.state.questions.push(questionObj);
          this.setState({});
        } else {
          this.setState({
            alert: true,
            message:
              "Answer munst be in Options Of Question" + Number(index + 1),
            type: "warning",
          });
          this.hideAlert();
          return;
        }
      }
    } else {
    }
  };

  hideAlert = () => {
    setTimeout(() => {
      this.setState({
        alert: false,
        message: "",
        type: "",
      });
    }, 3000);
  };

  render() {
    const data = {
      columns: [
        {
          label: "Sl.",
          field: "sl",
          sort: "asc",
          width: 150,
        },

        {
          label: "Name",
          field: "name",
          sort: "asc",
          width: 270,
        },
        {
          label: "Exam Type",
          field: "type",
          sort: "asc",
          width: 270,
        },
        {
          label: "No. Of Question",
          field: "question",
          sort: "asc",
          width: 270,
        },
        {
          label: "Status",
          field: "status",
          sort: "asc",
          width: 100,
        },
        {
          label: "View",
          field: "view",
          sort: "asc",
          width: 100,
        },
        {
          label: "Action",
          field: "action",
          sort: "asc",
          width: 100,
        },
      ],
      rows: this.state.data,
    };
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title={this.state.breadcrumbItems[0].title}
              breadcrumbItems={this.state.breadcrumbItems}
            />

            <Row>
              {/* <BaseInfo /> */}
              <Col xs={12}>
                <Card>
                  <CardBody>
                    {this.state.alert ? (
                      <UncontrolledAlert
                        color={this.state.type}
                        className="alert-dismissible fade show"
                        role="alert"
                      >
                        {this.state.type == "warning" ? (
                          <i className="mdi mdi-alert-outline mr-2"></i>
                        ) : this.state.type == "success" ? (
                          <i className="mdi mdi-check-all mr-2"></i>
                        ) : this.state.type == "danger" ? (
                          <i className="mdi mdi-block-helper mr-2"></i>
                        ) : null}
                        {this.state.message}
                      </UncontrolledAlert>
                    ) : null}

                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Exam Name
                      </Label>
                      <Col md={10}>
                        <Input
                          className="form-control"
                          type="text"
                          value={this.state.name}
                          id="example-text-input"
                          onChange={(val) => {
                            this.setState({ name: val.target.value });
                          }}
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Exam Type
                      </Label>
                      <Col md={10}>
                        <select
                          className="form-control"
                          onChange={(val) => {
                            this.setState({ selectExamType: val.target.value });
                          }}
                          value={this.state.selectExamType}
                        >
                          <option>Select Exam Type</option>
                          {this.state.examType.map((item, index) => {
                            return (
                              <option value={item.value}>{item.label}</option>
                            );
                          })}
                        </select>
                      </Col>
                    </FormGroup>
                    <hr />
                    {this.state.questions.map((item, index) => {
                      return (
                        <>
                          <FormGroup row>
                            <div className="button-items">
                              <Button
                                color="success"
                                type="button"
                                className="waves-effect waves-light mr-1"
                                onClick={() => {}}
                                disabled={this.state.submit}
                                disabled={true}
                              >
                                {"Question "}
                                {Number(index) + 1}
                                <i className="ri-arrow-right-line align-middle ml-1"></i>
                              </Button>
                            </div>
                          </FormGroup>
                          <FormGroup row>
                            <Label
                              htmlFor="example-search-input"
                              className="col-md-2 col-form-label"
                            >
                              Question
                            </Label>
                            <Col md={10}>
                              <textarea
                                id="question-input1"
                                className="form-control"
                                rows="2"
                                placeholder=""
                                onChange={(val) => {
                                  this.state.questions[index].question =
                                    val.target.value;
                                  this.setState({});
                                }}
                                value={item.question}
                              ></textarea>
                            </Col>
                          </FormGroup>

                          <FormGroup row>
                            <Label
                              htmlFor="example-text-input"
                              className="col-md-2 col-form-label"
                            >
                              Question Type
                            </Label>
                            <Col md={10}>
                              <select
                                className="form-control"
                                onChange={(val) => {
                                  this.state.questions[index].questionType =
                                    val.target.value;
                                  this.setState({});
                                }}
                                value={item.questionType}
                              >
                                <option value="">Select Question Type</option>
                                {this.state.questionType.map((item, index) => {
                                  return (
                                    <option value={item.value}>
                                      {item.label}
                                    </option>
                                  );
                                })}
                              </select>
                            </Col>
                          </FormGroup>
                          {item.questionType == "" ? null : (
                            <>
                              {item.questionType == "trueFalse" ? null : (
                                <>
                                  <FormGroup row>
                                    <Label
                                      htmlFor="example-text-input"
                                      className="col-md-2 col-form-label"
                                    >
                                      Option 1
                                    </Label>
                                    <Col md={4}>
                                      <Input
                                        className="form-control"
                                        type="text"
                                        value={item.option1}
                                        id="example-text-input"
                                        onChange={(val) => {
                                          this.state.questions[index].option1 =
                                            val.target.value;
                                          this.setState({});
                                        }}
                                      />
                                    </Col>
                                    <Label
                                      htmlFor="example-text-input"
                                      className="col-md-2 col-form-label"
                                    >
                                      Option 2
                                    </Label>
                                    <Col md={4}>
                                      <Input
                                        className="form-control"
                                        type="text"
                                        value={item.option2}
                                        id="example-text-input"
                                        onChange={(val) => {
                                          this.state.questions[index].option2 =
                                            val.target.value;
                                          this.setState({});
                                        }}
                                      />
                                    </Col>
                                  </FormGroup>
                                  <FormGroup row>
                                    <Label
                                      htmlFor="example-text-input"
                                      className="col-md-2 col-form-label"
                                    >
                                      Option 3
                                    </Label>
                                    <Col md={4}>
                                      <Input
                                        className="form-control"
                                        type="text"
                                        value={item.option3}
                                        id="example-text-input"
                                        onChange={(val) => {
                                          this.state.questions[index].option3 =
                                            val.target.value;
                                          this.setState({});
                                        }}
                                      />
                                    </Col>
                                    <Label
                                      htmlFor="example-text-input"
                                      className="col-md-2 col-form-label"
                                    >
                                      Option 4
                                    </Label>
                                    <Col md={4}>
                                      <Input
                                        className="form-control"
                                        type="text"
                                        value={item.option4}
                                        id="example-text-input"
                                        onChange={(val) => {
                                          this.state.questions[index].option4 =
                                            val.target.value;
                                          this.setState({});
                                        }}
                                      />
                                    </Col>
                                  </FormGroup>
                                </>
                              )}
                              <FormGroup row>
                                <Label
                                  htmlFor="example-text-input"
                                  className="col-md-2 col-form-label"
                                >
                                  Answer
                                </Label>
                                {item.questionType == "mcq" ? (
                                  <Col md={4}>
                                    <Input
                                      className="form-control"
                                      type="text"
                                      value={item.answer}
                                      id="example-text-input"
                                      onChange={(val) => {
                                        this.state.questions[index].answer =
                                          val.target.value;
                                        this.setState({});
                                      }}
                                    />
                                  </Col>
                                ) : (
                                  <Col md={4}>
                                    <select
                                      className="form-control"
                                      onChange={(val) => {
                                        this.state.questions[index].answer =
                                          val.target.value;
                                        this.setState({});
                                      }}
                                    >
                                      <option value="">Select Answer</option>
                                      <option value={true}>True</option>
                                      <option value={false}>False</option>
                                    </select>
                                  </Col>
                                )}

                                <Col md={6}>
                                  <Row>
                                    {this.state.questions.length ==
                                    Number(index + 1) ? (
                                      <FormGroup>
                                        <div className="button-items">
                                          <Button
                                            color="success"
                                            type="button"
                                            className="waves-effect waves-light mr-1"
                                            onClick={() => {
                                              this.validateQuestion(index);
                                            }}
                                            disabled={this.state.submit}
                                          >
                                            {"Add More"}{" "}
                                            <i className="ri-arrow-right-line align-middle ml-1"></i>
                                          </Button>
                                        </div>
                                      </FormGroup>
                                    ) : null}
                                    {this.state.questions.length == 1 ? (
                                      null
                                    ) : <FormGroup>
                                    <div className="button-items">
                                      <Button
                                        color="danger"
                                        type="button"
                                        className="waves-effect waves-light mr-1"
                                        onClick={() => {
                                          this.delQuestion(item);
                                        }}
                                        disabled={this.state.submit}
                                      >
                                        {"Remove"}{" "}
                                        <i className="ri-arrow-right-line align-middle ml-1"></i>
                                      </Button>
                                    </div>
                                  </FormGroup>}
                                  </Row>
                                </Col>
                              </FormGroup>
                            </>
                          )}
                        </>
                      );
                    })}

                    <FormGroup className="mb-0">
                      <div className="button-items pt-4">
                        <Button
                          color="primary"
                          type="button"
                          className="waves-effect waves-light mr-1"
                          onClick={() => {
                            this.submit();
                          }}
                          disabled={this.state.submit}
                        >
                          {this.state.edit_enable ? "Update" : "Submit"}{" "}
                          <i className="ri-arrow-right-line align-middle ml-1"></i>
                        </Button>
                      </div>
                    </FormGroup>
                  </CardBody>
                </Card>
              </Col>
              <Col xl={4}>
                {/* <div>
                    <EcomPreview />
                  </div> */}
              </Col>
            </Row>
            <Row>
              <Col xl={12}>
                <div className="card p-3">
                  <MDBDataTable responsive bordered data={data} />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default FormElements;
