import React, { Component } from "react";
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  FormGroup,
  Label,
  Input,
  CustomInput,
  Button,
  UncontrolledAlert,
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { MDBDataTable } from "mdbreact";
import { reactLocalStorage } from "reactjs-localstorage";
import moment from "moment";
import HttpClient from "./../../utils/HttpClient";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
class FormElements extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: "FAQ", link: "#" },
        { title: "Manage FAQ", link: "#" },
      ],
      customchk: true,
      toggleSwitch: true,
      // page states
      name: "",
      desc: "",
      duration: "",
      price: "",
      data: [],
      alert: false,
      message: "",
      type: "",
      result: [],
      edit_enable: false,
      edit_item_id: "",
      image_select: false,
      terms: "",
    };
  }

  componentDidMount = async () => {
    this.fetchData();
  };

  fetchData = async () => {
    let result = await HttpClient.requestData(
      "common/faq",
      "GET"
    );
    console.log("result", result);
    if (result && result.status) {
      this.setState({ terms: result.data.row });
    } else {
    }
  };

 
  submit = async () => {
    if (this.state.terms != "") {
      let data = null;
      let result = null;

      data = {
        row: this.state.terms
      };
      result = await HttpClient.requestData("admin/common/faq", "POST", data);

      if (result && result.status) {
        this.setState({
          alert: true,
          message: "Updated successfully",
          type: "success",
        });

        this.fetchData();
      } else {
        this.setState({
          alert: true,
          message: result.error,
          type: "danger",
        });
      }
    } else {
      this.setState({
        alert: true,
        message: "Please Enter Something.",
        type: "warning",
      });
    }
    setTimeout(() => {
      this.setState({
        alert: false,
        message: "",
        type: "",
      });
    }, 3000);
  };

  

  render() {
    const data = {
      columns: [
        {
          label: "Sl.",
          field: "sl",
          sort: "asc",
          width: 150,
        },
        {
          label: "Name",
          field: "name",
          sort: "asc",
          width: 270,
        },
        {
          label: "Category Type",
          field: "category_type",
          sort: "asc",
          width: 270,
        },
        {
          label: "Image",
          field: "img",
          sort: "asc",
          width: 270,
        },
        {
          label: "Action",
          field: "action",
          sort: "asc",
          width: 100,
        },
      ],
      rows: this.state.data,
    };
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title={this.state.breadcrumbItems[0].title}
              breadcrumbItems={this.state.breadcrumbItems}
            />

            <Row>
              <Col xs={12}>
                <Card>
                  <CardBody>
                    {this.state.alert ? (
                      <UncontrolledAlert
                        color={this.state.type}
                        className="alert-dismissible fade show"
                        role="alert"
                      >
                        {this.state.type == "warning" ? (
                          <i className="mdi mdi-alert-outline mr-2"></i>
                        ) : this.state.type == "success" ? (
                          <i className="mdi mdi-check-all mr-2"></i>
                        ) : this.state.type == "danger" ? (
                          <i className="mdi mdi-block-helper mr-2"></i>
                        ) : null}
                        {this.state.message}
                      </UncontrolledAlert>
                    ) : null}
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        FAQ
                      </Label>
                      <Col md={10}>
                        <CKEditor 
                        
                          editor={ClassicEditor}
                          data={this.state.terms}
                          onReady={(editor) => {
                            
                          }}
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            
                          }}
                          onBlur={(event, editor) => {
                            const data = editor.getData();
                            this.setState({ terms: data });
                            console.log("Blur.", editor.getData());
                          }}
                          onFocus={(event, editor) => {
                            
                          }}
                        />
                      </Col>
                    </FormGroup>

                    <FormGroup className="mb-0">
                      <div className="button-items pt-4">
                        <Button
                          color="primary"
                          type="button"
                          className="waves-effect waves-light mr-1"
                          onClick={() => {
                            this.submit();
                          }}
                        >
                          {"Update"}{" "}
                          <i className="ri-arrow-right-line align-middle ml-1"></i>
                        </Button>
                      </div>
                    </FormGroup>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default FormElements;
