import React, { Component } from "react";
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  FormGroup,
  Label,
  Input,
  CustomInput,
  Button,
  UncontrolledAlert,
  InputGroup,
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { MDBDataTable } from "mdbreact";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { reactLocalStorage } from "reactjs-localstorage";
import moment from "moment";
import HttpClient from "../../utils/HttpClient";
import { Link } from "react-router-dom";

class FormElements extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: "Scratch Card List", link: "#" },
        { title: "Manage Scratch Card List", link: "#" },
      ],
      customchk: true,
      toggleSwitch: true,
      // page states
      name: "",
      email: "",
      mobile: "",
      password: "",
      data: [],
      alert: false,
      message: "",
      type: "",
      result: [],
      edit_enable: false,
      edit_item_id: "",
      image_select: false,
      img_url: "",
      userId: "",
      userDetails: {},
      default_date: new Date(),
      details: "",
      link: "",
      price: "",
      total_seats: "",
      vanue:"",
      latitude:"",
      longitude:'',
      image_select:false,
      img_url:""
    };
    
  }

  componentDidMount = async () => {
    this.checkUser();
  };

  fetchData = async () => {
    let user = reactLocalStorage.getObject("userData");
    let data={
      owner_id: user.id,
    };
    console.log("DDD",data);
    let result = await HttpClient.requestData(
      "admin/scrach-card/fetch-all",
      "GET",
      data,
    );
    
    console.log("ScratchCardWinnerList",result);
    if (result && result.status > 0) {
      let data = [];
      let i = 1;
      this.setState({ result: result.data });
      this.state.result.forEach((element, index) => {
        let rows = {
          sl: i,
          scratchName: element.scratchName,
          giftName: element.giftName,
          winningUser: element.winningUser,
          loyaltyPoint: element.loyaltyPoint,
          winningUser: element.winningUser,
          loyaltyPoint: element.loyaltyPoint,
          giftImage: (
            <div>
              <img
                src={element.giftImage}
                alt="images"
                className="rounded avatar-md card-img"
              />
            </div>
          ),
          view: (
            <Link
              className="btn btn-danger mr-2"
              to={"/scratch-view/" + element.id}
            >
              View
            </Link>
          ),
         
          // status: (
          //   <button
          //     title="Delete"
          //     className={
          //       element.userData.status ? "btn btn-success mr-2" : "btn btn-danger mr-2"
          //     }
          //     onClick={() => {
          //       this.status(element, index);
          //     }}
          //   >
          //     {element.userData.status ? "Active" : "Not Active"}
          //   </button>
          // ),
          // action: (
          //   <>
          //     <button
          //       title="View"
          //       className="btn btn-danger mr-2"
          //       onClick={() => {
          //         this.delete(element, index);
          //       }}
          //     >
          //       <i className="fa fa-trash" />
          //     </button>
          //   </>
          // ),
        };
        i++;
        data.push(rows);
      });
      this.setState({
        data: data,
      });
    } else {
      this.setState({
        data: [],
      });
    }
  };
  delete = async (item, index) => {
    let sendData = {
      id: item._id,
    };
    let result = await HttpClient.requestData(
      "app-owner/event/delete",
      "POST",
      sendData
    );
    if (result && result.status) {
      let index = this.state.result.indexOf(item);
      if (index > -1) {
        this.state.result.splice(index, 1);
        this.setState({
          alert: true,
          message: "Deleted Successfully",
          type: "success",
        });
        setTimeout(() => {
          this.setState({
            alert: false,
            message: "",
            type: "",
          });
        }, 3000);
        this.fetchData();
      }
    }
  };
  edit = async (item, index) => {
    this.setState({
      edit_enable: true,
      name: item.name,
      edit_item_id: item._id,
      timing: moment(item.timing).format("dd/mm/YYYY"),
      default_date: new Date(item.timing),
      link: item.link,
      details: item.details,
      vanue:item.vanue,
      latitude:item.latitude,
      longitude:item.longitude,
      image_select:item.img_url == ''?false:true,
      img_url:item.img_url,
      price: item.seat_price,
      total_seats: item.total_seats,
    });
  };
  status = async (item, index) => {
    let sendData = {
      id: item._id,
      status: !item.status,
    };
    let result = await HttpClient.requestData(
      "app-owner/event/status",
      "POST",
      sendData
    );
    if (result && result.status) {
      this.state.result.splice(index, 1);
      this.setState({
        alert: true,
        message: "Status Updated Successfully",
        type: "success",
      });
      setTimeout(() => {
        this.setState({
          alert: false,
          message: "",
          type: "",
        });
      }, 3000);
      this.fetchData();
    }
  };
  
  imageUpload = async (e) => {
    let file = e.target.files[0].type;
    let fileType = file.split("/")[0];
    // fileType = fileType[0];
    console.log("e", file.split("/")[0]);

    if (fileType == "image" || fileType == "video") {
      // return false;
      this.setState({ fileType: fileType });
      let dataSend = { buckate: true, buckate_name: "appIcon" };

      let result = await HttpClient.newFileUpload(
        "image-upload/advertise",
        e.target.files[0],
        dataSend
      );
      console.log("result", result);
      if (result && result.status) {
        this.setState({ img_url: result.url, image_select: true });
      }
    } else {
      this.setState({
        alert: true,
        message: "Please Fill Up All Details.",
        type: "warning",
      });
    }
  };

  checkUser = async () => {
    let user = await reactLocalStorage.getObject("userData");
    if (user != null && Object.keys(user).length > 0) {
      this.setState({
        userId: user.id,
        userDetails: user,
      });
    } else {
    }
    this.fetchData();
  };

  render() {
    const data = {
      columns: [
        {
          label: "Sl.",
          field: "sl",
          sort: "asc",
          width: 150,
        },
        {
          label: "Scratch Card Name",
          field: "scratchName",
          sort: "asc",
          width: 270,
        },
        {
          label: "Gift Name",
          field: "giftName",
          sort: "asc",
          width: 270,
        },
        {
          label: "Winning User",
          field: "winningUser",
          sort: "asc",
          width: 270,
        },
        {
          label: "Loyalty Point",
          field: "loyaltyPoint",
          sort: "asc",
          width: 270,
        },
        {
          label: "Image",
          field: "giftImage",
          sort: "asc",
          width: 270,
        },
        {
          label: "View",
          field: "view",
          sort: "asc",
          width: 100,
        },
      ],
      rows: this.state.data,
    };
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title={this.state.breadcrumbItems[0].title}
              breadcrumbItems={this.state.breadcrumbItems}
            />

            <Row>
              <Col xs={12}>
                
              </Col>
            </Row>
            <Row>
              <Col xl={12}>
                <div className="card p-3">
                  <MDBDataTable responsive bordered data={data} />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default FormElements;


