import React, { Component } from "react";
import QuizPreview from "./QuizPreview/index";
import KycPreview from "./KycPreview/index";
import classnames from "classnames";
import { Link } from "react-router-dom";
import { MDBDataTable } from "mdbreact";

import {
  Container,
  Row,
  Card,
  Col,
  Tooltip,
  TabContent,
  TabPane,
  NavItem,
  NavLink,
  Label,
  Input,
  Form,
  Button,
  FormGroup,
  InputGroup,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  UncontrolledAlert,
} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import KnowCustomer from "./knowCustomer";
import { reactLocalStorage } from "reactjs-localstorage";
import moment from "moment";
import HttpClient from "./../../utils/HttpClient";
import BaseInfo from "../ChooseDesign/baseInfo";

export default class index extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: 1,
      tooltipOpen: false,
      result: [],
      data: [],
      monthDate: new Date(),
      modal_static: false,
      cardLoop: [1, 2, 3, 4],
      question: [
        {
          question: "",
          option1: "",
          option2: "",
          option3: "",
          option4: "",
          answer: "",
        },
      ],
      questionObj: {
        question: "",
        option1: "",
        option2: "",
        option3: "",
        option4: "",
        answer: "",
      },
      quizName: "",
      startDate: new Date(),
      endDate: new Date(),
      userId: "",
      userDetails: {},
      giftCard: [],
      giftCardSelected: [],
      img: {},
      imgUrl: "",
      refreshStatus: false,
      // know your customer
      previewKnow: false,
      edit_enable: false,
      edit_item_id: "",
      previewData: {},
      resultKnow: [],
      dataKnow: [],
      breadcrumbItems: [
        { title: "Quiz ", link: "#" },
        { title: "Add & Manage Quiz", link: "#" },
      ],
      alert: false,
      message: "",
      type: "",
    };
    // this.toggleTab = this.toggleTab.bind(this);
    // this.handleMonthChange = this.handleMonthChange.bind(this);
    this.tog_CardModal = this.tog_CardModal.bind(this);
  }

  toggle = () => {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen,
    });
  };
  // toggleTab(tab) {
  //   if (this.state.activeTab !== tab) {
  //     if (tab >= 1 && tab <= 4) {
  //       this.setState({
  //         activeTab: tab,
  //       });
  //     }
  //   }
  // }
  handleMonthChange(date, type) {
    if (type == "startDate") {
      this.setState({ startDate: date });
    } else {
      this.setState({ endDate: date });
    }
  }
  tog_CardModal() {
    this.setState((prevState) => ({
      modal_static: !prevState.modal_static,
    }));
    // this.removeBodyCss();
  }

  componentDidMount() {
    this.checkUser();
  }

  imageUpload = async (file) => {
    // alert("upload");
    let dataSend = { buckate: true, buckate_name: "appIcon" };
    let result = await HttpClient.newFileUpload(
      "image-upload/appIcon",
      file,
      dataSend
    );

    return result;
  };

  checkUser = async () => {
    let user = await reactLocalStorage.getObject("userData");
    if (user != null && Object.keys(user).length > 0) {
      this.setState({ userId: user.id, userDetails: user });
      this.fetch();
      this.fetchCard();
      this.fetchKnow();
    } else {
    }
  };

  fetchCard = async () => {
    let sendData = { owner_id: this.state.userId };
    let result = await HttpClient.requestData(
      "admin/gift-card/fetch-all",
      "POST",
      sendData
    );
    console.log("result", result);
    if (result && result.status > 0) {
      this.setState({ giftCard: result.data });
    } else {
    }
  };

  fetch = async () => {
    let sendData = {
      owner_id: this.state.userId,
    };
    let result = await HttpClient.requestData(
      "admin/quiz/fetch-all",
      "POST",
      sendData
    );
    console.log("result11", result);
    if (result && result.status > 0) {
      let data = [];
      let i = 1;
      this.setState({
        result: result.data,
        refreshStatus: !this.state.refreshStatus,
      });
      this.state.result.forEach((element, index) => {
        let rows = {
          sl: i,
          image: (<img src={element.image} style={{ height: 80, width: 80, borderRadius: 10 }} />),
          type: element.type,
          quizName: element.quizName,
          startDate: moment(element.startDate).format("L"),
          endDate: moment(element.endDate).format("L"),
          quizparticipant: (
            <>

              <button
                title="Quizwinner"
                className="btn btn-success"
                onClick={() => {
                  window.location.href = "/participant" + element.id;

                }}
              >
                Quiz Participant
                </button>

            </>
          ),
          action: (
            <>
              <button title="Delete" className="btn btn-danger mr-2"
                onClick={() => {
                  this.delete(element);
                }}>
                <i className="fa fa-trash" />
              </button>
              <button title="Edit" className="btn btn-primary" onClick={() => {
                this.edit(element, index);
              }}>
                <i className="fa fa-edit" />
              </button>
            </>
          ),
        };
        i++;
        data.push(rows);
      });
      this.setState({
        data: data,
      });
    } else {
      this.setState({
        data: [],
      });
    }
  };


  delete = async (item, index) => {
    console.log("DeletedItem", item);
    let sendData = {
      id: item._id,
    };

    let result = await HttpClient.requestData(
      "admin/quiz/delete",
      "POST",
      sendData
    );
    console.log("DeleteResultQuizList", result);
    if (result && result.status) {
      let index = this.state.result.indexOf(item);
      if (index > -1) {
        this.state.result.splice(index, 1);
        this.setState({
          alert: true,
          message: "Deleted Successfully",
          type: "success",
        });
        setTimeout(() => {
          this.setState({
            alert: false,
            message: "",
            type: "",
          });
        }, 3000);

        this.fetch();

      }
    }
  };


  edit = async (item, index) => {
    let sdate = moment(item.startDate).format('DD MMM, YYYY');
    let edate = moment(item.endDate).format('DD MMM, YYYY');
    console.log("startDate", sdate)
    console.log("endDate", edate)
    console.log("QuizlistEdit", item);
    this.state.question = item.data;
    this.state.quizName = item.quizName;
    this.state.edit_item_id = item._id;
    this.state.edit_enable = true;
    this.state.imgUrl = item.image;
    this.state.startDate = new Date(sdate);
    this.state.endDate = new Date(edate);
    console.log("startDate", this.state.startDate);
    console.log("endDate", this.state.endDate);

    this.setState({});


    // console.log(moment(item.startDate).format('DD MMM, YYYY'))
    console.log(item.quizName);
    console.log(this.state.edit_item_id);
    console.log(item.image)
  };
  fetchKnow = async () => {
    let sendData = {
      owner_id: this.state.userId,
    };
    let result = await HttpClient.requestData(
      "admin/know-your-customer/fetch-all",
      "POST",
      sendData
    );
    console.log("result", result);
    if (result && result.status > 0) {
      let data = [];
      let i = 1;
      this.setState({
        resultKnow: result.data,
        refreshStatus: !this.state.refreshStatus,
      });
      this.state.resultKnow.forEach((element, index) => {
        let rows = {
          sl: i,
          quizName: element.question,
          action: (
            <>
              <button
                title="Delete"
                className="btn btn-danger mr-2"
                onClick={() => {
                  this.delete(element, index);
                }}
              >
                <i className="fa fa-trash" />
              </button>
              <button title="Edit" className="btn btn-primary">
                <i className="fa fa-edit" />
              </button>
            </>
          ),
        };
        i++;
        data.push(rows);
      });
      this.setState({
        dataKnow: data,
      });
    } else {
      this.setState({
        dataKnow: [],
      });
    }
  };

  // delete = async (item, index) => {
  //   let sendData = {
  //     id: item._id,
  //   };
  //   let result = await HttpClient.requestData(
  //     "admin/know-your-customer/delete",
  //     "POST",
  //     sendData
  //   );
  //   if (result && result.status) {
  //     let index = this.state.resultKnow.indexOf(item);
  //     if (index > -1) {
  //       this.state.resultKnow.splice(index, 1);
  //       this.setState({
  //         alert: true,
  //         message: "Deleted Successfullyy",
  //         type: "success",
  //       });
  //       setTimeout(() => {
  //         this.setState({
  //           alert: false,
  //           message: "",
  //           type: "",
  //         });
  //       }, 3000);
  //       this.fetchKnow();
  //     }
  //   }
  // };

  blankChk = (index) => {
    if (
      this.state.question[index].answer != "" &&
      this.state.question[index].question != "" &&
      this.state.question[index].option1 != "" &&
      this.state.question[index].option2 != "" &&
      this.state.question[index].option3 != "" &&
      this.state.question[index].option4 != ""
    ) {
      return true;
    } else {
      return false;
    }
  };

  optionChk = (index) => {
    if (
      this.state.question[index].answer == this.state.question[index].option1 ||
      this.state.question[index].answer == this.state.question[index].option2 ||
      this.state.question[index].answer == this.state.question[index].option3 ||
      this.state.question[index].answer == this.state.question[index].option4
    ) {
      return true;
    } else {
      return false;
    }
  };

  formValid = async () => {
    if (
      this.state.quizName != "" &&
      this.state.startDate != "" &&
      this.state.endDate != ""
    ) {
      const startDate = moment(this.state.startDate);
      const timeEnd = moment(this.state.endDate);
      const diff = timeEnd.diff(startDate);
      const diffDuration = moment.duration(diff);
      if (diffDuration.days() > 0) {
        let blank = this.blankChk(0);
        // console.log("blank", blank);
        if (blank) {
          let option = this.optionChk(0);
          // console.log("option", option);
          if (option) {
            this.submitFrom()
          } else {
            alert("Answer Must Be In Options");
          }
        } else {
          alert("Please Add Atleast One Question.");
        }
      } else {
        alert("Invalid End Date Details.");
      }
    } else {
      alert("Please Fillup Details. ll");
    }
  };


  submitFrom = async () => {
    let result = null;
    if (this.state.edit_enable == false) {
      console.log("****Submit****");
      let data = {
        data: this.state.question,
        owner_id: this.state.userId,
        type: "Quiz",
        quizName: this.state.quizName,
        image: this.state.imgUrl,
        startDate: this.state.startDate,
        endDate: this.state.endDate,
      };
      console.log(data);

      result = await HttpClient.requestData("admin/quiz", "POST", data);
    }
    else {
      console.log("****Update****");
      let data = {
        edit_id: this.state.edit_item_id,
        data: this.state.question,
        owner_id: this.state.userId,
        type: "Quiz",
        quizName: this.state.quizName,
        image: this.state.imgUrl,
        startDate: this.state.startDate,
        endDate: this.state.endDate,
      };
      console.log("SENDdataforupdate", data);
      result = await HttpClient.requestData("admin/quiz/update", "POST", data);
    }
    console.log(result);
    if (result && result.status) {

      console.log("Quizz", result);


      this.setState({
        alert: true,
        message: this.state.edit_enable
          ? "QuizList Updated Successfully"
          : "QuizList Added Successfully",
        type: "success",
        quizName: "",
        edit_enable: false,
        imgUrl: "",
      });
      this.fetch();
      this.setState({
        question: [
          {
            question: "",
            option1: "",
            option2: "",
            option3: "",
            option4: "",
            answer: "",
          },
        ]



      });
    } else {
      alert(result.error);
    }
    setTimeout(() => {
      this.setState({
        alert: false,
        message: "",
        type: "",
      });
    }, 3000);
  };

  // submitFrom = async () => {
  //   let data = {
  //     data: this.state.question,
  //     owner_id: this.state.userId,
  //     type: "Quiz",
  //     quizName: this.state.quizName,
  //     image: this.state.imgUrl,
  //     startDate: this.state.startDate,
  //     endDate: this.state.endDate,
  //   };
  //   let result = await HttpClient.requestData("admin/quiz", "POST", data);
  //   if (result && result.status) {
  //     this.setState({
  //       alert: true,
  //       message: "Successfully Submited.",
  //       type: "success",
  //   });
  //     this.fetch();
  //     this.setState({
  //       question: [
  //         {
  //           question: "",
  //           option1: "",
  //           option2: "",
  //           option3: "",
  //           option4: "",
  //           answer: "",
  //         },
  //       ],
  //     });
  //   } else {
  //     alert(result.error);
  //   }
  //   setTimeout(() => {
  //     this.setState({
  //         alert: false,
  //         message: "",
  //         type: "",
  //     });
  // }, 3000);
  // };

  preview = () => { };

  render() {
    const data = {
      columns: [
        {
          label: "Sl.",
          field: "sl",
          sort: "asc",
          width: 150,
        },
        {
          label: "Image",
          field: "image",
          sort: "asc",
          width: 270,
        },
        {
          label: "Quiz Name",
          field: "quizName",
          sort: "asc",
          width: 270,
        },
        {
          label: "Type",
          field: "type",
          sort: "asc",
          width: 270,
        },
        {
          label: "Start Date",
          field: "startDate",
          sort: "asc",
          width: 150,
        },
        {
          label: "End Date",
          field: "endDate",
          sort: "asc",
          width: 150,
        },
        {
          label: "Participant",
          field: "quizparticipant",
          sort: "asc",
          width: 150,
        },
        {
          label: "Action",
          field: "action",
          sort: "asc",
          width: 100,
        },
      ],
      rows: this.state.data,
    };

    const dataKnow = {
      columns: [
        {
          label: "Sl.",
          field: "sl",
          sort: "asc",
          width: 150,
        },
        {
          label: "Question",
          field: "quizName",
          sort: "asc",
          width: 270,
        },
        {
          label: "Action",
          field: "action",
          sort: "asc",
          width: 100,
        },
      ],
      rows: this.state.dataKnow,
    };

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title={this.state.breadcrumbItems[0].title}
              breadcrumbItems={this.state.breadcrumbItems}
            />
            <Row>
              {/* <Col xl={12}> */}
              {/* <BaseInfo /> */}
              {/* </Col> */}
              <Col xl={12}>
                <Card>
                  {this.state.alert ? (
                    <UncontrolledAlert
                      color={this.state.type}
                      className="alert-dismissible fade show"
                      role="alert"
                    >
                      {this.state.type == "warning" ? (
                        <i className="mdi mdi-alert-outline mr-2"></i>
                      ) : this.state.type == "success" ? (
                        <i className="mdi mdi-check-all mr-2"></i>
                      ) : this.state.type == "danger" ? (
                        <i className="mdi mdi-block-helper mr-2"></i>
                      ) : null}
                      {this.state.message}
                    </UncontrolledAlert>
                  ) : null}


                  <div>
                    {/* <div>
                    <h3 className="title-editor py-1 text-center">
                      Gamify{" "}
                      <i
                        className="fa fa-info-circle display_tooltip"
                   `     id="TooltipInfo"
                      />
                      <Tooltip
                        placement="top"
                        isOpen={this.state.tooltipOpen}
                        target="TooltipInfo"
                        toggle={this.toggle}
                        autohide={false}
                      >
                        Refresh your mind with our quizes
                      </Tooltip>
                    </h3>
                  </div> */}
                    <div
                      id="basic-pills-wizard"
                      className="twitter-bs-wizard bg-white"
                    >
                      {/* <ul className="twitter-bs-wizard-nav nav nav-pills nav-justified">
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active: this.state.activeTab === 1,
                          })}
                          onClick={() => {
                            this.toggleTab(1);
                          }}
                        >
                          <span className="step-number">01</span>
                          <span className="step-title">Create a Quiz </span>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active: this.state.activeTab === 2,
                          })}
                          onClick={() => {
                            this.toggleTab(2);
                          }}
                        >
                          <span className="step-number">02</span>
                          <span className="step-title">Know Your Customer</span>
                        </NavLink>
                      </NavItem>
                    </ul> */}
                      <TabContent
                        activeTab={this.state.activeTab}
                        className="twitter-bs-wizard-tab-content pt-0"
                      >
                        {/* <TabPane tabId={1}> */}
                        <Form className="card p-3">
                          <h4>Add your question modules</h4>
                          <Row>
                            <Col lg="6">
                              <FormGroup>
                                <Label for="quiz-input1">Quiz Name</Label>
                                <Input
                                  className="form-control"
                                  type="text"
                                  defaultValue=""
                                  id="quiz-input1"
                                  value={this.state.quizName}
                                  placeholder="Enter Quiz Name..."
                                  onChange={(val) => {
                                    this.setState({
                                      quizName: val.target.value,
                                    });
                                  }}
                                />
                              </FormGroup>
                            </Col>
                            <Col lg="3">
                              <FormGroup>
                                <Label>Start Date</Label>
                                <InputGroup>
                                  <DatePicker
                                    selected={this.state.startDate}
                                    className="form-control"
                                    onChange={(e) => {
                                      this.handleMonthChange(e, "startDate");
                                    }}
                                    showMonthDropdown
                                    value={this.state.startDate}
                                  />
                                </InputGroup>
                              </FormGroup>
                            </Col>
                            <Col lg="3">
                              <FormGroup>
                                <Label>Expire Date</Label>
                                <InputGroup>
                                  <DatePicker
                                    selected={this.state.endDate}
                                    className="form-control"
                                    onChange={(e) => {
                                      this.handleMonthChange(e, "endDate");
                                    }}
                                    value={this.state.endDate}
                                    showMonthDropdown
                                  />
                                </InputGroup>
                              </FormGroup>
                            </Col>

                            <Col lg="6">
                              <FormGroup>
                                <Label for="question-input1">Image</Label>
                                <Input
                                  className="form-control"
                                  type="file"
                                  value={""}
                                  id="option-input1"
                                  placeholder="Enter option 1..."
                                  onChange={async (e) => {
                                    this.state.imgUrl = URL.createObjectURL(
                                      e.target.files[0]
                                    );
                                    let result = await this.imageUpload(
                                      e.target.files[0]
                                    );
                                    if (result && result.status) {
                                      this.setState({ imgUrl: result.url });
                                    }
                                  }}
                                />
                              </FormGroup>
                            </Col>
                            <Col lg="6">
                              {this.state.imgUrl ? (
                                <img
                                  className="rounded mr-2"
                                  alt="Quiz Image"
                                  height={100}
                                  src={this.state.imgUrl}
                                />
                              ) : null}
                            </Col>

                            {this.state.question.map((item, index) => {
                              return (
                                <>
                                  <Col lg="12">
                                    <FormGroup>
                                      <Label for="question-input1">
                                        Describe Question
                                      </Label>
                                      <textarea
                                        id="question-input1"
                                        className="form-control"
                                        rows="2"
                                        placeholder="Enter your question here..."
                                        onChange={(val) => {
                                          this.state.question[index].question =
                                            val.target.value;
                                          this.setState({});
                                        }}
                                        value={
                                          this.state.question[index].question
                                        }
                                      ></textarea>
                                    </FormGroup>
                                  </Col>
                                  <Col lg="6">
                                    <FormGroup>
                                      <Label for="option-input1">
                                        Option 1
                                      </Label>
                                      <Input
                                        className="form-control"
                                        type="text"
                                        value={
                                          this.state.question[index].option1
                                        }
                                        id="option-input1"
                                        placeholder="Enter option 1..."
                                        onChange={(val) => {
                                          this.state.question[index].option1 =
                                            val.target.value;
                                          this.setState({});
                                        }}
                                      />
                                    </FormGroup>
                                    <FormGroup>
                                      <Label for="option-input2">
                                        Option 2
                                      </Label>
                                      <Input
                                        className="form-control"
                                        type="text"
                                        value={
                                          this.state.question[index].option2
                                        }
                                        id="option-input2"
                                        placeholder="Enter option 2..."
                                        onChange={(val) => {
                                          this.state.question[index].option2 =
                                            val.target.value;
                                          this.setState({});
                                        }}
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col lg="6">
                                    <FormGroup>
                                      <Label for="option-input3">
                                        Option 3
                                      </Label>
                                      <Input
                                        className="form-control"
                                        type="text"
                                        value={
                                          this.state.question[index].option3
                                        }
                                        id="option-input3"
                                        placeholder="Enter option 3..."
                                        onChange={(val) => {
                                          this.state.question[index].option3 =
                                            val.target.value;
                                          this.setState({});
                                        }}
                                      />
                                    </FormGroup>
                                    <FormGroup>
                                      <Label for="option-input4">
                                        Option 4
                                      </Label>
                                      <Input
                                        className="form-control"
                                        type="text"
                                        value={
                                          this.state.question[index].option4
                                        }
                                        id="option-input4"
                                        placeholder="Enter option 4..."
                                        onChange={(val) => {
                                          this.state.question[index].option4 =
                                            val.target.value;
                                          this.setState({});
                                        }}
                                      />
                                    </FormGroup>
                                  </Col>

                                  <Col lg="6">
                                    <FormGroup>
                                      <Label for="answer-input1">
                                        Correct Answer
                                      </Label>
                                      <Input
                                        className="form-control"
                                        type="text"
                                        id="answer-input1"
                                        placeholder="Enter your answer..."
                                        onChange={(val) => {
                                          this.state.question[index].answer =
                                            val.target.value;
                                          this.setState({});
                                        }}
                                        value={this.state.question[index].answer}
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col lg="6">
                                    <FormGroup className="mb-0">
                                      <div className="button-items pt-4">
                                        {   
                                          this.state.question.length - 1 ==
                                          index ? (
                                          <>
                                            <Button
                                              color="danger"
                                              type="button"
                                              className="waves-effect waves-light mr-1"
                                              onClick={() => {
                                                if (
                                                  this.state.question.length > 1
                                                ) {
                                                  // this.state.question.pop(
                                                  //   index
                                                  // );
                                                  let indexx = this.state.question.indexOf(
                                                    item
                                                  );

                                                  // return;

                                                  if (indexx > -1) {
                                                    this.state.question.splice(
                                                      indexx,
                                                      1
                                                    );
                                                  }
                                                  console.log(
                                                    "after pop",
                                                    this.state.question
                                                  );

                                                  this.setState({});
                                                  console.log(
                                                    "after pop",
                                                    this.state.question
                                                  );
                                                }
                                              }}
                                            >
                                              <i className="ri-close-line align-middle mr-1"></i>{" "}
                                              Delete
                                            </Button>
                                            <Button
                                              color="secondary"
                                              type="button"
                                              className="waves-effect waves-light mr-1"
                                              onClick={() => {
                                                this.setState({});
                                                let blank = this.blankChk(
                                                  index
                                                );
                                                if (blank) {
                                                  let option = this.optionChk(
                                                    index
                                                  );
                                                  let questionObj = {
                                                    question: "",
                                                    option1: "",
                                                    option2: "",
                                                    option3: "",
                                                    option4: "",
                                                    answer: "",
                                                  };
                                                  if (option) {
                                                    this.state.question.push(
                                                      questionObj
                                                    );
                                                    this.setState({});
                                                    console.log(
                                                      "optionindexx",
                                                      this.state.question
                                                    );
                                                  } else {
                                                    alert(
                                                      "Answer Must Be In Options"
                                                    );
                                                  }
                                                } else {
                                                  alert(
                                                    "Quiz Must Not Be Blank"
                                                  );
                                                }
                                              }}
                                            >
                                              Add More{" "}
                                              <i className="ri-add-fill align-middle ml-1"></i>
                                            </Button>
                                          </>
                                        ) : (
                                          <Button
                                            color="danger"
                                            type="button"
                                            className="waves-effect waves-light mr-1"
                                            onClick={() => {
                                              if (
                                                this.state.question.length > 1
                                              ) {
                                                let indexx = this.state.question.indexOf(
                                                  item
                                                );

                                                // return;

                                                if (indexx > -1) {
                                                  this.state.question.splice(
                                                    indexx,
                                                    1
                                                  );
                                                }
                                                this.setState({});
                                                console.log(
                                                  "after pop",
                                                  this.state.question
                                                );
                                              }
                                            }}
                                          >
                                            <i className="ri-close-line align-middle mr-1"></i>{" "}
                                            Delete
                                          </Button>
                                        )}
                                      </div>
                                    </FormGroup>
                                  </Col>
                                </>
                              );
                            })}

                            <Col lg="6">
                              <FormGroup className="mb-0">
                                <div className="button-items pt-4">
                                  <Button
                                    color="primary"
                                    type="button"
                                    className="waves-effect waves-light mr-1"
                                    onClick={() => {
                                      this.formValid();
                                    }}
                                  >
                                    {this.state.edit_enable ? "Update" : "Submit"}{" "}
                                    <i className="ri-arrow-right-line align-middle ml-1"></i>
                                  </Button>
                                </div>
                              </FormGroup>
                            </Col>
                          </Row>
                        </Form>
                        <div className="card px-3 pt-0">
                          {/* <h4>Manage your quizes</h4>
                          <MDBDataTable responsive bordered data={data} /> */}
                        </div>
                        {/* </TabPane>
                      <TabPane tabId={2}> */}
                        {/* <KnowCustomer
                          previewKnow={this.state.previewKnow}
                          refreshStatus={!this.state.refreshStatus}
                          previewBtn={(val) => {
                            console.log("val", val);
                            this.setState({
                              previewData: val,
                            });
                            setTimeout(() => {
                              this.setState({
                                previewKnow: true,
                                refreshStatus: !this.state.refreshStatus,
                              });
                            }, 200);
                          }}
                          calbackKnow={() => {
                            this.fetchKnow();
                          }}
                        /> */}
                        {/* </TabPane> */}
                      </TabContent>
                    </div>
                    <Modal
                      isOpen={this.state.modal_static}
                      toggle={this.tog_CardModal}
                      backdrop="static"
                    >
                      <ModalHeader
                        toggle={() => this.setState({ modal_static: false })}
                        className="bg-light"
                      >
                        Choose card
                    </ModalHeader>
                      <ModalBody>
                        <h5 className="mb-3">Select card for your quiz</h5>
                        {this.state.giftCard.map((item, index) => {
                          return (
                            <div
                              className="custom-control custom-checkbox mb-3"
                              key={index}
                            >
                              <Input
                                type="checkbox"
                                className="custom-control-input"
                                id={"CustomCheck1" + index}
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    let check = this.state.giftCardSelected.filter(
                                      (it) => it.id == item.id
                                    );
                                    if (check.length == 0) {
                                      this.state.giftCardSelected.push(item);
                                      this.setState({});
                                    } else {
                                    }
                                  } else {
                                    let ind = this.state.giftCardSelected.indexOf(
                                      item
                                    );
                                    if (ind > -1) {
                                      this.state.giftCardSelected.splice(ind, 1);
                                      this.setState({});
                                    }
                                  }
                                }}
                              />
                              <Label
                                className="custom-control-label"
                                htmlFor={"CustomCheck1" + index}
                              >
                                Card : {item.loyaltyNumber + " - " + item.type}
                              </Label>
                            </div>
                          );
                        })}
                        <ModalFooter>
                          <Button
                            type="button"
                            color="light"
                            onClick={() => this.setState({ modal_static: false })}
                          >
                            Close
                        </Button>
                          <Button
                            type="button"
                            color="primary"
                            onClick={() => {
                              this.submitFrom();
                            }}
                          >
                            Save
                        </Button>
                        </ModalFooter>
                      </ModalBody>
                    </Modal>
                  </div>
                </Card>
              </Col>
              {/* <Col xl={4}>
                <div>
                  <QuizPreview
                    quizData={this.state.result}
                    refreshStatus={this.state.refreshStatus}
                    userDetails={this.state.userDetails}
                  />
                </div>
              </Col> */}
            </Row>
            <Row>
              <Col xl={12}>
                <div className="card p-3">
                  {this.state.activeTab == 1 ? (
                    <MDBDataTable responsive bordered data={data} />
                  ) : (
                    <MDBDataTable responsive bordered data={dataKnow} />
                  )}
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
