export const CHECK_LOGIN = 'check_login';
export const LOGIN_USER_SUCCESSFUL = 'login_user_successfull';

export const LOGOUT_USER = 'LOGOUT_USER';
export const LOGOUT_USER_SUCCESS = 'LOGOUT_USER_SUCCESS';

export const API_ERROR = 'api_failed';

export const SET_USER = 'SET_USER';
export const REMOVE_USER = 'REMOVE_USER';
