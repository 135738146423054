import React from "react";
import { Redirect } from "react-router-dom";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";
import AuthLockScreen from "../pages/Authentication/AuthLockScreen";

// Dashboard
import Dashboard from "../pages/Dashboard/index";
import StartApp from "../pages/ChooseDesign/startApp";
import ChooseDesign from "../pages/ChooseDesign/index";
import CreateApp from "../pages/CreateApp/index";
import WelcomeScreen from "../pages/ChooseDesign/welcomeScreen";
import Quizlist from "../pages/QuizList/index";
import KnowYourCustomer from "../pages/QuizList/knowCustomer";

import Monitize from "../pages/Monitize/index";
import Communicate from "../pages/Communicate/index";
import Forums from "../pages/Forums/index";
import Showroom from "../pages/Showroom/index";
import Directory from "../pages/Directory/index";

//User
import UserList from "./../pages/UserList/index";
import UserListUndo from "./../pages/UserList/userListUndo";
import DateWiseUser from "./../pages/UserList/DateWiseUser";
import SocialPost from "./../pages/SocialPost/index";



// Subscrption
import Subscrption from "../pages/Subscription/index";

import course from "../pages/course/exam";
import Exam from "../pages/Exam/index";

// Application
import App from "../pages/App/index";

import Category from "./../pages/Category/index";
import SubCategory from "./../pages/Category/subCategory";
import CategoryUndo from "./../pages/Category/catUndo";
import SubCategoryUndo from "./../pages/Category/subCatUndo";

// Products
import Products from "./../pages/Products";

//jd3 category
import Jdcategory from "./../pages/Jdcategory/index";
import CategoryUndoOtt from "./../pages/Jdcategory/categoryUndo";
import Genre from "./../pages/Jdcategory/genre";
import GenreUndo from "./../pages/Jdcategory/genreUndo";
// import Product from "./../pages/Product/index";
import ProductReview from "./../pages/Product/review";
import ProductAdd from "./../pages/Product/create";
import ProductUndo from "./../pages/Product/productUndo";
//Add banner

import Addbanner from "./../pages/Addbanner";
import AddbannerUndo from "./../pages/Addbanner/bannerUndo";

import ScratchCard from "./../pages/ScratchCard/index";
import scratchWinner from "../pages/ScratchCard/scratchWinner";
import Viewpage from "./../pages/ScratchCard/viewpage";

//Loyalty
import LoyaltyPoint from "./../pages/Loyalty/index";
import LoyaltyDetails from "./../pages/Loyalty/loyaltyDetails";
import Viewloyalty from "../pages/Loyalty/viewloyalty";
//Add banner

import AddSubscription from "./../pages/subscriptionplan";

// Events

import Events from "./../pages/Event";
import Sessions from "./../pages/Event/sessions";
import Speakers from "./../pages/Event/speekeers";
import Sponsors from "./../pages/Event/sponsers";
import EventUndo from "./../pages/Event/eventUndo";
import SessionUndo from "./../pages/Event/sessionUndo";
import SpeakerUndo from "./../pages/Event/speakerUndo";
import SponserUndo from "./../pages/Event/sponserUndo";
import DateWiseEvents from "./../pages/Event/DateWiseEvents";
import SpeakerWiseEvents from "./../pages/Event/SpeakerWiseEvents";
import ViewEvent from "./../pages/Event/viewEvent";
import EventHistory from "./../pages/Event/eventHistory";
import EventJoiner from "../pages/Event/eventJoiner";


//course
import Course from "./../pages/course";
import Lecturer from "./../pages/course/lecturer";
import AddCourse from "./../pages/course/addcourse";
import Lesson from "./../pages/course/lesson";
import VideoUpload from "./../pages/course/video";
import CourseUndo from "./../pages/course/courseUndo";
import LessonUndo from "./../pages/course/lessonUndo";
import LectureUndo from "./../pages/course/lectureUndo";
import IndexUndo from "./../pages/course/indexUndo";

//Podcast

import PodcastType from "./../pages/Podcast/type";
import PodcastCate from "./../pages/Podcast/categories";
import PodcastGenre from "./../pages/Podcast/genre";
import Podcast from "./../pages/Podcast/podcast";
import PodcastBaner from "./../pages/Podcast/banner";
import PodcastEpisode from "./../pages/Podcast/episode";
import PodcastUndo from "./../pages/Podcast/podcastUndo";
import EpiUndo from "./../pages/Podcast/epiUndo";
import Undotype from "./../pages/Podcast/undoType";
import Undogenre from "./../pages/Podcast/undoGenre";
import Undocategory from "./../pages/Podcast/undoCategory";
import UndoBanner from "./../pages/Podcast/bannerUndo";


// Subadmin

import Subadmin from "./../pages/Subadmin/index";

// Terms

import Terms from "./../pages/About/trems";
import About from "./../pages/About/about";
import Notification from "./../pages/Notification";
import FAQ from "./../pages/About/faq";
// Order

import Order from "./../pages/Order";
import DateWiseOrder from "./../pages/Order/DateWiseOrder";

// Advertisement

import Advertisement from "./../pages/Advertise";
import AdvertisementUndo from "./../pages/Advertise/indexUndo";


// Message

import UserChat from "./../pages/Message/user";
import ApponwerChat from "./../pages/Message/appowner";

// Profile

import Profile from "./../pages/Profile/index";

import Coaching from "./../pages/Coaching/index";
import CoachingUndo from "./../pages/Coaching/coachingUndo";
import Mentor from "./../pages/Mentor/index";
import MentorUndo from "./../pages/Mentor/mentorUndo";
// import ProgDetails from "./../pages/ProgDetails/index";

// Timeline

import Timeline from "./../pages/Timeline";

import sharePanel from "./../pages/Timeline/share";
import requestPanel from "./../pages/Timeline/request";
import Faq from "./../pages/Support&Faq/faq";

import Banner from "./../pages/Baner/index";

import News from "./../pages/News/index";
import NewsUndo from "./../pages/News/addnewsundo";
import NewsCategory from "./../pages/News/category";
import NewsCategoryUndo from "./../pages/News/categoryUndo";
import Baner_news from "../pages/News/baner_news";
import NewsReview from "../pages/News/review";

import Video from "./../pages/Video/index";
import Series from "./../pages/Series/index";
import Episodes from "./../pages/Series/episodes";
import EpisodesUndo from "./../pages/Series/episodeUndo";
import BannerUndoOTT from "./../pages/Baner/bannerUndo";

import Tv from "./../pages/Tv/index";
import Training from "./../pages/jd3Event/index";

import AllVideos from "./../pages/Series/allTypes";
import AddvideoUndo from "./../pages/Series/addVideoUndo";
import VideoWiseVideo from "./../pages/Series/VideoWiseVideo";


import ManageTypes from "./../pages/Jdcategory/type";
import TypesUndo from "./../pages/Jdcategory/typeUndo";

import Users from "./../pages/User";
import ScratchWinner from "./../pages/ScratchWinnerList";

// Quiz Participant
import Participant from "./../pages/QuizParticipant";

import UserSubscription from "./../pages/User/subscription";

// Pages Calendar
// import Calendar from "../pages/Calendar/Calendar";

// Pages Component
// import Chat from "../pages/Chat/Chat";

//Ecommerce Pages
// import Products from "../pages/Ecommerce/Products";
// import ProductDetail from "../pages/Ecommerce/ProductDetail";
// import Orders from "../pages/Ecommerce/Orders";
// import Customers from "../pages/Ecommerce/Customers";
// import Cart from "../pages/Ecommerce/Cart";
// import CheckOut from "../pages/Ecommerce/CheckOut";
// import Shops from "../pages/Ecommerce/Shops";
// import AddProduct from "../pages/Ecommerce/AddProduct";

//Email
// import EmailInbox from "../pages/Email/email-inbox";
// import EmailRead from "../pages/Email/email-read";

// Charts
// import ChartApex from "../pages/Charts/Apexcharts";
// import ChartjsChart from "../pages/Charts/ChartjsChart";
// import SparklineChart from "../pages/Charts/SparklineChart";
// import ChartsKnob from "../pages/Charts/charts-knob";

// Maps
// import MapsGoogle from "../pages/Maps/MapsGoogle";
// import MapsVector from "../pages/Maps/MapsVector";

//Icons
// import RemixIcons from "../pages/Icons/RemixIcons";
// import MaterialDesign from "../pages/Icons/MaterialDesign";
// import DripiIcons from "../pages/Icons/DripiIcons";
// import FontAwesome from "../pages/Icons/FontAwesome";

//Utility
// import StarterPage from "../pages/Utility/StarterPage";
// import Maintenance from "../pages/Utility/Maintenance";
// import CommingSoon from "../pages/Utility/CommingSoon";
// import Timeline from "../pages/Utility/Timeline";
// import FAQs from "../pages/Utility/FAQs";
// import Pricing from "../pages/Utility/Pricing";
// import Error404 from "../pages/Utility/Error404";
// import Error500 from "../pages/Utility/Error500";

// Forms
import FormElements from "../pages/Forms/FormElements";
import FormAdvanced from "../pages/Forms/FormAdvanced";
import FormEditors from "../pages/Forms/FormEditors";
import FormValidations from "../pages/Forms/FormValidations";
import FormMask from "../pages/Forms/FormMask";
import FormUpload from "../pages/Forms/FormUpload";
import FormWizard from "../pages/Forms/FormWizard";
// import FormXeditable from "../pages/Forms/FormXeditable";

//Ui
// import UiAlert from "../pages/Ui/UiAlert";
// import UiButtons from "../pages/Ui/UiButtons";
import UiCards from "../pages/Ui/UiCards";
// import UiCarousel from "../pages/Ui/UiCarousel";
// import UiDropdown from "../pages/Ui/UiDropdown";
import UiGeneral from "../pages/Ui/UiGeneral";
// import UiGrid from "../pages/Ui/UiGrid";
// import UiImages from "../pages/Ui/UiImages";
// import UiLightbox from "../pages/Ui/UiLightbox";
// import UiModal from "../pages/Ui/UiModal";
// import UiProgressbar from "../pages/Ui/UiProgressbar";
// import UiSweetAlert from "../pages/Ui/UiSweetAlert";
// import UiTabsAccordions from "../pages/Ui/UiTabsAccordions";
// import UiTypography from "../pages/Ui/UiTypography";
// import UiVideo from "../pages/Ui/UiVideo";
// import UiSessionTimeout from "../pages/Ui/UiSessionTimeout";
// import UiRating from "../pages/Ui/UiRating";
// import UiRangeSlider from "../pages/Ui/UiRangeSlider";
// import UiNotifications from "../pages/Ui/ui-notifications";
// import UIRoundSlider from "../pages/Ui/UIRoundSlider";

//Tables
// import BasicTables from "../pages/Tables/BasicTables";
// import DatatableTables from "../pages/Tables/DatatableTables";
// import ResponsiveTables from "../pages/Tables/ResponsiveTables";
// import EditableTables from "../pages/Tables/EditableTables";

// Inner Authentication
// import Login1 from "../pages/AuthenticationInner/Login";
// import Register1 from "../pages/AuthenticationInner/Register";
// import ForgetPwd1 from "../pages/AuthenticationInner/ForgetPassword";

//Kanban Board
// import KanbanBoard from "../pages/KanbanBoard/index";

const authProtectedRoutes = [
  //Kanban Board
  // { path: "/apps-kanban-board", component : KanbanBoard },

  // Tables
  // { path: "/tables-basic", component: BasicTables },
  // { path: "/tables-datatable", component: DatatableTables },
  // { path: "/tables-responsive", component: ResponsiveTables },
  // { path: "/tables-editable", component: EditableTables },

  // Ui
  // { path: "/ui-alerts", component: UiAlert },
  // { path: "/ui-buttons", component: UiButtons },
  { path: "/ui-cards", component: UiCards },
  // { path: "/ui-carousel", component: UiCarousel },
  // { path: "/ui-dropdowns", component: UiDropdown },
  { path: "/ui-general", component: UiGeneral },
  // { path: "/ui-grid", component: UiGrid },
  // { path: "/ui-images", component: UiImages },
  // { path: "/ui-lightbox", component: UiLightbox },
  // { path: "/ui-modals", component: UiModal },
  // { path: "/ui-progressbars", component: UiProgressbar },
  // { path: "/ui-sweet-alert", component: UiSweetAlert },
  // { path: "/ui-tabs-accordions", component: UiTabsAccordions },
  // { path: "/ui-typography", component: UiTypography },
  // { path: "/ui-video", component: UiVideo },
  // { path: "/ui-session-timeout", component: UiSessionTimeout },
  // { path: "/ui-rating", component: UiRating },
  // { path: "/ui-rangeslider", component: UiRangeSlider },
  // { path: "/ui-notifications", component: UiNotifications },
  // { path : "/ui-roundslider", component : UIRoundSlider },

  // Forms
  { path: "/form-elements", component: FormElements },
  { path: "/form-advanced", component: FormAdvanced },
  { path: "/form-editors", component: FormEditors },
  { path: "/form-mask", component: FormMask },
  { path: "/form-uploads", component: FormUpload },
  { path: "/form-wizard", component: FormWizard },
  { path: "/form-validation", component: FormValidations },
  // { path: "/form-xeditable", component: FormXeditable },

  //Utility
  // { path: "/pages-starter", component: StarterPage },
  // { path: "/pages-timeline", component: Timeline },
  // { path: "/pages-faqs", component: FAQs },
  // { path: "/pages-pricing", component: Pricing },

  //Icons
  // { path: "/icons-remix", component: RemixIcons },
  // { path: "/icons-materialdesign", component: MaterialDesign },
  // { path: "/icons-dripicons", component: DripiIcons },
  // { path: "/icons-fontawesome", component: FontAwesome },

  // Maps
  // { path: "/maps-google", component: MapsGoogle },
  // { path: "/maps-vector", component: MapsVector },

  //Charts
  // { path: "/charts-apex", component: ChartApex },
  // { path: "/charts-chartjs", component: ChartjsChart },
  // { path: "/charts-sparkline", component: SparklineChart },
  // { path: "/charts-knob", component: ChartsKnob },

  //Email
  // { path: "/email-inbox", component: EmailInbox },
  // { path: "/email-read", component: EmailRead },

  //Ecommerce
  // { path: "/ecommerce-products", component: Products },
  // { path: "/ecommerce-product-detail", component: ProductDetail },
  // { path: "/ecommerce-orders", component: Orders },
  // { path: "/ecommerce-customers", component: Customers },
  // { path: "/ecommerce-cart", component: Cart },
  // { path: "/ecommerce-checkout", component: CheckOut },
  // { path: "/ecommerce-shops", component: Shops },
  // { path: "/ecommerce-add-product", component: AddProduct },

  //chat
  // { path: "/apps-chat", component: Chat },

  //calendar
  // { path: "/calendar", component: Calendar },

  { path: "/dashboard", component: Dashboard },
  { path: "/proposed-showroom", component: Showroom },
  { path: "/published-showroom", component: Showroom },
  { path: "/startApp", component: StartApp },
  { path: "/choose-design", component: ChooseDesign },
  { path: "/create-app", component: CreateApp },
  { path: "/welcome-screen", component: WelcomeScreen },
  { path: "/communicate", component: Communicate },
  { path: "/monitize", component: Monitize },
  { path: "/know-your-customer", component: KnowYourCustomer },
  { path: "/quizlist", component: Quizlist },
  { path: "/forum", component: Forums },

  { path: "/directory", component: Directory },
  // subs
  { path: "/subscrption", component: Subscrption },
  // app
  { path: "/application", component: App },

  // product
  { path: "/category", component: Category },
  { path: "/category-undo", component: CategoryUndo },
  { path: "/sub-category", component: SubCategory },
  { path: "/sub-category-undo", component: SubCategoryUndo },
  { path: "/jd3category", component: Jdcategory },
  { path: "/category-ott-undo", component: CategoryUndoOtt },
  { path: "/genre", component: Genre },
  { path: "/genre-undo", component: GenreUndo },
  // { path: "/product", component: Product },
  { path: "/review/:id", component: ProductReview },
  { path: "/product-add", component: ProductAdd },
  { path: "/product-undo", component: ProductUndo },
  { path: "/add-banner", component: Addbanner },
  { path: "/add-banner-undo", component: AddbannerUndo },

  { path: "/exam", component: course },
  { path: "/exam-add", component: Exam },

  { path: "/news", component: News },
  { path: "/news-undo", component: NewsUndo },
  { path: "/news-category", component: NewsCategory },
  { path: "/news-category-undo", component: NewsCategoryUndo },
  { path: "/baner_news", component: Baner_news },
  { path: "/news-review/:id", component: NewsReview },

  //User
  { path: "/userlist", component: UserList },
  { path: "/userlist-undo", component: UserListUndo },
  { path: "/Date-wise-user", component: DateWiseUser },
  { path: "/socialPost", component: SocialPost },

    //Podcast
    { path: "/podtype", component: PodcastType },
    { path: "/podCategories", component: PodcastCate },
    { path: "/podgenre", component: PodcastGenre },
    { path: "/podcast", component: Podcast },
    { path: "/podcastbaner", component: PodcastBaner },
    { path: "/podcastepisode/:id", component: PodcastEpisode },
    { path: "/podcast-undo", component: PodcastUndo },
    { path: "/epi-undo/:id", component: EpiUndo },
    { path: "/undoType", component: Undotype },
    { path: "/undoGenre", component: Undogenre },
    { path: "/undoCategory", component: Undocategory },
    { path: "/undo-Banner", component: UndoBanner },


  // Subadmin 
  { path: "/sub-admin", component: Subadmin },

  //add subscription plan
  { path: "/subscription-plan", component: AddSubscription },

  // Events
  { path: "/events", component: Events },
  { path: "/events-session/:id", component: Sessions },
  { path: "/session-speakers/:id", component: Speakers },
  { path: "/session-sponsors/:id", component: Sponsors },
  { path: "/eventUndo", component: EventUndo },
  { path: "/sessionUndo/:id", component: SessionUndo },
  { path: "/speakerUndo/:id", component: SpeakerUndo },
  { path: "/sponserUndo/:id", component: SponserUndo },
  { path: "/Date-wise-events", component: DateWiseEvents },
  { path: "/Speaker-wise-events", component: SpeakerWiseEvents },
  { path: "/event-history/:id", component: ViewEvent },
  { path: "/eventHistory", component: EventHistory },
  { path: "/event-joiner/:id", component: EventJoiner },

  // Terms
  { path: "/terms-and-conditions", component: Terms },
  { path: "/about-us", component: About },
  { path: "/notification", component: Notification },
  { path: "/faq", component: FAQ },

  { path: "/scratchCard", component: ScratchCard },
  { path: "/scratch-winner", component: scratchWinner },
  { path: "/scratch-view/:id", component: Viewpage },

  //Loyalty
  { path: "/loyalty", component: LoyaltyPoint },
  { path: "/loyaltydetails", component: LoyaltyDetails },
  { path: "/viewloyalty/:id", component: Viewloyalty },

  // Order
  { path: "/order", component: Order },
  { path: "/Date-wise-order", component: DateWiseOrder },


  // Products
  { path: "/products:id", component: Products },


  // Advertise
  { path: "/advertisement", component: Advertisement },
  { path: "/advertisementUndo", component: AdvertisementUndo },


  // share panel

  { path: "/share-panel", component: sharePanel },
  { path: "/request-panel", component: requestPanel },
  // Timeline
  { path: "/timeline", component: Timeline },

  // messahe
  { path: "/user-chat", component: UserChat },
  { path: "/appowner-chat", component: ApponwerChat },

  // Profile
  { path: "/faq", component: Faq },
  { path: "/profile", component: Profile },


  // Banner

  { path: "/banner", component: Banner },

  { path: "/coaching", component: Coaching },
  { path: "/coaching-undo", component: CoachingUndo },
  { path: "/coaching-mentor/:id", component: Mentor },
  { path: "/coaching-mentor-undo/:id", component: MentorUndo },
  //  { path: "/coaching-ProgramDetails/:id", component: ProgDetails },

  //course
  { path: "/course-category", component: Course },
  { path: "/lecturer", component: Lecturer },
  { path: "/course", component: AddCourse },
  { path: "/lesson/:id", component: Lesson },
  { path: "/video/:id", component: VideoUpload },
  { path: "/courseUndo", component: CourseUndo },
  { path: "/lessonUndo/:id", component: LessonUndo },
  { path: "/lectureUndo", component: LectureUndo },
  { path: "/indexUndo", component: IndexUndo },



  // ScratchCardWinnerList
  { path: "/scratchwin", component: ScratchWinner },

  // Quiz Participant
  { path: "/participant:id", component: Participant },

  // Videos

  { path: "/movies", component: Video },

  { path: "/series", component: Series },
  { path: "/episodes/:id", component: Episodes },
  { path: "/episodes-undo/:id", component: EpisodesUndo },
  { path: "/Banner-undo-ott", component: BannerUndoOTT },

  { path: "/tv-shows", component: Tv },
  { path: "/training", component: Training },
  { path: "/videos", component: AllVideos },
  { path: "/videos-undo", component: AddvideoUndo },
  { path: "/video-wise-video", component: VideoWiseVideo },

  { path: "/types", component: ManageTypes },
  { path: "/types-undo", component: TypesUndo },
  // Users

  { path: "/users", component: Users },
  { path: "/user-subscription", component: UserSubscription },

  // this route should be at the end of all other routes
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
];

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },
  { path: "/auth-lock-screen", component: AuthLockScreen },

  // Authentication Inner
  // { path: "/auth-login", component: Login1 },
  // { path: "/auth-register", component: Register1 },
  // { path: "/auth-recoverpw", component: ForgetPwd1 },

  // { path: "/pages-maintenance", component: Maintenance },
  // { path: "/pages-comingsoon", component: CommingSoon },
  // { path: "/pages-404", component: Error404 },
  // { path: "/pages-500", component: Error500 },
];

export { authProtectedRoutes, publicRoutes };
