import React, { Component } from "react";
import {
    Col,
    Row,
    Container,
    UncontrolledAlert,
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { MDBDataTable } from "mdbreact";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import HttpClient from "./../../utils/HttpClient";
class FormElements extends Component {
    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems: [
                { title: "Deleted News Categories", link: "#" },
                { title: "Manage News Categories", link: "#" },
            ],
            customchk: true,
            toggleSwitch: true,
            // page states
            name: "",
            email: "",
            mobile: "",
            data: [],
            alert: false,
            message: "",
            type: "",
            result: [],

        };

    }

    componentDidMount = async () => {
        this.fetchData();
    };



    fetchData = async () => {
        let data = {};

        console.log("xxx", data)
        let result = await HttpClient.requestData(
            "admin/news/dell-news",
            "GET",
            data,

        );


        console.log("FetchAllData", result);

        if (result && result.status > 0) {
            let data = [];
            let i = 1;

            this.setState({ result: result.data });

            this.state.result && this.state.result.forEach((element, index) => {

                let rows = {
                    sl: i,
                    username: element.title,
                    author: element.author,
                    date: moment(element.created_on).format("L"),
                   
                    undo: (
                        <button
                            title="Undo"
                            className="btn btn-danger mr-2"
                            onClick={() => {
                                this.undo(element, index)
                            }}
                        >
                            <i class="fa fa-undo" />
                        </button>
                    ),
                };
                i++;
                data.push(rows);
            });
            this.setState({
                data: data,
            });
        } else {
            this.setState({
                data: [],
            });
        }
    };


    undo = async (item, index) => {
        let sendData = {
            id: item._id,
            cat_id: item.cat_id,
        };
        let result = await HttpClient.requestData(
            "admin/news/undo-delete",
            "POST",
            sendData
        );
        if (result && result.status == true) {
            let index = this.state.result.indexOf(item);
            if (index > -1) {
                this.state.result.splice(index, 1);
                this.setState({
                    alert: true,
                    message: "Undo Successfully",
                    type: "success",
                });
                setTimeout(() => {
                    this.setState({
                        alert: false,
                        message: "",
                        type: "",
                    });
                }, 3000);
                this.fetchData();
            }
        } else {
            this.setState({
              alert: true,
              message: "Sorry!! Category for this News is deleted. Kindly undo  the deleted Category first to use this News again",
              type: "warning",
            });
          }
    };



    render() {
        const data = {
            columns: [
                {
                    label: "Sl.",
                    field: "sl",
                    sort: "asc",
                    width: 150,
                },
                {
                    label: "Name",
                    field: "username",
                    sort: "asc",
                    width: 150,
                },
                {
                    label: "Author",
                    field: "author",
                    sort: "asc",
                    width: 270,
                },
                {
                    label: "Date",
                    field: "date",
                    sort: "asc",
                    width: 270,
                },

                {
                    label: "Undo",
                    field: "undo",
                    sort: "asc",
                    width: 270,
                },
            ],
            rows: this.state.data,
        };
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs
                            title={this.state.breadcrumbItems[0].title}
                            breadcrumbItems={this.state.breadcrumbItems}
                        />

                        <Row>
                            <Col xs={12}>
                                {this.state.alert ? (
                                    <UncontrolledAlert
                                        color={this.state.type}
                                        className="alert-dismissible fade show"
                                        role="alert"
                                    >
                                        {this.state.type == "warning" ? (
                                            <i className="mdi mdi-alert-outline mr-2"></i>
                                        ) : this.state.type == "success" ? (
                                            <i className="mdi mdi-check-all mr-2"></i>
                                        ) : this.state.type == "danger" ? (
                                            <i className="mdi mdi-block-helper mr-2"></i>
                                        ) : null}
                                        {this.state.message}
                                    </UncontrolledAlert>
                                ) : null}

                            </Col>
                        </Row>
                        <Row>
                            <Col xl={12}>
                                <div className="card p-3">
                                    <MDBDataTable responsive bordered data={data} />
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default FormElements;
