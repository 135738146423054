import React, { Component } from "react";
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  FormGroup,
  Label,
  Input,
  CustomInput,
  Button,
  UncontrolledAlert,
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { MDBDataTable } from "mdbreact";
import { reactLocalStorage } from "reactjs-localstorage";
import moment from "moment";
import HttpClient from "./../../utils/HttpClient";
import ProgressBar from "@ramonak/react-progress-bar";
import { Link } from "react-router-dom";

class FormElements extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: "Lesson", link: "#" },
        { title: "Add & Manage Lesson", link: "#" },
      ],
      customchk: true,
      toggleSwitch: true,
      // page states
      name: "",
      desc: "",
      time: "",
      course_id: this.props.match.params.id,
      data: [],
      alert: false,
      message: "",
      type: "",
      result: [],
      selectedvideoArr: [],
      edit_enable: false,
      edit_item_id: "",
      isloading: false,
      cat_id: "",
      userDetails: {},
      name: "",
      uploading_trailer: false,
      filetype: true,
      addmore: [
        {
          name: "",
          desc: "",
          time: "",
          uploading_trailer: "",

        },
      ],
    };
  }

  componentDidMount = async () => {
    this.checkUser();
    this.filterData();

  };

  checkUser = async () => {
    let user = await reactLocalStorage.getObject("userData");
    if (user != null && Object.keys(user).length > 0) {
      this.setState({
        userId: user.id,
        userDetails: user,
      });
    } else {
    }
    this.fetchData();
  };

  filterData = async (type) => {
    console.log("call");
    let result = await HttpClient.requestData("admin/course", "GET");
    console.log("call", result);

    if (result && result.status > 0) {
      let tr = result.data.filter((it) => it.id == this.state.course_id);
      console.log(tr[0]);
      this.state.data.push(tr[0]);

      this.setState({
        name: this.state.data[0].name,
        cat_id: this.state.data[0].cat_id,
      });
      this.fetchData(this.state.data[0].cat_id);
      console.log(this.state.data[0].cat_id);
      console.log(this.state.cat_id);
    }
  };

  fetchData = async (id) => {
    let data = {
      course: parseInt(this.state.course_id),
    };
    //console.log(this.state.lesson_id);
    let result = await HttpClient.requestData(
      "admin/lessons/get-all",
      "POST",
      data
    );
    console.log("jdCategoryresult", result);
    if (result && result.status > 0) {
      let data = [];
      let i = 1;

      result.data.forEach((element, index) => {
        let rows = {
          sl: i,
          name: element.name,
          created_by_m: element.created_by ? element.created_by.name + " on " + moment(element.created_on).utcOffset(element.created_on).format('DD/MM/YYYY, HH:mm') : "" + " on " + moment(element.created_on).utcOffset(element.created_on).format('DD/MM/YYYY, HH:mm'),
          updated_by_m: element.updated_by ? element.updated_by.name + " on " + moment(element.updated_on).utcOffset(element.updated_on).format('DD/MM/YYYY, HH:mm') : "" + "",
          //  desc:element.desc,
          lessons: Array.prototype.map
            .call(element.data, (s) => s.name)
            .toString(),
          action: (
            <>
              {this.state.userDetails.sub_admin == true ? null : (
                <button
                  title="Delete"
                  className="btn btn-danger mr-2"
                  onClick={() => {
                    if (window.confirm('Are you sure you wish to delete this item?'))
                      this.delete(element, index)
                  }}
                >
                  <i className="fa fa-trash" />
                </button>
              )}
              <button
                title="Edit"
                className="btn btn-primary"
                onClick={() => {
                  this.edit(element, index);
                }}
              >
                <i className="fa fa-edit" />
              </button>
            </>
          ),
        };
        i++;
        data.push(rows);
      });
      this.setState({
        data: data,
      });
    } else {
      this.setState({
        data: [],
      });
    }
  };
  delete = async (item, index) => {
    let sendData = {
      id: item._id,
    };
    let result = await HttpClient.requestData(
      "admin/lessons/delete",
      "POST",
      sendData
    );
    if (result && result.status) {
      let index = this.state.result.indexOf(item);

      this.setState({
        alert: true,
        message: "Deleted Successfully",
        type: "success",
      });
      setTimeout(() => {
        this.setState({
          alert: false,
          message: "",
          type: "",
        });
      }, 3000);
      this.fetchData();
    }
  };
  edit = async (item, index) => {
    this.setState({
      edit_enable: true,
      name: item.name,
      edit_item_id: item._id,
      addmore: item.data,
      uploading_trailer: item.uploading_trailer,
      videoUrl: item.videoUrl,
      url: item.url,
      trailer: item.trailer,
      trailerPlaylists: item.trailerUrl,
      selectedvideoArr: item.trailer,
    });
  };
  submit = async () => {

    if (
      this.state.name != ""
      // this.state.category_type != "" &&
      // this.state.img_url != ""
    ) {
      let vidIndex = this.state.addmore.findIndex(it => typeof (it.video) == 'undefined' || it.video == null)
      if (vidIndex >= 0) {
        this.setState({
          alert: true,
          message: "video.",
          type: "warning",
        });

        setTimeout(() => {
          this.setState({
            alert: false,
            message: "",
            type: "",
          });
        }, 3000);

        return;
      }
      this.setState({ loading: true });

      let data = null;
      let result = null;
      // alert('submit');

      if (this.state.edit_enable == false) {
        data = {
          edit_id: this.state.edit_item_id,
          //l_id:this.state.lesson_id,
          name: this.state.name,
          data: this.state.addmore,
          uploading_trailer: this.state.uploading_trailer,
          trailerUrl: this.state.trailerPlaylists,
          videoUrl: this.state.videoUrl,
          url: this.state.url,
          trailer: this.state.trailer,
          created_by: this.state.userId,
          course: parseInt(this.state.course_id),
        };
        console.log('send', data);
        result = await HttpClient.requestData("admin/lessons", "POST", data);
        console.log(result);
      } else {
        data = {
          name: this.state.name,
          data: this.state.addmore,
          course: parseInt(this.state.course_id),
          uploading_trailer: this.state.uploading_trailer,
          trailerUrl: this.state.trailerPlaylists,
          videoUrl: this.state.videoUrl,
          url: this.state.url,
          trailer: this.state.trailer,
          id: this.state.edit_item_id,
          updated_by: this.state.userId,
        };
        result = await HttpClient.requestData(
          "admin/lessons/update",
          "POST",
          data
        );
      }
      console.log("result", result);
      if (result && result.status) {
        this.setState({
          alert: true,
          message: this.state.edit_enable
            ? "Lesson Updated Successfully"
            : "Lesson Added Successfully",
          type: "success",
          // name: "",
          img_url: "",
          // category_type:'',
          image_select: false,
          edit_enable: false,
          edit_item_id: "",
          data: [],
          desc: "",
          time: "",
          uploadVideo: false,
          uploading_trailer: false,
          filetype: false,
          addmore: [
            {
              name: "",
              desc: "",
              time: "",
              uploading_trailer: "",
            },
          ],
        });
        setTimeout(() => {
          this.setState({
            filetype: true,
          })
        }, 500);
        this.fetchData();
      } else {
        this.setState({
          alert: true,
          message: typeof (result.error) == 'string' ? result.error : result.error[0].msg,
          type: "danger",
        });
      }

    } else {
      this.setState({
        alert: true,
        message: "Please Fill Up All Details.",
        type: "warning",
      });
    }

    setTimeout(() => {
      this.setState({
        alert: false,
        message: "",
        type: "",
      });
    }, 3000);
  };

  async remove_video(index) {
    this.state.addmore[index].video = null;
    this.setState({});
  }

  handleChange = (event) => {
    console.log(event.target.value);
    this.setState({ category_type: event.target.value });
  };

  imageUpload = async (e) => {
    console.log("e", e.target.files);
    let file = e.target.files;
    //   if(file.lenght>0)
    //   {
    let dataSend = { buckate: true, buckate_name: "appIcon" };

    let result = await HttpClient.newFileUpload(
      "image-upload/category",
      e.target.files[0],
      dataSend
    );
    // console.log("result", result);
    if (result && result.status) {
      this.setState({ img_url: result.url, image_select: true });
    }

    //   }
  };
  uploadVideo = async (video, type, index) => {
    this.setState({ filetype: video })
    if (type == "Trailer") {
      this.state.addmore[index].uploading_trailer = true;
      this.state.addmore[index].progressBarParsent = 0;
      this.setState({})
      // this.setState({ uploading_trailer : true, progressBarParsent: 0 });
    } else {
    }

    let dataSend = { buckate: false };
    let result = await HttpClient.uploadFileRequest(
      "video-upload/video",
      video,
      (data, data1, data2) => {

        if (type == "Trailer") {
          this.state.addmore[index].progressBarParsent = data;
          this.setState({})
          // this.setState({ progressBarParsent: data });
        } else {
          // this.setState({ progressBarParsentVideo: data });
        }
      }
    );


    if (result && result.status) {

      if (type == "Trailer") {
        this.state.addmore[index].uploading_trailer = false;
        this.state.addmore[index].video = result.data.url;

        this.setState({})
        // this.setState({
        //   trailerPlaylists: result.playlists,
        //   trailer: result.data.url,
        //   uploading_trailer: false,
        // });
        // setTimeout(() => {
        //   this.state.selectedvideoArr.push({ preview: result.data.url });
        //   this.setState({});
        // }, 3000);
      } else {
        this.setState({
          videoUrl: result.playlists,
          url: result.data.url,
          uploading_movie: false,
        });
      }
    }

  };


  render() {
    const data = {
      columns: [
        {
          label: "Sl.",
          field: "sl",
          sort: "asc",
          width: 150,
        },
        {
          label: "Course Name",
          field: "name",
          sort: "asc",
          width: 270,
        },
        {
          label: "Lesson Name",
          field: "lessons",
          sort: "asc",
          width: 270,
        },
        {
          label: "Created By",
          field: "created_by_m",
          sort: "asc",
          width: 270,
        },
        {
          label: "Updated By",
          field: "updated_by_m",
          sort: "asc",
          width: 270,
        },
        {
          label: "Action",
          field: "action",
          sort: "asc",
          width: 100,
        },
      ],
      rows: this.state.data,
    };
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title={this.state.breadcrumbItems[0].title}
              breadcrumbItems={this.state.breadcrumbItems}
            />

            <Row>
              <Col xs={12}>
                <Card>
                  <CardBody>
                    {this.state.alert ? (
                      <UncontrolledAlert
                        color={this.state.type}
                        className="alert-dismissible fade show"
                        role="alert"
                      >
                        {this.state.type == "warning" ? (
                          <i className="mdi mdi-alert-outline mr-2"></i>
                        ) : this.state.type == "success" ? (
                          <i className="mdi mdi-check-all mr-2"></i>
                        ) : this.state.type == "danger" ? (
                          <i className="mdi mdi-block-helper mr-2"></i>
                        ) : null}
                        {this.state.message}
                      </UncontrolledAlert>
                    ) : null}

                    {this.state.addmore.map((item, index) => {
                      return (
                        <>
                          {index > 0 ? (
                            <Col
                              md={2}
                              style={{ marginTop: index > 0 ? 5 : 0 }}
                            ></Col>
                          ) : null}
                          <FormGroup row>
                            <Label
                              htmlFor="example-text-input"
                              className="col-md-2 col-form-label"
                            >
                              Lesson Name<span style={{ color: 'red' }}>*</span>
                            </Label>
                            <Col md={10}>
                              <Input
                                className="form-control"
                                type="text"
                                value={this.state.addmore[index].name}
                                id="example-text-input"
                                onChange={(val) => {
                                  this.state.addmore[index].name =
                                    val.target.value;
                                  this.setState({});
                                }}
                              />
                            </Col>
                          </FormGroup>

                          <FormGroup row>
                            <Label
                              htmlFor="example-search-input"
                              className="col-md-2 col-form-label"
                            >
                              Lesson Description
                            </Label>
                            <Col md={10}>
                              <textarea
                                id="question-input1"
                                className="form-control"
                                rows="2"
                                onChange={(val) => {
                                  this.state.addmore[index].desc =
                                    val.target.value;
                                  this.setState({});
                                }}
                                value={this.state.addmore[index].desc}
                              ></textarea>
                            </Col>
                          </FormGroup>
                          <FormGroup row>
                            <Label
                              htmlFor="example-text-input"
                              className="col-md-2 col-form-label"
                            >
                              Upload Video<span style={{ color: 'red' }}>*</span>
                            </Label>
                            <Col md={6}>
                              {!this.state.filetype ? null :
                                <Input
                                  className="form-control"
                                  accept=".mp4,.flv,.MOV,.WMV,.AVI,.MKV,.AVCHD"
                                  type="file"
                                  id="example-text-input"
                                  // value={this.state.addmore[index].uploading_trailer}
                                  onChange={(val) => {
                                    this.state.addmore[index].uploading_trailer =
                                      val.target.value;

                                    this.uploadVideo(val.target.files[0], "Trailer", index);
                                    this.setState({});
                                  }}
                                // ref={(ref) => (this.fileInputTrl = ref)}
                                />}
                            </Col>
                            <Col md={4}>
                              {item.uploading_trailer ? (
                                <>
                                  <ProgressBar
                                    completed={item.progressBarParsent}
                                  />
                                  {item.progressBarParsent == 100
                                    ? "please wait untill transcoding is done"
                                    : null}
                                </>
                              ) : (
                                ""
                              )}
                              <span style={{ color: 'red' }}>[video should be in dimensions 1080 * 720 px]</span>
                            </Col>
                          </FormGroup>

                          <FormGroup row>
                            <Col md={10}>
                              <div
                                className="dropzone-previews mt-3"
                                id="file-previews"
                              >
                                {
                                  item.video ?
                                    <Card
                                      className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                      key={index + "-file"}
                                    >
                                      <div className="p-2">
                                        <Row className="align-items-center">
                                          <Col className="col-auto">
                                            <video
                                              width="150"
                                              height="120"
                                              controls
                                              className="rounded-lg"
                                            >
                                              <source
                                                src={item.video}
                                                type="video/mp4"
                                              />
                                            </video>
                                          </Col>
                                          <Col>
                                            <Button
                                              className="btn-icon"
                                              color="danger"
                                              style={{ marginRight: 25 }}
                                              onClick={(e) => { this.remove_video(index) }}
                                            >
                                              {" "}
                                              <span className="btn-icon-label">
                                                <i className="mdi mdi-alpha-x-box-outline mr-2"></i>
                                              </span>{" "}
                                              Remove
                                            </Button>
                                          </Col>
                                        </Row>
                                      </div>
                                    </Card>
                                    :
                                    null
                                }


                                {/* {this.state.selectedvideoArr.length > 0 &&
                                  this.state.selectedvideoArr.map((f, i) => {
                                    return (
                                      <Card
                                        className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                        key={i + "-file"}
                                      >
                                        <div className="p-2">
                                          <Row className="align-items-center">
                                            <Col className="col-auto">
                                              <video
                                                width="150"
                                                height="120"
                                                controls
                                                className="rounded-lg"
                                              >
                                                <source
                                                  src={f.preview}
                                                  type="video/mp4"
                                                />
                                              </video>
                                            </Col>
                                            <Col>
                                              <Button
                                                className="btn-icon"
                                                color="danger"
                                                style={{ marginRight: 25 }}
                                                onClick={(e) =>
                                                  this.remove_video(f, i)
                                                }
                                              >
                                                {" "}
                                                <span className="btn-icon-label">
                                                  <i className="mdi mdi-alpha-x-box-outline mr-2"></i>
                                                </span>{" "}
                                                Remove
                                              </Button>
                                            </Col>
                                          </Row>
                                        </div>
                                      </Card>
                                    );
                                  })} */}
                              </div>
                            </Col>
                          </FormGroup>
                          <FormGroup row>
                            <Label
                              htmlFor="example-search-input"
                              className="col-md-2 col-form-label"
                            >
                              Lesson Time
                            </Label>
                            <Col md={8}>
                              <Input
                                className="form-control"
                                type="time"
                                value={this.state.addmore[index].time}
                                id="example-text-input"
                                onChange={(val) => {
                                  this.state.addmore[index].time =
                                    val.target.value;
                                  this.setState({});
                                }}
                              />
                            </Col>

                            <Col md={2}>
                              <Button
                                color={index > 0 ? "danger" : "success"}
                                type="button"
                                className="waves-effect waves-light customUploadBtn mr-1"
                                onClick={(e) => {
                                  if (index > 0) {
                                    let indexx =
                                      this.state.addmore.indexOf(item);
                                    console.log(indexx);
                                    if (indexx > -1) {
                                      this.state.addmore.pop(indexx);
                                      this.setState({});
                                    }
                                  } else {
                                    this.state.addmore.push({
                                      name: "",
                                      desc: "",
                                      time: "",
                                      uploading_trailer: "",
                                    });
                                    this.setState({});
                                  }
                                }}
                              >
                                {index > 0 ? " - Remove" : " + Add More"}
                              </Button>
                            </Col>
                          </FormGroup>
                        </>
                      );
                    })}
                    <p style={{ marginTop: 5, textAlign: "right" }}>
                      [Note: Asterisk (<span style={{ color: 'red' }}>*</span>) denotes the mandatory field.]
                    </p>
                    <FormGroup className="mb-0">
                      <div className="button-items pt-4">
                        <Button
                          color="primary"
                          type="button"
                          className="waves-effect waves-light mr-1"
                          onClick={() => {
                            this.submit();
                          }}
                        >
                          {this.state.edit_enable ? "Update" : "Submit"}{" "}
                          <i className="ri-arrow-right-line align-middle ml-1"></i>
                        </Button>
                      </div>
                    </FormGroup>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col xl={12}>
                <div className="card p-3">
                  <div class="d-flex justify-content-between w-100">
                    <h5>LESSON</h5>
                    {this.state.userDetails.sub_admin == true ? null : (
                      <Link
                        className="btn btn-danger mr-2"
                        to={"/lessonUndo/" + this.state.course_id}
                      >
                        Deleted Lesson
                      </Link>
                    )}
                  </div>
                  <MDBDataTable responsive bordered data={data} />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default FormElements;
