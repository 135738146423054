import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Tooltip,
  UncontrolledAlert,
  Spinner,
} from "reactstrap";
import { Link } from "react-router-dom";
import "./design.css";
import "../AllDesignes/ind432a.css";
import { reactLocalStorage } from "reactjs-localstorage";
import HttpClient from "./../../utils/HttpClient";
// import DMSteps from "../Component/dMSteps";

export default class index extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tooltipOpen: false,
      alert: false,
      message: "",
      type: "",
      d1: "https://oneanddone.fra1.digitaloceanspaces.com/design/d-1.jpg",
      d2: "https://oneanddone.fra1.digitaloceanspaces.com/design/d-2.jpg",
      d3: "https://oneanddone.fra1.digitaloceanspaces.com/design/d-3.jpg",
      d4: "https://oneanddone.fra1.digitaloceanspaces.com/design/d-4.jpg",
      active_design: 2,
    };
  }
  toggle = () => {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen,
    });
  };
  componentDidMount = async () => {
    await this.checkUser();
    // await this.checkExesisting();
  };

  checkUser = async () => {
    let user = await reactLocalStorage.getObject("userData");
    if (user != null && Object.keys(user).length > 0) {
      this.setState({
        userId: user.id,
        userDetails: user,
      });
      this.checkExesisting(user.id);
    } else {
    }
  };

  checkExesisting = async (id) => {
    let sendData = { owner_id: id };
    let result = await HttpClient.requestData(
      "app-owner/application/check-pending",
      "POST",
      sendData
    );
    // console.log("result checkExesisting", result.data[0].design_num);
    if (result && result.status > 0) {
      this.setState({
        result: result.data[0],
        edit_enable: true,
        edit_id: result.data[0]._id,
        refreshStatus: true,
        // active_design:
        //   result.data[0].design_num && result.data[0].design_num == 1
        //     ? this.state.d1
        //     : result.data[0].design_num && result.data[0].design_num == 2
        //     ? this.state.d2
        //     : result.data[0].design_num && result.data[0].design_num == 3
        //     ? this.state.d3
        //     : result.data[0].design_num && result.data[0].design_num == 4
        //     ? this.state.d4
        //     : this.state.d1,
        active_design: result.data[0].design_num
          ? result.data[0].design_num
          : 1,
      });
    }
  };

  firstFromUpdate = async () => {
    if (this.state.edit_enable) {
      if (this.state.active_design != "") {
        this.setState({ loading: true });
        let sendData = this.state.result;
        sendData.edit_id = this.state.edit_id;
        sendData.design_num = this.state.active_design;

        console.log("sendData", sendData);
        let result = await HttpClient.requestData(
          "app-owner/application/update",
          "POST",
          sendData
        );
        if (result && result.status) {
          this.setState({
            alert: true,
            message: this.state.edit_enable
              ? "Application Updated Successfully"
              : "Application Added Successfullyy",
            type: "success",
            loading: false,
          });
          this.checkUser();
          setTimeout(() => {
            window.location.href = "/create-app";
          }, 400);
        } else {
          this.setState({
            loading: false,
            alert: true,
            message:
              typeof result.error == "string"
                ? result.error
                : result.error[0].msg,
            type: "danger",
          });
        }
      } else {
        this.setState({
          alert: true,
          message: "Please Choose Your design.",
          type: "warning",
        });
      }
    }else
    {
      this.setState({
        alert: true,
        message: "Please Create App First.",
        type: "warning",
      });
    }

    setTimeout(() => {
      this.setState({
        alert: false,
        message: "",
        type: "",
      });
    }, 3000);
  };

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Row>
              <Col xl={12}>
                {/* <DMSteps /> */}
                {this.state.alert ? (
                  <UncontrolledAlert
                    color={this.state.type}
                    className="alert-dismissible fade show"
                    role="alert"
                  >
                    {this.state.type == "warning" ? (
                      <i className="mdi mdi-alert-outline mr-2"></i>
                    ) : this.state.type == "success" ? (
                      <i className="mdi mdi-check-all mr-2"></i>
                    ) : this.state.type == "danger" ? (
                      <i className="mdi mdi-block-helper mr-2"></i>
                    ) : null}
                    {this.state.message}
                  </UncontrolledAlert>
                ) : null}
              </Col>
              <Col xl={12}>
                <div>
                  <div>
                    <h3 className="title-editor py-1 text-center">
                      Choose your design{" "}
                      <i
                        className="fa fa-info-circle display_tooltip"
                        id="TooltipInfo"
                      />
                      <Tooltip
                        placement="top"
                        isOpen={this.state.tooltipOpen}
                        target="TooltipInfo"
                        toggle={this.toggle}
                        autohide={false}
                      >
                        Choose a design template (combination of texts, images
                        and features)
                      </Tooltip>
                    </h3>
                  </div>
                </div>
                <div className="chs_design">
                  <div className="design_area">
                    <div>
                      <div className="d-flex overflow-x">
                        <div
                          className="flexDiv"
                          onClick={() => {
                            this.setState({ active_design: 1 });
                            setTimeout(() => {
                              this.firstFromUpdate();
                            }, 100);
                          }}
                        >
                          <div
                            className={
                              this.state.active_design == 1
                                ? "design_div bg-soft-secondary selected_cursor"
                                : "design_div bg-soft-secondary"
                            }
                            style={{ cursor: "pointer" }}
                          >
                            <img
                              src={this.state.d1}
                              alt="img"
                              className="img_des"
                            />
                            <span className="dsg_tltip">
                              3 panels, Icons on left scrollable
                            </span>
                          </div>
                        </div>
                        <div
                          className="flexDiv"
                          onClick={() => {
                            this.setState({ active_design: 2 });
                            setTimeout(() => {
                              this.firstFromUpdate();
                            }, 100);
                          }}
                        >
                          <div
                            className={
                              this.state.active_design == 2
                                ? "design_div bg-soft-secondary selected_cursor"
                                : "design_div bg-soft-secondary"
                            }
                            style={{ cursor: "pointer" }}
                          >
                            <img
                              src={this.state.d2}
                              alt="img"
                              className="img_des"
                            />
                            <span className="dsg_tltip">
                              Unlimited panels, click through to detailed panels
                             
                             
                             
                             
                              with texts, icons and links
                            </span>
                          </div>
                        </div>
                        <div
                          className="flexDiv"
                          onClick={() => {
                            this.setState({ active_design: 3 });
                            setTimeout(() => {
                              this.firstFromUpdate();
                            }, 100);
                          }}
                        >
                          <div
                            className={
                              this.state.active_design == 3
                                ? "design_div bg-soft-secondary selected_cursor"
                                : "design_div bg-soft-secondary"
                            }
                            style={{ cursor: "pointer" }}
                          >
                            <img
                              src={this.state.d3}
                              alt="img"
                              className="img_des"
                            />
                            <span className="dsg_tltip">
                              Only icons with texts and links
                            </span>
                          </div>
                        </div>
                        <div
                          className="flexDiv"
                          onClick={() => {
                            this.setState({ active_design: 4 });
                            setTimeout(() => {
                              this.firstFromUpdate();
                            }, 100);
                          }}
                        >
                          <div
                            className={
                              this.state.active_design == 4
                                ? "design_div bg-soft-secondary selected_cursor"
                                : "design_div bg-soft-secondary"
                            }
                            style={{ cursor: "pointer" }}
                          >
                            <img
                              src={this.state.d4}
                              alt="img"
                              className="img_des"
                            />
                            <span className="dsg_tltip">
                              Large single image, icons at bottom
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
