import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Tooltip,
  Label,
  Input,
  Button,
  CustomInput,
  UncontrolledAlert,
  Spinner,
} from "reactstrap";
import { Link } from "react-router-dom";
import "./design.css";
import AddImageBox from "./addImageBox";
import AddTextBox from "./addTextBox";
import Preview from "../Component/preview";
import AllDesignes from "../AllDesignes";
import d3 from "../../assets/images/d-3.jpg";
import { reactLocalStorage } from "reactjs-localstorage";
import HttpClient from "./../../utils/HttpClient";
import BaseInfo from "../ChooseDesign/baseInfo";
import ImgCrop from "../../components/ImageCrop/imgCrop";

export default class index extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showImageBox: false,
      showTextBox: false,
      tooltipOpen: false,
      chooseDesign: false,
      editText: false,
      backgroundImage:
        "https://png.pngtree.com/thumb_back/fw800/back_our/20190619/ourmid/pngtree-blue-stage-lighting-material-background-image_137923.jpg",
      logo:
        "https://upload.wikimedia.org/wikipedia/commons/thumb/2/2f/Google_2015_logo.svg/368px-Google_2015_logo.svg.png",
      title: "Add Your Title",
      showDesigns: true,
      appLogo:
        "https://www.logodesign.net/logo/letter-d-inside-polygon-4839ld.png",
      appBgImg: "/static/media/img-4.b2f77390.jpg",
      appLogoSelect: false,
      appBgImgSelect: false,
      selectedAppLogo: {},
      selectAppBgImg: {},
      userId: "",
      userDetails: {},
      result: {},
      appName: "",
      alert: false,
      message: "",
      type: "",
      edit_enable: false,
      edit_id: "",
      loading: false,
      d1: "https://oneanddone.fra1.digitaloceanspaces.com/design/d-1.jpg",
      d2: "https://oneanddone.fra1.digitaloceanspaces.com/design/d-2.jpg",
      d3: "https://oneanddone.fra1.digitaloceanspaces.com/design/d-3.jpg",
      d4: "https://oneanddone.fra1.digitaloceanspaces.com/design/d-4.jpg",
      active_design:
        "https://oneanddone.fra1.digitaloceanspaces.com/design/d-3.jpg",
      design_num: 3,
      refreshStatus: false,
      cropresult: "",
      cropImg: false,
      fontValue: 20,
      fontFamily: "",
    };
  }
  toggle = () => {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen,
    });
  };
  callBackImage = (val, img_url, img_file, image_status) => {
    this.setState({
      showImageBox: val,
      appLogoSelect: image_status,
      selectedAppLogo: img_file,
      appLogo: img_url,
      refreshStatus: !this.state.refreshStatus,
    });
  };
  callBackText = (val) => {
    this.setState({
      showTextBox: val,
    });
  };
  callBackDesign = (val) => {
    this.setState({
      chooseDesign: val,
    });
  };

  backgroundImage = (e) => {
    // console.log('e',e);
    this.setState({ backgroundImage: URL.createObjectURL(e.target.files[0]) });
  };

  componentDidMount = async () => {
    await this.checkUser();
    // await this.checkExesisting();
  };

  checkUser = async () => {
    let user = await reactLocalStorage.getObject("userData");
    if (user != null && Object.keys(user).length > 0) {
      this.setState({
        userId: user.id,
        userDetails: user,
      });
      this.checkExesisting(user.id);
    } else {
    }
  };

  checkExesisting = async (id) => {
    let sendData = { owner_id: id };
    let result = await HttpClient.requestData(
      "app-owner/application/check-pending",
      "POST",
      sendData
    );
    console.log("result checkExesisting", result);
    if (result && result.status > 0) {
      this.setState({
        result: result.data[0],
        appBgImg: result.data[0].bgImage,
        appLogo: result.data[0].logo,
        appName: result.data[0].name,
        title: result.data[0].title ? result.data[0].title : "Add Your Title",
        edit_enable: true,
        edit_id: result.data[0]._id,
        refreshStatus: true,
        active_design:
          result.data[0].design_num && result.data[0].design_num == 1
            ? this.state.d1
            : result.data[0].design_num && result.data[0].design_num == 2
            ? this.state.d2
            : result.data[0].design_num && result.data[0].design_num == 3
            ? this.state.d3
            : result.data[0].design_num && result.data[0].design_num == 4
            ? this.state.d4
            : this.state.d1,
        design_num: result.data[0].design_num ? result.data[0].design_num : 1,
        showDesigns: true,
        fontFamily:result.data[0].fontFamily ? result.data[0].fontFamily : "",
        fontValue:result.data[0].fontValue ? result.data[0].fontValue : 20,

      });
    }
  };

  imageUpload = async (file) => {
    // console.log("e", file);
    // let file = e.target.files;
    //   if(file.lenght>0)
    //   {
    let dataSend = { buckate: true, buckate_name: "appIcon" };

    let result = await HttpClient.newFileUpload(
      "image-upload/appIcon",
      file,
      dataSend
    );
    // console.log("result", result);
    return result;
  };

  firstFrom = async () => {
    if (
      this.state.appName != "" &&
      this.state.appLogoSelect == true &&
      this.state.appBgImgSelect == true
    ) {
      this.setState({ loading: true });
      let sendData = {
        name: this.state.appName,
        owner_id: this.state.userId,
        // design: this.state.active_design,
        // design_num: this.state.design_num,
      };
      let result = null;
      let logoUpload = await this.imageUpload(this.state.selectedAppLogo);
      if (logoUpload && logoUpload.status) {
        sendData.logo = logoUpload.url;
        let bgUpload = await this.imageUpload(this.state.selectAppBgImg);
        if (bgUpload && bgUpload.status) {
          sendData.bgImage = bgUpload.url;
          console.log("sendData", sendData);
          result = await HttpClient.requestData(
            "app-owner/application",
            "POST",
            sendData
          );
          if (result && result.status) {
            this.setState({
              appBgImg: result.data.bgImage,
              appLogo: result.data.logo,
              alert: true,
              message: this.state.edit_enable
                ? "Application Updated Successfully"
                : "Application Added Successfullyy",
              type: "success",
              loading: false,
              selectAppBgImg: {},
              selectedAppLogo: {},
              appBgImgSelect: false,
              appLogoSelect: false,
            });
            this.checkUser();
            setTimeout(() => {
              this.setState({ showDesigns: true });
            }, 400);
          } else {
            this.setState({
              loading: false,
              alert: true,
              message:
                typeof result.error == "string"
                  ? result.error
                  : result.error[0].msg,
              type: "danger",
            });
          }
        }
      }
      // console.log("logoUpload", logoUpload);
      // this.setState({ showDesigns: true });
    } else {
      this.setState({
        alert: true,
        message: "Please Fill Up All Details.",
        type: "warning",
      });
    }
    setTimeout(() => {
      this.setState({
        alert: false,
        message: "",
        type: "",
      });
    }, 3000);
  };

  firstFromUpdate = async () => {
    if (this.state.appName != "") {
      this.setState({ loading: true });
      let sendData = this.state.result;
      sendData.edit_id = this.state.edit_id;
      sendData.name = this.state.appName;
      sendData.owner_id = this.state.userId;

      // console.log("send Update", sendData);
      // return false;
      let result = null;
      if (this.state.appLogoSelect) {
        let logoUpload = await this.imageUpload(this.state.selectedAppLogo);
        if (logoUpload && logoUpload.status) {
          sendData.logo = logoUpload.url;
        }
      } else {
        sendData.logo = this.state.appLogo;
      }
      if (this.state.appBgImgSelect) {
        let bgUpload = await this.imageUpload(this.state.selectedAppLogo);
        if (bgUpload && bgUpload.status) {
          sendData.bgImage = bgUpload.url;
        }
      } else {
        sendData.bgImage = this.state.appBgImg;
      }

      console.log("sendData", sendData);
      result = await HttpClient.requestData(
        "app-owner/application/update",
        "POST",
        sendData
      );
      if (result && result.status) {
        this.setState({
          appBgImg: result.data.bgImage,
          appLogo: result.data.logo,
          alert: true,
          message: this.state.edit_enable
            ? "Application Updated Successfully"
            : "Application Added Successfullyy",
          type: "success",
          loading: false,
          selectAppBgImg: {},
          selectedAppLogo: {},
          appBgImgSelect: false,
          appLogoSelect: false,
        });
        this.checkUser();
      } else {
        this.setState({
          loading: false,
          alert: true,
          message:
            typeof result.error == "string"
              ? result.error
              : result.error[0].msg,
          type: "danger",
        });
      }

      // console.log("logoUpload", logoUpload);
      // this.setState({ showDesigns: true });
    } else {
      this.setState({
        alert: true,
        message: "Please Fill Up All Details.",
        type: "warning",
      });
    }
    setTimeout(() => {
      this.setState({
        alert: false,
        message: "",
        type: "",
      });
    }, 3000);
  };

  updateFrom = async () => {
    if (this.state.edit_enable) {
      let sendData = this.state.result;

      sendData.edit_id = this.state.edit_id;
      if (this.state.title != "" && this.state.title != "Add Your Title") {
        if (this.state.appLogoSelect) {
          let logoUpload = await this.imageUpload(this.state.selectedAppLogo);
          if (logoUpload && logoUpload.status) {
            sendData.logo = logoUpload.url;
          }
        }
        if (this.state.appBgImgSelect) {
          console.log("this.state.selectAppBgImg", this.state.selectAppBgImg);
          let logoUploadNew = await this.imageUpload(this.state.selectAppBgImg);
          if (logoUploadNew && logoUploadNew.status) {
            sendData.bgImage = logoUploadNew.url;
          }
        }
        console.log("design", this.state.design_num);
        sendData.title = this.state.title;
        sendData.design_num = this.state.design_num;
        sendData.fontFamily = this.state.fontFamily;
        sendData.fontValue = this.state.fontValue;
        console.log("sendData", sendData);
        // return sendData;
        let result = await HttpClient.requestData(
          "app-owner/application/update",
          "POST",
          sendData
        );
        console.log("result", result);
        if (result && result.status) {
          this.setState({
            appBgImg: result.data.bgImage,
            appLogo: result.data.logo,
            title: result.data.title,
            alert: true,
            message: this.state.edit_enable
              ? "Application Updated Successfully"
              : "Application Added Successfullyy",
            type: "success",
            loading: false,
            selectAppBgImg: {},
            selectedAppLogo: {},
            appBgImgSelect: false,
            appLogoSelect: false,
          });
          this.checkUser();
          setTimeout(() => {
            // this.setState({ showDesigns: true });
            // window.location.href = "/create-app";
          }, 400);
        } else {
          this.setState({
            loading: false,
            alert: true,
            message:
              typeof result.error == "string"
                ? result.error
                : result.error[0].msg,
            type: "danger",
          });
        }
      } else {
        // alert();
        this.setState({
          alert: true,
          message: "Please Enter Your Title.",
          type: "warning",
        });
      }
    }
    setTimeout(() => {
      this.setState({
        alert: false,
        message: "",
        type: "",
      });
    }, 3000);
  };
  callBackCrop = (val, id, cropresult) => {
    this.setState(() => ({
      cropImg: val,
      cropresult: cropresult,
    }));
  };
  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Row>
              <Col xl={12}>
                <BaseInfo />
                {this.state.alert ? (
                  <UncontrolledAlert
                    color={this.state.type}
                    className="alert-dismissible fade show"
                    role="alert"
                  >
                    {this.state.type == "warning" ? (
                      <i className="mdi mdi-alert-outline mr-2"></i>
                    ) : this.state.type == "success" ? (
                      <i className="mdi mdi-check-all mr-2"></i>
                    ) : this.state.type == "danger" ? (
                      <i className="mdi mdi-block-helper mr-2"></i>
                    ) : null}
                    {this.state.message}
                  </UncontrolledAlert>
                ) : null}
              </Col>
              <Col xl={this.state.showDesigns ? 8 : 12}>
                <div>
                  <div>
                    <h3 className="title-editor py-1 text-center">
                      Welcome Screen
                      <i
                        className="fa fa-info-circle display_tooltip"
                        id="TooltipInfo"
                      />
                      <Tooltip
                        placement="top"
                        isOpen={this.state.tooltipOpen}
                        target="TooltipInfo"
                        toggle={this.toggle}
                        autohide={false}
                      >
                        Customize your welcome screen
                      </Tooltip>
                    </h3>
                  </div>
                  {/* app homepage/splash screen */}

                  <Row>
                    <Col className="col-md-6 col-lg-6 col-xl-6 col-12">
                      <div
                        className="actionBox"
                        style={{
                          backgroundImage: `url('${this.state.appBgImg}')`,
                        }}
                      >
                        <div className="d-flex flex-column justify-content-end h-100 px-3 relative">
                          <div className="text-center home_logo relative">
                            <div
                              className="relative"
                              style={{ zIndex: "1002" }}
                            >
                              <div
                                onClick={() =>
                                  this.setState({
                                    cropImg: true,
                                  })
                                }
                                title="Upload logo"
                                style={{ cursor: "pointer" }}
                              >
                                <img
                                  // src={this.state.appLogo}
                                  src={
                                    this.state.cropresult !== ""
                                      ? this.state.cropresult
                                      : this.state.appLogo
                                  }
                                  alt="logo"
                                  className="img-fluid"
                                />
                              </div>
                              {/* image crop modal */}
                              <div
                                className={
                                  this.state.cropImg
                                    ? "modal fade show"
                                    : "modal fade"
                                }
                                id={"imgCropBox"}
                                tabindex="-1"
                                style={{
                                  display: this.state.cropImg
                                    ? "block"
                                    : "none",
                                }}
                              >
                                <div
                                  className="modal-dialog mt-5 pt-5"
                                  role="document"
                                >
                                  <ImgCrop
                                    callBackCrop={this.callBackCrop}
                                    id={1}
                                  />
                                </div>
                              </div>
                            </div>
                            {this.state.editText ? (
                              <div className="d-flex mt-3">
                                <input
                                  type="text"
                                  className="form-control mr-2"
                                  placeholder="Enter App Title..."
                                  onChange={(e) =>
                                    this.setState({ title: e.target.value })
                                  }
                                  value={this.state.title}
                                />
                                <button
                                  className="btn btn-primary"
                                  onClick={() =>
                                    this.setState({ editText: false })
                                  }
                                >
                                  Save
                                </button>
                              </div>
                            ) : (
                              <h3
                                className="text-light mt-3"
                                onClick={() =>
                                  this.setState({ editText: true })
                                }
                                style={{
                                  fontFamily: this.state.fontFamily,
                                  fontSize: Number(this.state.fontValue),
                                }}
                              >
                                {this.state.title}
                              </h3>
                            )}
                          </div>
                          <input
                            type="file"
                            className="page_input_file"
                            title="Choose Background Image"
                            // style={{ zIndex: "102" }}
                            onChange={(e) => {
                              this.setState({
                                appBgImg: URL.createObjectURL(
                                  e.target.files[0]
                                ),
                                selectAppBgImg: e.target.files[0],
                                appBgImgSelect: true,
                                refreshStatus: !this.state.refreshStatus,
                              });
                            }}
                          />
                        </div>
                      </div>
                    </Col>
                    <Col className="col-md-6 col-lg-6 col-xl-6 col-12">
                      <div
                        className="actionBox bg-soft-secondary"
                        style={
                          {
                            // backgroundImage: `url('${this.state.active_design}')`,
                          }
                        }
                      >
                        <div
                          className="px-3 py-2 relative"
                          style={{ zIndex: 1 }}
                        >
                          <h5 className="text-center text-light mb-3">
                            Customize Your Homepage
                          </h5>
                          <button
                            type="button"
                            className="btn-success btn-full-width py-2 mb-2 customUploadBtn"
                          >
                            Upload Background Image
                            <input
                              type="file"
                              className="upload_input"
                              onChange={(e) => {
                                this.setState({
                                  appBgImg: URL.createObjectURL(
                                    e.target.files[0]
                                  ),
                                  selectAppBgImg: e.target.files[0],
                                  appBgImgSelect: true,
                                  refreshStatus: !this.state.refreshStatus,
                                });
                              }}
                            />
                          </button>

                          <div className="row no-gutters mb-2">
                            <div className="col-md-6">
                              <button
                                type="button"
                                className="btn-warning btn-full-width py-2"
                                onClick={() => this.callBackImage(true)}
                              >
                                Add Your Logo
                              </button>
                            </div>
                            <div className="col-md-6">
                              <button
                                type="button"
                                className="btn-danger btn-full-width py-2"
                                onClick={() => this.callBackText(true)}
                              >
                                Add Your Title
                              </button>
                            </div>
                            <div className="col-md-6 my-2">
                              <h6 className="mb-1 mt-1 font-size-15 text-dark">
                                Font Size
                              </h6>
                              <select
                                className="w-100"
                                name="f_size"
                                onChange={(event) =>
                                  this.setState({
                                    fontValue: event.target.value,
                                    refreshStatus: !this.state.refreshStatus,
                                  })
                                }
                                value={this.state.fontValue}
                              >
                                <option value="20">20</option>
                                <option value="22">22</option>
                                <option value="24">24</option>
                                <option value="26">26</option>
                                <option value="28">28</option>
                                <option value="30">30</option>
                                <option value="32">32</option>
                              </select>
                            </div>
                            <div className="col-md-6 my-2">
                              <h6 className="mb-1 mt-1 font-size-15 text-dark">
                                Font Family
                              </h6>
                              <select
                                className="w-100"
                                name="f_family"
                                onChange={(event) =>
                                  this.setState({
                                    fontFamily: event.target.value,
                                    refreshStatus: !this.state.refreshStatus,
                                  })
                                }
                                value={this.state.fontFamily}
                              >
                                <option value="sans-serif">sans-serif</option>
                                <option value="ui-serif">ui-serif</option>
                                <option value="system-ui">system-ui</option>
                                <option value="monospace">monospace</option>
                                <option value="serif">serif</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                    {/* <Col className="col-md-6 col-lg-6 col-xl-6 col-12">
                        <div
                          className="actionBox bg-soft-secondary"
                          style={{
                            backgroundImage: `url('${this.state.active_design}')`,
                          }}
                        >
                          <div className="px-3 py-2 relative">
                           
                            <Button
                              color="danger"
                              type="button"
                              className="waves-effect waves-light float-right"
                              onClick={() => {
                                if (
                                  this.state.title != "" &&
                                  this.state.title != "Add Your Title"
                                ) {
                                  this.firstFromUpdate();
                                } else {
                                  this.setState({
                                    alert: true,
                                    message: "Please Enter Your Title.",
                                    type: "warning",
                                  });
                                }
                                setTimeout(() => {
                                  this.setState({
                                    alert: false,
                                    message: "",
                                    type: "",
                                  });
                                }, 3000);
                              }}
                            >
                              Next{" "}
                              <i className="ri-arrow-right-line align-middle ml-1"></i>
                            </Button>
                           
                          </div>
                        </div>
                      </Col> */}
                  </Row>
                </div>
              </Col>
              {this.state.showDesigns ? (
                <Col xl={4}>
                  {/* app preview here */}
                  <div>
                    <Preview
                      showDesigns={this.state.showDesigns}
                      logo={this.state.appLogo}
                      bgImage={this.state.appBgImg}
                      title={this.state.title}
                      refresh={this.state.refreshStatus}
                      fontValue={this.state.fontValue}
                      fontFamily={this.state.fontFamily}
                      save={() => {
                        this.updateFrom();
                      }}
                    />
                  </div>
                </Col>
              ) : null}
            </Row>
          </Container>
          {/* upload logo box */}
          {this.state.showImageBox ? (
            <AddImageBox
              title="Upload Your Logo"
              showImageBox={true}
              callBackImage={this.callBackImage}
            />
          ) : null}
          {/* add texts box */}
          {this.state.showTextBox ? (
            <AddTextBox
              showTextBox={true}
              callBackText={this.callBackText}
              changeTitle={(val) => {
                this.setState({ title: val });
              }}
              title={this.state.title}
            />
          ) : null}

          {/* choose design box */}
          {this.state.chooseDesign ? (
            <AllDesignes
              chooseDesign={true}
              callBackDesign={this.callBackDesign}
              callBackSelectedDesign={(design) => {
                console.log(design);
                if (design == 1) {
                  this.setState({
                    active_design: this.state.d1,
                    design_num: 1,
                  });
                }
                if (design == 2) {
                  this.setState({
                    active_design: this.state.d2,
                    design_num: 2,
                  });
                }
                if (design == 3) {
                  this.setState({
                    active_design: this.state.d3,
                    design_num: 3,
                  });
                }
                if (design == 4) {
                  this.setState({
                    active_design: this.state.d4,
                    design_num: 4,
                  });
                }
                console.log("design_num", this.state.design_num);
                setTimeout(() => {
                  this.updateFrom();
                }, 200);
              }}
              design={this.state.edit_enable ? this.state.design_num : 1}
            />
          ) : null}
        </div>
      </React.Fragment>
    );
  }
}
