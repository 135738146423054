import React, { Component } from "react";
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  FormGroup,
  Label,
  Input,
  CustomInput,
  Button,
  UncontrolledAlert,
  InputGroup,
} from "reactstrap";
import { Link } from "react-router-dom";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { MDBDataTable } from "mdbreact";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { reactLocalStorage } from "reactjs-localstorage";
import moment from "moment";
import HttpClient from "./../../utils/HttpClient";
import Dropzone from "react-dropzone";
class FormElements extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: "Events", link: "#" },
        { title: "Add & Manage Events", link: "#" },
      ],
      customchk: true,
      toggleSwitch: true,
      // page states
      name: "",
      email: "",
      mobile: "",
      password: "",
      data: [],
      alert: false,
      message: "",
      type: "",
      result: [],
      edit_enable: false,
      edit_item_id: "",
      image_select: false,
      img_url: "",
      userId: "",
      userDetails: {},
      default_date: new Date(),
      default_end_date: new Date(),
      end_date: "",
      details: "",
      host: "",
      price: "",
      total_tables: "",
      vanue: "",
      latitude: "",
      longitude: "",
      image_select: false,
      img_url: "",
      start_time: "",
      start_time: new Date(),
      end_time: "",
      end_time: new Date(),
      selectedFiles: [],
      images: [],
      uploaded_images: [],

      num_of_floor: "",
      board_per_floor: "",
      pay_type: "",

      own_event: true,
      event_url: "",

      currentDate: new Date().toISOString().slice(0, 10),
      // tomorrowDate: "",
    };
    this.handleDefault = this.handleDefault.bind(this);
    // this.handleDefaultEnd = this.handleDefaultEnd.bind(this);
  }

  componentDidMount = async () => {
    this.checkUser();
    this.componentDate();
  };

  componentDate = async () => {
    const tomorrow = new Date(this.state.currentDate)
    tomorrow.setDate(tomorrow.getDate() + 1)
    this.setState({ tomorrowDate: tomorrow.toISOString().slice(0, 10) })
 
  };
  handleDefault(date) {
    this.setState({ default_date: date });
  }

  // handleDefaultEnd(date) {
  //   this.setState({ default_end_date: date });
  // }

  handleStartTIme(time) {

  }

  fetchData = async () => {
    let result = await HttpClient.requestData(
      "admin/event/all-events",
      "POST",
      {
        add_by:
          this.state.userDetails.subadmin == true
            ? Number(this.state.userId)
            : "admin",
      }
    );
    if (result && result.status > 0) {
      let data = [];
      let i = 1;
      this.setState({ result: result.data });
      this.state.result.forEach((element, index) => {
        let rows = {
          sl: i,
          own_event: element.own_event ? "Yes" : "No",
          created_by: element.created_by ? element.created_by.name + " on " + moment(element.created_on).utcOffset(element.created_on).format('DD/MM/YYYY, HH:mm') : "" + " on " + moment(element.created_on).utcOffset(element.created_on).format('DD/MM/YYYY, HH:mm'),
          updated_by: element.updated_by ? element.updated_by.name + " on " + moment(element.updated_on).utcOffset(element.updated_on).format('DD/MM/YYYY, HH:mm') : "" + "",
          name: (
            <>
              <p>{element.name}</p>
              <br />
              {this.state.userDetails.subadmin == false ? (
                <i>
                  Add By :{" "}
                  <b style={{ color: "#3444c1" }}>
                    {element.addBy && element.addBy.subadmin
                      ? element.addBy.name
                      : "Admin"}
                  </b>
                </i>
              ) : null}
            </>
          ),
          timing:
            moment(element.start_date).format("LL") +
            " To " +
            moment(element.end_date).format("LL"),
          time:
            moment(element.start_time, "hh:mm").format("hh:mm a") +
            " To " +
            moment(element.end_time, "hh:mm").format("hh:mm a"),
          host: element.host,
          details: element.details,
          price: element.seat_price,
          total_tables: element.num_of_floor,
          eventJoiner: (
            <>
              <Link
                className="btn btn-danger mr-2"
                to={"/event-joiner/" + element.id}
              >
                View Joiners
              </Link>
            </>
          ),
          session: (
            <Link
              className="btn btn-danger mr-2"
              to={"/events-session/" + element.id}
            >
              Session
            </Link>
          ),
          sponsor: (
            <Link
              className="btn btn-danger mr-2"
              to={"/session-sponsors/" + element.id}
            >
              Sponsor
            </Link>
          ),
          status: (
            <button
              title="Delete"
              className={
                element.status ? "btn btn-success mr-2" : "btn btn-danger mr-2"
              }
              onClick={() => {
                this.status(element, index);
              }}
            >
              {element.status ? "Active" : "Not Active"}
            </button>
          ),
          action: (
            <>
            {this.state.userDetails.sub_admin == true ? null : (
              <button
                title="Delete"
                className="btn btn-danger mr-2"
                onClick={() => {
                  if (window.confirm('Are you sure you wish to delete this item?'))
                    this.delete(element, index)
                }}
              >
                <i className="fa fa-trash" />
              </button>
            )}
              <button
                title="Edit"
                className="btn btn-primary"
                onClick={() => {
                  this.edit(element, index);
                  window.scrollTo(0, 0);
                }}
              >
                <i className="fa fa-edit" />
              </button>
            </>
          ),
        };
        i++;
        data.push(rows);
      });
      this.setState({
        data: data,
      });
    } else {
      this.setState({
        data: [],
      });
    }
  };

  delete = async (item, index) => {
    let sendData = {
      id: item._id,
    };
    let result = await HttpClient.requestData(
      "admin/event/delete",
      "POST",
      sendData
    );
    if (result && result.status) {
      let index = this.state.result.indexOf(item);
      if (index > -1) {
        this.state.result.splice(index, 1);
        this.setState({
          alert: true,
          message: "Deleted Successfully",
          type: "success",
        });
        setTimeout(() => {
          this.setState({
            alert: false,
            message: "",
            type: "",
          });
        }, 3000);
        this.fetchData();
      }
    }
  };

  edit = async (item, index) => {
    this.setState({
      edit_enable: true,
      name: item.name,
      edit_item_id: item._id,
      timing: moment(item.timing).format("dd/mm/YYYY"),
      // default_date: new Date(item.start_date),
      // default_end_date: new Date(item.end_date),
      host: item.host,
      details: item.details,
      start_time: item.start_time,
      end_time: item.end_time,
      vanue: item.vanue,
      uploaded_images: item.images ? item.images : [],
      price: item.seat_price,
      // total_tables: item.total_tables,
      pay_type: item.pay_type,
      board_per_floor: item.board_per_floor,
      num_of_floor: item.num_of_floor,
      own_event: typeof item.own_event == "undefined" ? true : item.own_event,
      event_url: typeof item.event_url == "undefined" ? "" : item.event_url,
      default_end_date: item.default_end_date,
      default_date: item.default_date,
    });
    window.scrollTo(0, 0);
  };

  status = async (item, index) => {
    let sendData = {
      id: item._id,
      status: !item.status,
    };
    let result = await HttpClient.requestData(
      "admin/event/status",
      "POST",
      sendData
    );
    if (result && result.status) {
      this.state.result.splice(index, 1);
      this.setState({
        alert: true,
        message: "Status Updated Successfully",
        type: "success",
      });
      setTimeout(() => {
        this.setState({
          alert: false,
          message: "",
          type: "",
        });
      }, 3000);
      this.fetchData();
    }
  };

  submit = async () => {
    if (this.state.uploaded_images.length == 0) {
      this.setState({
        alert: true,
        message: "Please Upload Images.",
        type: "warning",
      });
      return;
    }
    if (
      this.state.name != "" &&
      this.state.host != "" &&
      this.state.vanue != ""
    ) {
      if (moment(this.state.default_date).format("YYYY/DD/MM")
        <= moment(this.state.default_end_date).format("YYYY/DD/MM")) {
        if (this.state.start_time, "hh:mm:ss" < this.state.end_time, "hh:mm:ss") {
          let data = null;
          let result = null;

          if (this.state.edit_enable == false) {
            data = {
              name: this.state.name,
              start_date: this.state.default_date,
              end_date: this.state.default_end_date,
              // end_date: this.state.end_date,
              seat_price: this.state.price.length > 0 ? this.state.price  : 0,
              details: this.state.details,
              host: this.state.host,
              start_time: this.state.start_time,
              end_time: this.state.end_time,
              vanue: this.state.vanue,
              images: this.state.uploaded_images,
              pay_type: this.state.pay_type.length > 0 ? this.state.pay_type : "free",
              board_per_floor: this.state.board_per_floor,
              num_of_floor: this.state.num_of_floor,
              own_event: this.state.own_event,
              event_url: this.state.event_url,
              add_by: Number(this.state.userId),
              status: this.state.userDetails.subadmin ? false : true,
              default_end_date: this.state.default_end_date,
              default_date: this.state.default_date,
              created_by: this.state.userId,
            };

            result = await HttpClient.requestData("admin/event", "POST", data);
          } else {
            data = {
              name: this.state.name,
              start_date: this.state.default_date,
              end_date: this.state.default_end_date,
              // end_date: this.state.end_date,
              details: this.state.details,
              host: this.state.host,
              start_time: this.state.start_time,
              end_time: this.state.end_time,
              // total_tables: this.state.total_tables,
              vanue: this.state.vanue,
              images: this.state.uploaded_images,
              edit_id: this.state.edit_item_id,
              own_event: this.state.own_event,
              event_url: this.state.event_url,
              default_end_date: this.state.default_end_date,
              default_date: this.state.default_date,
              updated_by: this.state.userId,
            };
            if (!this.state.own_event) {
              data.event_url = this.state.event_url;
            }
            else{
              data.event_url = null;
            }
            if (this.state.own_event) {
              data.board_per_floor = this.state.board_per_floor;
              data.num_of_floor = this.state.num_of_floor;
              data.pay_type = this.state.pay_type;
              data.seat_price = this.state.price;
              
            } else {
              data.board_per_floor = null;
              data.num_of_floor = null;
              data.pay_type = null;
              data.seat_price = null;
            }
            result = await HttpClient.requestData(
              "admin/event/update",
              "POST",
              data
            );
            console.log("datetime", data);
          }
          if (result && result.status) {
            this.setState({
              alert: true,
              message: this.state.edit_enable
                ? "Event Updated Successfully"
                : "Event Added Successfully",
              type: "success",
              name: "",
              img_url: "",
              image_select: false,
              password: "",
              edit_enable: false,
              edit_item_id: "",
              default_date: new Date(),
              // default_end_date: new Date(),
              default_end_date: "",
              price: "",
              details: "",
              host: "",
              event_url: "",
              start_time: "",
              start_time: new Date(),
              end_time: "",
              end_time: new Date(),
              end_date: "",
              vanue: "",
              pay_type: "",
              uploaded_images: [],
              num_of_floor: "",
              board_per_floor: "",
            });

            this.fetchData();
          } else {
            this.setState({
              alert: true,
              message:
                typeof result.error == "string"
                  ? result.error
                  : result.error[0].msg,
              type: "danger",
            });
          }
        } else {
          this.setState({
            alert: true,
            message: "End time must be greater than start time.",
            type: "warning",
          });
        }
      } else {
        this.setState({
          alert: true,
          message: "End date must be equal or greater than start date.",
          type: "warning",
        });
      }
    } else {
      this.setState({
        alert: true,
        message: "Please Fill Up All Details.",
        type: "warning",
      });
    }
    setTimeout(() => {
      this.setState({
        alert: false,
        message: "",
        type: "",
      });
    }, 3000);
  };

  imageUpload = async (file, index) => {
    // console.log("e", file);

    let dataSend = { buckate: false };

    let result = await HttpClient.newFileUpload(
      "image-upload/event",
      file,
      dataSend
    );
    console.log("result", result);
    if (result && result.status) {
      this.state.uploaded_images.push({ preview: result.url });
      if (this.state.images.length - 1 == index) {
        setTimeout(() => {
          this.setState({ loading: false });
          console.log("this.state.uploaded_images", this.state.uploaded_images);
        }, 200);
      }
      this.setState({});
    }
  };

  handleAcceptedFiles = (files) => {
    let data = [];
    let file = {
      preview: URL.createObjectURL(files[0]),
      formattedSize: this.formatBytes(files[0].size),
      // image:files,
    };
    let file2 = {
      image: files,
    };

    let file3 = files;
    console.log("data", file);
    this.state.selectedFiles.push(file);
    this.state.images.push(files[0]);
    this.imageUpload(files[0]);
    this.setState({});
  };

  async remove_image(item, index) {
    this.state.selectedFiles.pop(index);
    this.state.images.pop(index);
    this.state.uploaded_images.pop(index);
    this.setState({});
  }

  /**
   * Formats the size
   */
  formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  checkUser = async () => {
    let user = await reactLocalStorage.getObject("userData");
    if (user != null && Object.keys(user).length > 0) {
      this.setState({
        userId: user.id,
        userDetails: user,
      });
    } else {
    }
    this.fetchData();
  };

  render() {
    const data = {
      columns: [
        {
          label: "Sl.",
          field: "sl",
          sort: "asc",
          width: 150,
        },
        {
          label: "Twiller Porties Event",
          field: "own_event",
          sort: "asc",
          width: 270,
        },
        {
          label: "Name",
          field: "name",
          sort: "asc",
          width: 270,
        },
        {
          label: "Seat Price",
          field: "price",
          sort: "asc",
          width: 270,
        },
        {
          label: "Total floor",
          field: "total_tables",
          sort: "asc",
          width: 270,
        },
        {
          label: "Created By",
          field: "created_by",
          sort: "asc",
          width: 270,
        },
        {
          label: "Updated By",
          field: "updated_by",
          sort: "asc",
          width: 270,
        },
        // timing
        // link
        // details
        // price
        {
          label: "Date",
          field: "timing",
          sort: "asc",
          width: 270,
        },
        {
          label: "Host",
          field: "host",
          sort: "asc",
          width: 270,
        },
        
        {
          label: "Session",
          field: "session",
          sort: "asc",
          width: 100,
        },
        {
          label: "Sponsor",
          field: "sponsor",
          sort: "asc",
          width: 100,
        },
        {
          label: "Status",
          field: "status",
          sort: "asc",
          width: 100,
        },
        {
          label: "Event Joiner",
          field: "eventJoiner",
          sort: "asc",
          width: 100,
        },
        {
          label: "Action",
          field: "action",
          sort: "asc",
          width: 100,
        },
      ],
      rows: this.state.data,
    };
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title={this.state.breadcrumbItems[0].title}
              breadcrumbItems={this.state.breadcrumbItems}
            />

            <Row>
              <Col xs={12}>
                <Card>
                  <CardBody>
                    {this.state.alert ? (
                      <UncontrolledAlert
                        color={this.state.type}
                        className="alert-dismissible fade show"
                        role="alert"
                      >
                        {this.state.type == "warning" ? (
                          <i className="mdi mdi-alert-outline mr-2"></i>
                        ) : this.state.type == "success" ? (
                          <i className="mdi mdi-check-all mr-2"></i>
                        ) : this.state.type == "danger" ? (
                          <i className="mdi mdi-block-helper mr-2"></i>
                        ) : null}
                        {this.state.message}
                      </UncontrolledAlert>
                    ) : null}
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Twiller Porties Event
                      </Label>
                      <Col md={10}>
                        <select
                          className="form-control"
                          onChange={(val) => {
                            if (val.target.value == "No") {
                              this.setState({ own_event: false });
                            } else {
                              this.setState({ own_event: true });
                            }
                          }}
                          value={this.state.own_event ? "Yes" : "No"}
                        >
                          <option value="" selected disabled>
                            Select One
                          </option>
                          <option value="Yes">Yes</option>
                          <option value="No">No</option>
                        </select>
                      </Col>
                    </FormGroup>
                    {this.state.own_event == false && (
                      <FormGroup row>
                        <Label
                          htmlFor="example-text-input"
                          className="col-md-2 col-form-label"
                        >
                          Event Url
                        </Label>
                        <Col md={10}>
                          <Input
                            className="form-control"
                            type="text"
                            value={this.state.event_url}
                            id="example-text-input"
                            onChange={(val) => {
                              this.setState({ event_url: val.target.value });
                            }}
                          />
                        </Col>
                      </FormGroup>
                    )}
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Event Name<span style={{ color: 'red' }}>*</span>
                      </Label>
                      <Col md={10}>
                        <Input
                          className="form-control"
                          type="text"
                          value={this.state.name}
                          id="example-text-input"
                          onChange={(val) => {
                            this.setState({ name: val.target.value });
                          }}
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Start Date<span style={{ color: 'red' }}>*</span>
                      </Label>
                      <Col md={4}>
                        <InputGroup>
                          <Input
                            className="form-control"
                            type="date"
                            min={this.state.currentDate}
                            value={this.state.default_date}
                            id="example-text-input"
                            onChange={(val) => {
                              this.setState({ default_date: val.target.value });
                            }}
                          />
                          {/* <DatePicker
                            className="form-control"
                            selected={this.state.default_date}
                            onChange={this.handleDefault}
                          /> */}
                        </InputGroup>
                      </Col>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        End Date<span style={{ color: 'red' }}>*</span>
                      </Label>
                      <Col md={4}>
                        <InputGroup>
                          <Input
                            className="form-control"
                            type="date"
                            min={this.state.currentDate}
                            value={this.state.default_end_date}
                            id="example-text-input"
                            onChange={(val) => {
                              this.setState({ default_end_date: val.target.value });
                            }}
                          />
                          {/* <DatePicker
                            className="form-control"
                            type="date"
                            min={this.state.currentDate}
                            value={this.state.default_end_date}
                            id="example-text-input"
                            onChange={(val) => {
                              this.setState({ default_end_date: val.target.value });
                            }}
                            // selected={this.state.default_end_date}
                            // onChange={this.handleDefaultEnd}
                          /> */}
                          {/* {this.state.default_end_date.toDateString()} */}
                        </InputGroup>
                      </Col>
                    </FormGroup>

                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Start Time<span style={{ color: 'red' }}>*</span>
                      </Label>
                      <Col md={4}>
                        <input
                          type="time"
                          value={this.state.start_time}
                          className="form-control"
                          onChange={(val) => {
                            // console.log(val.target.value);
                            this.setState({ start_time: val.target.value });
                          }}
                        />
                      </Col>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        End Time<span style={{ color: 'red' }}>*</span>
                      </Label>
                      <Col md={4}>
                        <input
                          type="time"
                          value={this.state.end_time}
                          className="form-control"
                          onChange={(val) => {
                            console.log(val.target.value);
                            this.setState({ end_time: val.target.value });
                          }}
                        />
                      </Col>
                    </FormGroup>

                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Details
                      </Label>
                      <Col md={10}>
                        <Input
                          className="form-control"
                          type="textarea"
                          value={this.state.details}
                          id="example-text-input"
                          onChange={(val) => {
                            this.setState({ details: val.target.value });
                          }}
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Hosted By<span style={{ color: 'red' }}>*</span>
                      </Label>
                      <Col md={10}>
                        <Input
                          className="form-control"
                          type="text"
                          value={this.state.host}
                          id="example-text-input"
                          onChange={(val) => {
                            this.setState({ host: val.target.value });
                          }}
                        />
                      </Col>
                    </FormGroup>

                    {this.state.own_event == false ? null : (
                      <>
                        <FormGroup row>
                          <Label
                            htmlFor="example-text-input"
                            className="col-md-2 col-form-label"
                          >
                            Event Type
                          </Label>
                          <Col md={4}>
                            <select
                              className="form-control"
                              value={this.state.pay_type}
                              onChange={(val) => {
                                this.setState({ pay_type: val.target.value });
                                if (val.target.value == "Free") {
                                  this.setState({ price: "0" });
                                }
                              }}
                             
                            >
                              {/* {this.state.pay_type.map((item,index){})} */}
                              <option value="">Select Type</option>
                              <option value="Free">Free</option>
                              <option value="Paid">Paid</option>
                            </select>
                          </Col>
                          <Label
                            htmlFor="example-text-input"
                            className="col-md-2 col-form-label"
                          >
                            Seat Price(in USD)
                          </Label>
                          <Col md={4}>
                            <Input
                              className="form-control"
                              type="number"
                              value={this.state.price}
                              min={0}
                              id="example-text-input"
                              onChange={(val) => {
                                this.setState({ price: val.target.value });
                              }}
                              readOnly={
                                this.state.pay_type == "" ||
                                  this.state.pay_type == "Free"
                                  ? true
                                  : false
                              }
                            />
                          </Col>
                        </FormGroup>

                        <FormGroup row>
                          <Label
                            htmlFor="example-text-input"
                            className="col-md-2 col-form-label"
                          >
                            Number Of Floors
                          </Label>
                          <Col md={4}>
                            <Input
                              className="form-control"
                              type="number"
                              value={this.state.num_of_floor}
                              min={0}
                              id="example-text-input"
                              onChange={(val) => {
                                this.setState({ num_of_floor: val.target.value });
                              }}
                            />
                          </Col>

                          <Label
                            htmlFor="example-text-input"
                            className="col-md-2 col-form-label"
                          >
                            Seats Per Floor
                          </Label>
                          <Col md={4}>
                            <Input
                              className="form-control"
                              type="number"
                              value={this.state.board_per_floor}
                              min={0}
                              id="example-text-input"
                              onChange={(val) => {
                                this.setState({
                                  board_per_floor: val.target.value,
                                });
                              }}
                            />
                          </Col>
                        </FormGroup>

                      </>
                    )}
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Venue<span style={{ color: 'red' }}>*</span>
                      </Label>
                      <Col md={10}>
                        <Input
                          className="form-control"
                          type="text"
                          value={this.state.vanue}
                          id="example-text-input"
                          onChange={(val) => {
                            this.setState({ vanue: val.target.value });
                          }}
                        />
                      </Col>
                    </FormGroup>

                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Images<span style={{ color: 'red' }}>*</span>
                      </Label>
                      <Col md={10}>
                        <Dropzone
                          accept=".jpeg,.jpg,.png,.svg,.webp,.jfif,.apng"
                          onDrop={(acceptedFiles) =>
                            this.handleAcceptedFiles(acceptedFiles)
                          }
                        >
                          {({ getRootProps, getInputProps }) => (
                            <div className="dropzone">
                              <div
                                className="dz-message needsclick mt-2"
                                {...getRootProps()}
                              >
                                <input {...getInputProps()} />
                                <div className="mb-3">
                                  <i className="display-4 text-muted ri-upload-cloud-2-line"></i>
                                </div>
                                <h4>Drop files here or click to upload.</h4>
                              </div>

                            </div>
                          )}
                        </Dropzone>
                        <p style={{ color: 'red' }}>[image should be in dimensions 1280 * 960 px]</p>
                        <div
                          className="dropzone-previews mt-3"
                          id="file-previews"
                        >
                          {this.state.uploaded_images.map((f, i) => {
                            return (
                              <Card
                                className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                key={i + "-file"}
                              >
                                <div className="p-2">
                                  <Row className="align-items-center">
                                    <Col className="col-auto">
                                      <img
                                        style={{ height: 120, width: 100 }}
                                        data-dz-thumbnail=""
                                        height="100"
                                        className="avatar-sm rounded bg-light"
                                        // alt={f.name}
                                        src={f.preview}
                                      />
                                    </Col>
                                    <Col>
                                      <Button
                                        className="btn-icon"
                                        color="danger"
                                        style={{ marginRight: 25 }}
                                        onClick={(e) => this.remove_image(f, i)}
                                      >
                                        {" "}
                                        <span className="btn-icon-label">
                                          <i className="mdi mdi-alpha-x-box-outline mr-2"></i>
                                        </span>{" "}
                                        Remove
                                      </Button>
                                    </Col>
                                  </Row>
                                </div>
                              </Card>
                            );
                          })}
                        </div>
                      </Col>
                    </FormGroup>
                    <p style={{ marginTop: 5, textAlign: "right" }}>
                      [Note: Asterisk (<span style={{ color: 'red' }}>*</span>) denotes the mandatory field.]
                    </p>
                    <FormGroup className="mb-0">
                      <div className="button-items pt-4">
                        <Button
                          color="primary"
                          type="button"
                          className="waves-effect waves-light mr-1"
                          onClick={() => {
                            this.submit();
                          }}
                        >
                          {this.state.edit_enable ? "Update" : "Submit"}{" "}
                          <i className="ri-arrow-right-line align-middle ml-1"></i>
                        </Button>
                      </div>
                    </FormGroup>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col xl={12}>
                <div className="card p-3">
                  <div class="d-flex justify-content-between w-100">
                   <h5>EVENTS</h5>
                   {this.state.userDetails.sub_admin == true ? null : (
                    <Link
                      className="btn btn-danger mr-2"
                      to={"/eventUndo/"}
                    >
                      Deleted Events
                    </Link>
                   )}
                  </div>
                  <MDBDataTable responsive bordered data={data} />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default FormElements;
